import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import './ActionDataCell.scss';

const ActionDataCell = ({ value }) => {
  const [show, setShow] = useState(false);
  const divRef = useRef(null);

  const handleClickOutside = (e) => {
    if (divRef.current && !divRef.current.contains(e.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const renderResult = useCallback(() => {
    const jsonValue = value ? JSON.stringify(value.changes || value.added, undefined, 2) : '';

    const splitValue = jsonValue.split('\n');

    if (splitValue.length > 5) {
      return (
        <>
          <div>
            {splitValue.slice(0, 4).join('\n')}
          </div>
          <div onClick={() => setShow(state => !state)} className="action-data-box-button">show more</div>

          {show && (
            <div className="action-data-box-popup">
              {jsonValue}
            </div>
          )}
        </>
      );
    }

    return (
      <>
        {jsonValue}
      </>
    );
  }, [value, show]);

  return (
    <div ref={divRef} className="timeline-log-action-data-box">
      {renderResult()}
    </div>
  );
};

ActionDataCell.propTypes = {
  value: PropTypes.any
};

export default ActionDataCell;
