import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import './WizardConfirmation.scss';

const WizardConfirmation = ({
  onSubmit,
  data,
  title,
  action,
  withoutDispatch
}) => {
  const [opened, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleOpen = (e) => {
    e.preventDefault();

    if (opened) {
      return;
    }

    setOpen(true);
  };

  const handleCancel = (e) => {
    e.preventDefault();

    setOpen(false);
  };

  return (
    <OverlayTrigger
      placement="auto"
      trigger="focus"
      overlay={
        <Popover id={JSON.stringify(data)}>
          <div className="confirm-popover-container">
            <p className="title">{title}</p>
            <div className="w-c-m-btn btn-container">
              <Button
                variant="secondary"
                className="btn btn-cancel"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                className="w-c-m-btn btn btn-confirm"
                onClick={(e) => {
                  e.preventDefault();

                  if (withoutDispatch) {
                    onSubmit(data);
                  } else {
                    onSubmit(data)(dispatch);
                  }
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Popover>
      }
    >
      {action(handleOpen)}
    </OverlayTrigger>
  );
};

WizardConfirmation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  withoutDispatch: PropTypes.bool
};

export default WizardConfirmation;
