import { PARTNER_WIZARD } from '../../constants';
import { validateFormData } from '../shared';
import { validateAuthData, validateAuthDataUpdate } from './auth';
import { validateContactData } from './contact';

const partnerForms = [
  {
    validator: validateContactData
  },
  {
    validator: validateAuthData
  }
];

export const validatePartnerFormData = (toForm, exclude) => validateFormData(PARTNER_WIZARD, [...partnerForms], toForm, exclude);

const partnerUpdateForms = [
  {
    validator: validateContactData
  },
  {
    validator: validateAuthDataUpdate
  }
];

export const validatePartnerUpdateFormData = (toForm, exclude) => validateFormData(PARTNER_WIZARD, [...partnerUpdateForms], toForm, exclude);
