// AUTH
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

// Notifications
export const NOTIFICATIONS_FETCH_NOTIFICATIONS = 'fetch_notifications';
export const NOTIFICATIONS_MARK_NOTIFICATION_AS_READ = 'mark_notification_as_read';
export const NOTIFICATIONS_MARK_NOTIFICATION_AS_UNREAD = 'mark_notification_as_unread';
export const NOTIFICATIONS_MARK_ALL_NOTIFICATIONS_AS_READ = 'mark_all_notifications_as_read';
