import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import './TableBody.scss';

const TableBody = ({
  page,
  prepareRow,
  getTableBodyProps
}) => {
  return (
    <tbody className="table-body" {...getTableBodyProps()}>
      {page.map((row, index) => {
        prepareRow(row);

        return (
          <tr key={index} {...row.getRowProps()}>
            {row.cells.map((cell, index) => (
              <td key={index} {...cell.getCellProps()}>
                {cell.column.id === 'createdAt'
                  ? moment(cell.render('Cell')).format('DD/MM/YYYY')
                  : cell.render('Cell')}
              </td>
            ))}
          </tr>
        );
      })}
    </tbody>
  );
};

TableBody.propTypes = {
  page: PropTypes.array.isRequired,
  prepareRow: PropTypes.func.isRequired,
  getTableBodyProps: PropTypes.func.isRequired
};

export default TableBody;
