import * as yup from 'yup';

import {
  PARTNER_ADDRESS_FIELD,
  PARTNER_BUSINESS_FIELD,
  PARTNER_COMMENTS_FIELD,
  PARTNER_EMAIL_FIELD,
  PARTNER_TYPE_FIELD,
  TAGS_FIELD
} from '../../constants';

import { multiSelectSchema, selectSchema } from '../shared/shared-schemas';

export const contactSchema = yup.object().shape({
  [PARTNER_BUSINESS_FIELD]: selectSchema.required('Business type is required').nullable(),
  [PARTNER_EMAIL_FIELD]: yup.string().email('Invalid email address').required('Email is required'),
  [TAGS_FIELD]: multiSelectSchema,
  [PARTNER_TYPE_FIELD]: selectSchema.required('Source type is required').nullable(),
  [PARTNER_ADDRESS_FIELD]: yup.string(),
  [PARTNER_COMMENTS_FIELD]: yup.string()
});

export const validateContactData = (data) => {
  try {
    contactSchema.validateSync(data);

    return true;
  } catch (e) {
    return false;
  }
};
