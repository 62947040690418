import PropTypes from 'prop-types';
import React from 'react';

const WizardCheckboxInput = ({
  name,
  label,
  propRef,
  subLabel,
  className,
  handleChange
}) => {
  const handleOnChange = (e) => {
    if (handleChange) {
      handleChange(name, e.target.checked);
    }
  };

  return (
    <div className="form-group">
      <div className={`switch-list ${className}`}>
        <label>
          <input
            name={name}
            ref={propRef}
            type="checkbox"
            onChange={handleOnChange}
          />
          <span>{label} {subLabel ? <em>{subLabel}</em> : ''}</span>
        </label>
      </div>
    </div>
  );
};

WizardCheckboxInput.propTypes = {
  label: PropTypes.string.isRequired,
  subLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  propRef: PropTypes.func.isRequired,
  className: PropTypes.string,
  handleChange: PropTypes.func
};

export default WizardCheckboxInput;
