import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

import Popover from './Popover';

import '../../wizard-base/WizardSharedStyles.scss';

const WizardSelect = (props) => {
  const {
    label,
    error,
    errorMessage,
    placeholder,
    isMulti,
    options,
    control,
    name,
    rules,
    onChange,
    defaultValue
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  const onMenuClose = () => setIsFocused(false);
  const onMenuOpen = () => setIsFocused(true);

  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (value, { action }) => {
    if (action === 'input-change') {
      setInputValue(value);
    }
  };

  return (
    <div className="form-group">
      {label ? <label>{label}</label> : null}
      <div className="wizard-input-container">
        <Popover
          title={errorMessage || ' '}
          open={error && isFocused}
        >
          <Controller
            rules={rules || {}}
            control={control}
            name={name}
            placeholder={placeholder || ''}
            className={`the-field selectable ${error ? 'error' : ''}`}
            classNamePrefix='react-select'
            onMenuClose={onMenuClose}
            onMenuOpen={onMenuOpen}
            onChange={onChange}
            isMulti={isMulti}
            closeMenuOnSelect={!isMulti}
            as={<Select
              isMulti={isMulti}
              options={options}
              menuPlacement="auto"
              isClearable={true}
              isSearchable={true}
              onSelectResetsInput={false}
              inputValue={inputValue}
              onInputChange={handleInputChange}
            />}
            defaultValue={defaultValue}
          />
        </Popover>

        {(error && !isFocused) && <FontAwesomeIcon className="field-error-icon" icon={faExclamationCircle}/>}
      </div>
    </div>
  );
};

WizardSelect.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  error: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool.isRequired,
  options: PropTypes.array.isRequired,
  rules: PropTypes.object,
  onChange: PropTypes.func,
  defaultValue: PropTypes.any
};

export default WizardSelect;
