import React from 'react';

import { ID_FIELD, PARTNER_NAME_FIELD, TITLE_FIELD } from '../../wizards-manager/constants';

import './CellStyles.scss';

export const titleColumn = (fn, partner = false) => (data) => {
  const record = data.row.original;
  const id = record[ID_FIELD];
  const title = record[partner ? PARTNER_NAME_FIELD : TITLE_FIELD];

  return (
    <a onClick={() => fn(id)} className="title-cell-box">
      <div>{title}</div>
      <div className="title-cell-id">{id}</div>
    </a>
  );
};
