import PropTypes from 'prop-types';
import React, { useState } from 'react';

import './WizardCustomCheckbox.scss';

const WizardCustomCheckbox = ({
  label,
  defaultValue,
  changeChecked,
  name,
  propRef
}) => {
  const [isChecked, setIsChecked] = useState(defaultValue);

  const handleChangeChecked = () => {
    changeChecked(name, !isChecked);
    setIsChecked(!isChecked);
  };

  return (
    <div className="form-group wizard-custom-checkbox">
      <div className="mb5 gray">
        <label className="w-c-checkbox-label">{label}&nbsp;(
          <div className="w-c-in-ex-checkbox" onClick={handleChangeChecked}>
            {isChecked ? (
              <span className="include-exclude-check checked">include</span>
            ) : (
              <span className="include-exclude-check">exclude</span>
            )}
          </div>)
        </label>
        <input
          name={name}
          type="checkbox"
          ref={propRef}
          style={{ display: 'none' }}
        />
      </div>
    </div>
  );
};

WizardCustomCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  changeChecked: PropTypes.func.isRequired,
  propRef: PropTypes.func.isRequired
};

export default WizardCustomCheckbox;
