export const businessTypes = [
  {
    label: 'Individual',
    value: 'individual'
  },
  {
    label: 'Company',
    value: 'company'
  }
];

export const sourceTypes = [
  {
    label: 'Traffic Source',
    value: 'isBuy'
  },
  {
    label: 'Demand Source',
    value: 'isSell'
  },
  {
    label: 'Traffic/Demand Source',
    value: 'both'
  }
];
