import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  deleteEndpointAction,
  duplicateEndpointAction,
  editEndpointAction,
  editEndpointFromTableAction,
  fetchEndpointsAction,
  updateEndpointStatus
} from '../../../../actions/endpoint';

import { editPartnerFromNotificationsAction } from '../../../../actions/partners';

import {
  ACTIVE_FIELD,
  ENDPOINT_WIZARD,
  PARTNER_FIELD,
  TAGS_FIELD,
  SOURCE_FIELD,
  ID_FIELD,
  CREATED_AT_FIELD,
  PARTNER_ID_FIELD
} from '../../../wizards-manager/constants';

import { useTablesEffect } from '../../custom-hooks/useTablesEffect';

import { titleColumn } from '../../helpers';
import TablePanelSpinner from '../../shared/TablePanelSpinner';
import TableSelectFilter from '../../shared/TableSelectFilter';
import TableTagsCell from '../../shared/TableTagsCell';
import TableColumnsActions from '../../table-base/TableColumnsActions';
import TablesManager from '../../TablesManager';
import EndpointsDemandFeeds from './EndpointsDemandFeeds';

import '../../table-base/TableColumnsActions.scss';

const getEndpointColumns = (dispatch) => [
  {
    Header: 'Endpoint',
    accessor: ID_FIELD,
    width: 140,
    Cell: titleColumn(editEndpointFromTableAction)
  },
  {
    Header: 'Active',
    accessor: ACTIVE_FIELD,
    width: 70,
    Filter: TableSelectFilter,
    filter: 'equals',
    Cell: (data) => {
      const endpoint = data.row.original;

      const handleActivating = async (id, active) => {
        await updateEndpointStatus(id, active)(dispatch);
      };

      return (
        <div className="switch-list">
          <label>
            <input
              type="checkbox"
              onChange={e => handleActivating(endpoint._id, e.target.checked)}
              checked={endpoint[ACTIVE_FIELD] === 'Active'}
            />
          </label>
        </div>
      );
    }
  },
  {
    Header: 'Traffic Source',
    accessor: PARTNER_FIELD,
    minWidth: 110,
    Filter: TableSelectFilter,
    filter: 'equals',
    Cell: (data) => {
      const demand = data.row.original;
      const partnerID = demand[PARTNER_ID_FIELD];

      return (
        <a
          style={{
            textDecoration: 'dashed underline',
            cursor: 'pointer',
            width: 'max-content'
          }}
          onClick={() => editPartnerFromNotificationsAction(partnerID)}
        >
          {demand[PARTNER_FIELD]}
        </a>
      );
    }
  },
  {
    Header: 'Tags',
    accessor: TAGS_FIELD,
    minWidth: 110,
    Cell: TableTagsCell
  },
  {
    Header: 'Demand feeds',
    id: 'demand_feeds',
    width: 200,
    Cell: (data) => {
      const endpoint = data.row.original;

      return (
        <EndpointsDemandFeeds
          source='demand'
          id={endpoint._id}
          updatedAt={endpoint.updatedAt}
        />
      );
    }
  },
  {
    Header: 'Assigned To',
    accessor: SOURCE_FIELD,
    minWidth: 100,
    Filter: TableSelectFilter,
    filter: 'equals'
  },
  {
    Header: 'Created Date',
    id: 'createdDate',
    accessor: (d) => d[CREATED_AT_FIELD],
    Cell: ({ value }) => moment(value).format('DD/MM/YYYY')
  },
  {
    Header: 'Actions',
    id: 'action',
    width: 50,
    Cell: (data) => {
      const endpoint = data.row.original;

      return (
        <TableColumnsActions
          editItem={editEndpointAction}
          duplicateItem={duplicateEndpointAction}
          deleteItem={deleteEndpointAction}
          data={endpoint}
          endpoint={true}
        />
      );
    }
  },
  {
    Header: '',
    id: 'all',
    width: 0
  }
];

const EndpointsTable = () => {
  const {
    endpoints,
    loading
  } = useSelector(state => ({
    endpoints: state[ENDPOINT_WIZARD].endpoints,
    loading: state[ENDPOINT_WIZARD].loading
  }));

  const dispatch = useDispatch();

  useTablesEffect(fetchEndpointsAction, 'all');

  const searchFields = [ACTIVE_FIELD, PARTNER_FIELD, SOURCE_FIELD];
  const hiddenColumns = [SOURCE_FIELD, CREATED_AT_FIELD];
  const endpointColumns = getEndpointColumns(dispatch);

  return (
    <>
      {loading ? <TablePanelSpinner/>
        : <TablesManager
          searchFields={searchFields}
          tableName="Endpoints"
          fields={endpointColumns}
          items={endpoints}
          hiddenColumns={hiddenColumns}
        />
      }
    </>
  );
};

export default EndpointsTable;
