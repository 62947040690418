import PropTypes from 'prop-types';
import React from 'react';

import './SettingsSideBar.scss';
import {
  SETTINGS_MANUAL_REVENUE_TABLE,
  SETTINGS_REVENUE_SYNC_TABLE,
  SETTINGS_TIMELINE_LOG_TABLE
} from '../../actions/types';

const SettingsSideBar = ({
  tab,
  changeTab
}) => {
  return (
    <div className="settings-side-bar">
      <div className="settings-row no-cursor">
        <div className="settings-row-icon">
          <img
            src={require('../../assets/images/icons/settings.svg')}
            alt=""
          />
          <img
            className="img-active"
            src={require('../../assets/images/icons/settings.svg')}
            alt=""
          />
        </div>

        <div className="settings-row-title">Settings</div>
      </div>

      <div
        onClick={() => changeTab(SETTINGS_REVENUE_SYNC_TABLE)}
        className={`settings-row ${tab === SETTINGS_REVENUE_SYNC_TABLE ? 'active' : ''}`}
      >
        <div className="settings-row-icon">
          <img
            src={require('../../assets/images/icons/half_circle.svg')}
            alt=""
          />
          <img
            className="img-active"
            src={require('../../assets/images/icons/half_circle_o.svg')}
            alt=""
          />
        </div>

        <div className="settings-row-title">Revenue Sync Status</div>
      </div>

      <div
        onClick={() => changeTab(SETTINGS_MANUAL_REVENUE_TABLE)}
        className={`settings-row ${tab === SETTINGS_MANUAL_REVENUE_TABLE ? 'active' : ''}`}
      >
        <div className="settings-row-icon">
          <img
            src={require('../../assets/images/icons/half_circle.svg')}
            alt=""
          />
          <img
            className="img-active"
            src={require('../../assets/images/icons/half_circle_o.svg')}
            alt=""
          />
        </div>

        <div className="settings-row-title">Manual Revenue Update</div>
      </div>

      <div
        onClick={() => changeTab(SETTINGS_TIMELINE_LOG_TABLE)}
        className={`settings-row ${tab === SETTINGS_TIMELINE_LOG_TABLE ? 'active' : ''}`}
      >
        <div className="settings-row-icon">
          <img
            src={require('../../assets/images/icons/half_circle.svg')}
            alt=""
          />
          <img
            className="img-active"
            src={require('../../assets/images/icons/half_circle_o.svg')}
            alt=""
          />
        </div>

        <div className="settings-row-title">Timeline Log</div>
      </div>

    </div>
  );
};

SettingsSideBar.propTypes = {
  tab: PropTypes.string.isRequired,
  changeTab: PropTypes.func.isRequired
};

export default SettingsSideBar;
