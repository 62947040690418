import { editDemandFromTableAction } from '../../../../actions/demand';
import { editEndpointFromTableAction } from '../../../../actions/endpoint';
import { editPartnerFromNotificationsAction } from '../../../../actions/partners';

import {
  DASHBOARD_APPROVED_PAYOUTS,
  DASHBOARD_APPROVED_REVENUE,
  DASHBOARD_AVG_NET_BID,
  DASHBOARD_COUNTRIES_FIELD,
  DASHBOARD_DEMAND_FEEDS_FIELD,
  DASHBOARD_DEMAND_NAME_FIELD,
  DASHBOARD_DF_SOURCE_FIELD,
  DASHBOARD_DF_SOURCE_NAME_FIELD,
  DASHBOARD_E_CPM,
  DASHBOARD_ENDPOINT_ID_FIELD,
  DASHBOARD_ENDPOINT_NAME_FIELD,
  DASHBOARD_EP_SOURCE_FIELD,
  DASHBOARD_EP_SOURCE_NAME_FIELD,
  DASHBOARD_EST_PAYOUT,
  DASHBOARD_EST_REVENUE,
  DASHBOARD_INT_REQUESTS_AVG_GROSS_BID,
  DASHBOARD_INT_REQUESTS_AVG_NET_BID,
  DASHBOARD_INT_REQUESTS_BIDS,
  DASHBOARD_INT_REQUESTS_COUNT,
  DASHBOARD_INT_REQUESTS_COVERAGE,
  DASHBOARD_OS_FIELD,
  DASHBOARD_PLATFORM_FIELD,
  DASHBOARD_PROFIT,
  DASHBOARD_REQUESTS_AVG_GROSS_BID,
  DASHBOARD_REQUESTS_AVG_NET_BID,
  DASHBOARD_REQUESTS_BIDS,
  DASHBOARD_REQUESTS_COUNT,
  DASHBOARD_REQUESTS_COVERAGE,
  DASHBOARD_ROI
} from '../../../../actions/types';

import { formatCurrency, formatNumbers, formatPercentage } from '../../numeric-helpers';
import { transformToCapitalized } from '../../table/helpers/table-manager-helpers';
import { checkIsDemandFiltered } from './top-charts-helpers';

export const dimensionsOptions = {
  [DASHBOARD_ENDPOINT_ID_FIELD]: 'Endpoints',
  [DASHBOARD_DEMAND_FEEDS_FIELD]: 'Demands',
  [DASHBOARD_EP_SOURCE_FIELD]: 'Traffic Source',
  [DASHBOARD_DF_SOURCE_FIELD]: 'Demand Source',
  [DASHBOARD_COUNTRIES_FIELD]: 'Country',
  [DASHBOARD_OS_FIELD]: 'OS',
  [DASHBOARD_PLATFORM_FIELD]: 'Platform'
};

export const getDimensionsOptions = (isPartner) => {
  if (isPartner) {
    return {
      [DASHBOARD_ENDPOINT_ID_FIELD]: 'Endpoints',
      [DASHBOARD_COUNTRIES_FIELD]: 'Country',
      [DASHBOARD_OS_FIELD]: 'OS',
      [DASHBOARD_PLATFORM_FIELD]: 'Platform'
    };
  }

  return {
    [DASHBOARD_ENDPOINT_ID_FIELD]: 'Endpoints',
    [DASHBOARD_DEMAND_FEEDS_FIELD]: 'Demands',
    [DASHBOARD_EP_SOURCE_FIELD]: 'Traffic Source',
    [DASHBOARD_DF_SOURCE_FIELD]: 'Demand Source',
    [DASHBOARD_COUNTRIES_FIELD]: 'Country',
    [DASHBOARD_OS_FIELD]: 'OS',
    [DASHBOARD_PLATFORM_FIELD]: 'Platform'
  };
};

export const metricsOptions = {
  [DASHBOARD_REQUESTS_COUNT]: 'Requests',
  [DASHBOARD_REQUESTS_COVERAGE]: '%Req. Coverage',
  [DASHBOARD_APPROVED_REVENUE]: 'Revenues',
  [DASHBOARD_EST_REVENUE]: 'Est. Revenues',
  [DASHBOARD_APPROVED_PAYOUTS]: 'Earnings',
  [DASHBOARD_EST_PAYOUT]: 'Est. Earnings',
  [DASHBOARD_REQUESTS_AVG_GROSS_BID]: 'Gross CPM',
  [DASHBOARD_REQUESTS_AVG_NET_BID]: 'Net CPM',
  [DASHBOARD_AVG_NET_BID]: 'Win CPM',
  [DASHBOARD_E_CPM]: 'eCPM',
  [DASHBOARD_PROFIT]: 'Profit',
  [DASHBOARD_ROI]: 'Roi'
};

export const getMetricsOptions = (isPartner) => {
  if (isPartner) {
    return {
      [DASHBOARD_REQUESTS_COUNT]: 'Requests',
      [DASHBOARD_REQUESTS_COVERAGE]: '%Req. Coverage',
      [DASHBOARD_APPROVED_PAYOUTS]: 'Earnings',
      [DASHBOARD_EST_PAYOUT]: 'Est. Earnings',
      [DASHBOARD_AVG_NET_BID]: 'Win CPM'
    };
  }

  return {
    [DASHBOARD_REQUESTS_COUNT]: 'Requests',
    [DASHBOARD_REQUESTS_COVERAGE]: '%Req. Coverage',
    [DASHBOARD_APPROVED_REVENUE]: 'Revenues',
    [DASHBOARD_EST_REVENUE]: 'Est. Revenues',
    [DASHBOARD_REQUESTS_AVG_GROSS_BID]: 'Gross CPM',
    [DASHBOARD_REQUESTS_AVG_NET_BID]: 'Net CPM',
    [DASHBOARD_AVG_NET_BID]: 'Win CPM',
    [DASHBOARD_E_CPM]: 'eCPM',
    [DASHBOARD_PROFIT]: 'Profit',
    [DASHBOARD_ROI]: 'Roi'
  };
};

export const checkIsDemand = (dimension, filters) =>
  dimension === DASHBOARD_DEMAND_FEEDS_FIELD ||
  dimension === DASHBOARD_DF_SOURCE_FIELD ||
  checkIsDemandFiltered(filters[DASHBOARD_DEMAND_FEEDS_FIELD], filters[DASHBOARD_DF_SOURCE_FIELD]);

const getItemValue = (item, metrics, isDemand) => {
  switch (metrics) {
    case DASHBOARD_REQUESTS_COUNT:
      return item[isDemand ? DASHBOARD_INT_REQUESTS_COUNT : DASHBOARD_REQUESTS_COUNT];

    case DASHBOARD_REQUESTS_COVERAGE:
      return item[isDemand ? DASHBOARD_INT_REQUESTS_COVERAGE : DASHBOARD_REQUESTS_COVERAGE];

    case DASHBOARD_REQUESTS_AVG_GROSS_BID:
      return item[isDemand ? DASHBOARD_INT_REQUESTS_AVG_GROSS_BID : DASHBOARD_REQUESTS_AVG_GROSS_BID] * 1000;

    case DASHBOARD_REQUESTS_AVG_NET_BID:
      return item[isDemand ? DASHBOARD_INT_REQUESTS_AVG_NET_BID : DASHBOARD_REQUESTS_AVG_NET_BID] * 1000;

    case DASHBOARD_AVG_NET_BID:
      return item[DASHBOARD_AVG_NET_BID] * 1000;

    case DASHBOARD_E_CPM: {
      const approvedRevenue = item[DASHBOARD_APPROVED_REVENUE];
      const reqBids = item[isDemand ? DASHBOARD_INT_REQUESTS_BIDS : DASHBOARD_REQUESTS_BIDS];

      return reqBids > 0 ? 1000 * (approvedRevenue / reqBids) : 0;
    }

    default:
      return item[metrics];
  }
};

const getItemName = (item, dimension) => {
  switch (dimension) {
    case DASHBOARD_ENDPOINT_ID_FIELD:
      return `Endpoint ${item[DASHBOARD_ENDPOINT_NAME_FIELD]} (${item[DASHBOARD_ENDPOINT_ID_FIELD]})`;

    case DASHBOARD_DEMAND_FEEDS_FIELD:
      return `Demand ${item[DASHBOARD_DEMAND_NAME_FIELD]} (${item[DASHBOARD_DEMAND_FEEDS_FIELD]})`;

    case DASHBOARD_EP_SOURCE_FIELD:
      return `Traffic source ${item[DASHBOARD_EP_SOURCE_NAME_FIELD]} (${item[DASHBOARD_EP_SOURCE_FIELD]})`;

    case DASHBOARD_DF_SOURCE_FIELD:
      return `Demand source ${item[DASHBOARD_DF_SOURCE_NAME_FIELD]} (${item[DASHBOARD_DF_SOURCE_FIELD]})`;

    case DASHBOARD_COUNTRIES_FIELD:
      return `Country ${item[dimension]}`;

    case DASHBOARD_OS_FIELD:
      return `OS ${transformToCapitalized(item[dimension])}`;

    case DASHBOARD_PLATFORM_FIELD: {
      return `Platform ${transformToCapitalized(item[dimension])}`;
    }
  }
};

export const buildDonutLabels = (items, dimension) =>
  items.map((i) => getItemName(i, dimension));

export const buildDonutItems = (items, metrics, isDemand) =>
  items.map((i) => getItemValue(i, metrics, isDemand));

export const getDonutFormatter = (metric) => {
  switch (metric) {
    case DASHBOARD_REQUESTS_AVG_GROSS_BID:
    case DASHBOARD_REQUESTS_AVG_NET_BID:
    case DASHBOARD_AVG_NET_BID:
    case DASHBOARD_PROFIT:
    case DASHBOARD_APPROVED_REVENUE:
    case DASHBOARD_EST_REVENUE:
    case DASHBOARD_E_CPM:
    case DASHBOARD_APPROVED_PAYOUTS:
    case DASHBOARD_EST_PAYOUT:
      return formatCurrency;

    case DASHBOARD_REQUESTS_COVERAGE:
    case DASHBOARD_ROI:
      return formatPercentage;

    default:
      return formatNumbers;
  }
};

export const donutOptions = {
  noData: {
    text: 'No Data',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: '14px',
      fontFamily: undefined
    }
  },
  chart: {
    type: 'donut',
    events: {
      dataPointSelection: async (event, chartContext, config) => {
        const selectedIndex = config.dataPointIndex;
        const selectedArray = config.selectedDataPoints[0];

        if (!selectedArray.includes(selectedIndex)) {
          const label = config.w.config.labels[config.dataPointIndex];
          const regExp = /(?:\s\(.+\)?)?\s\((.+)\)$/g;

          const matched = regExp.exec(label);
          let id;

          if (matched) {
            id = matched[1];
          }

          if (label.includes('Endpoint ')) {
            if (id) {
              await editEndpointFromTableAction(id);
            }
          }

          if (label.includes('Demand ') && !label.includes('Demand source ')) {
            if (id) {
              await editDemandFromTableAction(id);
            }
          }

          if (label.includes('Traffic source ') || label.includes('Demand source ')) {
            if (id) {
              await editPartnerFromNotificationsAction(id);
            }
          }
        }
      }
    }
  },
  dataLabels: {
    enabled: false,
    enabledOnSeries: undefined,
    formatter: function (val) {
      return val + '%';
    },
    textAnchor: 'middle',
    offsetX: 0,
    offsetY: 10,
    style: {
      fontSize: '14px',
      fontFamily: 'Roboto, sans-serif'
    }
  },
  colors: ['#fc7b99', '#534fa0', '#e2759c', '#ce6f9a', '#7a549a', '#a9659e'],
  // background color
  fill: {
    opacity: 1,
    type: 'solid'
  },
  // color stroke
  stroke: {
    show: true,
    curve: ['smooth'],
    lineCap: 'butt',
    colors: ['#EFF4F8'],
    width: 3,
    dashArray: 0
  },
  plotOptions: {
    pie: {
      donut: {
        background: '#ffffff',
        size: '68%',
        position: 'middle',
        labels: {
          show: false
        }
      }
    }
  },

  // Note chart
  legend: {
    show: false,
    position: 'bottom',
    labels: {
      // color: undefined,
      useSeriesColors: false
    }
  },

  responsive: [{
    breakpoint: 480,
    options: {
      chart: {
        width: 250
      }
    }
  }],
  // Name of chart
  labels: [],
  tooltip: {
    style: {
      backgroundColor: 'green'
    }
  }
};
