import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import Select from 'react-select';

import { setDashboardOption } from '../../actions/dashboard';

const SelectDashboard = ({ setDashboardOption }) => {
  const filterDashboardShow = [
    {
      value: 'native',
      label: 'Native Bidder'
    },
    {
      value: 'rtb',
      label: 'RTB Bidder'
    }
  ];

  const { curDashboardOption } = useSelector(state => ({
    curDashboardOption: state.dashboard.curDashboardOption
  }));

  const [selectedOption, setSelectedOption] = useState({
    value: 'native',
    label: 'Native dashboard'
  });

  useEffect(() => {
    const filteredOne = filterDashboardShow.filter(
      item => item.value === curDashboardOption
    );

    setSelectedOption(filteredOne[0]);
  }, [curDashboardOption]);

  const handleChange = e => {
    if (e.value === curDashboardOption) return;
    setDashboardOption(e.value);
  };

  return (
    <div className="select-dashboard p-3">
      <Select
        className="react-select-container-default"
        classNamePrefix="react-select-default"
        defaultValue={filterDashboardShow[0]}
        value={selectedOption}
        onChange={handleChange}
        options={filterDashboardShow}
      />
    </div>
  );
};

SelectDashboard.propTypes = {
  setDashboardOption: PropTypes.func.isRequired
};

export default connect(null, {
  setDashboardOption
})(SelectDashboard);
