import {
  DRAWER_FORM_CLOSE,
  DRAWER_FORM_OPEN,
  DRAWER_FORM_CREATE_PARTNER,
  ENDPOINT_GET_INITIAL_ENDPOINT_FORM_STATE,
  GET_INITIAL_PARTNER_FORM_STATE,
  DRAWER_FORM_CREATE_ENDPOINT,
  DEMAND_GET_INITIAL_DEMAND_FORM_STATE,
  DRAWER_FORM_CREATE_DEMAND
} from './types';

export const showForm = name => dispatch => {
  dispatch({ type: DRAWER_FORM_CLOSE });
  dispatch({ type: DRAWER_FORM_OPEN, payload: name });
};

export const hideCurrentForm = () => dispatch => {
  dispatch({ type: DRAWER_FORM_CLOSE });
};

export const showDrawerForm = (payload) => dispatch => {
  dispatch({ type: DRAWER_FORM_CLOSE });

  switch (payload) {
    case DRAWER_FORM_CREATE_PARTNER:
      dispatch({ type: GET_INITIAL_PARTNER_FORM_STATE });
      break;

    case DRAWER_FORM_CREATE_ENDPOINT:
      dispatch({ type: ENDPOINT_GET_INITIAL_ENDPOINT_FORM_STATE });
      break;

    case DRAWER_FORM_CREATE_DEMAND:
      dispatch({ type: DEMAND_GET_INITIAL_DEMAND_FORM_STATE });
      break;

    default:
      break;
  }

  dispatch({ type: DRAWER_FORM_OPEN, payload });
};
