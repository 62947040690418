import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import './TablePagination.scss';

const TablePagination = ({
  // How items per page
  pageSize,
  // Change items per page
  setPageSize,
  pageSizeOptions,
  // Current page
  pageIndex,
  // Max pages
  pageCount,
  itemsCount,

  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
  gotoPage
}) => {
  const filterVisiblePages = (pages, total) => pages.filter(p => p <= total);

  const getVisiblePages = (page, total) => {
    if (total < 7) {
      return filterVisiblePages([1, 2, 3, 4, 5, 6], total);
    }

    if (page % 5 >= 0 && page > 4 && page + 2 < total) {
      return [1, page - 1, page, page + 1, total];
    }

    if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
      return [1, total - 3, total - 2, total - 1, total];
    }

    return [1, 2, 3, 4, 5, total];
  };

  const [visiblePages, setVisiblePages] = useState([]);

  useEffect(() => {
    const pages = getVisiblePages(pageIndex, pageCount);
    const visiblePages = filterVisiblePages(pages, pageCount);

    setVisiblePages(visiblePages);
  }, [pageIndex, pageCount]);

  return (
    <div className="table-pagination">
      <div className="t-p-select">
        <select
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {pageSizeOptions.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      <div className="t-p-text">
        <span>records per page | Show {pageIndex + 1} to {pageCount} of {itemsCount} entries</span>
      </div>

      <div className="t-p-button-box">
        <button
          className="t-p-button"
          disabled={!canPreviousPage}
          onClick={() => previousPage()}
        >
          Previous
        </button>

        {visiblePages.map((page, index, array) => (
          <button
            key={index}
            className={`t-p-button other ${page - 1 === pageIndex ? 'active' : ''}`}
            onClick={() => gotoPage(page - 1)}
          >
            {array[index - 1] + 2 < page ? `...${page}` : page}
          </button>
        ))}

        <button
          className="t-p-button next"
          disabled={!canNextPage}
          onClick={() => nextPage()}
        >
          Next
        </button>
      </div>
    </div>
  );
};

TablePagination.propTypes = {
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  gotoPage: PropTypes.func.isRequired,
  pageSizeOptions: PropTypes.array.isRequired,
  itemsCount: PropTypes.number.isRequired,
  previousPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired
};

export default TablePagination;
