import { transformToCapitalized } from '../../dashboard/table/helpers/table-manager-helpers';

import {
    BID_MODIFY_FIELD,
    BROWSERS_FIELD,
    CARRIERS_FIELD,
    CLICK_LIMITS_FIELD,
    CTYPES_FIELD,
    CUSTOM_TAGS_FIELD,
    DEMAND_FIELDS_FIELD,
    DEMAND_INTEGRATION_FIELD,
    DEVICE_TYPES_FIELD,
    DMS_DB_FIELD,
    DOMAIN_FIELD,
    GEOS_FIELD,
    INCLUDE_FIELD,
    IP_FIELD,
    LIMITS_FIELD,
    LIST_FIELD,
    OS_FIELD,
    PARTNER_BUSINESS_FIELD,
    PARTNER_TYPE_FIELD,
    PROXIES_FIELD,
    REDIRECT_DOMAINS_FIELD,
    REV_SHARE_FIELD,
    SELECTED_FIELD,
    SUB_ID_DB_FIELD,
    SUB_ID_FIELD, SUBID_BID_MODIFY_FIELD,
    USER_AGENTS_FIELD,
    VALUE_FIELD,
    VERSIONS_FIELD
} from '../../wizards-manager/constants';

import { deviceTypesOptions, proxiesOptions } from '../../wizards-manager/helpers/staticData';
import { businessTypes, sourceTypes } from '../../wizards-manager/wizards/partner/partner-fake-data';

export const transformTagsToTableTags = (tags, tagOptions) => {
  const data = tags.reduce((acc, t) => {
    const data = tagOptions.find(o => o[VALUE_FIELD] === t);

    const tag = data ? data.label : t;

    acc.push(tag);
    return acc;
  }, []);

  return data.join(', ');
};

export const transformTagsToFormTags = (tags, tagOptions) =>
  tags.map((tag) => {
    const data = tagOptions.find(o => o[VALUE_FIELD] === tag);

    return data || {
      label: transformToCapitalized(tag),
      [VALUE_FIELD]: tag.toLowerCase()
    };
  });

export const transformListToFormData = (list, options) =>
  list.reduce((acc, item) => {
    const data = options.find(o => o[VALUE_FIELD] === item);

    acc.push(data || {
      label: transformToCapitalized(item),
      [VALUE_FIELD]: item.toLowerCase()
    });

    return acc;
  }, []);

export const transformOptimizationToFormData = (optimization, staticList) =>
  Object.keys(optimization)
    .reduce((acc, field) => {
      if ([BID_MODIFY_FIELD, LIMITS_FIELD, REV_SHARE_FIELD, CLICK_LIMITS_FIELD].includes(field)) {
        acc[field] = optimization[field]
          .map((item) => ({
            [VALUE_FIELD]: item[VALUE_FIELD],
            [GEOS_FIELD]: item[GEOS_FIELD].map((geo) => {
              const country = staticList.countryOptions.find(c => c[VALUE_FIELD] === geo);

              return country || {
                label: geo,
                value: geo
              };
            })
          }));

        return acc;
      }

        if ([SUBID_BID_MODIFY_FIELD].includes(field)) {
            if (!optimization[field]) optimization[field] = [];

            acc[field] = optimization[field]
                .map((item) => ({
                    [VALUE_FIELD]: item[VALUE_FIELD],
                    ['subids']: item['subids'].map((subid) => {
                        return {
                            label: subid,
                            value: subid
                        };
                    })
                }));

            return acc;
        }

      if (field === REDIRECT_DOMAINS_FIELD) {
        acc[field] = transformListToFormData(optimization[field], staticList.redirectDomainOptions);
        return acc;
      }

      if (field === DMS_DB_FIELD) {
        acc[field] = transformListToFormData(optimization[field], staticList.domainMaskingOptions);
        return acc;
      }

      if (field === SUB_ID_DB_FIELD) {
        acc[field] = transformListToFormData(optimization[field], staticList.domainMaskingOptions);
        return acc;
      }

      acc[field] = optimization[field];
      return acc;
    }, {});

const transformVersionsList = (list, options) =>
  list.map((l) =>
    Object.keys(l)
      .reduce((acc, field) => {
        const data = options.find(d => d[VALUE_FIELD] === field);

        acc[SELECTED_FIELD] = {
          label: data ? data.label : field,
          [VALUE_FIELD]: data ? data[VALUE_FIELD] : field
        };
        acc[VERSIONS_FIELD] = l[field].map((version) => {
          const result = data[VERSIONS_FIELD].find(v => v[VALUE_FIELD] === version);

          return result || {
            label: version,
            value: version
          };
        });

        return acc;
      }, {})
  );

export const transformTargetingToFormData = (targeting, staticList) =>
  Object.keys(targeting)
    .reduce((acc, field) => {
      switch (field) {
        case GEOS_FIELD:
          acc[field] = {
            [INCLUDE_FIELD]: targeting[field][INCLUDE_FIELD],
            [LIST_FIELD]: transformListToFormData(targeting[field][LIST_FIELD], staticList.countryOptions)
          };
          break;

        case CTYPES_FIELD:
          acc[field] = {
            [INCLUDE_FIELD]: targeting[field][INCLUDE_FIELD],
            [LIST_FIELD]: transformListToFormData(targeting[field][LIST_FIELD], staticList.connectionTypeOptions)
          };
          break;

        case CARRIERS_FIELD:
          acc[field] = {
            [INCLUDE_FIELD]: targeting[field][INCLUDE_FIELD],
            [LIST_FIELD]: transformListToFormData(targeting[field][LIST_FIELD], staticList.carrierOptions)
          };
          break;

        case CUSTOM_TAGS_FIELD:
          acc[field] = {
            [INCLUDE_FIELD]: targeting[field][INCLUDE_FIELD],
            [LIST_FIELD]: transformListToFormData(targeting[field][LIST_FIELD], [])
          };
          break;

        case DEVICE_TYPES_FIELD:
          acc[field] = {
            [INCLUDE_FIELD]: targeting[field][INCLUDE_FIELD],
            [LIST_FIELD]: transformListToFormData(targeting[field][LIST_FIELD], deviceTypesOptions)
          };
          break;

        case BROWSERS_FIELD:
          acc[field] = {
            [INCLUDE_FIELD]: targeting[field][INCLUDE_FIELD],
            [LIST_FIELD]: transformVersionsList(targeting[field][LIST_FIELD], staticList.browserOptions)
          };
          break;

        case OS_FIELD:
          acc[field] = {
            [INCLUDE_FIELD]: targeting[field][INCLUDE_FIELD],
            [LIST_FIELD]: transformVersionsList(targeting[field][LIST_FIELD], staticList.osOptions)
          };
          break;

        default:
          acc[field] = targeting[field];
          break;
      }

      return acc;
    }, {});

export const transformFilteringToFormData = (filtering) =>
  Object.keys(filtering)
    .reduce((acc, field) => {
      if (field === IP_FIELD || field === SUB_ID_FIELD || field === DOMAIN_FIELD || field === USER_AGENTS_FIELD) {
        acc[field] = filtering[field].join('\n');
        return acc;
      }

      if (field === PROXIES_FIELD) {
        const value = proxiesOptions.find(o => o.value === filtering[field]);

        acc[field] = value || {
          label: filtering[field],
          value: filtering[field]
        };

        return acc;
      }

      acc[field] = filtering[field];
      return acc;
    }, {});

export const transformDemandSetupToFormData = (data, options) =>
  Object.keys(data)
    .reduce((acc, field) => {
      if (field === DEMAND_INTEGRATION_FIELD) {
        const selected = options.find(o => o.value === data[field]);

        acc[field] = {
          label: selected ? selected.label : data[field],
          value: selected ? selected._id : data[field]
        };

        return acc;
      }

      if (field === DEMAND_FIELDS_FIELD) {
        acc[field] = data[field]
          .map((item) => ({
            name: Object.keys(item)[0],
            [VALUE_FIELD]: item[Object.keys(item)[0]]
          }));

        return acc;
      }

      acc[field] = data[field];

      return acc;
    }, {});

const getRelatedOptions = (id) => {
  switch (id) {
    case PARTNER_TYPE_FIELD:
      return sourceTypes;

    case PARTNER_BUSINESS_FIELD:
      return businessTypes;

    default:
      return [];
  }
};

export const getFilterTableSelected = (id, value) => {
  const options = getRelatedOptions(id);
  const data = options.find(l => l[VALUE_FIELD] === value);

  return data || {
    label: transformToCapitalized(value),
    value: value.toLowerCase()
  };
};

export const transformOptionsForTables = (options, id) => {
  const optionsList = getRelatedOptions(id);

  return options.map((o) => {
    const data = optionsList.find(l => l[VALUE_FIELD] === o);
    return data || {
      label: o,
      value: o
    };
  });
};
