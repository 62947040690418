import * as yup from 'yup';

import {
  AUTHENTICATION_NAME_FIELD,
  AUTHENTICATION_EMAIL_FIELD,
  AUTHENTICATION_PASSWORD_FIELD,
  AUTHENTICATION_CONFIRM_PASSWORD_FIELD
} from '../../../actions/types';

export const registerSchema = yup.object().shape({
  [AUTHENTICATION_NAME_FIELD]: yup.string().required('Name is required'),
  [AUTHENTICATION_EMAIL_FIELD]: yup.string().email('Invalid email').required('Email is required'),
  [AUTHENTICATION_PASSWORD_FIELD]: yup.string().min(6, 'Min 6 characters').required('Password is required'),
  [AUTHENTICATION_CONFIRM_PASSWORD_FIELD]: yup.string()
    .oneOf([yup.ref(AUTHENTICATION_PASSWORD_FIELD), null], 'Passwords must match')
    .min(6, 'Min 6 characters').required('Password is required')
});

export const loginSchema = yup.object().shape({
  [AUTHENTICATION_EMAIL_FIELD]: yup.string().email('Invalid email').required('Email is required'),
  [AUTHENTICATION_PASSWORD_FIELD]: yup.string().min(6, 'Min 6 characters').required('Password is required')
});

export const forgotPasswordFormSchema = yup.object().shape({
  [AUTHENTICATION_EMAIL_FIELD]: yup.string().email('Invalid email').required('Email is required')
});

export const resetPasswordFormSchema = yup.object().shape({
  [AUTHENTICATION_PASSWORD_FIELD]: yup.string().min(6, 'Min 6 characters').required('Password is required'),
  [AUTHENTICATION_CONFIRM_PASSWORD_FIELD]: yup.string()
    .oneOf([yup.ref(AUTHENTICATION_PASSWORD_FIELD), null], 'Passwords must match')
    .min(6, 'Min 6 characters').required('Password is required')
});
