import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchReportTableDataAction } from '../../actions/reports';

import TablePanelSpinner from '../tables-manager/shared/TablePanelSpinner';
import TablesManager from '../tables-manager/TablesManager';

const ReportsTable = () => {
  const dispatch = useDispatch();

  const {
    startFetching,
    loading,
    items,
    columns,
    hiddenColumns,
    query,
    forceRerender
  } = useSelector(
    state => ({
      items: state.reports.tableData.items,
      columns: state.reports.tableData.columns,
      hiddenColumns: state.reports.tableData.hiddenColumns,
      loading: state.reports.loading,
      startFetching: state.reports.startFetching,
      query: state.reports.tableQuery,
      forceRerender: state.reports.forceRerender
    })
  );

  useEffect(() => {
    if (startFetching) {
      fetchReportTableDataAction(query)(dispatch);
    }
  }, [JSON.stringify(query), forceRerender, startFetching]);

  return (
    <>
      {loading ? <TablePanelSpinner/>
        : <TablesManager
          searchFields={[]}
          fields={columns}
          tableName=''
          items={items}
          hiddenColumns={hiddenColumns}
          withoutSearchHeaders={true}
        />
      }
    </>
  );
};

export default ReportsTable;
