import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import { hideCurrentForm } from '../../../actions/drawerForms';

import {
  DEMAND_WIZARD,
  ENDPOINT_WIZARD,
  GENERAL_FORM,
  OPTIMIZATION_FORM,
  PROTECTION_FORM,
  TARGETING_FORM
} from '../constants';

import { useIsMountedRef } from '../custom-hooks/useIsMountedRef';

import WizardSteps from './WizardSteps';
import WizardTitle from './WizardTitle';

import './Wizard.scss';

const Wizard = ({
  stepsForms,
  handleSubmit,
  updateTitle,
  title,
  titlePlaceholder,
  clearFormState,
  id,
  validateForms,
  withoutTitle,
  wizardName
}) => {
  const steps = stepsForms.map(i => i.label);

  const dispatch = useDispatch();

  const {
    isOpen
  } = useSelector(
    state => ({
      isOpen: state.drawerForms.isOpen
    })
  );

  const [wizardState, setWizardState] = useState({
    currentStep: 0,
    isSubmitted: false,
    saving: false,
    canSave: false,
    isValid: false,
    changingStep: false,
    next: true,
    clickStep: false,
    clickedStep: 0
  });

  const isMountedRef = useIsMountedRef();
  const [partner, setPartner] = useState(false);

  const setIsValid = (isValid) => setWizardState(state => ({
    ...state,
    isValid
  }));

  let validationDeps = [JSON.stringify({
    currentStep: wizardState.currentStep,
    isValid: wizardState.isValid
  }), title, partner];

  if (withoutTitle) {
    validationDeps = [JSON.stringify({
      currentStep: wizardState.currentStep,
      isValid: wizardState.isValid
    })];
  }

  useEffect(() => {
    const withoutTitleCheck = withoutTitle
      ? true
      : title;

    const validateFormsCheck = wizardState.changingStep
      ? validateForms('all')
      : validateForms('all', wizardState.currentStep);

    const canSave =
      wizardState.isValid &&
      withoutTitleCheck &&
      validateFormsCheck;

    setWizardState(state => ({
      ...state,
      changingStep: false,
      canSave
    }));
  }, validationDeps);

  if (!stepsForms || !stepsForms.length) {
    return null;
  }

  const CF = stepsForms[wizardState.currentStep];
  const CurrentForm = CF.component;

  const clickNext = () => {
    if (isMountedRef.current) {
      setWizardState(state => ({
        ...state,
        isSubmitted: true,
        next: true
      }));
    }
  };

  const onSubmit = async (isValid) => {
    // If no title can't continue
    if (!withoutTitle) {
      if (!title) {
        if (isMountedRef.current) {
          setWizardState(state => ({
            ...state,
            isSubmitted: false,
            saving: false
          }));
        }

        return;
      }
    }

    let currentStep = wizardState.currentStep;

    if (wizardState.saving) {
      if (isValid) {
        const result = await handleSubmit();

        if (!result) {
          if (isMountedRef.current) {
            setWizardState(state => ({
              ...state,
              isSubmitted: false,
              currentStep,
              saving: false
            }));
          }
          // Handle if fail creating endpoint
          return;
        }

        hideCurrentForm()(dispatch);

        return;
      }
    }

    if (isValid) {
      if (currentStep === stepsForms.length - 1 && wizardState.next !== false && !wizardState.clickStep) {
        const result = await handleSubmit();

        if (!result) {
          if (isMountedRef.current) {
            setWizardState(state => ({
              ...state,
              isSubmitted: false,
              currentStep,
              saving: false,
              clickStep: false,
              clickedStep: 0
            }));
          }
          // Handle if fail creating endpoint
          return;
        }

        hideCurrentForm()(dispatch);

        return;
      }

      if (wizardState.clickStep) {
        currentStep = wizardState.clickedStep;
      } else {
        if (wizardState.next) {
          if (currentStep < stepsForms.length - 1) {
            currentStep++;
          }
        } else {
          if (currentStep) {
            currentStep--;
          }
        }
      }
    }

    if (isMountedRef.current) {
      setWizardState(state => ({
        ...state,
        isSubmitted: false,
        currentStep,
        saving: false,
        changingStep: true,
        clickStep: false,
        clickedStep: 0
      }));
    }
  };

  const handleBackStep = () => {
    if (wizardState.currentStep) {
      if (isMountedRef.current) {
        setWizardState(state => ({
          ...state,
          isSubmitted: true,
          next: false
        }));
      }
    }
  };

  const renderCurrentForm = () => {
    switch (CF.label) {
      case GENERAL_FORM:
        return (
          <CurrentForm
            isSubmitted={wizardState.isSubmitted}
            onSubmit={onSubmit}
            parentWizard={CF.parentWizard}
            setIsValid={setIsValid}
            setPartner={CF.parentWizard === ENDPOINT_WIZARD ? setPartner : false}
          />
        );

      case OPTIMIZATION_FORM:
      case PROTECTION_FORM:
      case TARGETING_FORM:
        return (
          <CurrentForm
            isSubmitted={wizardState.isSubmitted}
            onSubmit={onSubmit}
            parentWizard={CF.parentWizard}
            setIsValid={setIsValid}
          />
        );

      default:
        return (
          <CurrentForm
            isSubmitted={wizardState.isSubmitted}
            onSubmit={onSubmit}
            setIsValid={setIsValid}
          />
        );
    }
  };

  const handleOnChangeStep = (clickedStep) => {
    if (!withoutTitle) {
      if (!title) {
        return;
      }
    }

    if (wizardState.currentStep === clickedStep) {
      return;
    }

    const isValid = validateForms(clickedStep);

    if (isValid) {
      setWizardState(state => ({
        ...state,
        isSubmitted: true,
        clickStep: true,
        clickedStep
      }));
    }
  };

  const handleSave = () => {
    if (isMountedRef.current) {
      setWizardState(state => ({
        ...state,
        isSubmitted: true,
        saving: true
      }));
    }
  };

  const renderButtons = () => {
    const backButton = wizardState.currentStep === 0
      ? (<button
        className="btn btn-pink-light"
        onClick={() => {
          clearFormState()(dispatch);
          hideCurrentForm()(dispatch);
        }}
      >
        Cancel
      </button>) : (<button className="btn btn-pink-light" onClick={handleBackStep}>Back</button>);

    if (wizardState.currentStep === stepsForms.length - 1) {
      return (
        <div className="wizard-footer panel-footer">
          <div className="wizard-footer-back-box">
            {backButton}
          </div>

          <div className="wizard-footer-other-box">
            <button className="wizard-footer-finish second-btn btn btn-dark-violet" onClick={clickNext}>
              {id ? 'Update' : 'Finish'}
            </button>
          </div>
        </div>
      );
    }

    if (wizardState.canSave) {
      return (
        <div className="wizard-footer panel-footer">
          <div className="wizard-footer-back-box">
            {backButton}
          </div>

          <div className="wizard-footer-other-box">
            <button className="wizard-footer-next second-btn btn btn-pink" onClick={clickNext}>Next</button>

            <button className="wizard-footer-finish second-btn btn btn-dark-violet" onClick={handleSave}>
              {id ? 'Update' : 'Finish'}
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className="wizard-footer panel-footer">
        <div className="wizard-footer-back-box">
          {backButton}
        </div>

        <div className="wizard-footer-other-box">
          <button className="wizard-footer-next second-btn btn btn-pink" onClick={clickNext}>Next</button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      appElement={document.getElementById('modal-wrapper')}
      style={{
        overlay: {
          zIndex: 9998
        },
        content: {
          padding: 0,
          top: 80,
          bottom: 80,
          left: 80,
          right: 80
        }
      }}
      isOpen={isOpen}
    >
      <div className='wizard panel panel-create panel-show'>
        <div className="wizard-steps-box panel-content">
          <WizardSteps
            wizardName={wizardName}
            steps={steps}
            currentStep={wizardState.currentStep}
            onChangeStep={handleOnChangeStep}
          />
        </div>
        <div className="wizard-content-box">
          <div className="wizard-content">
            <div className='panel-title'>
              {withoutTitle ? null : (
                <div className="wizard-content-title form-group">
                  <WizardTitle id={id} updateTitle={updateTitle} title={title} placeholder={titlePlaceholder}/>
                </div>
              )}
              <p className='btn-close'>
                <span
                  onClick={() => {
                    clearFormState()(dispatch);
                    hideCurrentForm()(dispatch);
                  }}
                />
              </p>
            </div>
            <div className="wizard-form-container panel-content">
              {renderCurrentForm()}
            </div>
          </div>
        </div>

        {renderButtons()}
      </div>
    </Modal>
  );
};

Wizard.propTypes = {
  stepsForms: PropTypes.arrayOf(PropTypes.exact({
    label: PropTypes.string.isRequired,
    component: PropTypes.elementType.isRequired,
    parentWizard: PropTypes.oneOf([ENDPOINT_WIZARD, DEMAND_WIZARD])
  })).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  updateTitle: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  titlePlaceholder: PropTypes.string.isRequired,
  clearFormState: PropTypes.func.isRequired,
  id: PropTypes.string,
  validateForms: PropTypes.func,
  withoutTitle: PropTypes.bool,
  partner: PropTypes.any,
  wizardName: PropTypes.string.isRequired
};

export default Wizard;
