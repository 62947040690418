import PropTypes from 'prop-types';
import React from 'react';

import ManualRevenueUpdateFrom from './ManualRevenueUpdateForm';

const ManualRevenueUpdateTableHead = ({
  headerGroups,
  handleCreateItem,
  setRowState
}) => {
  return (
    <thead>
      {headerGroups.map((headerGroup, index) => (
        <React.Fragment key={index}>
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, cIndex) => (
              <th key={cIndex * 123} scope="col" {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
              </th>
            ))}
          </tr>
          <ManualRevenueUpdateFrom setRowState={setRowState} handleCreateItem={handleCreateItem} headerGroup={headerGroup}/>
        </React.Fragment>
      ))}
    </thead>
  );
};

ManualRevenueUpdateTableHead.propTypes = {
  headerGroups: PropTypes.array.isRequired,
  handleCreateItem: PropTypes.func.isRequired,
  setRowState: PropTypes.func.isRequired
};

export default ManualRevenueUpdateTableHead;
