import {
  DEMAND_SETUP_DATA_FIELD,
  DEMAND_WIZARD,
  FILTERS_FIELD,
  OPTIMIZATION_FIELD,
  TARGETING_FIELD
} from '../../constants';

import { validateFormData } from '../shared';
import { validateGeneralData } from '../shared/general';
import { validateOptimizationData } from '../shared/optimization';
import { validateProtectionData } from '../shared/protection';
import { validateTargetingData } from '../shared/targeting';
import { validateSetupData } from './setup-data';

const demandForms = [
  {
    validator: validateGeneralData
  },
  {
    validator: validateSetupData,
    field: DEMAND_SETUP_DATA_FIELD
  },
  {
    validator: validateOptimizationData,
    field: OPTIMIZATION_FIELD
  },
  {
    validator: validateTargetingData,
    field: TARGETING_FIELD
  },
  {
    validator: validateProtectionData,
    field: FILTERS_FIELD
  }
];

export const validateDemandFormData = (toForm, exclude) => validateFormData(DEMAND_WIZARD, [...demandForms], toForm, exclude);
