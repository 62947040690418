import {
  DASHBOARD_APPROVED_PAYOUTS,
  DASHBOARD_APPROVED_REVENUE,
  DASHBOARD_AVG_NET_BID,
  DASHBOARD_BREAKDOWN_DAY,
  DASHBOARD_BREAKDOWN_HOUR,
  DASHBOARD_BREAKDOWN_MONTH,
  DASHBOARD_BREAKDOWN_WEEK,
  DASHBOARD_CLICKS_COUNT,
  DASHBOARD_COUNTRIES_FIELD,
  DASHBOARD_DEMAND_FEEDS_FIELD,
  DASHBOARD_DF_SOURCE_FIELD,
  DASHBOARD_DEMAND_TAGS_FIELD,
  DASHBOARD_E_CPM,
  DASHBOARD_ENDPOINT_ID_FIELD,
  DASHBOARD_ENDPOINT_STATUS_FIELD,
  DASHBOARD_ENDPOINT_TAGS_FIELD,
  DASHBOARD_EP_SOURCE_FIELD,
  DASHBOARD_EST_PAYOUT,
  DASHBOARD_EST_PROFIT,
  DASHBOARD_EST_REVENUE,
  DASHBOARD_EST_ROI,
  DASHBOARD_IMPS_COUNT,
  DASHBOARD_INT_REQUESTS_AVG_GROSS_BID,
  DASHBOARD_INT_REQUESTS_AVG_NET_BID,
  DASHBOARD_INT_REQUESTS_COUNT,
  DASHBOARD_INT_REQUESTS_COVERAGE,
  DASHBOARD_INTERVAL_TODAY,
  DASHBOARD_OS_FIELD,
  DASHBOARD_PLATFORM_FIELD,
  DASHBOARD_PROFIT,
  DASHBOARD_REQUESTS_AVG_GROSS_BID,
  DASHBOARD_REQUESTS_AVG_NET_BID,
  DASHBOARD_REQUESTS_COUNT,
  DASHBOARD_REQUESTS_COVERAGE,
  DASHBOARD_ROI,
  DASHBOARD_SUB_BIDS_FIELD,
  REPORTS_AVG_GROSS_BID_FIELD,
  REPORTS_BROWSER_FIELD,
  REPORTS_BROWSER_VERSION,
  REPORTS_CLICKS_COUNT_DISCP_FIELD,
  REPORTS_CLICKS_COVERAGE_FIELD,
  REPORTS_CLICKS_ERRORS_PCT_FIELD,
  REPORTS_CTR_FIELD,
  REPORTS_DEMAND_STATUS_FIELD,
  REPORTS_DIMENSION_FILTERS_FIELD,
  REPORTS_DIMENSIONS_FIELD,
  REPORTS_IMPS_COVERAGE_FIELD,
  REPORTS_KEY_FIELD,
  REPORTS_OPERATION_FIELD,
  REPORTS_OS_VERSION_FIELD,
  REPORTS_PAYOUT_DISCP_FIELD,
  REPORTS_REVENUE_DISCP_FIELD,
  REPORTS_VALUE_FIELD,
  DASHBOARD_INTERVAL_YESTERDAY,
  REPORTS_INTERVAL_LAST_48_HOURS
} from '../../../actions/types';

import { searchDemands } from '../../../api/demand';
import { searchEndpoints } from '../../../api/endpoint';
import { searchPartners } from '../../../api/partners';

export const getBreakdownList = (interval) => {
  if (interval === DASHBOARD_INTERVAL_TODAY ||
    interval === DASHBOARD_INTERVAL_YESTERDAY ||
    interval === REPORTS_INTERVAL_LAST_48_HOURS
  ) {
    return [
      {
        label: 'None',
        value: 'none'
      },
      {
        label: 'Hourly',
        value: DASHBOARD_BREAKDOWN_HOUR
      },
      {
        label: 'Daily',
        value: DASHBOARD_BREAKDOWN_DAY
      },
      {
        label: 'Weekly',
        value: DASHBOARD_BREAKDOWN_WEEK
      },
      {
        label: 'Monthly',
        value: DASHBOARD_BREAKDOWN_MONTH
      }
    ];
  }

  return [
    {
      label: 'None',
      value: 'none'
    },
    {
      label: 'Daily',
      value: DASHBOARD_BREAKDOWN_DAY
    },
    {
      label: 'Weekly',
      value: DASHBOARD_BREAKDOWN_WEEK
    },
    {
      label: 'Monthly',
      value: DASHBOARD_BREAKDOWN_MONTH
    }
  ];
};

export const dimensionsList = [
  {
    label: 'Endpoint(s)',
    value: DASHBOARD_ENDPOINT_ID_FIELD
  },
  {
    label: 'Traffic Source(s)',
    value: DASHBOARD_EP_SOURCE_FIELD
  },
  {
    label: 'Demand(s)',
    value: DASHBOARD_DEMAND_FEEDS_FIELD
  },
  {
    label: 'Demand Source(s)',
    value: DASHBOARD_DF_SOURCE_FIELD
  },
  {
    label: 'Country',
    value: DASHBOARD_COUNTRIES_FIELD
  },
  {
    label: 'OS',
    value: DASHBOARD_OS_FIELD
  },
  {
    label: 'OS version',
    value: REPORTS_OS_VERSION_FIELD
  },
  {
    label: 'SubId',
    value: DASHBOARD_SUB_BIDS_FIELD
  },
  {
    label: 'Platform',
    value: DASHBOARD_PLATFORM_FIELD
  },
  {
    label: 'Browser',
    value: REPORTS_BROWSER_FIELD
  },
  {
    label: 'Browser version',
    value: REPORTS_BROWSER_VERSION
  }
];

export const metricsList = [
  {
    label: 'Requests',
    value: DASHBOARD_REQUESTS_COUNT
  },
  {
    label: 'Avg. Net Bid (Request)',
    value: DASHBOARD_REQUESTS_AVG_NET_BID
  },
  {
    label: 'Avg. Gross Bid (Request)',
    value: DASHBOARD_REQUESTS_AVG_GROSS_BID
  },
  {
    label: 'Clicks',
    value: DASHBOARD_CLICKS_COUNT
  },
  {
    label: 'Revenue',
    value: DASHBOARD_APPROVED_REVENUE
  },
  {
    label: 'Payout',
    value: DASHBOARD_APPROVED_PAYOUTS
  },
  {
    label: 'Est. Payout',
    value: DASHBOARD_EST_PAYOUT
  },
  {
    label: 'Est. Revenue',
    value: DASHBOARD_EST_REVENUE
  },
  {
    label: 'Avg. Gross Bid (Click)',
    value: REPORTS_AVG_GROSS_BID_FIELD
  },
  {
    label: 'Avg. Net Bid (Click)',
    value: DASHBOARD_AVG_NET_BID
  },
  {
    label: 'Impressions',
    value: DASHBOARD_IMPS_COUNT
  },
  {
    label: 'Clicks/Imps%',
    value: REPORTS_CTR_FIELD
  },
  {
    label: 'Coverage% (Req Bids)',
    value: DASHBOARD_REQUESTS_COVERAGE
  },
  {
    label: 'Profit',
    value: DASHBOARD_PROFIT
  },
  {
    label: 'Est. Profit',
    value: DASHBOARD_EST_PROFIT
  },
  {
    label: 'Est. ROI',
    value: DASHBOARD_EST_ROI
  },
  {
    label: 'Discrepancy% (Clicks)',
    value: REPORTS_CLICKS_COUNT_DISCP_FIELD
  },
  {
    label: 'Discrepancy% (Payout)',
    value: REPORTS_PAYOUT_DISCP_FIELD
  },
  {
    label: 'Discrepancy% (Revenue)',
    value: REPORTS_REVENUE_DISCP_FIELD
  },
  {
    label: 'Errors% (Clicks)',
    value: REPORTS_CLICKS_ERRORS_PCT_FIELD
  },
  {
    label: 'eCPM',
    value: DASHBOARD_E_CPM
  },
  {
    label: 'Clicks/Req%',
    value: REPORTS_CLICKS_COVERAGE_FIELD
  },
  {
    label: 'Imps/Req%',
    value: REPORTS_IMPS_COVERAGE_FIELD
  },
  {
    label: 'ROI',
    value: DASHBOARD_ROI
  }
];

export const filterDimensionsList = [
  {
    label: 'Endpoint(s)',
    value: DASHBOARD_ENDPOINT_ID_FIELD
  },
  {
    label: 'Traffic Source(s)',
    value: DASHBOARD_EP_SOURCE_FIELD
  },
  {
    label: 'Demand(s)',
    value: DASHBOARD_DEMAND_FEEDS_FIELD
  },
  {
    label: 'Demand Source(s)',
    value: DASHBOARD_DF_SOURCE_FIELD
  },
  {
    label: 'Country',
    value: DASHBOARD_COUNTRIES_FIELD
  },
  {
    label: 'OS',
    value: DASHBOARD_OS_FIELD
  },
  {
    label: 'SubId',
    value: DASHBOARD_SUB_BIDS_FIELD
  },
  {
    label: 'Platform',
    value: DASHBOARD_PLATFORM_FIELD
  },
  {
    label: 'Browser',
    value: REPORTS_BROWSER_FIELD
  },
  {
    label: 'Endpoint Tags',
    value: DASHBOARD_ENDPOINT_TAGS_FIELD
  },
  {
    label: 'Endpoint Status',
    value: DASHBOARD_ENDPOINT_STATUS_FIELD
  },
  {
    label: 'Demand Tags',
    value: DASHBOARD_DEMAND_TAGS_FIELD
  },
  {
    label: 'Demand Status',
    value: REPORTS_DEMAND_STATUS_FIELD
  }
];

export const operationsList = [
  {
    label: '>',
    value: 'gt'
  },
  {
    label: '<',
    value: 'lt'
  },
  {
    label: '==',
    value: 'eq'
  },
  {
    label: '>=',
    value: 'gteq'
  },
  {
    label: '<=',
    value: 'lteq'
  }
];

export const reportsLoadTrafficSourceOptions = async (search, loadedOptions, { page }) => {
  const data = {
    type: ['isBuy', 'both'],
    filter: { page, limit: 10, search }
  };

  const response = await searchPartners(data);

  const options = response.data.results.map(({ label, value }) => ({
    label: `${label} (${value})`,
    value
  }));

  return {
    options,
    hasMore: response.data.has_more,
    additional: {
      page: page + 1
    }
  };
};

export const reportsLoadDemandSourceOptions = async (search, loadedOptions, { page }) => {
  const data = {
    type: ['isSell', 'both'],
    filter: { page, limit: 10, search }
  };

  const response = await searchPartners(data);

  const options = response.data.results.map(({ label, value }) => ({
    label: `${label} (${value})`,
    value
  }));

  return {
    options,
    hasMore: response.data.has_more,
    additional: {
      page: page + 1
    }
  };
};

export const reportsLoadEndpointOptions = async (search, loadedOptions, { page }) => {
  const data = {
    filter: { page, limit: 10, search }
  };

  const response = await searchEndpoints(data);

  const options = response.data.results.map(({ label, value }) => ({
    label: `${label} (${value})`,
    value
  }));

  return {
    options,
    hasMore: response.data.has_more,
    additional: {
      page: page + 1
    }
  };
};

export const reportsLoadDemandOptions = async (search, loadedOptions, { page }) => {
  const data = {
    filter: { page, limit: 10, search }
  };

  const response = await searchDemands(data);

  const options = response.data.results.map(({ label, value }) => ({
    label: `${label} (${value})`,
    value
  }));

  return {
    options,
    hasMore: response.data.has_more,
    additional: {
      page: page + 1
    }
  };
};

export const platformsList = [
  {
    label: 'Desktop',
    value: 'desktop'
  },
  {
    label: 'Mobile',
    value: 'mobile'
  },
  {
    label: 'Tablet',
    value: 'tablet'
  },
  {
    label: 'Smartty',
    value: 'smartty'
  },
  {
    label: 'Console',
    value: 'console'
  },
  {
    label: 'Wearable',
    value: 'wearable'
  },
  {
    label: 'Other',
    value: 'other'
  }
];

export const reportsCheckIsDemand = (data) =>
  !!data[REPORTS_DIMENSIONS_FIELD]
    .find(d =>
      d[REPORTS_VALUE_FIELD] === DASHBOARD_DEMAND_FEEDS_FIELD ||
      d[REPORTS_VALUE_FIELD] === DASHBOARD_DF_SOURCE_FIELD
    ) ||
  !!data[REPORTS_DIMENSION_FILTERS_FIELD]
    .find(d =>
      d[REPORTS_KEY_FIELD][REPORTS_VALUE_FIELD] === DASHBOARD_DEMAND_FEEDS_FIELD ||
      d[REPORTS_KEY_FIELD][REPORTS_VALUE_FIELD] === DASHBOARD_DF_SOURCE_FIELD
    );

export const getFilterDimensionsOperatorOptions = (watchFilterDimensions, index) => {
  if (watchFilterDimensions[index] && watchFilterDimensions[index].key) {
    if ([DASHBOARD_ENDPOINT_STATUS_FIELD, REPORTS_DEMAND_STATUS_FIELD].includes(watchFilterDimensions[index].key.value)) {
      return [{
        label: 'is',
        value: 'is'
      }];
    }
  }

  return [{
    label: 'is in',
    value: 'is_in'
  }];
};

export const handleFilterDimensionsChange = (index, setValue) => ([selected]) => {
  if ([DASHBOARD_ENDPOINT_STATUS_FIELD, REPORTS_DEMAND_STATUS_FIELD].includes(selected.value)) {
    setValue(`${REPORTS_DIMENSION_FILTERS_FIELD}[${index}][${REPORTS_OPERATION_FIELD}]`, {
      label: 'is',
      value: 'is'
    });

    setValue(`${REPORTS_DIMENSION_FILTERS_FIELD}[${index}][${REPORTS_VALUE_FIELD}]`, null);
  } else {
    setValue(`${REPORTS_DIMENSION_FILTERS_FIELD}[${index}][${REPORTS_OPERATION_FIELD}]`, {
      label: 'is in',
      value: 'is_in'
    });

    setValue(`${REPORTS_DIMENSION_FILTERS_FIELD}[${index}][${REPORTS_VALUE_FIELD}]`, []);
  }
};

export const reportsDimensionsObject = {
  [DASHBOARD_ENDPOINT_ID_FIELD]: 'Endpoint',
  [DASHBOARD_EP_SOURCE_FIELD]: 'Traffic Source',
  [DASHBOARD_DEMAND_FEEDS_FIELD]: 'Demand',
  [DASHBOARD_DF_SOURCE_FIELD]: 'Demand Source',
  [DASHBOARD_COUNTRIES_FIELD]: 'Country',
  [DASHBOARD_OS_FIELD]: 'OS',
  [REPORTS_OS_VERSION_FIELD]: 'OS version',
  [DASHBOARD_SUB_BIDS_FIELD]: 'SubId',
  [DASHBOARD_PLATFORM_FIELD]: 'Platform',
  [REPORTS_BROWSER_FIELD]: 'Browser',
  [REPORTS_BROWSER_VERSION]: 'Browser version'
};

export const reportsMetricsObject = {
  [DASHBOARD_REQUESTS_COUNT]: 'Requests',
  [DASHBOARD_INT_REQUESTS_COUNT]: 'Requests',
  [DASHBOARD_REQUESTS_AVG_NET_BID]: 'Avg. Net Bid (Request)',
  [DASHBOARD_INT_REQUESTS_AVG_NET_BID]: 'Avg. Net Bid (Request)',
  [DASHBOARD_REQUESTS_AVG_GROSS_BID]: 'Avg. Gross Bid (Request)',
  [DASHBOARD_INT_REQUESTS_AVG_GROSS_BID]: 'Avg. Gross Bid (Request)',
  [DASHBOARD_CLICKS_COUNT]: 'Clicks',
  [DASHBOARD_APPROVED_REVENUE]: 'Revenue',
  [DASHBOARD_APPROVED_PAYOUTS]: 'Payout',
  [DASHBOARD_EST_PAYOUT]: 'Est. Payout',
  [DASHBOARD_EST_REVENUE]: 'Est. Revenue',
  [REPORTS_AVG_GROSS_BID_FIELD]: 'Avg. Gross Bid (Click)',
  [DASHBOARD_AVG_NET_BID]: 'Avg. Net Bid (Click)',
  [DASHBOARD_IMPS_COUNT]: 'Impressions',
  [REPORTS_CTR_FIELD]: 'Clicks/Imps%',
  [DASHBOARD_REQUESTS_COVERAGE]: 'Coverage% (Req Bids)',
  [DASHBOARD_INT_REQUESTS_COVERAGE]: 'Coverage% (Req Bids)',
  [DASHBOARD_PROFIT]: 'Profit',
  [DASHBOARD_EST_PROFIT]: 'Est. Profit',
  [DASHBOARD_EST_ROI]: 'Est. ROI',
  [REPORTS_CLICKS_COUNT_DISCP_FIELD]: 'Discrepancy% (Clicks)',
  [REPORTS_PAYOUT_DISCP_FIELD]: 'Discrepancy% (Payout)',
  [REPORTS_REVENUE_DISCP_FIELD]: 'Discrepancy% (Revenue)',
  [REPORTS_CLICKS_ERRORS_PCT_FIELD]: 'Errors% (Clicks)',
  [DASHBOARD_E_CPM]: 'eCPM',
  [REPORTS_CLICKS_COVERAGE_FIELD]: 'Clicks/Req%',
  [REPORTS_IMPS_COVERAGE_FIELD]: 'Imps/Req%',
  [DASHBOARD_ROI]: 'ROI'
};

export const reportsPlatformsObject = {
  desktop: 'Desktop',
  mobile: 'Mobile',
  tablet: 'Tablet',
  smartty: 'Smartty',
  console: 'Console',
  wearable: 'Wearable',
  other: 'Other'
};

export const reportsStatusList = [
  {
    label: 'Active',
    value: true
  },
  {
    label: 'Inactive',
    value: false
  }
];

export const reportsBreakdownObject = {
  none: {
    label: 'None',
    value: 'none'
  },
  [DASHBOARD_BREAKDOWN_HOUR]: {
    label: 'Hourly',
    value: DASHBOARD_BREAKDOWN_HOUR
  },
  [DASHBOARD_BREAKDOWN_DAY]: {
    label: 'Daily',
    value: DASHBOARD_BREAKDOWN_DAY
  },
  [DASHBOARD_BREAKDOWN_WEEK]: {
    label: 'Weekly',
    value: DASHBOARD_BREAKDOWN_WEEK
  },
  [DASHBOARD_BREAKDOWN_MONTH]: {
    label: 'Monthly',
    value: DASHBOARD_BREAKDOWN_MONTH
  }
};
