import moment from 'moment';

import {
  DASHBOARD_INTERVAL_YESTERDAY,
  RULES_ACTION_DATA_FIELD,
  RULES_ACTION_FIELD,
  RULES_ANALYZE_INTERVAL_FIELD,
  RULES_CREATE_RULE,
  RULES_DELETE_RULE,
  RULES_DIMENSION_FILTERS_FIELD,
  RULES_DUPLICATE_RULE,
  RULES_EDIT_RULE,
  RULES_FETCH_RULES,
  RULES_FREQUENCY_CUSTOM_FIELD,
  RULES_FREQUENCY_DAYS_FIELD,
  RULES_FREQUENCY_INTERVAL_FIELD,
  RULES_METRIC_FILTERS_FIELD,
  RULES_OUTCOME_FIELD,
  RULES_RUN_RULE,
  RULES_START_FETCHING_RULES,
  RULES_TAGS_FIELD,
  RULES_UPDATE_RULE,
  RULES_UPDATE_RULE_STATUS,
  RULES_UPDATE_TAGS
} from '../actions/types';

import { rulesFrequencyDays } from '../components/rules/helpers';
import { TITLE_FIELD } from '../components/wizards-manager/constants';

const rulesInitialFormData = {
  [TITLE_FIELD]: '',
  [RULES_TAGS_FIELD]: [],
  [RULES_ANALYZE_INTERVAL_FIELD]: {
    label: 'Yesterday',
    value: DASHBOARD_INTERVAL_YESTERDAY
  },
  [RULES_ACTION_FIELD]: null,
  [RULES_ACTION_DATA_FIELD]: 0.1,
  [RULES_OUTCOME_FIELD]: 'alert',
  [RULES_DIMENSION_FILTERS_FIELD]: [],
  [RULES_METRIC_FILTERS_FIELD]: [],
  [RULES_FREQUENCY_DAYS_FIELD]: [...rulesFrequencyDays],
  [RULES_FREQUENCY_INTERVAL_FIELD]: {
    label: 'Every 3 hours',
    value: '3h'
  },
  [RULES_FREQUENCY_CUSTOM_FIELD]: moment(new Date()).format('HH:MM')
};

const initialState = {
  formData: rulesInitialFormData,
  rules: [],
  loading: true
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RULES_START_FETCHING_RULES:
      return {
        ...state,
        loading: true
      };

    case RULES_CREATE_RULE:
      return {
        ...state,
        rules: [payload, ...state.rules],
        formData: rulesInitialFormData
      };

    case RULES_EDIT_RULE:
    case RULES_DUPLICATE_RULE:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload
        }
      };

    case RULES_UPDATE_RULE:
      return {
        ...state,
        formData: rulesInitialFormData,
        rules: state.rules.map(item => {
          if (item._id === payload._id) {
            return { ...payload, updatedAt: new Date() };
          }

          return item;
        })
      };

    case RULES_RUN_RULE:
      return {
        ...state,
        rules: state.rules.map(item => {
          if (item._id === payload._id) {
            return payload;
          }

          return item;
        })
      };

    case RULES_DELETE_RULE:
      return {
        ...state,
        rules: state.rules.filter(item => item._id !== payload)
      };

    case RULES_FETCH_RULES:
      return {
        ...state,
        rules: payload,
        loading: false
      };

    case RULES_UPDATE_RULE_STATUS:
      return {
        ...state,
        rules: state.rules.map(r => {
          if (r._id === payload.id) {
            return {
              ...r,
              active: payload.active
            };
          }

          return r;
        })
      };

    case RULES_UPDATE_TAGS:
      return {
        ...state,
        rules: state.rules.map(r => {
          if (r._id === payload.id) {
            return {
              ...r,
              tags: payload.tags
            };
          }

          return r;
        })
      };

    default:
      return state;
  }
};
