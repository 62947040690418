import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { updateFilterState } from '../../../../actions/dashboard';
import { hideCurrentForm } from '../../../../actions/drawerForms';

import {
  DASHBOARD_ASSIGNEE_FIELD,
  DASHBOARD_COUNTRIES_FIELD,
  DASHBOARD_DEMAND_FEEDS_FIELD,
  DASHBOARD_DEMAND_TAGS_FIELD,
  DASHBOARD_ENDPOINT_ID_FIELD,
  DASHBOARD_ENDPOINT_STATUS_FIELD,
  DASHBOARD_ENDPOINT_TAGS_FIELD,
  DASHBOARD_EP_SOURCE_FIELD,
  DASHBOARD_DF_SOURCE_FIELD
} from '../../../../actions/types';

import { fetchAllUsers } from '../../../../api/users';
import { useIsMountedRef } from '../../custom-hooks/useIsMountedRef';
import { filterAllList } from '../../helpers/wizard-helpers';

import WizardSelectWithValidation, { ASYNC_TYPE, SELECT_TYPE } from '../shared/WizardSelectWithValidation';

import { loadDemandOptions, loadDemandSourceOptions, loadEndpointOptions, loadTrafficSourceOptions } from './helpers';

import './DashboardFilterForm.scss';

const statusOptions = [
  {
    label: 'All',
    value: '*'
  },
  {
    label: 'Active',
    value: true
  },
  {
    label: 'Inactive',
    value: false
  }
];

const DashboardFilterForm = () => {
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();

  const {
    tagOptions,
    countryOptions,
    defaultValues
  } = useSelector(
    state => ({
      tagOptions: state.staticList.tagOptions,
      countryOptions: state.staticList.countryOptions,
      defaultValues: state.dashboard.filters
    })
  );

  const {
    control,
    getValues
  } = useForm({
    mode: 'onBlur',
    defaultValues
  });

  const [users, setUsers] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await fetchAllUsers();
        const users = res.data.data.data.map((user) => ({
          label: user.name,
          value: user._id
        }));

        if (isMountedRef.current) {
          setUsers(users);
        }
      } catch (err) {
        if (isMountedRef.current) {
          setUsers([]);
        }
      }
    })();
  }, []);

  const handleOnSubmit = () => {
    const data = getValues({ nest: true });

    updateFilterState(data)(dispatch);
    hideCurrentForm()(dispatch);
  };

  // TODO Finish form fields
  return (
    <div
      className="dashboard-filter-form panel panel-create drawer-form-lg panel-show">
      <div className="panel-title">
        <p className="btn-close">
          <span onClick={() => {
            hideCurrentForm()(dispatch);
          }}/>
        </p>
        <h4>Filtering</h4>
      </div>

      <div className="dashboard-filter-container panel-content">
        <div className="dashboard-filter-form-content">
          <WizardSelectWithValidation
            control={control}
            error={false}
            isMulti={true}
            errorMessage={''}
            name={DASHBOARD_EP_SOURCE_FIELD}
            options={[]}
            selectType={ASYNC_TYPE}
            filterSelected={filterAllList}
            loadOptions={loadTrafficSourceOptions}
            label='By Traffic Source'
            defaultValue={defaultValues[DASHBOARD_EP_SOURCE_FIELD]}
          />

          <WizardSelectWithValidation
            control={control}
            error={false}
            isMulti={true}
            errorMessage={''}
            name={DASHBOARD_DF_SOURCE_FIELD}
            options={[]}
            selectType={ASYNC_TYPE}
            filterSelected={filterAllList}
            loadOptions={loadDemandSourceOptions}
            label='By Demand Source'
            defaultValue={defaultValues[DASHBOARD_DF_SOURCE_FIELD]}
          />
          <WizardSelectWithValidation
            control={control}
            error={false}
            isMulti={true}
            errorMessage={''}
            name={DASHBOARD_ENDPOINT_ID_FIELD}
            options={[]}
            selectType={ASYNC_TYPE}
            label='By Endpoint'
            filterSelected={filterAllList}
            loadOptions={loadEndpointOptions}
            defaultValue={defaultValues[DASHBOARD_ENDPOINT_ID_FIELD]}
          />

          <WizardSelectWithValidation
            selectType={SELECT_TYPE}
            name={DASHBOARD_ENDPOINT_TAGS_FIELD}
            control={control}
            error={false}
            errorMessage={''}
            isMulti={true}
            options={[{ label: 'All', value: '*' }, ...tagOptions]}
            placeholder='#mainstream,...'
            filterSelected={filterAllList}
            label='Endpoint Tags'
            defaultValue={defaultValues[DASHBOARD_ENDPOINT_TAGS_FIELD]}
          />

          <WizardSelectWithValidation
            control={control}
            error={false}
            isMulti={true}
            errorMessage={''}
            name={DASHBOARD_DEMAND_FEEDS_FIELD}
            options={[]}
            selectType={ASYNC_TYPE}
            label='By Demand'
            filterSelected={filterAllList}
            loadOptions={loadDemandOptions}
            defaultValue={defaultValues[DASHBOARD_DEMAND_FEEDS_FIELD]}
          />

          <WizardSelectWithValidation
            selectType={SELECT_TYPE}
            name={DASHBOARD_DEMAND_TAGS_FIELD}
            control={control}
            error={false}
            errorMessage={''}
            isMulti={true}
            options={[{ label: 'All', value: '*' }, ...tagOptions]}
            placeholder='#mainstream,...'
            filterSelected={filterAllList}
            label='Demand Tags'
            defaultValue={defaultValues[DASHBOARD_DEMAND_TAGS_FIELD]}
          />

          <WizardSelectWithValidation
            control={control}
            error={false}
            isMulti={true}
            errorMessage={''}
            name={DASHBOARD_COUNTRIES_FIELD}
            options={countryOptions}
            selectType={SELECT_TYPE}
            filterSelected={filterAllList}
            label='By Country'
            defaultValue={defaultValues[DASHBOARD_COUNTRIES_FIELD]}
          />

          <WizardSelectWithValidation
            control={control}
            error={false}
            isMulti={false}
            errorMessage={''}
            name={DASHBOARD_ENDPOINT_STATUS_FIELD}
            options={statusOptions}
            selectType={SELECT_TYPE}
            label='By Endpoint Status'
            defaultValue={defaultValues[DASHBOARD_ENDPOINT_STATUS_FIELD]}
          />

          <div className="dashboard-filter-form-last-element">
            <WizardSelectWithValidation
              control={control}
              error={false}
              isMulti={true}
              errorMessage={''}
              name={DASHBOARD_ASSIGNEE_FIELD}
              options={[{ label: 'All', value: '*' }, ...users]}
              selectType={SELECT_TYPE}
              label='By Assignees'
              filterSelected={filterAllList}
              defaultValue={defaultValues[DASHBOARD_ASSIGNEE_FIELD]}
            />
          </div>
        </div>
      </div>

      <div className="panel-footer">
        <button onClick={handleOnSubmit} className="second-btn btn btn-dark-violet">Apply Filters</button>
      </div>
    </div>
  );
};

export default DashboardFilterForm;
