import { allObject } from '../../../../../../reducers/dashboard';

export const addAllObject = (options) => {
  const all = options.find(o => o.value === '*');

  if (!all) {
    return [allObject, ...options];
  }

  return options;
};

export const filterPossibleOptions = (options, possible, field = '_id') => {
  if (!possible) {
    return options;
  }

  const possibleOptions = possible.list;
  const include = possible.include;

  const possibleOptionsMap = {};
  possibleOptions.forEach((option) => {
    possibleOptionsMap[option] = true;
  });

  return options.filter((option) => {
    if (include) {
      return possibleOptionsMap[option[field]];
    }

    return !possibleOptionsMap[option[field]];
  });
};

export const validatePossibleOptions = (possible, field = '_id') => (values) => {
  if (!possible || !values) {
    return true;
  }

  const possibleOptions = possible.list;
  const include = possible.include;

  const possibleOptionsMap = {};
  possibleOptions.forEach((option) => {
    possibleOptionsMap[option] = true;
  });

  for (const value of values) {
    if (include && !possibleOptionsMap[value[field]]) {
      return false;
    }

    if (!include && possibleOptionsMap[value[field]]) {
      return false;
    }
  }

  return true;
};
