import PropTypes from 'prop-types';
import React from 'react';

import TableGlobalSearch from '../shared/TableGlobalSearch';

import './TableSearch.scss';

const TableSearch = ({
  title,
  count,
  searchFields,
  setGlobalFilter,
  globalFilter,
  columns,
  withoutSearchHeaders
}) => {
  const headers = searchFields
    .map((field) => columns.find(h => h.id === field));

  const renderSearchFields = (headers) => (
    headers.map((column, index) => {
      return (
        <div key={index} className="t-search-fields-item">
          <div className="t-search-fields-item-title">{column.render('Header')}:</div>
          <div className="t-search-fields-item-field">{column.render('Filter')}</div>
        </div>
      );
    })
  );

  return (
    <div className="table-search">
      <div className="t-search-text">
        {!withoutSearchHeaders && `${title} ${count}`}
      </div>
      <div className="t-search-fields">
        {renderSearchFields(headers)}

        <TableGlobalSearch
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
    </div>
  );
};

TableSearch.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  searchFields: PropTypes.array.isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
  globalFilter: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  withoutSearchHeaders: PropTypes.bool
};

export default TableSearch;
