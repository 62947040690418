import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  deletePartnerAction,
  duplicatePartnerAction,
  editPartnerAction,
  editPartnerFromNotificationsAction,
  fetchPartnersAction
} from '../../../../actions/partners';

import {
  PARTNER_ADDRESS_FIELD,
  PARTNER_BUSINESS_FIELD,
  PARTNER_EMAIL_FIELD,
  TAGS_FIELD,
  PARTNER_TYPE_FIELD,
  PARTNER_USERNAME_FIELD,
  PARTNER_WIZARD,
  ID_FIELD,
  VALUE_FIELD,
  CREATED_AT_FIELD
} from '../../../wizards-manager/constants';

import { businessTypes, sourceTypes } from '../../../wizards-manager/wizards/partner/partner-fake-data';
import { useTablesEffect } from '../../custom-hooks/useTablesEffect';

import { titleColumn } from '../../helpers';

import TablePanelSpinner from '../../shared/TablePanelSpinner';
import TableSelectFilter from '../../shared/TableSelectFilter';
import TableTagsCell from '../../shared/TableTagsCell';
import TableColumnsActions from '../../table-base/TableColumnsActions';
import TablesManager from '../../TablesManager';

const getPartnerColumns = () => {
  return [
    {
      Header: 'Partner',
      accessor: ID_FIELD,
      width: 140,
      Cell: titleColumn(editPartnerFromNotificationsAction, true)
    },
    {
      Header: 'Source Type',
      accessor: PARTNER_TYPE_FIELD,
      minWidth: 130,
      Filter: TableSelectFilter,
      filter: 'equals',
      Cell: ({ value }) => {
        const data = sourceTypes.find(s => s[VALUE_FIELD] === value);
        return data ? data.label : value;
      }
    },
    {
      Header: 'Tags',
      accessor: TAGS_FIELD,
      minWidth: 120,
      Cell: TableTagsCell
    },
    {
      Header: 'Email',
      accessor: PARTNER_EMAIL_FIELD,
      minWidth: 120
    },
    {
      Header: 'Business Type',
      accessor: PARTNER_BUSINESS_FIELD,
      minWidth: 120,
      Filter: TableSelectFilter,
      filter: 'equals',
      Cell: ({ value }) => {
        const data = businessTypes.find(s => s[VALUE_FIELD] === value);
        return data ? data.label : value;
      }
    },
    {
      Header: 'Username',
      accessor: PARTNER_USERNAME_FIELD,
      minWidth: 120
    },
    {
      Header: 'Address',
      accessor: PARTNER_ADDRESS_FIELD,
      minWidth: 180
    },
    {
      Header: 'Created Date',
      id: 'createdDate',
      accessor: (d) => d[CREATED_AT_FIELD],
      Cell: ({ value }) => moment(value).format('DD/MM/YYYY')
    },
    {
      Header: 'Actions',
      id: 'action',
      width: 50,
      Cell: (data) => {
        const partner = data.row.original;

        return (
          <TableColumnsActions
            editItem={editPartnerAction}
            duplicateItem={duplicatePartnerAction}
            deleteItem={deletePartnerAction}
            data={partner}
          />
        );
      }
    },
    {
      Header: '',
      id: 'all',
      width: 0
    }
  ];
};

const PartnersTable = () => {
  const {
    partners,
    loading
  } = useSelector(state => ({
    partners: state[PARTNER_WIZARD].partners,
    loading: state[PARTNER_WIZARD].loading
  }));

  useTablesEffect(fetchPartnersAction, 'all');

  const searchFields = [PARTNER_BUSINESS_FIELD, PARTNER_TYPE_FIELD];
  const hiddenColumns = [PARTNER_USERNAME_FIELD, PARTNER_ADDRESS_FIELD, CREATED_AT_FIELD];
  const partnerColumns = getPartnerColumns();

  return (
    <>
      {loading ? <TablePanelSpinner/>
        : <TablesManager
          searchFields={searchFields}
          tableName="Partners"
          fields={partnerColumns}
          items={partners}
          hiddenColumns={hiddenColumns}
        />
      }
    </>
  );
};

export default PartnersTable;
