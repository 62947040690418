import PropTypes from 'prop-types';
import React from 'react';

const TableHead = ({
  headerGroups
}) => {
  return (
    <thead>
      {headerGroups.map((headerGroup, index) => (
        <tr key={index} {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column, cIndex) => (
            <th key={cIndex * 123} scope="col" {...column.getHeaderProps(column.getSortByToggleProps())}>
              {column.render('Header')}
              <span>
                {column.isSorted
                  ? column.isSortedDesc
                    ? ' 🔽'
                    : ' 🔼'
                  : ''}
              </span>
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

TableHead.propTypes = {
  headerGroups: PropTypes.array.isRequired
};

export default TableHead;
