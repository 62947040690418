import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import Moment from 'react-moment';
import Select from 'react-select';
import { AsyncPaginateBase } from 'react-select-async-paginate';

import { getAllAppEvents } from '../../actions/settings';
import {
  DASHBOARD_INTERVAL_CUSTOM,
  DASHBOARD_INTERVAL_LAST_48_HOURS,
  DASHBOARD_INTERVAL_LAST_7_DAYS,
  DASHBOARD_INTERVAL_LAST_MONTH,
  DASHBOARD_INTERVAL_THIS_MONTH,
  DASHBOARD_INTERVAL_TODAY,
  DASHBOARD_INTERVAL_YESTERDAY,
  SETTINGS_INTERVAL_INTERVAL,
  SETTINGS_TIMELINE_LOG_DATA_FIELD,
  SETTINGS_TIMELINE_LOG_KIND_FIELD,
  SETTINGS_TIMELINE_LOG_KING_TYPE_FIELD,
  SETTINGS_TIMELINE_LOG_TYPE_FIELD,
  SETTINGS_TIMELINE_LOG_USER_FIELD
} from '../../actions/types';

import TablePanelSpinner from '../tables-manager/shared/TablePanelSpinner';
import TablesManager from '../tables-manager/TablesManager';

import { CREATED_AT_FIELD } from '../wizards-manager/constants';
import { useIsMountedRef } from '../wizards-manager/custom-hooks/useIsMountedRef';
import {
  loadDemandOptions,
  loadEndpointOptions
} from '../wizards-manager/wizards/dashboard/helpers';
import ActionDataCell from './ActionDataCell';
import {
  anyChangeObject,
  anyUserObject,
  buildTimelineLogQuery,
  loadAllUserOptions,
  loadEndpointDemands,
  loadPartners,
  timelineTypeOptions
} from './helpers';

import '../wizards-manager/wizards/dashboard/DashboardDatePickFilterForm.scss';
import './TimelineLog.scss';

const customStyles = {
  indicatorsContainer: (styles) => ({
    ...styles,
    display: 'none'
  })
};

const capitalize = (word) =>
  word.charAt(0).toUpperCase() + word.slice(1);

const transformToCapitalized = (words) =>
  words.split('_')
    .map(capitalize)
    .join('_');

const getTimelineLogColumns = () => [
  {
    Header: 'Date',
    id: 'createdDate',
    accessor: (d) => d[CREATED_AT_FIELD],
    Cell: ({ value }) => moment(value).format('DD/MM/YYYY')
  },
  {
    Header: 'Action',
    accessor: SETTINGS_TIMELINE_LOG_TYPE_FIELD,
    Cell: ({ value }) => transformToCapitalized(value)
  },
  {
    Header: 'Type',
    accessor: SETTINGS_TIMELINE_LOG_KING_TYPE_FIELD,
    Cell: ({ value }) => transformToCapitalized(value)
  },
  {
    Header: 'Asset',
    accessor: SETTINGS_TIMELINE_LOG_KIND_FIELD,
    Cell: ({ value }) => value.toString()
    //  TODO Add name of demand/endpoint
  },
  {
    Header: 'Action Data',
    accessor: SETTINGS_TIMELINE_LOG_DATA_FIELD,
    Cell: ActionDataCell
  },
  {
    Header: 'User',
    accessor: SETTINGS_TIMELINE_LOG_USER_FIELD
  },
  {
    Header: '',
    id: 'all',
    width: 0
  }
];

const intervalObject = {
  [SETTINGS_INTERVAL_INTERVAL]: 'Interval',
  [DASHBOARD_INTERVAL_TODAY]: 'Today',
  [DASHBOARD_INTERVAL_YESTERDAY]: 'Yesterday',
  [DASHBOARD_INTERVAL_LAST_48_HOURS]: 'Last 48 hours',
  [DASHBOARD_INTERVAL_LAST_7_DAYS]: 'Last 7 days',
  [DASHBOARD_INTERVAL_THIS_MONTH]: 'This Month',
  [DASHBOARD_INTERVAL_LAST_MONTH]: 'Last Month'
};

const TimelineLog = () => {
  const isMounted = useIsMountedRef();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const timelineLogColumns = getTimelineLogColumns();

  const [isOpenUsers, setIsOpenUsers] = useState(false);
  const [userInputValue, setUserInputValue] = useState('');
  const [selectedUser, setSelectedUser] = useState(anyUserObject);

  const [selectedType, setSelectedType] = useState(anyChangeObject);

  const [isOpenKind, setIsOpenKind] = useState(false);
  const [kindInputValue, setKindInputValue] = useState('');
  const [selectedKind, setSelectedKind] = useState(anyChangeObject);

  const intervalRef = useRef(null);
  const [interval, setInterval] = useState(SETTINGS_INTERVAL_INTERVAL);
  const [showInterval, setShowInterval] = useState(false);

  const handleOpenInterval = useCallback(() => setShowInterval(state => !state), []);

  const [customInterval, setCustomInterval] = useState(false);
  const [customRange, setCustomRange] = useState({
    from: new Date(),
    to: new Date()
  });

  const isActiveInterval = (currentInterval, interval, customInterval) => currentInterval === interval && !customInterval;

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const handleClickOutside = e => {
    if (!intervalRef.current.contains(e.target)) {
      setShowInterval(false);

      if (interval !== DASHBOARD_INTERVAL_CUSTOM && customInterval) {
        setCustomInterval(false);
      }
    }
  };

  useEffect(() => {
    setLoading(true);

    (async () => {
      const query = buildTimelineLogQuery(
        selectedUser.value,
        selectedType.value,
        selectedKind.value,
        interval,
        customRange
      );

      const res = await getAllAppEvents(query);

      if (isMounted.current) {
        setItems(res);
        setLoading(false);
      }
    })();
  }, [interval, selectedUser, selectedType, selectedKind, isMounted]);

  const renderKindField = useCallback(() => {
    let options;
    let loadOptions;
    let defaultValue = null;

    switch (selectedType.value) {
      case '*': {
        options = [anyChangeObject];
        loadOptions = () => ({
          options: [],
          hasMore: false,
          additional: {
            page: 1
          }
        });
        defaultValue = anyChangeObject;
        break;
      }

      case 'endpoint': {
        options = [];
        loadOptions = loadEndpointOptions;
        break;
      }

      case 'demand': {
        options = [];
        loadOptions = loadDemandOptions;
        break;
      }

      case 'partner': {
        options = [];
        loadOptions = loadPartners;
        break;
      }

      case 'endpoint_demand': {
        options = [];
        loadOptions = loadEndpointDemands;
        break;
      }
    }

    return (
      <AsyncPaginateBase
        key={`${selectedType.value}_${selectedType.value}`} // Hack for disable caching of options
        styles={customStyles}
        components={{ DropdownIndicator: () => null }}
        className='the-field selectable'
        classNamePrefix="react-select"
        isMulti={false}
        closeMenuOnSelect={true}
        onInputChange={(value) => setKindInputValue(value)}
        onMenuClose={() => setIsOpenKind(false)}
        onMenuOpen={() => setIsOpenKind(true)}
        menuIsOpen={isOpenKind}
        inputValue={kindInputValue}
        loadOptions={loadOptions}
        options={options}
        onChange={setSelectedKind}
        debounceTimeout={200}
        additional={{
          page: 1
        }}
        defaultValue={defaultValue}
      />
    );
  }, [selectedType, isOpenKind, kindInputValue]);

  return (
    <div className="timeline-log-container settings-table">
      <h4>Timeline Log</h4>
      <div className="timeline-log-filters row">
        <div className="timeline-log-user-filter col-3">
          <AsyncPaginateBase
            styles={customStyles}
            components={{ DropdownIndicator: () => null }}
            className='the-field selectable'
            classNamePrefix="react-select"
            isMulti={false}
            closeMenuOnSelect={true}
            options={[]}
            loadOptions={loadAllUserOptions}
            onInputChange={(value) => setUserInputValue(value)}
            onMenuClose={() => setIsOpenUsers(false)}
            onMenuOpen={() => setIsOpenUsers(true)}
            menuIsOpen={isOpenUsers}
            inputValue={userInputValue}
            additional={{
              page: 1
            }}
            onChange={setSelectedUser}
            defaultValue={selectedUser}
          />
        </div>

        <div className="timeline-log-kind-type-filter col-3">
          <Select
            components={{ DropdownIndicator: () => null }}
            closeMenuOnSelect={true}
            options={timelineTypeOptions}
            onChange={(value) => {
              setSelectedType(value);
              setSelectedKind(anyChangeObject);
            }}
            styles={customStyles}
            className='the-field selectable'
            classNamePrefix="react-select"
            defaultValue={selectedType}
          />
        </div>

        <div className="timeline-log-kind-filter col-3">
          {renderKindField()}
        </div>

        <div ref={intervalRef} className="dropdown dropdown-arrow-top pos-left timeline-log-interval-filter col-3">
          <button
            className='btn btn-gray dropdown-toggle'
            type='button'
            id='dropdownMenuButton'
            disabled={loading}
            onClick={handleOpenInterval}
          >
            <img src={require('../../assets/images/icons/ico_calendar.svg')} alt=''/>
            <img className='img-active' src={require('../../assets/images/icons/ico_calendar_o.svg')} alt=''/>
            {interval !== DASHBOARD_INTERVAL_CUSTOM ? (
              <span>{intervalObject[interval]}</span>
            ) : (
              <span>
                <Moment format='YYYY/MM/DD'>
                  {customRange.from}
                </Moment>
                &nbsp;-&nbsp;
                <Moment format='YYYY/MM/DD'>
                  {customRange.to}
                </Moment>
              </span>
            )}
          </button>
          <ul
            className={`dashboard-dropdown-menu dropdown-menu ${showInterval ? 'show' : ''}`}
            aria-labelledby='dropdownMenuButton'
          >
            <li>
              <a
                onClick={() => {
                  setInterval(SETTINGS_INTERVAL_INTERVAL);
                  setCustomInterval(false);
                  setShowInterval(false);
                }}
                className={`dropdown-item ${isActiveInterval(interval, SETTINGS_INTERVAL_INTERVAL, customInterval) ? 'active' : ''}`}
              >
                {intervalObject[SETTINGS_INTERVAL_INTERVAL]}
              </a>
            </li>

            <li>
              <a
                onClick={() => {
                  setInterval(DASHBOARD_INTERVAL_YESTERDAY);
                  setCustomInterval(false);
                  setShowInterval(false);
                }}
                className={`dropdown-item ${isActiveInterval(interval, DASHBOARD_INTERVAL_YESTERDAY, customInterval) ? 'active' : ''}`}
              >
                {intervalObject[DASHBOARD_INTERVAL_YESTERDAY]}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  setInterval(DASHBOARD_INTERVAL_TODAY);
                  setCustomInterval(false);
                  setShowInterval(false);
                }}
                className={`dropdown-item ${isActiveInterval(interval, DASHBOARD_INTERVAL_TODAY, customInterval) ? 'active' : ''}`}
              >
                {intervalObject[DASHBOARD_INTERVAL_TODAY]}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  setInterval(DASHBOARD_INTERVAL_LAST_48_HOURS);
                  setCustomInterval(false);
                  setShowInterval(false);
                }}
                className={`dropdown-item ${isActiveInterval(interval, DASHBOARD_INTERVAL_LAST_48_HOURS, customInterval) ? 'active' : ''}`}
              >
                {intervalObject[DASHBOARD_INTERVAL_LAST_48_HOURS]}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  setInterval(DASHBOARD_INTERVAL_LAST_7_DAYS);
                  setCustomInterval(false);
                  setShowInterval(false);
                }}
                className={`dropdown-item ${isActiveInterval(interval, DASHBOARD_INTERVAL_LAST_7_DAYS, customInterval) ? 'active' : ''}`}
              >
                {intervalObject[DASHBOARD_INTERVAL_LAST_7_DAYS]}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  setInterval(DASHBOARD_INTERVAL_THIS_MONTH);
                  setCustomInterval(false);
                  setShowInterval(false);
                }}
                className={`dropdown-item ${isActiveInterval(interval, DASHBOARD_INTERVAL_THIS_MONTH, customInterval) ? 'active' : ''}`}
              >
                {intervalObject[DASHBOARD_INTERVAL_THIS_MONTH]}
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  setInterval(DASHBOARD_INTERVAL_LAST_MONTH);
                  setCustomInterval(false);
                  setShowInterval(false);
                }}
                className={`dropdown-item ${isActiveInterval(interval, DASHBOARD_INTERVAL_LAST_MONTH, customInterval) ? 'active' : ''}`}
              >
                {intervalObject[DASHBOARD_INTERVAL_LAST_MONTH]}
              </a>
            </li>
            <li className='custom-filter'>
              <a
                onClick={() => setCustomInterval(true)}
                className={`dropdown-item ${customInterval ? 'active show-box' : ''}`}
              >
                Custom
              </a>
              <div className='box-custom'>
                <div className='box-item'>
                  <p className='label'>From</p>
                  <div className='box-date'>
                    <DatePicker
                      dateFormat='dd/MM/yyyy'
                      selected={customRange.from}
                      selectsStart
                      startDate={customRange.from}
                      endDate={customRange.from}
                      onChange={(date) => setCustomRange((state) => ({
                        ...state,
                        from: date
                      }))}
                    />
                    <img className="date-picker-icon" src={require('../../assets/images/icons/ico_calendar.svg')}
                      alt=''/>
                  </div>
                </div>

                <div className='box-item'>
                  <p className='label'>To</p>
                  <div className='box-date'>
                    <DatePicker
                      dateFormat='dd/MM/yyyy'
                      selected={customRange.to}
                      selectsEnd
                      startDate={customRange.to}
                      endDate={customRange.to}
                      onChange={(date) => setCustomRange((state) => ({
                        ...state,
                        to: date
                      }))}
                    />
                    <img className="date-picker-icon" src={require('../../assets/images/icons/ico_calendar.svg')}
                      alt=''/>
                  </div>
                </div>
                <p className='apply'>
                  <a onClick={() => setInterval(DASHBOARD_INTERVAL_CUSTOM)} className='btn btn-pink'>
                    Apply
                  </a>
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      {loading ? <TablePanelSpinner/>
        : <TablesManager
          searchFields={[]}
          fields={timelineLogColumns}
          items={items}
          tableName=''
          withoutSearchHeaders={true}
        />
      }
    </div>
  );
};

export default TimelineLog;
