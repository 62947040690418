import React from 'react';

import { useAuth } from '../../../context/auth/AuthState';
import { getChartsList } from './helpers/top-charts-helpers';

import TopChart from './TopChart';

import './TopCharts.scss';

const TopCharts = () => {
  const { isPartner } = useAuth();

  return (
    <div className='top-list-box five-col'>
      <div className='row graph-container'>
        {getChartsList(isPartner).map((title, index) => (
          <div className='col-md-6 col-lg-4 col-xl-3 box-item' key={index}>
            <TopChart title={title}/>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopCharts;
