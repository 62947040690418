import moment from 'moment';
import React from 'react';

import { editDemandFromTableAction } from '../../../../actions/demand';
import { editEndpointFromTableAction } from '../../../../actions/endpoint';
import { editPartnerFromNotificationsAction } from '../../../../actions/partners';
import {
  DASHBOARD_APPROVED_PAYOUTS,
  DASHBOARD_APPROVED_REVENUE,
  DASHBOARD_CLICKS_COUNT,
  DASHBOARD_DATE_FIELD,
  DASHBOARD_DEMAND_FEEDS_FIELD,
  DASHBOARD_ENDPOINT_ID_FIELD,
  DASHBOARD_ENDPOINT_STATUS_FIELD,
  DASHBOARD_EST_PAYOUT,
  DASHBOARD_EST_REVENUE,
  DASHBOARD_IMPS_COUNT,
  DASHBOARD_INT_REQUESTS_COUNT,
  DASHBOARD_INT_REQUESTS_BIDS,
  DASHBOARD_INT_REQUESTS_COVERAGE,
  DASHBOARD_INT_REQUESTS_ERRORS_PCT,
  DASHBOARD_INT_REQUESTS_NO_BIDS,
  DASHBOARD_INT_REQUESTS_WINS,
  DASHBOARD_OS_FIELD,
  DASHBOARD_PLATFORM_FIELD,
  DASHBOARD_PROFIT,
  DASHBOARD_REQUESTS_COUNT,
  DASHBOARD_REQUESTS_BIDS,
  DASHBOARD_REQUESTS_COVERAGE,
  DASHBOARD_REQUESTS_ERRORS,
  DASHBOARD_REQUESTS_ERRORS_PCT,
  DASHBOARD_ROI,
  DASHBOARD_DF_SOURCE_FIELD,
  DASHBOARD_ENDPOINT_NAME_FIELD,
  DASHBOARD_DEMAND_NAME_FIELD,
  DASHBOARD_EP_SOURCE_FIELD,
  DASHBOARD_EP_SOURCE_NAME_FIELD,
  DASHBOARD_DF_SOURCE_NAME_FIELD,
  DASHBOARD_EST_PROFIT,
  DASHBOARD_EST_ROI
} from '../../../../actions/types';

import TableSelectFilter from '../../../tables-manager/shared/TableSelectFilter';
import { formatCurrency, formatNumbers, formatPercentage } from '../../numeric-helpers';

import { dashboardTables } from '../DashboardTableManager';

const getDefaultValues = (field) => {
  switch (field) {
    case DASHBOARD_EST_PROFIT:
    case DASHBOARD_PROFIT:
      return '$0.00 (0%)';

    case DASHBOARD_INT_REQUESTS_COUNT:
    case DASHBOARD_REQUESTS_COUNT:
    case DASHBOARD_IMPS_COUNT:
    case DASHBOARD_CLICKS_COUNT:
    case DASHBOARD_REQUESTS_ERRORS:
      return '0.000 (0%)';

    default:
      return '0.000 (0%)';
  }
};

const renderCellData = (field, roi = false) => (data) => {
  const item = data.row.original;
  const itemValue = item[field] || getDefaultValues(field);
  const splitValue = itemValue.split('(');
  const value = splitValue[0].trim();
  const secondaryValue = splitValue[1].trim().slice(0, -1);

  if (roi) {
    const positive = parseFloat(secondaryValue.slice(0, -1)) >= 0;

    return (
      <>
        {value}
        &nbsp;(<span className={`table-manager-secondary-text ${positive ? 'up' : 'down'}`}>
          {secondaryValue}
        </span>)
      </>
    );
  }

  return (
    <>
      {value}<span className="table-manager-secondary-text">&nbsp;({secondaryValue})</span>
    </>
  );
};

const renderCellID = (table) => ({ value }) => {
  const regExp = /(?:\s\(.+\)?)?\s\((.+)\)$/g;

  const matched = regExp.exec(value);
  let id;

  if (matched) {
    id = matched[1];
  }

  switch (table) {
    case DASHBOARD_ENDPOINT_ID_FIELD:
      return (
        <a
          onClick={() => editEndpointFromTableAction(id)}
          style={{ textDecoration: 'dashed underline', cursor: 'pointer' }}
        >
          {value}
        </a>
      );

    case DASHBOARD_DEMAND_FEEDS_FIELD:
      return (
        <a
          onClick={() => editDemandFromTableAction(id)}
          style={{ textDecoration: 'dashed underline', cursor: 'pointer' }}
        >
          {value}
        </a>
      );

    case DASHBOARD_DF_SOURCE_FIELD:
    case DASHBOARD_EP_SOURCE_FIELD:
      return (
        <a
          onClick={() => editPartnerFromNotificationsAction(id)}
          style={{ textDecoration: 'dashed underline', cursor: 'pointer' }}
        >
          {value}
        </a>
      );

    default:
      return value;
  }
};

export const buildTableColumns = (table, isPartner) => {
  const isDemand = table === DASHBOARD_DEMAND_FEEDS_FIELD || table === DASHBOARD_DF_SOURCE_FIELD;

  if (isPartner) {
    return [
      {
        Header: dashboardTables[table],
        accessor: table,
        Filter: TableSelectFilter,
        filter: 'equals',
        Cell: renderCellID(table)
      },
      {
        Header: 'REQUESTS',
        accessor: isDemand ? DASHBOARD_INT_REQUESTS_COUNT : DASHBOARD_REQUESTS_COUNT,
        Cell: renderCellData(isDemand ? DASHBOARD_INT_REQUESTS_COUNT : DASHBOARD_REQUESTS_COUNT)
      },
      {
        Header: 'Imps',
        accessor: DASHBOARD_IMPS_COUNT,
        Cell: renderCellData(DASHBOARD_IMPS_COUNT)
      },
      {
        Header: 'CLICKS',
        accessor: DASHBOARD_CLICKS_COUNT,
        Cell: renderCellData(DASHBOARD_CLICKS_COUNT)
      },
      {
        Header: 'ERRORS',
        accessor: DASHBOARD_REQUESTS_ERRORS,
        Cell: renderCellData(DASHBOARD_REQUESTS_ERRORS)
      },
      {
        Header: 'EST Earnings',
        accessor: DASHBOARD_EST_PAYOUT
      },
      {
        Header: 'Earnings',
        accessor: DASHBOARD_APPROVED_PAYOUTS
      },
      {
        Header: '',
        id: 'all',
        width: 0
      }
    ];
  }

  return [
    {
      Header: dashboardTables[table],
      accessor: table,
      Filter: TableSelectFilter,
      filter: 'equals',
      Cell: renderCellID(table)
    },
    {
      Header: 'REQUESTS',
      accessor: isDemand ? DASHBOARD_INT_REQUESTS_COUNT : DASHBOARD_REQUESTS_COUNT,
      Cell: renderCellData(isDemand ? DASHBOARD_INT_REQUESTS_COUNT : DASHBOARD_REQUESTS_COUNT)
    },
    {
      Header: 'Imps',
      accessor: DASHBOARD_IMPS_COUNT,
      Cell: renderCellData(DASHBOARD_IMPS_COUNT)
    },
    {
      Header: 'CLICKS',
      accessor: DASHBOARD_CLICKS_COUNT,
      Cell: renderCellData(DASHBOARD_CLICKS_COUNT)
    },
    {
      Header: 'Wins',
      accessor: DASHBOARD_INT_REQUESTS_WINS
    },
    {
      Header: 'ERRORS',
      accessor: DASHBOARD_REQUESTS_ERRORS,
      Cell: renderCellData(DASHBOARD_REQUESTS_ERRORS)
    },
    {
      Header: 'EST Cost',
      accessor: DASHBOARD_EST_PAYOUT
    },
    {
      Header: 'Cost',
      accessor: DASHBOARD_APPROVED_PAYOUTS
    },
    {
      Header: 'Est Revenues',
      accessor: DASHBOARD_EST_REVENUE
    },
    {
      Header: 'Revenues',
      accessor: DASHBOARD_APPROVED_REVENUE
    },
    {
      Header: 'Profit (Roi)',
      accessor: DASHBOARD_PROFIT,
      Cell: renderCellData(DASHBOARD_PROFIT, true)
    },
    {
      Header: 'Est. Profit (Roi)',
      accessor: DASHBOARD_EST_PROFIT,
      Cell: renderCellData(DASHBOARD_EST_PROFIT, true)
    },
    {
      Header: '',
      id: 'all',
      width: 0
    }
  ];
};

export const transformFilters = (filters) =>
  Object.keys(filters)
    .reduce((acc, field) => {
      const value = filters[field];

      if (value) {
        if (field === DASHBOARD_ENDPOINT_STATUS_FIELD) {
          if (value.value === '*') {
            return acc;
          } else {
            acc[field] = value.value;

            return acc;
          }
        }

        const all = value.map(v => v.value).includes('*');

        if (all) {
          return acc;
        }

        acc[field] = filters[field].map(d => d.value);
        return acc;
      }

      return acc;
    }, {});

export const transformTableMetrics = (table, isDemand, isPartner) => {
  if (isPartner) {
    return [
      DASHBOARD_IMPS_COUNT,
      isDemand ? DASHBOARD_INT_REQUESTS_COVERAGE : DASHBOARD_REQUESTS_COVERAGE,
      isDemand ? DASHBOARD_INT_REQUESTS_COUNT : DASHBOARD_REQUESTS_COUNT,
      isDemand ? DASHBOARD_INT_REQUESTS_BIDS : DASHBOARD_REQUESTS_BIDS,
      isDemand ? DASHBOARD_INT_REQUESTS_NO_BIDS : DASHBOARD_REQUESTS_ERRORS,
      isDemand ? DASHBOARD_INT_REQUESTS_ERRORS_PCT : DASHBOARD_REQUESTS_ERRORS_PCT,
      DASHBOARD_CLICKS_COUNT,
      DASHBOARD_EST_PAYOUT,
      DASHBOARD_APPROVED_PAYOUTS
    ];
  }

  return [
    DASHBOARD_IMPS_COUNT,
    isDemand ? DASHBOARD_INT_REQUESTS_COVERAGE : DASHBOARD_REQUESTS_COVERAGE,
    isDemand ? DASHBOARD_INT_REQUESTS_COUNT : DASHBOARD_REQUESTS_COUNT,
    isDemand ? DASHBOARD_INT_REQUESTS_BIDS : DASHBOARD_REQUESTS_BIDS,
    isDemand ? DASHBOARD_INT_REQUESTS_NO_BIDS : DASHBOARD_REQUESTS_ERRORS,
    isDemand ? DASHBOARD_INT_REQUESTS_ERRORS_PCT : DASHBOARD_REQUESTS_ERRORS_PCT,
    DASHBOARD_CLICKS_COUNT,
    DASHBOARD_INT_REQUESTS_WINS,
    DASHBOARD_EST_PAYOUT,
    DASHBOARD_APPROVED_PAYOUTS,
    DASHBOARD_EST_REVENUE,
    DASHBOARD_APPROVED_REVENUE,
    DASHBOARD_PROFIT,
    DASHBOARD_EST_PROFIT,
    DASHBOARD_ROI,
    DASHBOARD_EST_ROI
  ];
};

const capitalize = (word) =>
  word.charAt(0).toUpperCase() + word.slice(1);

export const transformToCapitalized = (words) =>
  words.split(' ')
    .map(capitalize)
    .join(' ');

export const transformResponseToTableData = (list, table, isDemand, isPartner, isToday) =>
  list.map(d => {
    const data = {};

    data[table] = d[table];

    if (table === DASHBOARD_ENDPOINT_ID_FIELD) {
      data[table] = `${d[DASHBOARD_ENDPOINT_NAME_FIELD]} (${d[table]})`;
    }

    if (table === DASHBOARD_DEMAND_FEEDS_FIELD) {
      data[table] = `${d[DASHBOARD_DEMAND_NAME_FIELD]} (${d[table]})`;
    }

    if (table === DASHBOARD_EP_SOURCE_FIELD) {
      data[table] = `${d[DASHBOARD_EP_SOURCE_NAME_FIELD]} (${d[table]})`;
    }

    if (table === DASHBOARD_DF_SOURCE_FIELD) {
      data[table] = `${d[DASHBOARD_DF_SOURCE_NAME_FIELD]} (${d[table]})`;
    }

    if (table === DASHBOARD_DATE_FIELD) {
      data[table] = moment(new Date(d[table])).format(isToday ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY');
    }

    if (table === DASHBOARD_PLATFORM_FIELD || table === DASHBOARD_OS_FIELD) {
      data[table] = transformToCapitalized(d[table]);
    }

    const reqCountField = isDemand ? DASHBOARD_INT_REQUESTS_COUNT : DASHBOARD_REQUESTS_COUNT;
    const reqCovField = isDemand ? DASHBOARD_INT_REQUESTS_COVERAGE : DASHBOARD_REQUESTS_COVERAGE;
    const reqCounts = d[reqCountField];

    data[isDemand ? DASHBOARD_INT_REQUESTS_COUNT : DASHBOARD_REQUESTS_COUNT] = `${formatNumbers(reqCounts)} (${formatPercentage(d[reqCovField])})`;

    const clickCounts = d[DASHBOARD_CLICKS_COUNT];

    const clicksValue = reqCounts
      ? clickCounts / reqCounts
      : reqCounts;

    data[DASHBOARD_CLICKS_COUNT] = `${formatNumbers(clickCounts)} (${formatPercentage(clicksValue)})`;

    data[DASHBOARD_EST_PAYOUT] = formatCurrency(d[DASHBOARD_EST_PAYOUT]);
    data[DASHBOARD_APPROVED_PAYOUTS] = formatCurrency(d[DASHBOARD_APPROVED_PAYOUTS]);

    const errorsField = isDemand ? DASHBOARD_INT_REQUESTS_NO_BIDS : DASHBOARD_REQUESTS_ERRORS;
    const reqPctField = isDemand ? DASHBOARD_INT_REQUESTS_ERRORS_PCT : DASHBOARD_REQUESTS_ERRORS_PCT;

    data[DASHBOARD_REQUESTS_ERRORS] = `${formatNumbers(d[errorsField])} (${formatPercentage(d[reqPctField])})`;

    if (!isPartner) {
      const impCounts = d[DASHBOARD_IMPS_COUNT];

      const impValue = impCounts
        ? clickCounts / impCounts
        : impCounts;

      data[DASHBOARD_IMPS_COUNT] = `${formatNumbers(impCounts)} (${formatPercentage(impValue)})`;

      const profit = d[DASHBOARD_PROFIT];
      const roi = d[DASHBOARD_ROI];

      const estProfit = d[DASHBOARD_EST_PROFIT];
      const estRoi = d[DASHBOARD_EST_ROI];

      data[DASHBOARD_PROFIT] = `${formatCurrency(profit)} (${formatPercentage(roi)})`;
      data[DASHBOARD_EST_PROFIT] = `${formatCurrency(estProfit)} (${formatPercentage(estRoi)})`;
      data[DASHBOARD_EST_REVENUE] = formatCurrency(d[DASHBOARD_EST_REVENUE]);
      data[DASHBOARD_APPROVED_REVENUE] = formatCurrency(d[DASHBOARD_APPROVED_REVENUE]);
      data[DASHBOARD_INT_REQUESTS_WINS] = formatNumbers(d[DASHBOARD_INT_REQUESTS_WINS]);
    }

    return data;
  });
