import {
  ENDPOINT_DEMANDS_WIZARD,
  FILTERS_FIELD,
  OPTIMIZATION_FIELD,
  TARGETING_FIELD
} from '../../constants';

import { validateFormData } from '../shared';
import { validateEndpointDemandsOptimizationData } from './optimization';
import { validateEndpointDemandsProtectionData } from './protection';
import { validateEndpointDemandsTargetingData } from './targeting';

const endpointDemandsForms = [
  {
    validator: validateEndpointDemandsOptimizationData,
    field: OPTIMIZATION_FIELD
  },
  {
    validator: validateEndpointDemandsTargetingData,
    field: TARGETING_FIELD
  },
  {
    validator: validateEndpointDemandsProtectionData,
    field: FILTERS_FIELD
  }
];

export const validateEndpointDemandsFormData = (toForm, exclude) => validateFormData(ENDPOINT_DEMANDS_WIZARD, [...endpointDemandsForms], toForm, exclude);
