import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
  SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD,
  SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD,
  SETTINGS_MANUAL_REVENUE_DEMAND_ID,
  SETTINGS_MANUAL_REVENUE_REPORT_DATE_FIELD,
  SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD
} from '../../actions/types';
import { timezonesOptions } from '../wizards-manager/wizards/dashboard/helpers/timezones';

import WizardInputWithValidation from '../wizards-manager/wizards/shared/WizardInputWithValidation';
import WizardSelectWithValidation, {
  ASYNC_TYPE,
  DATE_PICK_TYPE,
  SELECT_TYPE
} from '../wizards-manager/wizards/shared/WizardSelectWithValidation';

import { loadAllDemandOptions } from './helpers';
import { transformManualRevenueData } from './helpers/transformers';
import { manualRevenueUpdateSchema } from './helpers/validators';

import './ManualRevenueUpdateForm.scss';

const defaultValues = {
  [SETTINGS_MANUAL_REVENUE_REPORT_DATE_FIELD]: null,
  [SETTINGS_MANUAL_REVENUE_DEMAND_ID]: null,
  [SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD]: null,
  [SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD]: null,
  [SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD]: null
};

const ManualRevenueUpdateFrom = ({
  headerGroup,
  handleCreateItem,
  setRowState
}) => {
  const headers = headerGroup.headers;

  const {
    register,
    errors,
    reset,
    control,
    getValues,
    triggerValidation,
    watch
  } = useForm({
    mode: 'onBlur',
    validationSchema: manualRevenueUpdateSchema,
    defaultValues: defaultValues
  });

  const handleSave = useCallback(async (e) => {
    e.preventDefault();

    const isValid = await triggerValidation();

    if (!isValid) {
      return;
    }

    const data = getValues({ nest: true });
    const transformedData = transformManualRevenueData(data);

    const res = await handleCreateItem(transformedData);

    if (res) {
      reset(defaultValues);
      setRowState(0, (old) => ({
        ...old,
        [SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD]: res[SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD],
        [SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD]: res[SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD],
        [SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD]: res[SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD]
      }));
    }
  }, []);

  const dateValue = watch(SETTINGS_MANUAL_REVENUE_REPORT_DATE_FIELD, defaultValues[SETTINGS_MANUAL_REVENUE_REPORT_DATE_FIELD]);

  return (
    <tr className="manual-revenue-update-form" {...headerGroup.getHeaderGroupProps()}>
      <th scope="col" {...headers[0].getHeaderProps()}>
        <WizardSelectWithValidation
          control={control}
          isMulti={false}
          selectType={DATE_PICK_TYPE}
          options={[]}
          error={!!errors[SETTINGS_MANUAL_REVENUE_REPORT_DATE_FIELD]}
          errorMessage={errors[SETTINGS_MANUAL_REVENUE_REPORT_DATE_FIELD] ? errors[SETTINGS_MANUAL_REVENUE_REPORT_DATE_FIELD].message : ''}
          name={SETTINGS_MANUAL_REVENUE_REPORT_DATE_FIELD}
          removeIndicator={true}
          rules={{ required: true }}
          dateValue={dateValue}
        />
      </th>
      <th scope="col" {...headers[1].getHeaderProps()}>
        <WizardSelectWithValidation
          control={control}
          error={!!errors[SETTINGS_MANUAL_REVENUE_DEMAND_ID]}
          errorMessage={errors[SETTINGS_MANUAL_REVENUE_DEMAND_ID] ? 'Demand is required' : ''}
          isMulti={false}
          selectType={ASYNC_TYPE}
          options={[]}
          name={SETTINGS_MANUAL_REVENUE_DEMAND_ID}
          loadOptions={loadAllDemandOptions}
          removeIndicator={true}
          rules={{ required: true }}
        />
      </th>
      <th scope="col" {...headers[2].getHeaderProps()}>
        <WizardSelectWithValidation
          control={control}
          error={!!errors[SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD]}
          errorMessage={errors[SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD] ? 'Timezone is required' : ''}
          isMulti={false}
          selectType={SELECT_TYPE}
          options={timezonesOptions}
          removeIndicator={true}
          name={SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD}
        />
      </th>
      <th scope="col" {...headers[3].getHeaderProps()}>
        <WizardInputWithValidation
          type='number'
          error={!!errors[SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD]}
          errorMessage={errors[SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD] ? errors[SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD].message : ''}
          name={SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD}
          propRef={register}
        />
      </th>
      <th scope="col" {...headers[4].getHeaderProps()}>
        <WizardInputWithValidation
          type='number'
          error={!!errors[SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD]}
          errorMessage={errors[SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD] ? errors[SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD].message : ''}
          name={SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD}
          propRef={register}
        />
      </th>
      <th scope="col" {...headers[5].getHeaderProps()}/>
      <th className="save-report-box" scope="col" {...headers[6].getHeaderProps()}>
        <div className="save-report-btn" onClick={handleSave}>
          <i className="fa fa-plus"/>
          <span>Add</span>
        </div>
      </th>
    </tr>
  );
};

ManualRevenueUpdateFrom.propTypes = {
  headerGroup: PropTypes.any.isRequired,
  handleCreateItem: PropTypes.func.isRequired,
  setRowState: PropTypes.func.isRequired
};

export default ManualRevenueUpdateFrom;
