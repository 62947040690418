import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchTableData } from '../../../actions/dashboard';

import {
  DASHBOARD_COUNTRIES_FIELD,
  DASHBOARD_DATE_FIELD,
  DASHBOARD_DEMAND_FEEDS_FIELD,
  DASHBOARD_PLATFORM_FIELD,
  DASHBOARD_ENDPOINT_ID_FIELD,
  DASHBOARD_OS_FIELD,
  DASHBOARD_SUB_BIDS_FIELD,
  DASHBOARD_INTERVAL,
  DASHBOARD_FILTERS,
  DASHBOARD_IMPS_COUNT,
  DASHBOARD_INT_REQUESTS_WINS,
  DASHBOARD_EST_PAYOUT,
  DASHBOARD_EST_REVENUE,
  DASHBOARD_EP_SOURCE_FIELD,
  DASHBOARD_DF_SOURCE_FIELD,
  DASHBOARD_EST_PROFIT,
  DASHBOARD_INTERVAL_TODAY,
  DASHBOARD_PROFIT,
  DASHBOARD_APPROVED_PAYOUTS,
  DASHBOARD_APPROVED_REVENUE,
  DASHBOARD_INTERVAL_LAST_48_HOURS,
  DASHBOARD_INTERVAL_YESTERDAY
} from '../../../actions/types';

import { useAuth } from '../../../context/auth/AuthState';
import DashboardTable from '../../tables-manager/tables/dashboard/DashboardTable';

import { buildTableColumns } from './helpers/table-manager-helpers';

import './DashboardTableManger.scss';

export const dashboardTables = {
  [DASHBOARD_DATE_FIELD]: 'Date',
  [DASHBOARD_ENDPOINT_ID_FIELD]: 'Endpoint',
  [DASHBOARD_EP_SOURCE_FIELD]: 'Traffic Source',
  [DASHBOARD_DEMAND_FEEDS_FIELD]: 'Demand',
  [DASHBOARD_DF_SOURCE_FIELD]: 'Demand Source',
  [DASHBOARD_PLATFORM_FIELD]: 'Platform',
  [DASHBOARD_COUNTRIES_FIELD]: 'Country',
  [DASHBOARD_OS_FIELD]: 'OS',
  [DASHBOARD_SUB_BIDS_FIELD]: 'SubId'
};

export const getDashboardTables = (isPartner) => {
  if (isPartner) {
    return {
      [DASHBOARD_DATE_FIELD]: 'Date',
      [DASHBOARD_ENDPOINT_ID_FIELD]: 'Endpoint',
      [DASHBOARD_PLATFORM_FIELD]: 'Platform',
      [DASHBOARD_COUNTRIES_FIELD]: 'Country',
      [DASHBOARD_OS_FIELD]: 'OS',
      [DASHBOARD_SUB_BIDS_FIELD]: 'SubId'
    };
  }

  return {
    [DASHBOARD_DATE_FIELD]: 'Date',
    [DASHBOARD_ENDPOINT_ID_FIELD]: 'Endpoint',
    [DASHBOARD_EP_SOURCE_FIELD]: 'Traffic Source',
    [DASHBOARD_DEMAND_FEEDS_FIELD]: 'Demand',
    [DASHBOARD_DF_SOURCE_FIELD]: 'Demand Source',
    [DASHBOARD_PLATFORM_FIELD]: 'Platform',
    [DASHBOARD_COUNTRIES_FIELD]: 'Country',
    [DASHBOARD_OS_FIELD]: 'OS',
    [DASHBOARD_SUB_BIDS_FIELD]: 'SubId'
  };
};

const DashboardTableManager = () => {
  const dispatch = useDispatch();

  const [table, setTable] = useState(DASHBOARD_DATE_FIELD);
  const [tableColumns, setTableColumns] = useState([]);
  const { isPartner } = useAuth();

  const {
    filters,
    interval,
    loading,
    dateRange
  } = useSelector(
    state => ({
      filters: state.dashboard[DASHBOARD_FILTERS],
      interval: state.dashboard[DASHBOARD_INTERVAL],
      loading: state.dashboard.tableLoading,
      dateRange: state.dashboard.dateRange
    })
  );

  useEffect(() => {
    if (isPartner === null) {
      return;
    }

    fetchTableData(table, interval, { ...filters }, dateRange, isPartner)(dispatch);
    setTableColumns(buildTableColumns(table, isPartner));
  }, [table, JSON.stringify(filters), interval, JSON.stringify(dateRange), isPartner]);

  const getHiddenColumns = useCallback((isPartner, table, interval) => {
    if (isPartner) {
      return [
        DASHBOARD_IMPS_COUNT
      ];
    }

    if (interval === DASHBOARD_INTERVAL_TODAY ||
      interval === DASHBOARD_INTERVAL_LAST_48_HOURS ||
      interval === DASHBOARD_INTERVAL_YESTERDAY
    ) {
      return [
        DASHBOARD_IMPS_COUNT,
        DASHBOARD_INT_REQUESTS_WINS,
        DASHBOARD_PROFIT,
        DASHBOARD_APPROVED_PAYOUTS,
        DASHBOARD_APPROVED_REVENUE
      ];
    }

    return [
      DASHBOARD_IMPS_COUNT,
      DASHBOARD_INT_REQUESTS_WINS,
      DASHBOARD_EST_PAYOUT,
      DASHBOARD_EST_REVENUE,
      DASHBOARD_EST_PROFIT
    ];
  }, []);

  const hiddenColumns = getHiddenColumns(isPartner, table, interval);

  return (
    <div className="dashboard-table-manager">
      <div className="table-tabs">
        {Object.keys(getDashboardTables(isPartner)).map((t, index) => (
          <button
            disabled={loading}
            onClick={() => {
              if (!loading) {
                setTable(t);
              }
            }}
            className={`table-item ${table === t ? 'active-table' : ''}`}
            key={index}
          >
            {dashboardTables[t]}
          </button>
        ))}
      </div>
      <div className="dashboard-table">
        <DashboardTable
          columns={tableColumns}
          hiddenColumns={hiddenColumns}
        />
      </div>
    </div>
  );
};

export default DashboardTableManager;
