import {
  RULES_ACTION_DATA_FIELD,
  RULES_ACTION_FIELD,
  RULES_ANALYZE_INTERVAL_FIELD,
  RULES_FREQUENCY_DAYS_FIELD,
  RULES_FREQUENCY_INTERVAL_FIELD,
  RULES_TAGS_FIELD
} from '../../../actions/types';

import { transformTagsToFormTags } from '../../tables-manager/helpers/transormers-helpers';
import { transformMultiSelect, transformSingleSelect } from '../../wizards-manager/helpers/wizard-helpers';
import {
  rulesAnalyzeIntervals,
  rulesFrequencyDays,
  rulesFrequencyIntervals,
  rulesTempActions,
  rulesTempTags
} from './index';

export const transformRuleData = (data) =>
  Object.keys(data)
    .reduce((acc, field) => {
      switch (field) {
        case RULES_ACTION_FIELD:
        case RULES_FREQUENCY_INTERVAL_FIELD:
        case RULES_ANALYZE_INTERVAL_FIELD:
          acc[field] = transformSingleSelect(data[field]);
          break;

        case RULES_TAGS_FIELD:
          acc[field] = transformMultiSelect(data[field]);
          break;

        case RULES_ACTION_DATA_FIELD:
          acc[field] = parseFloat(data[field]);
          break;

        case RULES_FREQUENCY_DAYS_FIELD:
          acc[field] = data[field].filter(d => d.selected).map(d => d.value);
          break;

        default:
          acc[field] = data[field];
          break;
      }

      return acc;
    }, {});

export const transformRuleToFromData = (data) =>
  Object.keys(data)
    .reduce((acc, field) => {
      switch (field) {
        case RULES_TAGS_FIELD:
          acc[field] = transformTagsToFormTags(data[field], rulesTempTags);
          break;

        case RULES_ACTION_FIELD:
          acc[field] = rulesTempActions.find(a => a.value === data[field]);
          break;

        case RULES_ANALYZE_INTERVAL_FIELD:
          acc[field] = rulesAnalyzeIntervals.find(i => i.value === data[field]);
          break;

        case RULES_FREQUENCY_DAYS_FIELD:
          acc[field] = rulesFrequencyDays.map((d) => {
            const selected = data[field].includes(d.value);

            if (!selected) {
              d.selected = false;
            }

            return d;
          });

          break;

        case RULES_FREQUENCY_INTERVAL_FIELD:
          acc[field] = rulesFrequencyIntervals.find(i => i.value === data[field]);
          break;

        default:
          acc[field] = data[field];
          break;
      }

      return acc;
    }, {});
