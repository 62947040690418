import React, { useContext, useEffect } from 'react';
import { Switch } from 'react-router-dom';

import { getStaticList } from '../../actions/staticList';
import AuthContext from '../../context/auth/authContext';

import store from '../../store';

import Dashboard from '../dashboard/Dashboard';
import Layout from '../layout/Layout';

import Reports from '../reports/Reports';
import RulesTable from '../rules/RulesTable';
import Settings from '../settings/Settings';
import DemandsTable from '../tables-manager/tables/demands/DemandsTable';
import EndpointsTable from '../tables-manager/tables/endpoints/EndpointsTable';
import PartnersTable from '../tables-manager/tables/partners/PartnersTable';

import PrivateRoute from './PrivateRoute';

const Routes = () => {
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    if (isAuthenticated) {
      store.dispatch(getStaticList());
    }
  }, [isAuthenticated]);

  return (
    <Layout>
      <Switch>
        <PrivateRoute exact path="/dashboard" component={Dashboard}/>
        <PrivateRoute exact path="/partners" component={PartnersTable}/>
        <PrivateRoute exact path="/endpoints" component={EndpointsTable}/>
        <PrivateRoute exact path="/demand" component={DemandsTable}/>
        <PrivateRoute exact path="/reports" component={Reports}/>
        <PrivateRoute exact path="/rules" component={RulesTable}/>
        <PrivateRoute exact path="/settings" component={Settings}/>
        {/* TODO To be created */}
        {/* */}
        {/* <PrivateRoute exact path="/admin" component={AdminPage}/> */}
      </Switch>
    </Layout>
  );
};

export default Routes;
