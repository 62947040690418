import React, { Fragment, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useAuth } from '../../context/auth/AuthState';

import './Landing.scss';

const Landing = () => {
  const { isAuthenticated, loadUser } = useAuth();
  const history = useHistory();

  useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/dashboard');
    }
  }, [isAuthenticated, history]);

  return (
    <Fragment>
      <div className="landing2 text-center">
        <div className="d-flex h-100 p-3 mx-auto flex-column">
          <div className="mt-auto">
            <div className="col-md-6">
              <h1 className="display-4">DigitalFlare App</h1>
              <p className="lead">Data Driven Optimization Engine</p>
              <p className="lead">
                <Link
                  to="/register"
                  className="btn btn-pink btn-lg mr-3"
                  role="button"
                >
                  Sign Up
                </Link>
                <Link to="/login" className="btn btn-pink-light btn-lg ">
                  Login
                </Link>
              </p>
            </div>
          </div>
          <footer className="mt-auto p-4">
            Copyright &copy; {new Date().getFullYear()} DigitalFlare
          </footer>
        </div>
      </div>
    </Fragment>
  );
};

export default Landing;
