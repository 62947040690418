import React, { useCallback, useState } from 'react';

import {
  SETTINGS_MANUAL_REVENUE_TABLE,
  SETTINGS_REVENUE_SYNC_TABLE,
  SETTINGS_TIMELINE_LOG_TABLE
} from '../../actions/types';

import ManualRevenueUpdate from './ManualRevenueUpdate';
import RevenueSyncStatus from './RevenueSyncStatus';
import SettingsSideBar from './SettingsSideBar';
import TimelineLog from './TimelineLog';

import './Settings.scss';

const Settings = () => {
  const [tab, setTab] = useState(SETTINGS_REVENUE_SYNC_TABLE);

  const renderTable = useCallback((tab) => {
    switch (tab) {
      case SETTINGS_REVENUE_SYNC_TABLE:
        return <RevenueSyncStatus/>;

      case SETTINGS_MANUAL_REVENUE_TABLE:
        return <ManualRevenueUpdate/>;

      case SETTINGS_TIMELINE_LOG_TABLE:
        return <TimelineLog/>;
    }
  }, []);

  return (
    <div className="settings-container">
      <SettingsSideBar tab={tab} changeTab={setTab}/>

      <div className="settings-table-box">
        {renderTable(tab)}
      </div>
    </div>
  );
};

export default Settings;
