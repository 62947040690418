import {
  fetchStaticList
} from '../api/data';

import {
  REQUEST_LOAD_STATIC_LIST,
  GET_STATIC_LIST_ERROR,
  GET_STATIC_LIST
} from './types';

export const getStaticList = () => async dispatch => {
  try {
    dispatch({ type: REQUEST_LOAD_STATIC_LIST });

    const [
      tags,
      carrier,
      iabcats,
      connectiontype,
      domainMasking,
      metrics,
      countries,
      actionList,
      redirectDomainList,
      osList,
      browserList,
      deviceList,
      pluginList,
      rulesTags,
      rulesActions
    ] = await fetchStaticList();

    const payload = {
      tagOptions: tags.data,
      carrierOptions: carrier.data,
      iabcatOptions: iabcats.data,
      connectionTypeOptions: connectiontype.data,
      domainMaskingOptions: domainMasking.data,
      metrics: metrics.data,
      countryOptions: countries.data,
      actionOptions: actionList.data,
      redirectDomainOptions: redirectDomainList.data,
      osOptions: osList.data,
      browserOptions: browserList.data,
      deviceOptions: deviceList.data,
      feedOptions: pluginList.data.filter(e => e.type === 'bidder'),
      statsOptions: pluginList.data.filter(e => e.type === 'stats'),
      rulesTags: rulesTags.data,
      rulesActions: rulesActions.data
    };

    dispatch({
      type: GET_STATIC_LIST,
      payload
    });
  } catch (err) {
    dispatch({ type: GET_STATIC_LIST_ERROR });
    console.log('Loading static list error');
  }
};
