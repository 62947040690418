import moment from 'moment';

import {
  DASHBOARD_INTERVAL,
  DASHBOARD_INTERVAL_YESTERDAY, DASHBOARD_TIMEZONE_FIELD,
  REPORTS_BREAKDOWN_FIELD,
  REPORTS_CREATE_REPORT,
  REPORTS_DATE_RANGE_FIELD,
  REPORTS_DELETE_REPORT,
  REPORTS_DIMENSION_FILTERS_FIELD,
  REPORTS_DIMENSIONS_FIELD,
  REPORTS_EDIT_REPORT,
  REPORTS_FETCH_REPORT_TABLE_DATA,
  REPORTS_FETCH_REPORTS,
  REPORTS_GENERATE_REPORT,
  REPORTS_GET_INITIAL_FORM_STATE,
  REPORTS_LOAD_REPORT,
  REPORTS_METRIC_FILTERS_FIELD,
  REPORTS_METRICS_FIELD,
  REPORTS_START_FETCHING_REPORT_TABLE_DATA
} from '../actions/types';

export const initialReportsFormDataState = {
  [DASHBOARD_TIMEZONE_FIELD]: {
    label: '(GMT+00:00) UTC',
    value: '+0000'
  },
  [DASHBOARD_INTERVAL]: DASHBOARD_INTERVAL_YESTERDAY,
  [REPORTS_DATE_RANGE_FIELD]: {
    from: moment(new Date()).startOf('day').toDate(),
    to: moment(new Date()).endOf('day').toDate()
  },
  [REPORTS_BREAKDOWN_FIELD]: {
    label: 'None',
    value: 'none'
  },
  [REPORTS_DIMENSIONS_FIELD]: [],
  [REPORTS_METRICS_FIELD]: [],
  [REPORTS_METRIC_FILTERS_FIELD]: [],
  [REPORTS_DIMENSION_FILTERS_FIELD]: []
};

const initialReportsState = {
  reports: [],
  formData: initialReportsFormDataState,
  tableQuery: initialReportsFormDataState,
  loading: false,
  tableData: {
    items: [],
    columns: [],
    hiddenColumns: []
  },
  forceRerender: false,
  startFetching: false
};

export default function (state = initialReportsState, action) {
  const { type, payload } = action;

  switch (type) {
    case REPORTS_CREATE_REPORT:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload.formData
        },
        tableQuery: {
          ...state.tableQuery,
          ...payload.formData
        },
        reports: [payload.report, ...state.reports],
        forceRerender: !state.forceRerender,
        startFetching: true
      };

    case REPORTS_LOAD_REPORT:
      return {
        ...state,
        tableQuery: {
          ...state.tableQuery,
          ...payload
        },
        forceRerender: !state.forceRerender,
        startFetching: true
      };

    case REPORTS_EDIT_REPORT:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload
        }
      };

    case REPORTS_GENERATE_REPORT:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload
        },
        tableQuery: {
          ...state.tableQuery,
          ...payload
        },
        forceRerender: !state.forceRerender,
        startFetching: true
      };

    case REPORTS_FETCH_REPORTS:
      return {
        ...state,
        reports: payload
      };

    case REPORTS_START_FETCHING_REPORT_TABLE_DATA:
      return {
        ...state,
        loading: true
      };

    case REPORTS_FETCH_REPORT_TABLE_DATA:
      return {
        ...state,
        loading: false,
        tableData: payload
      };

    case REPORTS_GET_INITIAL_FORM_STATE:
      return {
        ...state,
        loading: false,
        formData: initialReportsFormDataState,
        tableQuery: initialReportsFormDataState
      };

    case REPORTS_DELETE_REPORT:
      return {
        ...state,
        reports: state.reports.filter(item => item.id !== payload)
      };

    default:
      return state;
  }
};
