import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { fetchPieChartData } from '../../../actions/dashboard';
import {
  DASHBOARD_ENDPOINT_ID_FIELD,
  DASHBOARD_FILTERS,
  DASHBOARD_INTERVAL,
  DASHBOARD_REQUESTS_COUNT
} from '../../../actions/types';

import arrow from '../../../assets/images/icons/ico_arrow_down.svg';
import { useAuth } from '../../../context/auth/AuthState';
import TablePanelSpinner from '../../tables-manager/shared/TablePanelSpinner';

import {
  buildDonutItems,
  buildDonutLabels,
  checkIsDemand,
  dimensionsOptions,
  donutOptions,
  getDimensionsOptions,
  getDonutFormatter,
  getMetricsOptions,
  metricsOptions
} from './helpers/donut-chart-helpers';

import './DonutChart.scss';

const DonutChart = () => {
  const dispatch = useDispatch();
  const { isPartner } = useAuth();

  const {
    data,
    loading,
    filters,
    interval,
    dateRange
  } = useSelector(state => ({
    data: state.dashboard.pieChart,
    loading: state.dashboard.loadingPieChart,
    filters: state.dashboard[DASHBOARD_FILTERS],
    interval: state.dashboard[DASHBOARD_INTERVAL],
    dateRange: state.dashboard.dateRange
  }));

  const [dimension, setDimension] = useState(DASHBOARD_ENDPOINT_ID_FIELD);
  const [metric, setMetric] = useState(DASHBOARD_REQUESTS_COUNT);
  const [grouped, setGrouped] = useState({
    items: [],
    labels: []
  });

  const chartOptions = {
    ...donutOptions,
    labels: grouped.labels,
    tooltip: {
      y: {
        formatter: (value) => getDonutFormatter(metric)(value)
      }
    }
  };

  // Build labels
  useEffect(() => {
    const isDemand = checkIsDemand(dimension, filters);

    setGrouped({
      labels: buildDonutLabels(data, dimension),
      items: buildDonutItems(data, metric, isDemand)
    });
  }, [JSON.stringify(data)]);

  useEffect(() => {
    (async () => {
      await fetchPieChartData(dimension, { ...filters }, interval, dateRange, isPartner)(dispatch);
    })();
  }, [JSON.stringify(filters), interval, JSON.stringify(dateRange), dimension, isPartner]);

  const handleChangeMetrics = (metric) => {
    const isDemand = checkIsDemand(dimension, filters);
    const items = buildDonutItems(data, metric, isDemand);

    setGrouped(state => ({
      ...state,
      items
    }));
    setMetric(metric);
  };

  return (
    <div className='donut-chart-box block-inner'>
      <div className='donut-chart clearfix'>
        <div className='donut-chart-head'>
          <div className="donut-chart-head-left">
            <Dropdown>
              <Dropdown.Toggle variant='user' id='dropdown-basic' style={{ textTransform: 'capitalize' }}>
                <span>{dimensionsOptions[dimension]}</span>
                <img className='arrow' src={arrow} alt='arrow'/>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {Object.keys(getDimensionsOptions(isPartner)).map((d, index) => (
                  <Dropdown.Item
                    href="#!"
                    key={index}
                    onClick={() => setDimension(d)}
                  >
                    {dimensionsOptions[d]}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="donut-chart-head-right">
            <Dropdown>
              <Dropdown.Toggle variant='user' id='dropdown-basic' style={{ textTransform: 'capitalize' }}>
                <img className='arrow' src={arrow} alt='arrow'/>
                <span>{metricsOptions[metric]}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {Object.keys(getMetricsOptions(isPartner)).map((m, index) => (
                  <Dropdown.Item
                    href="#!"
                    key={index}
                    onClick={() => handleChangeMetrics(m)}
                  >
                    {metricsOptions[m]}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className='donut-chart-chart block-content'>
          {loading ? <div className="donut-chart-loading"><TablePanelSpinner/></div> : (
            <ReactApexChart
              options={{ ...chartOptions, [metric]: true }}
              series={grouped.items.length ? grouped.items : [{ data: [] }]}
              type="donut"
              height="300"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DonutChart;
