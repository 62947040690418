import { toast } from 'react-toastify';

import {
  fetchDemandById,
  removeDemand,
  saveDemand,
  modifyDemand
} from '../api/demand';

import { fetchEndpointById } from '../api/endpoint';
import { fetchEndpointDemandByDemandId } from '../api/endpointDemand';
import {
  createEndpointDemands,
  deleteEndpointDemand,
  updateEndpointDemand
} from '../api/helpers/endpoint-demand';
import {
  transformDemandToFormData,
  transformEndpointsOrDemandsToTableData
} from '../components/tables-manager/helpers/transformers';

import { ACTIVE_FIELD, DEMAND_WIZARD } from '../components/wizards-manager/constants';

import { transformDemandData } from '../components/wizards-manager/helpers/transformers';
import { transformMultiSelect } from '../components/wizards-manager/helpers/wizard-helpers';

import store from '../store';

import {
  DEMAND_EDIT_DEMAND,
  DRAWER_FORM_OPEN,
  DRAWER_FORM_CREATE_DEMAND,
  DEMAND_DUPLICATE_DEMAND,
  DEMAND_CREATE_DEMAND,
  DEMAND_UPDATE_DEMAND,
  DEMAND_DELETE_DEMAND,
  DEMAND_START_FETCHING_DEMANDS,
  DEMAND_FETCH_DEMANDS,
  DEMAND_UPDATE_DEMAND_STATE,
  DEMAND_GET_INITIAL_DEMAND_FORM_STATE,
  ENDPOINT_FETCH_DEMANDS,
  DEMAND_UPDATE_STATUS,
  DEMAND_CONNECT_DEMAND_ENDPOINTS_STATE,
  DEMAND_ENDPOINTS_FEEDS,
  DEMAND_UPDATE_UPDATED_AT,
  DEMAND_UPDATE_TAGS
} from './types';

const dispatch = store.dispatch;

export const editDemandAction = (data, staticList) => (dispatch) => {
  const transformedData = transformDemandToFormData(data, staticList);

  const payload = {
    formData: transformedData,
    duplicated_id: null
  };

  dispatch({ type: DEMAND_EDIT_DEMAND, payload });
  dispatch({ type: DRAWER_FORM_OPEN, payload: DRAWER_FORM_CREATE_DEMAND });
};

export const editDemandFromTableAction = async (id) => {
  try {
    const staticList = store.getState().staticList;
    const demandRes = await fetchDemandById(id);
    const demand = transformEndpointsOrDemandsToTableData([demandRes.data]);
    const data = transformDemandToFormData(demand[0], staticList);

    const payload = {
      formData: data,
      duplicated_id: null
    };

    dispatch({ type: DEMAND_EDIT_DEMAND, payload });
    dispatch({ type: DRAWER_FORM_OPEN, payload: DRAWER_FORM_CREATE_DEMAND });
  } catch (err) {
    toast.error('Open edit demand error', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};

export const duplicateDemandAction = (data, staticList) => (dispatch) => {
  const transformedData = transformDemandToFormData(data, staticList);
  const duplicatedId = transformedData._id;

  delete transformedData._id;

  transformedData.title = `${transformedData.title} (copy 1)`;

  const payload = {
    formData: transformedData,
    duplicated_id: duplicatedId
  };

  dispatch({ type: DEMAND_DUPLICATE_DEMAND, payload });
  dispatch({ type: DRAWER_FORM_OPEN, payload: DRAWER_FORM_CREATE_DEMAND });
};

export const createDemandAction = () => async (dispatch) => {
  try {
    const demand = store.getState()[DEMAND_WIZARD].formData;
    const data = transformDemandData(demand);

    const res = await saveDemand(data);

    const endpointFeeds = store.getState()[DEMAND_WIZARD].endpoint_feeds;
    const duplicatedID = store.getState()[DEMAND_WIZARD].duplicated_id;
    const visited = store.getState()[DEMAND_WIZARD].visited_demand_endpoints_form;

    if (duplicatedID) {
      if (visited) {
        if (endpointFeeds) {
          const createPromises = endpointFeeds.create.map((df) =>
            createEndpointDemands({
              ...df,
              demand: res.data._id
            })
          );

          await Promise.all(createPromises);
        }
      } else {
        const duplicateEndpointFeeds = await fetchEndpointDemandByDemandId(duplicatedID);

        const createPromises = duplicateEndpointFeeds.data.map((df) =>
          createEndpointDemands({
            active: df[ACTIVE_FIELD],
            demand: res.data._id,
            endpoint: df.endpoint._id
          })
        );

        await Promise.all(createPromises);
      }
    } else {
      if (endpointFeeds) {
        const createPromises = endpointFeeds.create.map((df) =>
          createEndpointDemands({
            ...df,
            demand: res.data._id
          })
        );

        await Promise.all(createPromises);
      }
    }

    toast.info('Demand Created', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    const transformedDemand = transformEndpointsOrDemandsToTableData([res.data]);
    const payload = transformedDemand[0];

    dispatch({
      type: DEMAND_CREATE_DEMAND,
      payload
    });

    return false;
  } catch (err) {
    toast.error('Create Demand Failed', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    return err;
  }
};

export const updateDemandAction = () => async (dispatch) => {
  try {
    const demand = store.getState()[DEMAND_WIZARD].formData;
    const data = transformDemandData(demand);

    const res = await modifyDemand(data._id, data);

    const endpointDemands = store.getState()[DEMAND_WIZARD].endpoint_feeds;

    if (endpointDemands) {
      const dfMap = store.getState()[DEMAND_WIZARD].df_map;

      const createPromises = endpointDemands.create.map((df) =>
        createEndpointDemands(df)
      );

      const updatePromises = endpointDemands.update.map((df) =>
        updateEndpointDemand(dfMap[df.endpoint], {
          active: df.active
        })
      );

      const removePromises = endpointDemands.remove.map((df) =>
        deleteEndpointDemand(dfMap[df.endpoint])
      );

      await Promise.all([
        ...createPromises,
        ...updatePromises,
        ...removePromises
      ]);
    }

    const transformedRes = transformEndpointsOrDemandsToTableData([res.data]);
    const payload = transformedRes[0];

    dispatch({
      type: DEMAND_UPDATE_DEMAND,
      payload
    });

    toast.info('Demand Updated', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    return false;
  } catch (err) {
    toast.error('Update Demand Error', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    return err;
  }
};

export const updateDemandStatus = (id, active) => async (dispatch) => {
  try {
    await modifyDemand(id, { active });

    dispatch({
      type: DEMAND_UPDATE_STATUS,
      payload: {
        _id: id,
        active: active ? 'Active' : 'Inactive'
      }
    });

    toast.info(`Demand ${active ? 'activated' : 'deactivated'}`, {
      position: toast.POSITION.BOTTOM_CENTER
    });
  } catch (e) {
    toast.error(`${active ? 'Activating' : 'Deactivating'} demand fail`, {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};

export const deleteDemandAction = (id) => async (dispatch) => {
  try {
    await removeDemand(id);

    dispatch({
      type: DEMAND_DELETE_DEMAND,
      payload: id
    });
  } catch (err) {
    toast.error('Delete Demand Failed', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};

export const fetchDemandsAction = (id, endpoint = false) => async (
  dispatch
) => {
  try {
    dispatch({ type: DEMAND_START_FETCHING_DEMANDS });

    const res = await fetchDemandById(id);
    const payload = transformEndpointsOrDemandsToTableData(res.data);

    if (endpoint) {
      dispatch({
        type: ENDPOINT_FETCH_DEMANDS,
        payload
      });
    } else {
      dispatch({
        type: DEMAND_FETCH_DEMANDS,
        payload
      });
    }
  } catch (err) {
    toast.error('Fetching Demands Error', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};

export const connectDemandEndpointsAction = (id, duplicated) => async (dispatch) => {
  try {
    const demandEndpoints = await fetchEndpointDemandByDemandId(id);
    const fetchedEndpoints = await fetchEndpointById('all');

    const payload = {
      endpoints: fetchedEndpoints.data,
      demand_id: id,
      demand_endpoints: demandEndpoints.data,
      visited_demand_endpoints_form: duplicated
    };

    dispatch({
      type: DEMAND_CONNECT_DEMAND_ENDPOINTS_STATE,
      payload
    });
  } catch (err) {
    toast.error('Fetching DemandEndpoints Error', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};

export const deleteConnectDemandEndpointsAction = ({ id, sourceID }) => async (dispatch) => {
  const res = await deleteEndpointDemand(id);

  if (res) {
    dispatch({
      type: DEMAND_UPDATE_UPDATED_AT,
      payload: sourceID
    });

    toast.info('Connection Deleted Successfully', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  } else {
    toast.error('Connection Deleted Fail', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};

export const updateDemandEndpointsFeeds = (payload) => (dispatch) => {
  dispatch({ type: DEMAND_ENDPOINTS_FEEDS, payload });
};

export const updateDemandFormDataAction = (payload) => (dispatch) => {
  dispatch({ type: DEMAND_UPDATE_DEMAND_STATE, payload });
};

export const getInitialDemandFormDataAction = () => (dispatch) => {
  dispatch({ type: DEMAND_GET_INITIAL_DEMAND_FORM_STATE });
};

export const updateDemandTagsAction = async (id, data) => {
  try {
    const tags = transformMultiSelect(data);

    await modifyDemand(id, { tags });

    const payload = {
      _id: id,
      tags: data
    };

    dispatch({ type: DEMAND_UPDATE_TAGS, payload });
  } catch (err) {
    toast.error('Demand tags update fail', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};
