import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useFlexLayout,
  useRowState
} from 'react-table';

import {
  calculateManualRevenueReport,
  updateManualRevenueReportAction
} from '../../actions/settings';

import TableBody from '../tables-manager/table-base/TableBody';
import TableDefaultColumnFilter from '../tables-manager/table-base/TableDefaultColumnFilter';
import TablePagination from '../tables-manager/table-base/TablePagination';
import TableSearch from '../tables-manager/table-base/TableSearch';
import ManualRevenueUpdateTableHead from './ManualRevenueUpdateTableHead';

import '../tables-manager/TableManager.scss';

const ManualRevenueUpdateTable = ({
  items,
  fields,
  tableName,
  searchFields,
  hiddenColumns,
  updateDataFieldFromCell,
  updateDataFromCell,
  handelDeleteItem,
  handleCreateItem
}) => {
  const columns = useMemo(() => fields, []);
  const data = useMemo(() => items, [items]);

  const defaultColumn = useMemo(
    () => ({
      Filter: TableDefaultColumnFilter
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    allColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {
      pageIndex,
      pageSize,
      globalFilter
    },
    setGlobalFilter,
    setRowState
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: 50,
        hiddenColumns
      },
      updateDataFieldFromCell,
      updateDataFromCell,
      updateItem: updateManualRevenueReportAction,
      calculateManualRevenueReport,
      deleteItem: handelDeleteItem,
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
      autoResetGlobalFilter: false
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useFlexLayout,
    useRowState
  );

  const pageSizeOptions = [5, 10, 20, 25, 50, 100];

  return (
    <div className="table-manager data-grid">
      <div className="bot-grid df-react-table">
        <TableSearch
          globalFilter={globalFilter || ''}
          count={data.length}
          setGlobalFilter={setGlobalFilter}
          searchFields={searchFields}
          columns={allColumns}
          title={tableName}
          withoutSearchHeaders={true}
        />

        <table
          className="table table-borderless"
          {...getTableProps()}
        >
          <ManualRevenueUpdateTableHead setRowState={setRowState} handleCreateItem={handleCreateItem} headerGroups={headerGroups}/>
          <TableBody page={page} prepareRow={prepareRow} getTableBodyProps={getTableBodyProps}/>
        </table>
        <TablePagination
          pageSizeOptions={pageSizeOptions}
          pageSize={pageSize}
          setPageSize={setPageSize}
          nextPage={nextPage}
          previousPage={previousPage}
          itemsCount={pageOptions.length}
          pageCount={pageCount}
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
        />
      </div>
    </div>
  );
};

ManualRevenueUpdateTable.propTypes = {
  items: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  searchFields: PropTypes.array.isRequired,
  tableName: PropTypes.string.isRequired,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateDataFieldFromCell: PropTypes.func.isRequired,
  updateDataFromCell: PropTypes.func.isRequired,
  handelDeleteItem: PropTypes.func.isRequired,
  handleCreateItem: PropTypes.func.isRequired
};

export default ManualRevenueUpdateTable;
