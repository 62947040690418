import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';

import {
  DASHBOARD_BREAKDOWN_DAY,
  DASHBOARD_BREAKDOWN_MONTH,
  DASHBOARD_DEMAND_FEEDS_FIELD,
  DASHBOARD_DF_SOURCE_FIELD,
  DASHBOARD_FILTERS,
  DASHBOARD_INTERVAL,
  DASHBOARD_INTERVAL_CUSTOM,
  DASHBOARD_INTERVAL_LAST_3_MONTHS,
  DASHBOARD_INTERVAL_LAST_48_HOURS,
  DASHBOARD_INTERVAL_LAST_7_DAYS,
  DASHBOARD_INTERVAL_TODAY,
  DASHBOARD_INTERVAL_YESTERDAY,
  DASHBOARD_STATUS,
  DASHBOARD_TIMEZONE_FIELD
} from '../../../actions/types';

import { fetchClientReport } from '../../../api/client-report';
import { useAuth } from '../../../context/auth/AuthState';
import TablePanelSpinner from '../../tables-manager/shared/TablePanelSpinner';

import { useIsMountedRef } from '../../wizards-manager/custom-hooks/useIsMountedRef';
import { transformFilters } from '../table/helpers/table-manager-helpers';
import {
  buildGraphData,
  buildGraphMetrics,
  buildItemData,
  buildMetrics,
  chartOptions,
  checkIsDemandFiltered,
  getTopChartTitle
} from './helpers/top-charts-helpers';

const TopChart = ({
  title
}) => {
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState(null);
  const [chartData, setChartData] = useState([]);
  const isMountedRef = useIsMountedRef();
  const { isPartner } = useAuth();

  const {
    filters,
    interval,
    dateRange
  } = useSelector(
    state => ({
      filters: state.dashboard[DASHBOARD_FILTERS],
      interval: state.dashboard[DASHBOARD_INTERVAL],
      dateRange: state.dashboard.dateRange
    })
  );

  useEffect(() => {
    setLoading(true);

    (async () => {
      const filtersCopy = { ...filters };
      const isDemandFiltered = checkIsDemandFiltered(filtersCopy[DASHBOARD_DEMAND_FEEDS_FIELD], filtersCopy[DASHBOARD_DF_SOURCE_FIELD]);
      const timeZone = filters[DASHBOARD_TIMEZONE_FIELD].value;

      delete filtersCopy[DASHBOARD_TIMEZONE_FIELD];
      delete filtersCopy[DASHBOARD_STATUS];

      const isToday = interval === DASHBOARD_INTERVAL_TODAY ||
        interval === DASHBOARD_INTERVAL_LAST_48_HOURS ||
        interval === DASHBOARD_INTERVAL_YESTERDAY;

      const query = {
        metrics: buildMetrics(title, isDemandFiltered),
        timeZone
      };

      const queryGraph = {
        metrics: buildGraphMetrics(title, isDemandFiltered, isToday),
        timeZone
      };

      if (interval === DASHBOARD_INTERVAL_CUSTOM) {
        query.dateRange = dateRange;

        const { from, to } = dateRange;
        const diff = moment(to).diff(moment(from), 'days', true);

        if (diff > 30) {
          queryGraph.breakdown = DASHBOARD_BREAKDOWN_MONTH;
          queryGraph.interval = DASHBOARD_INTERVAL_LAST_3_MONTHS;
        } else {
          queryGraph.breakdown = DASHBOARD_BREAKDOWN_DAY;
          queryGraph.interval = DASHBOARD_INTERVAL_LAST_7_DAYS;
        }
      } else {
        query.interval = interval;

        if (
          interval === DASHBOARD_INTERVAL_TODAY ||
          interval === DASHBOARD_INTERVAL_LAST_48_HOURS ||
          interval === DASHBOARD_INTERVAL_YESTERDAY ||
          interval === DASHBOARD_INTERVAL_LAST_7_DAYS
        ) {
          queryGraph.breakdown = DASHBOARD_BREAKDOWN_DAY;
          queryGraph.interval = DASHBOARD_INTERVAL_LAST_7_DAYS;
        } else {
          queryGraph.breakdown = DASHBOARD_BREAKDOWN_MONTH;
          queryGraph.interval = DASHBOARD_INTERVAL_LAST_3_MONTHS;
        }
      }

      const filter = transformFilters(filtersCopy);

      if (Object.keys(filter).length) {
        query.filters = filter;
        queryGraph.filters = filter;
      }

      let res;
      let resGraph;

      // TODO Handle error
      try {
        res = await fetchClientReport(query);
        const resGraphData = await fetchClientReport(queryGraph);
        resGraph = resGraphData.data;
      } catch (err) {
        res = {
          data: []
        };

        resGraph = [];
      }

      if (isMountedRef.current) {
        setItem(buildItemData(title, isDemandFiltered, res ? res.data[0] : [], isToday));
        setChartData(buildGraphData(title, isDemandFiltered, resGraph || [], isToday));
        setLoading(false);
      }
    })();
  }, [title, JSON.stringify(filters), interval, JSON.stringify(dateRange)]);

  const isToday = interval === DASHBOARD_INTERVAL_TODAY ||
    interval === DASHBOARD_INTERVAL_LAST_48_HOURS ||
    interval === DASHBOARD_INTERVAL_YESTERDAY;

  return (
    <div className='box-inner'>
      <p className='box-name'>{getTopChartTitle(title, isToday, isPartner)}</p>
      {loading ? <TablePanelSpinner/> : (
        <>
          <div className='curency-line clearfix'>
            <p className="curency-satus">
              {item.value} {item.pct && (<span className={`status ${item.pctPositive ? 'up' : 'down'}`}>{item.pct}</span>)}
            </p>
            <div className="sparkline">
              <ReactApexChart
                options={chartOptions}
                series={chartData}
                type="line"
                height="35"
                width="100%"
              />
            </div>
          </div>

          <ul style={{ textAlign: 'center' }} className='list-bot clearfix'>
            {item.list.map((list, key) => (
              <li key={`item-${key}`}>
                <p>
                  <span
                    className={`span-value ${list.positive !== undefined ? list.positive ? 'up' : 'down' : ''}`}
                  >
                    {list.value}
                  </span>
                  <span className='span-name'>{list.name}</span>
                </p>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

TopChart.propTypes = {
  title: PropTypes.string.isRequired
};

export default TopChart;
