import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';

import { SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD } from '../../actions/types';

import { useIsMountedRef } from '../wizards-manager/custom-hooks/useIsMountedRef';

import { timezonesOptions } from '../wizards-manager/wizards/dashboard/helpers/timezones';

import '../wizards-manager/wizard-base/WizardSharedStyles.scss';

const timezonesObject = {
  '+0000': {
    label: '(GMT+00:00) UTC',
    value: '+0000'
  },
  '-0700': {
    label: '(GMT-07:00) Pacific Time',
    value: '-0700'
  },
  '-0400': {
    label: '(GMT-04:00) Eastern Time',
    value: '-0400'
  },
  '-0500': {
    label: '(GMT-05:00) Central Time',
    value: '-0500'
  }
};

const customStyles = {
  indicatorsContainer: (styles) => ({
    ...styles,
    display: 'none'
  })
};

const EditableCell = ({
  value: initialValue,
  row: { index, state, setState, original },
  column: { id },
  updateDataFieldFromCell
}) => {
  const isMounted = useIsMountedRef();
  const recordId = original._id;

  useEffect(() => {
    if (!state.oldID) {
      if (isMounted.current) {
        setState((state) => ({
          ...state,
          oldID: recordId,
          [id]: initialValue
        }));
      }
    } else if (state.oldID !== recordId) {
      if (isMounted.current) {
        setState((state) => ({
          ...state,
          oldID: recordId,
          [id]: initialValue
        }));
      }
    }
  }, [recordId, state[id], isMounted]);

  const editing = state.editing;
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  const onChange = useCallback((e) => {
    if (id === SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD) {
      return setValue(e.value);
    }

    setValue(e.target.value);
  }, [id]);

  // We'll only update the external data when the input is blurred
  const onBlur = useCallback(() => {
    updateDataFieldFromCell(index, id, value);
  }, [index, id, value]);

  // If the initialValue is changed externall, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const renderField = useCallback(() => {
    if (id === SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD) {
      return (
        <Select
          components={{ DropdownIndicator: () => null }}
          options={timezonesOptions}
          className="the-field selectable"
          classNamePrefix="react-select"
          onChange={onChange}
          onBlur={onBlur}
          isMulti={false}
          menuPlacement="auto"
          closeMenuOnSelect={true}
          value={timezonesObject[value]}
          styles={customStyles}
        />
      );
    }

    return (
      <input
        type="number"
        className="the-field"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  }, [id, value]);

  return (
    <div className="wizard-input-container">
      {editing ? (
        renderField()
      ) : (
        <>{value}</>
      )}
    </div>
  );
};

EditableCell.propTypes = {
  value: PropTypes.any.isRequired,
  row: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  updateDataFieldFromCell: PropTypes.func.isRequired
};

export default EditableCell;
