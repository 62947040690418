import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { deleteConnectDemandEndpointsAction, editDemandFromTableAction } from '../../../../actions/demand';
import {
  deleteConnectEndpointDemandAction,
  editEndpointFromTableAction,
  openEditEndpointDemandsAction,
  updateEndpointDemandsActivation
} from '../../../../actions/endpoint';
import {
  fetchEndpointDemandsByEndpoint,
  fetchEndpointDemandsByDemand
} from '../../../../api/helpers/endpoint-demand';
import { useIsMountedRef } from '../../../wizards-manager/custom-hooks/useIsMountedRef';

import WizardConfirmation from '../../../wizards-manager/wizards/shared/WizardConfirmation';

import '../../table-base/TableColumnsActions.scss';
import './EndpointsDemandFeeds.scss';

const EndpointsDemandFeeds = ({
  id,
  source,
  updatedAt
}) => {
  const isMountedRef = useIsMountedRef();
  const [endpointDemands, setEndpointDemands] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      let demands;

      if (source === 'endpoint') {
        demands = await fetchEndpointDemandsByDemand(id);
      } else {
        demands = await fetchEndpointDemandsByEndpoint(id);
      }

      if (isMountedRef.current) {
        setEndpointDemands(demands.map((d) => ({
          id: d._id,
          title: d[source].title,
          sourceID: d[source]._id,
          active: d.active
        })));
      }
    })();
  }, [id, updatedAt]);

  const handleOpen = (id) => {
    if (source === 'endpoint') {
      // TODO Fix because dispatch
      editEndpointFromTableAction(id);
    } else {
      editDemandFromTableAction(id);
    }
  };

  return (
    <div className="endpoints-demand-feed">
      {endpointDemands.map((d) => (
        <div className="demand-feed-box" key={d.id}>
          <div
            className="endpoints-demand-title"
            onClick={() => handleOpen(d.sourceID)}
          >
            {d.title}
          </div>
          <div className="form-group">
            <div className="switch-list">
              <label>
                <input
                  type="checkbox"
                  onChange={e => updateEndpointDemandsActivation(d.id, e.target.checked, source, id)(dispatch)}
                  checked={d.active}
                />
              </label>
            </div>
          </div>
          <ul className="action-btn">
            <li>
              <button
                className="table-action-btn"
                onClick={() => openEditEndpointDemandsAction(d.id)(dispatch)}
              >
                <img title="Edit endpoint demand" src={require('../../../../assets/images/icons/ico_edit.svg')} alt=""/>
                <img title="Edit endpoint demand" className='img-active' src={require('../../../../assets/images/icons/ico_edit_o.svg')} alt=''/>
              </button>
            </li>
            <li>
              <WizardConfirmation
                onSubmit={source === 'endpoint' ? deleteConnectDemandEndpointsAction : deleteConnectEndpointDemandAction}
                data={{
                  id: d.id,
                  sourceID: id
                }}
                action={(onClick) => (
                  <button onClick={onClick} className="table-action-btn">
                    <img src={require('../../../../assets/images/icons/ico_delete.svg')} alt=''/>
                    <img className='img-active' src={require('../../../../assets/images/icons/ico_delete_o.svg')} alt=''/>
                  </button>
                )}
                title='Are you sure to delete?'
              />
            </li>
          </ul>
        </div>
      ))}
    </div>
  );
};

EndpointsDemandFeeds.propTypes = {
  id: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  updatedAt: PropTypes.instanceOf(Date)
};

export default EndpointsDemandFeeds;
