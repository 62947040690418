import * as yup from 'yup';

import {
  DASHBOARD_TIMEZONE_FIELD,
  REPORTS_BREAKDOWN_FIELD,
  REPORTS_DIMENSION_FILTERS_FIELD,
  REPORTS_DIMENSIONS_FIELD,
  REPORTS_KEY_FIELD,
  REPORTS_METRIC_FILTERS_FIELD,
  REPORTS_METRICS_FIELD,
  REPORTS_OPERATION_FIELD,
  REPORTS_VALUE_FIELD
} from '../../../actions/types';

import { LABEL_FIELD, VALUE_FIELD } from '../../wizards-manager/constants';

import { emptyStringToNull } from '../../wizards-manager/validators/shared/optimization';
import {
  multiSelectSchema,
  requiredMultiSelectSchema,
  selectSchema
} from '../../wizards-manager/validators/shared/shared-schemas';

export const dimensionFiltersSchema = yup.array().of(yup.object().shape({
  [REPORTS_KEY_FIELD]: selectSchema.required('Select something').nullable(),
  [REPORTS_OPERATION_FIELD]: selectSchema.required('Select something').nullable(),
  [REPORTS_VALUE_FIELD]: yup.lazy((value) => {
    if (Array.isArray(value)) {
      return requiredMultiSelectSchema;
    }

    return yup.object().shape({
      [LABEL_FIELD]: yup.string().required('Label is required'),
      [VALUE_FIELD]: yup.bool().required('Value is required')
    }).required('Select something').nullable();
  })
}));

export const metricFiltersSchema = yup.array().of(yup.object().shape({
  [REPORTS_KEY_FIELD]: selectSchema.required('Select something').nullable(),
  [REPORTS_OPERATION_FIELD]: selectSchema.required('Select something').nullable(),
  [REPORTS_VALUE_FIELD]: yup.number().required('Value is required').transform(emptyStringToNull).nullable()
}));

export const reportsSchema = yup.object().shape({
  [DASHBOARD_TIMEZONE_FIELD]: selectSchema.required('Select something').nullable(),
  [REPORTS_BREAKDOWN_FIELD]: selectSchema,
  [REPORTS_DIMENSIONS_FIELD]: multiSelectSchema,
  [REPORTS_METRICS_FIELD]: requiredMultiSelectSchema,
  [REPORTS_DIMENSION_FILTERS_FIELD]: dimensionFiltersSchema,
  [REPORTS_METRIC_FILTERS_FIELD]: metricFiltersSchema
});
