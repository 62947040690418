import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { updateEndpointFormDataAction } from '../../../../../actions/endpoint';

import {
  ENDPOINT_DEFAULT_DOMAIN_FIELD,
  ENDPOINT_FALLBACK_DOMAINS_FIELD,
  ENDPOINT_IP_MISMATCH_FIELD,
  ENDPOINT_IP_MISMATCH_USE_DEFAULT_FIELD,
  ENDPOINT_NO_BID_FIELD,
  ENDPOINT_NO_BID_USE_DEFAULT_FIELD,
  ENDPOINT_OTHER_CLICK_FILTERS_FIELD,
  ENDPOINT_OTHER_CLICK_FILTERS_USE_DEFAULT_FIELD,
  ENDPOINT_TARGETING_MISMATCH_FIELD,
  ENDPOINT_TARGETING_MISMATCH_USE_DEFAULT_FIELD,
  ENDPOINT_WIZARD
} from '../../../constants';

import { useFormsEffect } from '../../../custom-hooks/useFormsEffect';
import { useIsValidForm } from '../../../custom-hooks/useIsValidForm';
import { fallbackSchema, validateFallbackData } from '../../../validators/endpoint/fallback';

import WizardCheckboxInput from '../../shared/WizardCheckboxInput';
import WizardInputWithValidation from '../../shared/WizardInputWithValidation';

import './EndpointFallbackForm.scss';

const EndpointFallbackForm = ({
  isSubmitted,
  onSubmit,
  setIsValid
}) => {
  const {
    defaultValues
  } = useSelector(
    state => ({
      defaultValues: state[ENDPOINT_WIZARD].formData[ENDPOINT_FALLBACK_DOMAINS_FIELD]
    })
  );

  const {
    register,
    errors,
    triggerValidation,
    getValues,
    watch,
    setValue
  } = useForm({
    mode: 'onBlur',
    defaultValues,
    validationSchema: fallbackSchema
  });

  useFormsEffect(isSubmitted, triggerValidation, getValues, updateEndpointFormDataAction, onSubmit, ENDPOINT_FALLBACK_DOMAINS_FIELD);

  const data = getValues({ nest: true });
  const isValid = validateFallbackData(Object.keys(data).length ? data : defaultValues);

  useIsValidForm(isValid, setIsValid);

  const values = watch({ nest: true }, defaultValues);

  const handleChangeDefault = (field, value) => {
    if (value) {
      setValue(field.replace('_use_default', ''), values[ENDPOINT_DEFAULT_DOMAIN_FIELD]);
    }
  };

  return (
    <form className="endpoint-fallback step-content">
      <div className="group">
        <h4>Fallbacks Redirects</h4>
        <div className="group-item">
          <WizardInputWithValidation
            type="text"
            error={!!errors[ENDPOINT_DEFAULT_DOMAIN_FIELD]}
            errorMessage={errors[ENDPOINT_DEFAULT_DOMAIN_FIELD] ? errors[ENDPOINT_DEFAULT_DOMAIN_FIELD].message : ''}
            name={ENDPOINT_DEFAULT_DOMAIN_FIELD}
            propRef={register}
            placeholder="http://"
          />

          <div className="form-group">
            <div className="row-list">
              <div className="row-item">
                <div className="row">
                  <div className="col-sm-8">
                    <WizardInputWithValidation
                      label="No Bid Fallback"
                      type="text"
                      error={!!errors[ENDPOINT_NO_BID_FIELD]}
                      errorMessage={errors[ENDPOINT_NO_BID_FIELD] ? errors[ENDPOINT_NO_BID_FIELD].message : ''}
                      name={ENDPOINT_NO_BID_FIELD}
                      propRef={register}
                      placeholder="http://"
                      disabled={values[ENDPOINT_NO_BID_USE_DEFAULT_FIELD]}
                    />
                  </div>
                  <div className="col-sm-4 end-fb-default">
                    <WizardCheckboxInput
                      propRef={register}
                      handleChange={handleChangeDefault}
                      label="Use Default"
                      name={ENDPOINT_NO_BID_USE_DEFAULT_FIELD}
                    />
                  </div>
                </div>
              </div>

              <div className="row-item">
                <div className="row">
                  <div className="col-sm-8">
                    <WizardInputWithValidation
                      label="Targeting Mismatch"
                      type="text"
                      error={!!errors[ENDPOINT_TARGETING_MISMATCH_FIELD]}
                      errorMessage={errors[ENDPOINT_TARGETING_MISMATCH_FIELD] ? errors[ENDPOINT_TARGETING_MISMATCH_FIELD].message : ''}
                      name={ENDPOINT_TARGETING_MISMATCH_FIELD}
                      propRef={register}
                      placeholder="http://"
                      disabled={values[ENDPOINT_TARGETING_MISMATCH_USE_DEFAULT_FIELD]}
                    />
                  </div>
                  <div className="col-sm-4 end-fb-default">
                    <WizardCheckboxInput
                      propRef={register}
                      handleChange={handleChangeDefault}
                      label="Use Default"
                      name={ENDPOINT_TARGETING_MISMATCH_USE_DEFAULT_FIELD}
                    />
                  </div>
                </div>
              </div>

              <div className="row-item">
                <div className="row">
                  <div className="col-sm-8">
                    <WizardInputWithValidation
                      label="IP Mismatch"
                      type="text"
                      error={!!errors[ENDPOINT_IP_MISMATCH_FIELD]}
                      errorMessage={errors[ENDPOINT_IP_MISMATCH_FIELD] ? errors[ENDPOINT_IP_MISMATCH_FIELD].message : ''}
                      name={ENDPOINT_IP_MISMATCH_FIELD}
                      propRef={register}
                      placeholder="http://"
                      disabled={values[ENDPOINT_IP_MISMATCH_USE_DEFAULT_FIELD]}
                    />
                  </div>
                  <div className="col-sm-4 end-fb-default">
                    <WizardCheckboxInput
                      propRef={register}
                      handleChange={handleChangeDefault}
                      label="Use Default"
                      name={ENDPOINT_IP_MISMATCH_USE_DEFAULT_FIELD}
                    />
                  </div>
                </div>
              </div>

              <div className="row-item">
                <div className="row">
                  <div className="col-sm-8">
                    <WizardInputWithValidation
                      label="Other Click Filters"
                      type="text"
                      error={!!errors[ENDPOINT_OTHER_CLICK_FILTERS_FIELD]}
                      errorMessage={errors[ENDPOINT_OTHER_CLICK_FILTERS_FIELD] ? errors[ENDPOINT_OTHER_CLICK_FILTERS_FIELD].message : ''}
                      name={ENDPOINT_OTHER_CLICK_FILTERS_FIELD}
                      propRef={register}
                      placeholder="http://"
                      disabled={values[ENDPOINT_OTHER_CLICK_FILTERS_USE_DEFAULT_FIELD]}
                    />
                  </div>
                  <div className="col-sm-4 end-fb-default">
                    <WizardCheckboxInput
                      propRef={register}
                      handleChange={handleChangeDefault}
                      label="Use Default"
                      name={ENDPOINT_OTHER_CLICK_FILTERS_USE_DEFAULT_FIELD}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

EndpointFallbackForm.propTypes = {
  isSubmitted: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setIsValid: PropTypes.func.isRequired
};

export default EndpointFallbackForm;
