import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { AsyncPaginateBase } from 'react-select-async-paginate';
import Creatable from 'react-select/creatable';

import Popover from './Popover';

import '../../wizard-base/WizardSharedStyles.scss';

export const SELECT_TYPE = 'select';
export const CREATE_TYPE = 'create';
export const ASYNC_TYPE = 'async';
export const DATE_PICK_TYPE = 'date_pick';

const customStyles = {
  indicatorsContainer: (styles) => ({
    ...styles,
    display: 'none'
  })
};

const WizardSelectWithValidation = ({
  label,
  error,
  errorMessage,
  placeholder,
  isMulti,
  options,
  control,
  name,
  rules,
  filterSelected,
  selectType,
  defaultValue,
  loadOptions,
  onChange,
  removeIndicator,
  dateValue
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const onMenuClose = () => setIsFocused(false);
  const onMenuOpen = () => setIsFocused(true);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (value, { action }) => {
    switch (action) {
      case 'input-change':
      case 'set-value':
        setInputValue(value);
        break;
    }
  };

  const handleChange = ([selected, operation]) => {
    if (onChange) {
      onChange([selected, operation]);
    }

    if (!selected || (!selected.length && Array.isArray(selected))) {
      return [];
    }

    return filterSelected ? filterSelected(selected || [], operation) : selected;
  };

  const [selectedDate, setSelectedDate] = useState(dateValue);

  useEffect(() => {
    setSelectedDate(dateValue);
  }, [dateValue]);

  const renderField = () => {
    switch (selectType) {
      case SELECT_TYPE:
        return (
          <Controller
            rules={rules || {}}
            control={control}
            name={name}
            placeholder={placeholder || ''}
            className={`the-field selectable ${error ? 'error' : ''}`}
            classNamePrefix='react-select'
            onMenuClose={onMenuClose}
            onMenuOpen={onMenuOpen}
            onChange={handleChange}
            isMulti={isMulti}
            closeMenuOnSelect={!isMulti}
            as={<Select
              components={removeIndicator ? { DropdownIndicator: () => null } : {} }
              styles={removeIndicator ? customStyles : {}}
              isMulti={isMulti}
              options={options}
              menuPlacement="auto"
              isClearable={true}
              isSearchable={true}
              onSelectResetsInput={false}
              inputValue={inputValue}
              onInputChange={handleInputChange}
            />}
            defaultValue={defaultValue}
          />
        );

      case CREATE_TYPE:
        return (
          <Controller
            rules={rules || {}}
            control={control}
            name={name}
            placeholder={placeholder || ''}
            className={`the-field selectable ${error ? 'error' : ''}`}
            classNamePrefix="react-select"
            onMenuClose={onMenuClose}
            onMenuOpen={onMenuOpen}
            onChange={handleChange}
            closeMenuOnSelect={!isMulti}
            isMulti={isMulti}
            as={<Creatable
              components={removeIndicator ? { DropdownIndicator: () => null } : {} }
              styles={removeIndicator ? customStyles : {}}
              options={options}
              menuPlacement="auto"
              isClearable={true}
              isSearchable={true}
              onSelectResetsInput={false}
              inputValue={inputValue}
              onInputChange={handleInputChange}
            />}
            defaultValue={defaultValue}
          />
        );

      case ASYNC_TYPE:
        return (
          <Controller
            rules={rules || {}}
            control={control}
            name={name}
            placeholder={placeholder || ''}
            className={`the-field selectable ${error ? 'error' : ''}`}
            classNamePrefix="react-select"
            onChange={handleChange}
            isMulti={isMulti}
            closeMenuOnSelect={!isMulti}
            additional={{
              page: 1
            }}
            as={<AsyncPaginateBase
              styles={removeIndicator ? customStyles : {}}
              components={removeIndicator ? { DropdownIndicator: () => null } : {} }
              key={`${loadOptions.name}_${name}`} // Hack for disable caching of options
              onMenuClose={onMenuClose}
              onMenuOpen={onMenuOpen}
              loadOptions={loadOptions}
              options={options}
              menuIsOpen={isFocused}
              isClearable={true}
              isSearchable={true}
              onSelectResetsInput={false}
              inputValue={inputValue}
              onInputChange={handleInputChange}
            />}
            defaultValue={defaultValue}
          />
        );

      case DATE_PICK_TYPE:
        return (
          <Controller
            className={`the-field datepicker selectable ${error ? 'error' : ''}`}
            classNamePrefix="react-select"
            rules={rules || {}}
            dateFormat='dd/MM/yyyy'
            name={name}
            control={control}
            onChange={([date]) => {
              setSelectedDate(date);

              return date;
            }}
            selected={selectedDate}
            as={<DatePicker
              onCalendarClose={onMenuClose}
              onCalendarOpen={onMenuOpen}
              popperPlacement="bottom-start"
              popperModifiers={{
                flip: {
                  behavior: ['bottom-start'] // don't allow it to flip to be above
                },
                preventOverflow: {
                  enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                hide: {
                  enabled: false // turn off since needs preventOverflow to be enabled
                }
              }}
            />}
          />
        );
    }
  };

  return (
    <div className="form-group">
      {label ? <label>{label}</label> : null}
      <div className="wizard-input-container">
        <Popover
          title={errorMessage || ' '}
          open={error && isFocused}
        >
          {renderField()}
        </Popover>

        {(error && !isFocused) && <FontAwesomeIcon className="field-error-icon" icon={faExclamationCircle}/>}
      </div>
    </div>
  );
};

WizardSelectWithValidation.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  error: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool.isRequired,
  options: PropTypes.array.isRequired,
  rules: PropTypes.object,
  filterSelected: PropTypes.func,
  selectType: PropTypes.oneOf([SELECT_TYPE, ASYNC_TYPE, CREATE_TYPE, DATE_PICK_TYPE]).isRequired,
  defaultValue: PropTypes.any,
  loadOptions: PropTypes.func,
  onChange: PropTypes.func,
  removeIndicator: PropTypes.bool,
  dateValue: PropTypes.any
};

export default WizardSelectWithValidation;
