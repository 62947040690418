import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';

import {
  createManualRevenueReportAction,
  deleteManualRevenueReport,
  getAllManualRevenueReports
} from '../../actions/settings';

import {
  SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD,
  SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD,
  SETTINGS_MANUAL_REVENUE_DEMAND_ID,
  SETTINGS_MANUAL_REVENUE_REPORT_DATE_FIELD,
  SETTINGS_MANUAL_REVENUE_STATUS_FIELD,
  SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD
} from '../../actions/types';

import TablePanelSpinner from '../tables-manager/shared/TablePanelSpinner';

import { useIsMountedRef } from '../wizards-manager/custom-hooks/useIsMountedRef';
import EditableCell from './EditableCell';
import ManualRevenueUpdateTable from './ManualRevenueUpdateTable';
import SettingsActionCell from './SettingsActionCell';

const manualRevenueUpdateColumns = [
  {
    Header: 'Date',
    accessor: SETTINGS_MANUAL_REVENUE_REPORT_DATE_FIELD,
    Cell: ({ value }) => moment(value).format('DD/MM/YYYY'),
    width: 110
  },
  {
    Header: 'Demand',
    accessor: SETTINGS_MANUAL_REVENUE_DEMAND_ID,
    maxWidth: 100
  },
  {
    Header: 'TimeZone',
    accessor: SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD,
    Cell: EditableCell,
    maxWidth: 100
  },
  {
    Header: 'Clicks Count',
    accessor: SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD,
    Cell: EditableCell,
    width: 80
  },
  {
    Header: 'Revenue',
    accessor: SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD,
    Cell: EditableCell,
    width: 80
  },
  {
    Header: 'Status',
    accessor: SETTINGS_MANUAL_REVENUE_STATUS_FIELD,
    width: 90
  },
  {
    Header: 'Actions',
    id: 'action',
    width: 80,
    Cell: SettingsActionCell
  }
];

const ManualRevenueUpdate = () => {
  const isMounted = useIsMountedRef();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const columns = manualRevenueUpdateColumns;

  useEffect(() => {
    setLoading(true);

    (async () => {
      const res = await getAllManualRevenueReports();

      if (isMounted.current) {
        setItems(res);
        setLoading(false);
      }
    })();
  }, [isMounted]);

  const updateItemsFieldFromCell = useCallback((rowIndex, columnId, value) => {
    setItems(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value
          };
        }

        return row;
      })
    );
  }, []);

  const updateItemsFromCell = useCallback((rowIndex, columnId, value) => {
    setItems(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            ...value
          };
        }

        return row;
      })
    );
  }, []);

  const handelDeleteItem = useCallback(async (id) => {
    const res = await deleteManualRevenueReport(id);

    if (res) {
      setItems(old =>
        old.filter(row => row._id !== id)
      );
    }

    return res;
  }, []);

  const handleCreateItem = useCallback(async (data) => {
    const res = await createManualRevenueReportAction(data);

    if (res) {
      setItems(old =>
        [res, ...old]
          .sort((a, b) =>
            new Date(b[SETTINGS_MANUAL_REVENUE_REPORT_DATE_FIELD]).getTime() -
            new Date(a[SETTINGS_MANUAL_REVENUE_REPORT_DATE_FIELD]).getTime()
          ));
    }

    return res;
  }, []);

  return (
    <div className="manual-revenue-update settings-table">
      {loading ? <TablePanelSpinner/>
        : <ManualRevenueUpdateTable
          searchFields={[]}
          items={items}
          fields={columns}
          hiddenColumns={[]}
          tableName=''
          updateDataFieldFromCell={updateItemsFieldFromCell}
          updateDataFromCell={updateItemsFromCell}
          handelDeleteItem={handelDeleteItem}
          handleCreateItem={handleCreateItem}
        />
      }
    </div>
  );
};

export default ManualRevenueUpdate;
