import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useTablesEffect = (
  fetchData,
  fetchOptions
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData(fetchOptions)(dispatch);
  }, []);
};
