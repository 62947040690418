import store from '../../../../store';

export const validateFormData = (wizard, allForms, toForm, exclude) => {
  const formData = store.getState()[wizard].formData;

  let to;

  if (toForm === 'all') {
    to = allForms.length;
  } else {
    to = toForm;
  }

  if (exclude || exclude === 0) {
    allForms.splice(exclude, 1);
  }

  const forms = allForms.slice(0, to);

  for (const form of forms) {
    const { validator, field } = form;
    const data = field ? formData[field] : formData;

    const isValid = validator(data);

    if (!isValid) {
      return false;
    }
  }

  return true;
};
