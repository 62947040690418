import React from 'react';
import { NavLink } from 'react-router-dom';

import logo from '../../assets/images/common/logo.svg';

import { useAuth } from '../../context/auth/AuthState';

import HandleSidebar from './HandleSidebar';
import SelectDashboard from './SelectDashboard';

const SideBar = () => {
  const { onShowNavSide, wrapSidebar } = HandleSidebar();

  const { isPartner } = useAuth();

  return (
    <div
      className={['sidebar'].join(' ')}
      ref={wrapSidebar}
      onClick={onShowNavSide}
    >
      <div className="wrap-side">
        <p id="Logo">
          <NavLink exact to="/dashboard">
            <img src={logo} alt=""/>
          </NavLink>
        </p>

        <ul className="side-nav">
          <li>
            <NavLink exact to="/dashboard">
              <img
                src={require('../../assets/images/icons/ico_dashboard.svg')}
                alt=""
              />
              <img
                className="img-active"
                src={require('../../assets/images/icons/ico_dashboard_o.svg')}
                alt=""
              />
              <span>Dashboard</span>
            </NavLink>
          </li>
          {!isPartner ? (
            <>
              <li>
                <NavLink exact to="/partners">
                  <img
                    src={require('../../assets/images/icons/ico_partner.svg')}
                    alt=""
                  />
                  <img
                    className="img-active"
                    src={require('../../assets/images/icons/ico_partner_o.svg')}
                    alt=""
                  />
                  <span>Partners</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/endpoints">
                  <img
                    src={require('../../assets/images/icons/ico_endpoint.svg')}
                    alt=""
                  />
                  <img
                    className="img-active"
                    src={require('../../assets/images/icons/ico_endpoint_o.svg')}
                    alt=""
                  />
                  <span>Endpoints</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/demand">
                  <img
                    src={require('../../assets/images/icons/ico_deman.svg')}
                    alt=""
                  />
                  <img
                    className="img-active"
                    src={require('../../assets/images/icons/ico_deman_o.svg')}
                    alt=""
                  />
                  <span>Demand</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/reports">
                  <img
                    src={require('../../assets/images/icons/ico_report.svg')}
                    alt=""
                  />
                  <img
                    className="img-active"
                    src={require('../../assets/images/icons/ico_report_o.svg')}
                    alt=""
                  />
                  <span>Reports</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/rules">
                  <img
                    src={require('../../assets/images/icons/ico_rule.svg')}
                    alt=""
                  />
                  <img
                    className="img-active"
                    src={require('../../assets/images/icons/ico_rule_o.svg')}
                    alt=""
                  />
                  <span>Rules</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/settings"
                >
                  <img
                    src={require('../../assets/images/icons/settings.svg')}
                    alt=""
                  />
                  <img
                    className="img-active"
                    src={require('../../assets/images/icons/settings_o.svg')}
                    alt=""
                  />
                  <span>Settings</span>
                </NavLink>
              </li>
              {/*
              <li>
                <NavLink to='/admin'>
                  <FontAwesomeIcon className="side-bar-icon" icon={faCog}/>
                  <span>Admin</span>
                </NavLink>
              </li>
              */}
            </>) : null}
        </ul>

        <SelectDashboard/>
      </div>
    </div>
  );
};

export default SideBar;
