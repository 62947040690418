import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { showDrawerForm } from '../../actions/drawerForms';
import { deleteReportAction, editReportAction, fetchReportAction, fetchReportsAction } from '../../actions/reports';
import { DRAWER_FORM_REPORTS } from '../../actions/types';

import WizardConfirmation from '../wizards-manager/wizards/shared/WizardConfirmation';
import ReportsTable from './ReportsTable';

import './Reports.scss';

const Reports = () => {
  const dispatch = useDispatch();

  const {
    reports
  } = useSelector(
    state => ({
      reports: state.reports.reports
    })
  );

  useEffect(() => {
    fetchReportsAction()(dispatch);
  }, []);

  const reportListRef = useRef(null);
  const [openReportsList, setOpenReportsList] = useState(false);

  const handleClickOutside = (e) => {
    const target = e.target;
    const parentNode = target.parentNode;

    const classNamesList = [
      'fade show popover bs-popover-right',
      'confirm-popover-container',
      'w-c-m-btn btn-container',
      'w-c-m-btn btn btn-confirm'
    ];

    if (reportListRef.current &&
      !reportListRef.current.contains(e.target) &&
      !classNamesList.includes(parentNode.className)
    ) {
      setOpenReportsList(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  return (
    <div className="reports-page">
      <div className="reports-page-top-container">
        <Button className="insights-generator" variant="dark-violet" onClick={() => showDrawerForm(DRAWER_FORM_REPORTS)(dispatch)}>
          <img src={require('../../assets/images/icons/ico_insight.svg')} alt=""/>
          <span>Insights Generator</span>
        </Button>

        {reports && reports.length ? (
          <div ref={reportListRef} className="reports-page-list-reports">
            <button
              onClick={() => setOpenReportsList(state => !state)}
              className="btn btn-dark-violet list-reports-trigger"
            >
              Reports list
            </button>

            {openReportsList && (
              <div className="list-reports-box">
                {reports.map((report, index) => (
                  <div key={index} className="list-reports-item">
                    <div className="reports-item-title" onClick={() => fetchReportAction(report.id)(dispatch)}>
                      {report.title}
                    </div>
                    <button className="report-item-edit" onClick={() => editReportAction(report.id)(dispatch)}>
                      <img src={require('../../assets/images/icons/ico_edit.svg')} alt=""/>
                      <img src={require('../../assets/images/icons/ico_edit_o.svg')} className="img-hovered" alt=""/>
                    </button>

                    <WizardConfirmation
                      onSubmit={deleteReportAction}
                      data={report.id}
                      action={(onClick) => (
                        <button onClick={onClick} className="report-item-delete">
                          <img src={require('../../assets/images/icons/ico_delete.svg')} alt=""/>
                          <img src={require('../../assets/images/icons/ico_delete_o.svg')} className="img-hovered" alt=""/>
                        </button>
                      )}
                      title='Are you sure to delete?'
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : null}
      </div>

      <div className="reports-page-table">
        <ReportsTable/>
      </div>
    </div>
  );
};

export default Reports;
