import * as yup from 'yup';

import {
  BROWSERS_FIELD,
  CARRIERS_FIELD,
  CTYPES_FIELD,
  CUSTOM_TAGS_FIELD,
  DEVICE_TYPES_FIELD,
  GEOS_FIELD,
  INCLUDE_FIELD,
  LIST_FIELD,
  OS_FIELD
} from '../../constants';

import { multiSelectSchema } from '../shared/shared-schemas';
import { targetIncludeSchema } from '../shared/targeting';

export const sTargetIncludeSchema = yup.object().shape({
  [INCLUDE_FIELD]: yup.bool().required('Include/exclude is required'),
  [LIST_FIELD]: multiSelectSchema
});

export const endpointDemandsTargetingSchema = yup.object().shape({
  [GEOS_FIELD]: sTargetIncludeSchema,
  [CTYPES_FIELD]: sTargetIncludeSchema,
  [CARRIERS_FIELD]: sTargetIncludeSchema,
  [DEVICE_TYPES_FIELD]: sTargetIncludeSchema,
  [BROWSERS_FIELD]: targetIncludeSchema,
  [OS_FIELD]: targetIncludeSchema,
  [CUSTOM_TAGS_FIELD]: yup.object().shape({
    [INCLUDE_FIELD]: yup.bool().required('Include/exclude is required'),
    [LIST_FIELD]: multiSelectSchema
  })
});

export const validateEndpointDemandsTargetingData = (data) => {
  try {
    endpointDemandsTargetingSchema.validateSync(data);

    return true;
  } catch (e) {
    return false;
  }
};
