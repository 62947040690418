import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import './TableConfirmation.scss';

const TableConfirmation = ({
  onSubmit,
  data,
  title,
  handleCancel,
  onDelete,
  withoutDispatch = false
}) => {
  const dispatch = useDispatch();

  return (
    <div className="table-confirmation">
      <p className="title">{title}</p>
      <div className="btn-container">
        <Button
          variant="secondary"
          className="btn btn-cancel"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          variant="danger"
          className="btn btn-confirm"
          onClick={(e) => {
            e.preventDefault();

            if (withoutDispatch) {
              onSubmit(data);
            } else {
              onSubmit(data)(dispatch);
            }

            if (onDelete) {
              onDelete();
            }
          }}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

TableConfirmation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  withoutDispatch: PropTypes.bool
};

export default TableConfirmation;
