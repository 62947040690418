import {
  ENDPOINT_CREATE_ENDPOINT,
  ENDPOINT_UPDATE_ENDPOINT,
  ENDPOINT_DELETE_ENDPOINT,
  ENDPOINT_START_FETCHING_ENDPOINTS,
  ENDPOINT_FETCH_ENDPOINTS,
  ENDPOINT_EDIT_ENDPOINT,
  ENDPOINT_DUPLICATE_ENDPOINT,
  ENDPOINT_UPDATE_ENDPOINT_STATE,
  ENDPOINT_GET_INITIAL_ENDPOINT_STATE,
  ENDPOINT_GET_INITIAL_ENDPOINT_FORM_STATE,
  ENDPOINT_CONNECT_ENDPOINT_DEMANDS_STATE,
  ENDPOINT_UPDATE_UPDATED_AT,
  ENDPOINT_FETCH_DEMANDS,
  ENDPOINT_DEMAND_FEEDS,
  ENDPOINT_UPDATE_STATUS,
  ENDPOINT_UPDATE_TAGS
} from '../actions/types';

import { transformNumberFields } from '../components/wizards-manager/helpers/transformers-helpers';
import generate from '../utils/string-generator';

export const formInitialEndpointDemandState = {
  title: '',
  partner: '',
  iab_cat: {
    label: 'None',
    value: 'none'
  },
  auth: generate(12),
  active: false,
  tags: [],
  subid: true,
  branding: 'srcflare', 
  targeting: {
    geos: {
      include: true,
      list: [{
        label: 'All',
        value: '*'
      }]
    },
    ctypes: {
      include: true,
      list: [{
        label: 'All',
        value: '*'
      }]
    },
    carriers: {
      include: true,
      list: [{
        label: 'All',
        value: '*'
      }]
    },
    device_types: {
      include: true,
      list: [{
        label: 'All',
        value: '*'
      }]
    },
    browsers: {
      include: true,
      list: []
    },
    os: {
      include: true,
      list: []
    },
    custom_tags: {
      include: true,
      list: []
    }
  },
  filters: {
    bad_ips: true,
    proxies: {
      label: 'Block',
      value: 'block'
    },
    ip: '',
    subid: '',
    domain: '',
    user_agents: '',
    ip_include: false,
    subid_include: false,
    domain_include: false,
    user_agents_include: false
  },
  optimization: {
    request_timeout: 500,
    min_bid: 0,
    max_bid: 0,
    bid_modify: [],
    limits: [],
    click_limits: [],
    rev_share: [{
      value: 0.7,
      geos: [{
        label: 'All',
        value: '*'
      }]
    }],
    dms_db: [{
      label: 'None',
      value: 'none'
    }],
    subid_db: [{
      label: 'None',
      value: 'none'
    }],
    redirect_domains: [{
      label: 'None',
      value: 'none'
    }]
  }
};

const formInitialEndpointState = {
  ...formInitialEndpointDemandState,
  output: {
    redirect_type: {
      label: 'Default',
      value: 'default'
    },
    render_type: {
      label: 'JSON',
      value: 'json'
    },
  },
  subid_enc: {
    label: 'Text Cypher (use _)',
    value: 'text_cypher'
  },
  subid_strategy: {
    label: 'SubId (Default)',
    value: 'orig_subid'
  },
  fallback_domains: {
    default_domain: '',
    no_bid: '',
    no_bid_use_default: false,
    targeting_mismatch: '',
    targeting_mismatch_use_default: false,
    ip_mismatch: '',
    ip_mismatch_use_default: false,
    other_click_filters: '',
    other_click_filters_use_default: false
  }
};

export const initialEndpointsState = {
  loading: true,
  endpoints: [],
  formData: formInitialEndpointState,
  demands: [],
  endpoint_demands: [],
  endpoint_id: null,
  demand_feeds: null,
  ed_map: {},
  duplicated_id: null,
  visited_endpoint_demand_form: false
};

export default function (state = initialEndpointsState, action) {
  const { type, payload } = action;

  switch (type) {
    case ENDPOINT_CREATE_ENDPOINT:
      return {
        ...state,
        endpoints: [payload, ...state.endpoints],
        formData: formInitialEndpointState,
        duplicated_id: null,
        visited_endpoint_demand_form: false
      };

    case ENDPOINT_UPDATE_ENDPOINT:
      return {
        ...state,
        formData: formInitialEndpointState,
        endpoints: state.endpoints.map(item => {
          if (item._id === payload._id) {
            return { ...payload, updatedAt: new Date() };
          }

          return item;
        }),
        duplicated_id: null,
        visited_endpoint_demand_form: false
      };

    case ENDPOINT_DELETE_ENDPOINT:
      return {
        ...state,
        endpoints: state.endpoints.filter(item => item._id !== payload)
      };

    case ENDPOINT_START_FETCHING_ENDPOINTS:
      return {
        ...state,
        loading: true
      };

    case ENDPOINT_FETCH_ENDPOINTS:
      return {
        ...state,
        loading: false,
        endpoints: payload
      };

    case ENDPOINT_UPDATE_ENDPOINT_STATE:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...transformNumberFields(payload)
        }
      };

    case ENDPOINT_EDIT_ENDPOINT:
    case ENDPOINT_DUPLICATE_ENDPOINT:
      return {
        ...state,
        formData: payload.formData,
        duplicated_id: payload.duplicated_id,
        visited_endpoint_demand_form: false
      };

    case ENDPOINT_GET_INITIAL_ENDPOINT_FORM_STATE:
      return {
        ...state,
        formData: formInitialEndpointState,
        demands: [],
        endpoint_demands: [],
        endpoint_id: null,
        demand_feeds: null,
        ed_map: {},
        duplicated_id: null,
        visited_endpoint_demand_form: false
      };

    case ENDPOINT_GET_INITIAL_ENDPOINT_STATE:
      return initialEndpointsState;

    case ENDPOINT_CONNECT_ENDPOINT_DEMANDS_STATE:
      return {
        ...state,
        ...payload
      };

    case ENDPOINT_UPDATE_UPDATED_AT:
      return {
        ...state,
        endpoints: state.endpoints.map(e => {
          if (e._id === payload) {
            return {
              ...e,
              updatedAt: new Date()
            };
          }

          return e;
        })
      };

    case ENDPOINT_UPDATE_STATUS:
      return {
        ...state,
        endpoints: state.endpoints.map(e => {
          if (e._id === payload._id) {
            return {
              ...e,
              active: payload.active
            };
          }

          return e;
        })
      };

    case ENDPOINT_UPDATE_TAGS:
      return {
        ...state,
        endpoints: state.endpoints.map(e => {
          if (e._id === payload._id) {
            return {
              ...e,
              tags: payload.tags
            };
          }

          return e;
        })
      };

    case ENDPOINT_FETCH_DEMANDS:
      return {
        ...state,
        demands: payload
      };

    case ENDPOINT_DEMAND_FEEDS:
      return {
        ...state,
        ...payload
      };

    default:
      return state;
  }
};
