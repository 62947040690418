import PropTypes from 'prop-types';
import React from 'react';

const TableDefaultColumnFilter = ({
  column: {
    filterValue,
    preFilteredRows,
    setFilter
  }
}) => {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      onChange={e => setFilter(e.target.value || undefined)} // Set undefined to remove the filter entirely
      placeholder={`Search ${count} records...`}
    />
  );
};

TableDefaultColumnFilter.propTypes = {
  column: PropTypes.object.isRequired
};

export default TableDefaultColumnFilter;
