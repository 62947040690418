import {
  DEMAND_CREATE_DEMAND,
  DEMAND_UPDATE_DEMAND,
  DEMAND_DELETE_DEMAND,
  DEMAND_START_FETCHING_DEMANDS,
  DEMAND_FETCH_DEMANDS,
  DEMAND_UPDATE_DEMAND_STATE,
  DEMAND_EDIT_DEMAND,
  DEMAND_DUPLICATE_DEMAND,
  DEMAND_GET_INITIAL_DEMAND_FORM_STATE,
  DEMAND_GET_INITIAL_DEMAND_STATE,
  DEMAND_UPDATE_UPDATED_AT,
  DEMAND_UPDATE_STATUS,
  DEMAND_CONNECT_DEMAND_ENDPOINTS_STATE,
  DEMAND_ENDPOINTS_FEEDS,
  DEMAND_FETCH_ENDPOINTS,
  DEMAND_UPDATE_TAGS
} from '../actions/types';

import { transformNumberFields } from '../components/wizards-manager/helpers/transformers-helpers';

import { formInitialEndpointDemandState } from './endpoint';

const formInitialDemandState = {
  ...formInitialEndpointDemandState,
  setup_data: {
    feed_setup: {
      integration: '',
      fields: [{
        name: '',
        value: ''
      }]
    },
    stats_setup: {
      integration: '',
      fields: [{
        name: '',
        value: ''
      }]
    }
  }
};

const initialState = {
  demands: [],
  loading: true,
  formData: formInitialDemandState,
  endpoints: [],
  demand_endpoints: [],
  demand_id: null,
  endpoint_feeds: null,
  df_map: {},
  duplicated_id: null,
  visited_demand_endpoint_form: false
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case DEMAND_CREATE_DEMAND:
      return {
        ...state,
        demands: [payload, ...state.demands],
        formData: formInitialDemandState,
        duplicated_id: null,
        visited_demand_endpoint_form: false
      };

    case DEMAND_UPDATE_DEMAND:
      return {
        ...state,
        formData: formInitialDemandState,
        demands: state.demands.map(item => {
          if (item._id === payload._id) {
            return { ...payload, updatedAt: new Date() };
          }

          return item;
        }),
        duplicated_id: null,
        visited_demand_endpoint_form: false
      };

    case DEMAND_DELETE_DEMAND:
      return {
        ...state,
        demands: state.demands.filter(item => item._id !== payload)
      };

    case DEMAND_START_FETCHING_DEMANDS:
      return {
        ...state,
        loading: true
      };

    case DEMAND_FETCH_DEMANDS:
      return {
        ...state,
        loading: false,
        demands: payload
      };

    case DEMAND_UPDATE_DEMAND_STATE:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...transformNumberFields(payload)
        }
      };

    case DEMAND_EDIT_DEMAND:
    case DEMAND_DUPLICATE_DEMAND:
      return {
        ...state,
        formData: payload.formData,
        duplicated_id: payload.duplicated_id,
        visited_demand_endpoint_form: false
      };

    case DEMAND_GET_INITIAL_DEMAND_FORM_STATE:
      return {
        ...state,
        formData: formInitialDemandState,
        endpoints: [],
        demand_endpoints: [],
        demand_id: null,
        endpoint_feeds: null,
        df_map: {},
        duplicated_id: null,
        visited_demand_endpoint_form: false
      };

    case DEMAND_UPDATE_UPDATED_AT:
      return {
        ...state,
        demands: state.demands.map(d => {
          if (d._id === payload) {
            return {
              ...d,
              updatedAt: new Date()
            };
          }

          return d;
        })
      };

    case DEMAND_UPDATE_STATUS:
      return {
        ...state,
        demands: state.demands.map(d => {
          if (d._id === payload._id) {
            return {
              ...d,
              active: payload.active
            };
          }

          return d;
        })
      };

    case DEMAND_UPDATE_TAGS:
      return {
        ...state,
        demands: state.demands.map(d => {
          if (d._id === payload._id) {
            return {
              ...d,
              tags: payload.tags
            };
          }

          return d;
        })
      };

    case DEMAND_CONNECT_DEMAND_ENDPOINTS_STATE:
      return {
        ...state,
        ...payload
      };

    case DEMAND_ENDPOINTS_FEEDS:
      return {
        ...state,
        ...payload
      };

    case DEMAND_FETCH_ENDPOINTS:
      return {
        ...state,
        endpoints: payload
      };

    case DEMAND_GET_INITIAL_DEMAND_STATE:
      return initialState;

    default:
      return state;
  }
}
