import {
  DASHBOARD_APPROVED_COUNT,
  DASHBOARD_APPROVED_PAYOUTS,
  DASHBOARD_APPROVED_REVENUE,
  DASHBOARD_AVG_NET_BID,
  DASHBOARD_CLICK_ERRORS,
  DASHBOARD_CLICKS_COUNT,
  DASHBOARD_EST_PAYOUT,
  DASHBOARD_EST_PROFIT,
  DASHBOARD_EST_REVENUE,
  DASHBOARD_EST_ROI,
  DASHBOARD_IMPS_COUNT,
  DASHBOARD_INT_REQUESTS_AVG_GROSS_BID,
  DASHBOARD_INT_REQUESTS_AVG_NET_BID,
  DASHBOARD_INT_REQUESTS_COUNT,
  DASHBOARD_INT_REQUESTS_COVERAGE,
  DASHBOARD_INT_REQUESTS_ERRORS_PCT,
  DASHBOARD_PROFIT,
  DASHBOARD_REQUESTS_COVERAGE,
  DASHBOARD_REQUESTS_AVG_GROSS_BID,
  DASHBOARD_REQUESTS_AVG_NET_BID,
  DASHBOARD_REQUESTS_COUNT,
  DASHBOARD_REQUESTS_ERRORS_PCT,
  DASHBOARD_ROI,
  DASHBOARD_TOP_CHARTS_CLICKS,
  DASHBOARD_TOP_CHARTS_EARNINGS,
  DASHBOARD_TOP_CHARTS_PAYOUT,
  DASHBOARD_TOP_CHARTS_PROFIT,
  DASHBOARD_TOP_CHARTS_REQUESTS,
  DASHBOARD_REQUESTS_BIDS,
  DASHBOARD_INT_REQUESTS_BIDS,
  DASHBOARD_REQUESTS_ERRORS,
  DASHBOARD_INT_REQUESTS_ERRORS
} from '../../../../actions/types';
import {
  formatCurrency,
  formatMillions,
  formatPercentage
} from '../../numeric-helpers';

export const chartOptions = {
  chart: {
    height: 35,
    sparkline: {
      enabled: true
    }
  },
  stroke: {
    curve: 'smooth',
    width: 2
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 0.5,
      gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 50, 100],
      colorStops: [
        {
          offset: 0,
          color: '#fc7b99',
          opacity: 1
        },
        {
          offset: 20,
          color: '#e2759c',
          opacity: 1
        },
        {
          offset: 40,
          color: '#ce6f9a',
          opacity: 1
        },
        {
          offset: 60,
          color: '#a9659e',
          opacity: 1
        },
        {
          offset: 80,
          color: '#7a549a',
          opacity: 1
        },
        {
          offset: 100,
          color: '#534fa0',
          opacity: 1
        }
      ]
    }
  },
  markers: {
    colors: ['#ff0000', '#ff0000', '#ff0000']
  },
  plotOptions: {
    bar: {
      columnWidth: '80%'
    }
  },
  xaxis: {
    crosshairs: {
      width: 1
    }
  },
  tooltip: {
    fixed: {
      enabled: false
    },
    x: {
      show: false
    },
    y: {
      title: {
        formatter: function () {
          return '';
        }
      }
    },
    marker: {
      show: false
    }
  }
};

export const buildGraphMetrics = (title, isDemandsFiltered, isToday) => {
  switch (title) {
    case DASHBOARD_TOP_CHARTS_REQUESTS:
      return isDemandsFiltered
        ? [DASHBOARD_INT_REQUESTS_COUNT]
        : [DASHBOARD_REQUESTS_COUNT];

    case DASHBOARD_TOP_CHARTS_CLICKS:
      return [DASHBOARD_CLICKS_COUNT];

    case DASHBOARD_TOP_CHARTS_PAYOUT:
      return [isToday ? DASHBOARD_EST_PAYOUT : DASHBOARD_APPROVED_PAYOUTS];

    case DASHBOARD_TOP_CHARTS_EARNINGS:
      return [isToday ? DASHBOARD_EST_REVENUE : DASHBOARD_APPROVED_REVENUE];

    case DASHBOARD_TOP_CHARTS_PROFIT:
      if (isToday) {
        return [DASHBOARD_EST_REVENUE, DASHBOARD_EST_PAYOUT, DASHBOARD_EST_PROFIT];
      }

      return [DASHBOARD_APPROVED_REVENUE, DASHBOARD_APPROVED_PAYOUTS, DASHBOARD_PROFIT];
  }
};

export const buildMetrics = (title, isDemandsFiltered) => {
  switch (title) {
    case DASHBOARD_TOP_CHARTS_REQUESTS:
      return isDemandsFiltered
        ? [
          DASHBOARD_INT_REQUESTS_COUNT,
          DASHBOARD_INT_REQUESTS_AVG_NET_BID,
          DASHBOARD_INT_REQUESTS_COVERAGE,
          DASHBOARD_INT_REQUESTS_BIDS,
          DASHBOARD_INT_REQUESTS_ERRORS,
          DASHBOARD_INT_REQUESTS_ERRORS_PCT
        ]
        : [
          DASHBOARD_REQUESTS_COUNT,
          DASHBOARD_REQUESTS_AVG_NET_BID,
          DASHBOARD_REQUESTS_COVERAGE,
          DASHBOARD_REQUESTS_BIDS,
          DASHBOARD_REQUESTS_ERRORS,
          DASHBOARD_REQUESTS_ERRORS_PCT
        ];

    case DASHBOARD_TOP_CHARTS_CLICKS:
      return isDemandsFiltered
        ? [
          DASHBOARD_INT_REQUESTS_COUNT,
          DASHBOARD_CLICKS_COUNT,
          DASHBOARD_AVG_NET_BID,
          DASHBOARD_IMPS_COUNT,
          DASHBOARD_CLICK_ERRORS
        ]
        : [
          DASHBOARD_REQUESTS_COUNT,
          DASHBOARD_CLICKS_COUNT,
          DASHBOARD_AVG_NET_BID,
          DASHBOARD_IMPS_COUNT,
          DASHBOARD_CLICK_ERRORS
        ];

    case DASHBOARD_TOP_CHARTS_PAYOUT:
      return [
        DASHBOARD_EST_PAYOUT,
        DASHBOARD_APPROVED_PAYOUTS,
        DASHBOARD_CLICKS_COUNT,
        DASHBOARD_APPROVED_COUNT
      ];

    case DASHBOARD_TOP_CHARTS_EARNINGS:
      return isDemandsFiltered
        ? [
          DASHBOARD_EST_REVENUE,
          DASHBOARD_APPROVED_REVENUE,
          DASHBOARD_INT_REQUESTS_AVG_GROSS_BID
        ]
        : [
          DASHBOARD_EST_REVENUE,
          DASHBOARD_APPROVED_REVENUE,
          DASHBOARD_REQUESTS_AVG_GROSS_BID
        ];

    case DASHBOARD_TOP_CHARTS_PROFIT:
      return [
        DASHBOARD_APPROVED_REVENUE,
        DASHBOARD_APPROVED_PAYOUTS,
        DASHBOARD_EST_REVENUE,
        DASHBOARD_EST_PAYOUT,
        DASHBOARD_PROFIT,
        DASHBOARD_ROI,
        DASHBOARD_EST_PROFIT,
        DASHBOARD_EST_ROI
      ];
  }
};

export const checkIsDemandFiltered = (demands, demandSource) => {
  if (
    (!demands || !demands.length) &&
    (!demandSource || !demandSource.length)
  ) {
    return false;
  }

  if (!demands && demandSource) {
    return !demandSource.map((d) => d.value).includes('*');
  }

  if (!demandSource && demands) {
    return !demands.map((d) => d.value).includes('*');
  }

  const demandsAll = !demands.map((d) => d.value).includes('*');

  const demandSourceAll = !demandSource.map((d) => d.value).includes('*');

  return demandsAll && demandSourceAll;
};

const buildRequestItemData = (item, isDemandFiltered) => {
  if (!item) {
    return {
      value: formatMillions(0),
      pct: null,
      list: [
        {
          name: 'cpm',
          value: formatCurrency(0)
        },
        {
          name: 'cvg%',
          value: formatPercentage(0)
        },
        {
          name: 'filter%',
          value: formatPercentage(0)
        }
      ]
    };
  }

  const reqField = isDemandFiltered
    ? DASHBOARD_INT_REQUESTS_COUNT
    : DASHBOARD_REQUESTS_COUNT;
  const cmpField = isDemandFiltered
    ? DASHBOARD_INT_REQUESTS_AVG_NET_BID
    : DASHBOARD_REQUESTS_AVG_NET_BID;
  const coverageField = isDemandFiltered
    ? DASHBOARD_INT_REQUESTS_COVERAGE
    : DASHBOARD_REQUESTS_COVERAGE;
  const filteredField = isDemandFiltered
    ? DASHBOARD_INT_REQUESTS_ERRORS_PCT
    : DASHBOARD_REQUESTS_ERRORS_PCT;

  return {
    value: formatMillions(item[reqField]),
    pct: null,
    list: [
      {
        name: 'cpm',
        value: formatCurrency(item[cmpField] * 1000)
      },
      {
        name: 'cvg%',
        value: formatPercentage(item[coverageField])
      },
      {
        name: 'filter%',
        value: formatPercentage(item[filteredField])
      }
    ]
  };
};

const buildClicksItemData = (item, isDemandFiltered) => {
  if (!item) {
    return {
      value: formatMillions(0),
      pct: formatPercentage(0),
      pctPositive: true,
      list: [
        {
          name: 'Win. CPM',
          value: formatCurrency(0)
        },
        {
          name: 'Imp ctr%',
          value: formatPercentage(0)
        },
        {
          name: 'filter%',
          value: formatPercentage(0)
        }
      ]
    };
  }

  const reqField = isDemandFiltered
    ? DASHBOARD_INT_REQUESTS_COUNT
    : DASHBOARD_REQUESTS_COUNT;

  const reqCounts = item[reqField];
  const clickCounts = item[DASHBOARD_CLICKS_COUNT];
  const impCounts = item[DASHBOARD_IMPS_COUNT];
  const clickErrors = item[DASHBOARD_CLICK_ERRORS];
  const avgNetBid = item[DASHBOARD_AVG_NET_BID];

  const pct = reqCounts ? clickCounts / reqCounts : 0;

  const impValue =
    impCounts && impCounts > clickCounts ? clickCounts / impCounts : 0;

  const filterValue = clickCounts ? clickErrors / clickCounts : 0;

  const pctPositive = pct >= 0;

  return {
    value: formatMillions(clickCounts),
    pct: formatPercentage(pct),
    pctPositive,
    list: [
      {
        name: 'Win. CPM',
        value: formatCurrency(avgNetBid * 1000)
      },
      {
        name: 'Imp ctr%',
        value: formatPercentage(impValue)
      },
      {
        name: 'filter%',
        value: formatPercentage(filterValue)
      }
    ]
  };
};

const buildPayoutItemData = (item, isToday) => {
  if (!item) {
    return {
      value: formatCurrency(0),
      pct: null,
      list: [
        {
          name: isToday ? 'Approved' : 'Estimated',
          value: formatCurrency(0)
        },
        {
          name: 'dscp%',
          value: formatPercentage(0)
        },
        {
          name: 'clk dscp%',
          value: formatPercentage(0)
        }
      ]
    };
  }

  const estPayout = item[DASHBOARD_EST_PAYOUT];
  const approvedValue = item[DASHBOARD_APPROVED_PAYOUTS];
  const clickCounts = item[DASHBOARD_CLICKS_COUNT];
  const approvedClicks = item[DASHBOARD_APPROVED_COUNT];

  const dscpValue = estPayout ? 1 - approvedValue / estPayout : estPayout;

  const clickDscpValue = clickCounts
    ? 1 - approvedClicks / clickCounts
    : clickCounts;

  return {
    value: formatCurrency(isToday ? estPayout : approvedValue),
    pct: null,
    list: [
      {
        name: isToday ? 'Approved' : 'Estimated',
        value: formatCurrency(isToday ? approvedValue : estPayout)
      },
      {
        name: 'dscp%',
        value: formatPercentage(dscpValue)
      },
      {
        name: 'clk dscp%',
        value: formatPercentage(clickDscpValue)
      }
    ]
  };
};

const buildEarningsItemData = (item, isDemandFiltered, isToday) => {
  if (!item) {
    return {
      value: formatCurrency(0),
      pct: null,
      list: [
        {
          name: isToday ? 'Approved' : 'Estimated',
          value: formatCurrency(0)
        },
        {
          name: 'discp%',
          value: formatPercentage(0)
        },
        {
          name: 'Gross CPM',
          value: formatCurrency(0)
        }
      ]
    };
  }

  const grossField = isDemandFiltered
    ? DASHBOARD_INT_REQUESTS_AVG_GROSS_BID
    : DASHBOARD_REQUESTS_AVG_GROSS_BID;

  const estRevenue = item[DASHBOARD_EST_REVENUE];
  const approvedValue = item[DASHBOARD_APPROVED_REVENUE];
  const grossValue = item[grossField];

  const discp = estRevenue ? 1 - approvedValue / estRevenue : 0;

  return {
    value: formatCurrency(isToday ? estRevenue : approvedValue),
    pct: null,
    list: [
      {
        name: isToday ? 'Approved' : 'Estimated',
        value: formatCurrency(isToday ? approvedValue : estRevenue)
      },
      {
        name: 'discp%',
        value: formatPercentage(discp)
      },
      {
        name: 'Gross CPM',
        value: formatCurrency(grossValue * 1000)
      }
    ]
  };
};

const buildProfitItemData = (item, isToday) => {
  if (!item) {
    return {
      value: formatCurrency(0),
      pct: null,
      pctPositive: true,
      list: [
        {
          name: isToday ? 'Approved' : 'Estimated',
          value: formatCurrency(0)
        },
        {
          name: 'ROI',
          value: formatPercentage(0),
          positive: true
        },
        {
          name: 'Est.ROI',
          value: formatPercentage(0),
          positive: true
        }
      ]
    };
  }

  const profit = item[DASHBOARD_PROFIT];
  const roi = item[DASHBOARD_ROI];
  const estProfit = item[DASHBOARD_EST_PROFIT];
  const estRoi = item[DASHBOARD_EST_ROI];

  const pctPositive = estRoi >= 0;

  return {
    value: formatCurrency(isToday ? estProfit : profit),
    pct: null,
    pctPositive,
    list: [
      {
        name: isToday ? 'Approved' : 'Estimated',
        value: formatCurrency(isToday ? profit : estProfit)
      },
      {
        name: 'ROI',
        value: formatPercentage(roi),
        positive: roi >= 0
      },
      {
        name: 'Est.ROI',
        value: formatPercentage(estRoi),
        positive: roi >= 0
      }
    ]
  };
};

export const buildItemData = (title, isDemandFiltered, item, isToday) => {
  switch (title) {
    case DASHBOARD_TOP_CHARTS_REQUESTS:
      return buildRequestItemData(item, isDemandFiltered);

    case DASHBOARD_TOP_CHARTS_CLICKS:
      return buildClicksItemData(item, isDemandFiltered);

    case DASHBOARD_TOP_CHARTS_PAYOUT:
      return buildPayoutItemData(item, isToday);

    case DASHBOARD_TOP_CHARTS_EARNINGS:
      return buildEarningsItemData(item, isDemandFiltered, isToday);

    case DASHBOARD_TOP_CHARTS_PROFIT:
      return buildProfitItemData(item, isToday);
  }
};

const getGraphData = (items, field, parse) => [
  {
    data: items.map((item) => {
      if (parse) {
        return parseFloat(item[field].toFixed(2));
      }

      return item[field];
    })
  }
];

export const buildGraphData = (title, isDemandFiltered, items, isToday) => {
  switch (title) {
    case DASHBOARD_TOP_CHARTS_REQUESTS:
      return getGraphData(
        items,
        isDemandFiltered
          ? DASHBOARD_INT_REQUESTS_COUNT
          : DASHBOARD_REQUESTS_COUNT
      );

    case DASHBOARD_TOP_CHARTS_CLICKS:
      return getGraphData(items, DASHBOARD_CLICKS_COUNT);

    case DASHBOARD_TOP_CHARTS_PAYOUT:
      return getGraphData(items, isToday ? DASHBOARD_EST_PAYOUT : DASHBOARD_APPROVED_PAYOUTS, true);

    case DASHBOARD_TOP_CHARTS_EARNINGS:
      return getGraphData(items, isToday ? DASHBOARD_EST_REVENUE : DASHBOARD_APPROVED_REVENUE, true);

    case DASHBOARD_TOP_CHARTS_PROFIT:
      return getGraphData(items, isToday ? DASHBOARD_EST_PROFIT : DASHBOARD_PROFIT, true);
  }
};

export const getChartsList = (isPartner) => {
  if (isPartner) {
    return [
      DASHBOARD_TOP_CHARTS_REQUESTS,
      DASHBOARD_TOP_CHARTS_CLICKS,
      DASHBOARD_TOP_CHARTS_PAYOUT
    ];
  }

  return [
    DASHBOARD_TOP_CHARTS_REQUESTS,
    DASHBOARD_TOP_CHARTS_CLICKS,
    DASHBOARD_TOP_CHARTS_PAYOUT,
    DASHBOARD_TOP_CHARTS_EARNINGS,
    DASHBOARD_TOP_CHARTS_PROFIT
  ];
};

export const getTopChartTitle = (title, isToday, isPartner) => {
  if (isPartner) {
    if (title === DASHBOARD_TOP_CHARTS_PAYOUT) {
      if (isToday) {
        return `Est. ${DASHBOARD_TOP_CHARTS_EARNINGS}`;
      } else {
        return DASHBOARD_TOP_CHARTS_EARNINGS;
      }
    }
  }

  if (title === DASHBOARD_TOP_CHARTS_PAYOUT ||
    title === DASHBOARD_TOP_CHARTS_EARNINGS ||
    title === DASHBOARD_TOP_CHARTS_PROFIT) {
    if (isToday) {
      return `Est. ${title}`;
    }
  }

  return title;
};
