import { combineReducers } from 'redux';

import dashboard from './dashboard';
import demand from './demand';
import drawerForms from './drawerForms';
import endpoint from './endpoint';
import endpointDemand from './endpointDemand';
import partner from './partner';
import reports from './reports';
import rules from './rules';
import staticList from './staticList';

export default combineReducers({
  endpoint,
  endpointDemand,
  demand,
  drawerForms,
  partner,
  staticList,
  dashboard,
  reports,
  rules
});
