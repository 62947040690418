import { toast } from 'react-toastify';

import { fetchAllEndpointDemands, modifyEndpointDemand } from '../api/endpointDemand';

import { ENDPOINT_DEMANDS_WIZARD } from '../components/wizards-manager/constants';
import { transformEndpointData } from '../components/wizards-manager/helpers/transformers';

import store from '../store';

import {
  ENDPOINT_DEMAND_GET_INITIAL_ENDPOINT_DEMANDS_STATE,
  ENDPOINT_DEMAND_UPDATE_ENDPOINT_DEMAND_STATE
} from './types';

export const updateEndpointDemandsAction = () => async (dispatch) => {
  try {
    const endpointDemand = store.getState()[ENDPOINT_DEMANDS_WIZARD].formData;

    const data = transformEndpointData(endpointDemand);
    const id = data._id;

    // Remove unnecessary fields
    delete data._id;
    delete data.active;
    delete data.endpoint;
    delete data.demand;

    await modifyEndpointDemand(id, data);

    dispatch({ type: ENDPOINT_DEMAND_GET_INITIAL_ENDPOINT_DEMANDS_STATE });

    toast.info('Endpoint Demand Updated', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    return false;
  } catch (err) {
    toast.error('Update Endpoint Demand Error', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    return err;
  }
};

export const updateEndpointDemandsFormStateAction = (payload) => (dispatch) => {
  dispatch({ type: ENDPOINT_DEMAND_UPDATE_ENDPOINT_DEMAND_STATE, payload });
};

export const getInitialEndpointDemandsStateAction = () => (dispatch) => {
  dispatch({ type: ENDPOINT_DEMAND_GET_INITIAL_ENDPOINT_DEMANDS_STATE });
};

export const getAllEndpointDemands = async () => {
  try {
    const res = await fetchAllEndpointDemands();

    return res.data.data;
  } catch (e) {
    toast.error('Fetching endpoint-demands fail', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    return [];
  }
};
