import React from 'react';
import { Spinner } from 'react-bootstrap';

import './TablePanelSpinner.scss';

const GlobalSpinner = () => (
  <div className="table-panel-spinner">
    <Spinner className="panel-spinner" animation="border" variant="dark"/>
  </div>
);

export default GlobalSpinner;
