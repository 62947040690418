import {
  ENDPOINT_FALLBACK_DOMAINS_FIELD,
  ENDPOINT_WIZARD,
  FILTERS_FIELD,
  OPTIMIZATION_FIELD,
  TARGETING_FIELD
} from '../../constants';

import { validateFormData } from '../shared';
import { validateGeneralData } from '../shared/general';
import { validateOptimizationData } from '../shared/optimization';
import { validateProtectionData } from '../shared/protection';
import { validateTargetingData } from '../shared/targeting';
import { validateFallbackData } from './fallback';

const endpointForms = [
  {
    validator: validateGeneralData
  },
  {
    validator: validateOptimizationData,
    field: OPTIMIZATION_FIELD
  },
  {
    validator: validateTargetingData,
    field: TARGETING_FIELD
  },
  {
    validator: validateProtectionData,
    field: FILTERS_FIELD
  },
  {
    validator: validateFallbackData,
    field: ENDPOINT_FALLBACK_DOMAINS_FIELD
  }
];

export const validateEndpointFormData = (toForm, exclude) => validateFormData(ENDPOINT_WIZARD, [...endpointForms], toForm, exclude);
