import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { updatePartnerStateAction } from '../../../../../actions/partners';

import {
  PARTNER_CONFIRM_PASSWORD_FIELD,
  PARTNER_PASSWORD_FIELD,
  PARTNER_USERNAME_FIELD,
  PARTNER_WIZARD
} from '../../../constants';

import { useFormsEffect } from '../../../custom-hooks/useFormsEffect';
import { useIsValidForm } from '../../../custom-hooks/useIsValidForm';
import {
  validateAuthData,
  validateAuthDataUpdate
} from '../../../validators/partner/auth';

import WizardInputWithValidation from '../../shared/WizardInputWithValidation';

import '../../../wizard-base/WizardSharedStyles.scss';

const passwordErrorMessage = 'Passwords doesn\'t match';

export const getRegisterField = (register, getValues, clearError, setError, field, id) => {
  if (id) {
    if (field === PARTNER_USERNAME_FIELD) {
      return register;
    }

    if (field === PARTNER_PASSWORD_FIELD) {
      return register({
        validate: value => {
          const match = value === getValues({ nest: true })[PARTNER_CONFIRM_PASSWORD_FIELD];

          if (match) {
            clearError(PARTNER_CONFIRM_PASSWORD_FIELD);
            return true;
          }

          setError(PARTNER_CONFIRM_PASSWORD_FIELD, 'notMatch', passwordErrorMessage);
          return passwordErrorMessage;
        }
      });
    }

    return register({
      validate: value => {
        const match = value === getValues({ nest: true })[PARTNER_PASSWORD_FIELD];

        if (match) {
          clearError(PARTNER_PASSWORD_FIELD);
          return true;
        }

        setError(PARTNER_PASSWORD_FIELD, 'notMatch', passwordErrorMessage);
        return passwordErrorMessage;
      }
    });
  } else {
    if (field === PARTNER_USERNAME_FIELD) {
      return register({ required: 'Username is required' });
    }

    if (field === PARTNER_PASSWORD_FIELD) {
      register({
        required: 'Password is required',
        validate: value => {
          const match = value === getValues({ nest: true })[PARTNER_CONFIRM_PASSWORD_FIELD];

          if (match) {
            clearError(PARTNER_CONFIRM_PASSWORD_FIELD);
            return true;
          }

          setError(PARTNER_CONFIRM_PASSWORD_FIELD, 'notMatch', passwordErrorMessage);
          return passwordErrorMessage;
        }
      });
    }

    return register({
      required: 'Confirm Password is required',
      validate: value => {
        const match = value === getValues({ nest: true })[PARTNER_PASSWORD_FIELD];

        if (match) {
          clearError(PARTNER_PASSWORD_FIELD);
          return true;
        }

        setError(PARTNER_PASSWORD_FIELD, 'notMatch', passwordErrorMessage);
        return passwordErrorMessage;
      }
    });
  }
};

const PartnerAuthenticationForm = ({
  isSubmitted,
  onSubmit,
  setIsValid
}) => {
  const {
    id,
    defaultValues
  } = useSelector(
    state => ({
      id: state[PARTNER_WIZARD].formData._id,
      defaultValues: {
        username: state[PARTNER_WIZARD].formData[PARTNER_USERNAME_FIELD],
        password: state[PARTNER_WIZARD].formData[PARTNER_PASSWORD_FIELD],
        [PARTNER_CONFIRM_PASSWORD_FIELD]: state[PARTNER_WIZARD].formData[PARTNER_CONFIRM_PASSWORD_FIELD]
      }
    })
  );

  const {
    register,
    errors,
    clearError,
    setError,
    triggerValidation,
    getValues
  } = useForm({
    mode: 'onBlur',
    defaultValues
  });

  useFormsEffect(isSubmitted, triggerValidation, getValues, updatePartnerStateAction, onSubmit);
  const values = Object.keys(getValues({ nest: true })).length ? getValues({ nest: true }) : defaultValues;
  const validationFunction = id ? validateAuthDataUpdate : validateAuthData;
  const isValid = validationFunction(values);
  useIsValidForm(isValid, setIsValid);

  return (
    <form>
      <WizardInputWithValidation
        propRef={getRegisterField(register, getValues, clearError, setError, PARTNER_USERNAME_FIELD, id)}
        name={PARTNER_USERNAME_FIELD}
        label="Username"
        error={!!errors[PARTNER_USERNAME_FIELD]}
        errorMessage={errors[PARTNER_USERNAME_FIELD] ? errors[PARTNER_USERNAME_FIELD].message : ''}
        type='text'
        placeholder='Enter Username...'
      />

      <WizardInputWithValidation
        propRef={getRegisterField(register, getValues, clearError, setError, PARTNER_PASSWORD_FIELD, id)}
        name={PARTNER_PASSWORD_FIELD}
        label={id ? 'New Password' : 'Password'}
        error={!!errors[PARTNER_PASSWORD_FIELD]}
        errorMessage={errors[PARTNER_PASSWORD_FIELD] ? errors[PARTNER_PASSWORD_FIELD].message : ''}
        type='text'
        placeholder={id ? 'Enter New Password...' : 'Enter Password...'}
      />
      <WizardInputWithValidation
        propRef={getRegisterField(register, getValues, clearError, setError, PARTNER_CONFIRM_PASSWORD_FIELD, id)}
        name={PARTNER_CONFIRM_PASSWORD_FIELD}
        label={id ? 'Confirm New Password' : 'Confirm Password'}
        error={!!errors[PARTNER_CONFIRM_PASSWORD_FIELD]}
        errorMessage={errors[PARTNER_CONFIRM_PASSWORD_FIELD] ? errors[PARTNER_CONFIRM_PASSWORD_FIELD].message : ''}
        type='text'
        placeholder={id ? 'Repeat New Password...' : 'Repeat Password...'}
      />
    </form>
  );
};

PartnerAuthenticationForm.propTypes = {
  isSubmitted: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setIsValid: PropTypes.func.isRequired
};

export default PartnerAuthenticationForm;
