import { toast } from 'react-toastify';

import { fetchAllRules, fetchRuleById, modifyRule, removeRule, runRuleById, saveRule } from '../api/rules';

import { transformRuleData, transformRuleToFromData } from '../components/rules/helpers/transformers';

import { transformMultiSelect } from '../components/wizards-manager/helpers/wizard-helpers';

import store from '../store';

import {
  DRAWER_FORM_CREATE_RULE,
  DRAWER_FORM_OPEN,
  RULES_CREATE_RULE,
  RULES_DELETE_RULE,
  RULES_DUPLICATE_RULE,
  RULES_EDIT_RULE,
  RULES_FETCH_RULES,
  RULES_RUN_RULE,
  RULES_START_FETCHING_RULES,
  RULES_UPDATE_RULE,
  RULES_UPDATE_RULE_STATUS,
  RULES_UPDATE_TAGS
} from './types';

const dispatch = store.dispatch;

export const runNowRuleAction = (id) => async (dispatch) => {
  try {
    await runRuleById(id);

    const rule = await fetchRuleById(id);
    const payload = rule.data.data;

    toast.info('Run Rule successfully', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    dispatch({ type: RULES_RUN_RULE, payload });
  } catch (err) {
    toast.error('Run Rule Failed', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};

export const editRuleFromTableAction = async (id) => {
  try {
    const rule = await fetchRuleById(id);
    const payload = transformRuleToFromData(rule.data.data);

    dispatch({ type: RULES_EDIT_RULE, payload });
    dispatch({ type: DRAWER_FORM_OPEN, payload: DRAWER_FORM_CREATE_RULE });
  } catch (err) {
    toast.error('Open edit rule failed', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};

export const editRuleAction = (data, staticList) => (dispatch) => {
  const payload = transformRuleToFromData(data);

  dispatch({ type: RULES_EDIT_RULE, payload });
  dispatch({ type: DRAWER_FORM_OPEN, payload: DRAWER_FORM_CREATE_RULE });
};

export const duplicateRuleAction = (data, staticList) => (dispatch) => {
  const payload = transformRuleToFromData(data);

  payload.title = `${payload.title} (copy 1)`;

  delete payload._id;

  dispatch({ type: RULES_DUPLICATE_RULE, payload });
  dispatch({ type: DRAWER_FORM_OPEN, payload: DRAWER_FORM_CREATE_RULE });
};

export const createRuleAction = (rule) => async (dispatch) => {
  try {
    const data = transformRuleData(rule);
    const res = await saveRule(data);

    const payload = res.data.data;

    toast.info('Rule Created', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    dispatch({
      type: RULES_CREATE_RULE,
      payload
    });

    return true;
  } catch (err) {
    toast.error('Create Rule Failed', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    return false;
  }
};

export const updateRuleAction = (id, data) => async (dispatch) => {
  try {
    const rule = transformRuleData(data);
    const res = await modifyRule(id, rule);

    const payload = res.data.data;

    toast.info('Rule Updated', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    dispatch({
      type: RULES_UPDATE_RULE,
      payload
    });

    return true;
  } catch (err) {
    toast.error('Update Rule Error', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    return false;
  }
};

export const deleteRuleAction = (id) => async (dispatch) => {
  try {
    await removeRule(id);

    toast.info('Rule Deleted Successfully', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    dispatch({
      type: RULES_DELETE_RULE,
      payload: id
    });
  } catch (err) {
    toast.error('Delete Rule Failed', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};

export const fetchRulesAction = () => async (dispatch) => {
  try {
    dispatch({ type: RULES_START_FETCHING_RULES });

    const res = await fetchAllRules();
    const payload = res.data.data.data;

    dispatch({ type: RULES_FETCH_RULES, payload });
  } catch (err) {
    toast.error('Fetching Rules Error', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    // TODO Handle it
    dispatch({ type: RULES_FETCH_RULES, payload: [] });
  }
};

export const updateRuleStatus = (id, active) => async (dispatch) => {
  try {
    await modifyRule(id, { active });

    dispatch({
      type: RULES_UPDATE_RULE_STATUS,
      payload: {
        id,
        active
      }
    });

    toast.info(`Rule ${active ? 'activated' : 'deactivated'}`, {
      position: toast.POSITION.BOTTOM_CENTER
    });
  } catch (e) {
    toast.error(`${active ? 'Activating' : 'Deactivating'} rule fail`, {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};

export const updateRuleTagsAction = async (id, data) => {
  try {
    const tags = transformMultiSelect(data);

    await modifyRule(id, { tags });

    const payload = {
      _id: id,
      tags: data
    };

    dispatch({ type: RULES_UPDATE_TAGS, payload });
  } catch (err) {
    toast.error('Rule tags update fail', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};
