import {
  REQUEST_LOAD_STATIC_LIST,
  GET_STATIC_LIST_ERROR,
  GET_STATIC_LIST
} from '../actions/types';

const initiState = {
  staticListLoading: true,
  tagOptions: [],
  carrierOptions: [],
  iabcatOptions: [],
  connectionTypeOptions: [],
  domainMaskingOptions: [],
  metrics: [],
  countryOptions: [],
  actionOptions: [],
  redirectDomainOptions: [],

  osOptions: [],
  browserOptions: [],
  deviceOptions: [],
  feedOptions: [],
  statsOptions: [],

  rulesTags: [],
  rulesActions: []
};

export default function (state = initiState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_STATIC_LIST:
      return {
        ...payload,
        staticListLoading: false
      };

    case REQUEST_LOAD_STATIC_LIST:
      return {
        ...state,
        staticListLoading: true
      };

    // TODO What happens when have an error here?
    // It's important because a lot of fields works with those options
    case GET_STATIC_LIST_ERROR:
      return {
        ...state,
        staticListLoading: false
      };

    default:
      return {
        ...state
      };
  }
}
