import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useIsMountedRef } from '../custom-hooks/useIsMountedRef';
import Popover from '../wizards/shared/Popover';

import './WizardSharedStyles.scss';

const WizardTitle = ({
  title,
  updateTitle,
  placeholder,
  id
}) => {
  const { register, errors, handleSubmit } = useForm({
    defaultValues: {
      title
    },
    mode: 'onBlur'
  });

  const isMountedRef = useIsMountedRef();

  const [focusedTitle, setFocusedTitle] = useState(false);

  const handleOnBlur = (value) => {
    updateTitle(value);

    if (isMountedRef.current) {
      setFocusedTitle(false);
    }
  };

  const handleOnFocus = () => {
    if (isMountedRef.current) {
      setFocusedTitle(true);
    }
  };

  const handleHitEnter = (data, e) => {
    if (e) {
      if (e.target) {
        if (e.target.keyCode === 13) {
          e.preventDefault();
        }
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit(handleHitEnter)}
      onKeyPress={handleHitEnter}
      className="wizard-input-container"
    >
      <Popover
        title={'Title is required'}
        open={!!errors.title && focusedTitle}
      >
        <input
          spellCheck={false}
          type="type"
          name="title"
          ref={register({
            required: true
          })}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          placeholder={placeholder}
          className={`form-title-input title-forms the-field ${errors.title ? 'error' : ''}`}
          autoFocus={!id}
        />
      </Popover>
      {errors.title && !focusedTitle && <FontAwesomeIcon className="field-error-icon" icon={faExclamationCircle}/>}
    </form>
  );
};

WizardTitle.propTypes = {
  title: PropTypes.string.isRequired,
  updateTitle: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  id: PropTypes.string
};

export default WizardTitle;
