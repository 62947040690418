import moment from 'moment';

import {
  DASHBOARD_APPROVED_PAYOUTS,
  DASHBOARD_APPROVED_REVENUE,
  DASHBOARD_BREAKDOWN_DAY,
  DASHBOARD_BREAKDOWN_HOUR,
  DASHBOARD_BREAKDOWN_MONTH,
  DASHBOARD_BREAKDOWN_WEEK,
  DASHBOARD_CLICKS_COUNT,
  DASHBOARD_EST_PAYOUT,
  DASHBOARD_EST_REVENUE,
  DASHBOARD_IMPS_COUNT,
  DASHBOARD_INT_REQUESTS_COUNT,
  DASHBOARD_INTERVAL_CUSTOM,
  DASHBOARD_INTERVAL_LAST_48_HOURS,
  DASHBOARD_INTERVAL_LAST_7_DAYS,
  DASHBOARD_INTERVAL_LAST_MONTH,
  DASHBOARD_INTERVAL_THIS_MONTH,
  DASHBOARD_INTERVAL_TODAY,
  DASHBOARD_INTERVAL_YESTERDAY,
  DASHBOARD_MAIN_CHART_EARNINGS,
  DASHBOARD_MAIN_CHART_EST_EARNINGS,
  DASHBOARD_MAIN_CHART_EST_VS_APPROVED_EARNINGS,
  DASHBOARD_MAIN_CHART_IMPRESSIONS_VS_CLICKS,
  DASHBOARD_MAIN_CHART_REQUESTS_VS_CLICKS,
  DASHBOARD_REQUESTS_COUNT
} from '../../../../actions/types';

import { formatCurrency, formatNumbers } from '../../numeric-helpers';

export const getHours = () => [...Array(24).keys()].map(x => `${x + 1}:00`);

export const getMonthDays = () => [...Array(moment().daysInMonth()).keys()].map(x => x + 1);

export const getMonths = () => moment.monthsShort();

export const buildItems = (items, chart, isPartner) => {
  switch (chart) {
    case DASHBOARD_MAIN_CHART_EARNINGS:
      return [
        {
          type: 'line',
          name: 'Approved revenue',
          data: items.map((i) => ({
            x: i.date,
            y: i[DASHBOARD_APPROVED_REVENUE]
          }))
        },
        {
          type: 'area',
          name: 'Approved payout',
          data: items.map((i) => ({
            x: i.date,
            y: i[DASHBOARD_APPROVED_PAYOUTS]
          }))
        }
      ];

    case DASHBOARD_MAIN_CHART_EST_EARNINGS:
      return [
        {
          type: 'line',
          name: 'Est revenue',
          data: items.map((i) => ({
            x: i.date,
            y: i[DASHBOARD_EST_REVENUE]
          }))
        },
        {
          type: 'area',
          name: 'Est payout',
          data: items.map((i) => ({
            x: i.date,
            y: i[DASHBOARD_EST_PAYOUT]
          }))
        }
      ];

    case DASHBOARD_MAIN_CHART_EST_VS_APPROVED_EARNINGS:
      return [
        {
          type: 'line',
          name: `Approved ${isPartner ? 'earnings' : 'revenue'}`,
          data: items.map((i) => ({
            x: i.date,
            y: i[isPartner ? DASHBOARD_APPROVED_PAYOUTS : DASHBOARD_APPROVED_REVENUE]
          }))
        },
        {
          type: 'area',
          name: `Est ${isPartner ? 'earnings' : 'revenue'}`,
          data: items.map((i) => ({
            x: i.date,
            y: i[isPartner ? DASHBOARD_EST_PAYOUT : DASHBOARD_EST_REVENUE]
          }))
        }
      ];

    case DASHBOARD_MAIN_CHART_REQUESTS_VS_CLICKS:
      return [
        {
          type: 'line',
          name: 'Requests count',
          data: items.map((i) => ({
            x: i.date,
            y: i[DASHBOARD_REQUESTS_COUNT] || i[DASHBOARD_INT_REQUESTS_COUNT]
          }))
        },
        {
          type: 'area',
          name: 'Clicks count',
          data: items.map((i) => ({
            x: i.date,
            y: i[DASHBOARD_CLICKS_COUNT]
          }))
        }
      ];

    case DASHBOARD_MAIN_CHART_IMPRESSIONS_VS_CLICKS:
      return [
        {
          type: 'line',
          name: 'Imps count',
          data: items.map((i) => ({
            x: i.date,
            y: i[DASHBOARD_IMPS_COUNT]
          }))
        },
        {
          type: 'area',
          name: 'Clicks count',
          data: items.map((i) => ({
            x: i.date,
            y: i[DASHBOARD_CLICKS_COUNT]
          }))
        }
      ];
  }
};

export const breakdownLabels = {
  [DASHBOARD_BREAKDOWN_HOUR]: 'Hourly',
  [DASHBOARD_BREAKDOWN_DAY]: 'Daily',
  [DASHBOARD_BREAKDOWN_WEEK]: 'Weekly',
  [DASHBOARD_BREAKDOWN_MONTH]: 'Monthly'
};

export const buildBreakdown = (interval, dateRange) => {
  switch (interval) {
    case DASHBOARD_INTERVAL_TODAY:
    case DASHBOARD_INTERVAL_YESTERDAY:
    case DASHBOARD_INTERVAL_LAST_48_HOURS:
      return {
        breakdownList: [DASHBOARD_BREAKDOWN_HOUR, DASHBOARD_BREAKDOWN_DAY],
        breakdown: DASHBOARD_BREAKDOWN_HOUR
      };

    case DASHBOARD_INTERVAL_LAST_7_DAYS:
      return {
        breakdownList: [DASHBOARD_BREAKDOWN_DAY, DASHBOARD_BREAKDOWN_WEEK],
        breakdown: DASHBOARD_BREAKDOWN_DAY
      };

    case DASHBOARD_INTERVAL_LAST_MONTH:
      return {
        breakdownList: [DASHBOARD_BREAKDOWN_WEEK, DASHBOARD_BREAKDOWN_MONTH],
        breakdown: DASHBOARD_BREAKDOWN_WEEK
      };

    case DASHBOARD_INTERVAL_THIS_MONTH:
      return {
        breakdownList: [DASHBOARD_BREAKDOWN_DAY, DASHBOARD_BREAKDOWN_WEEK, DASHBOARD_BREAKDOWN_MONTH],
        breakdown: DASHBOARD_BREAKDOWN_DAY
      };

    case DASHBOARD_INTERVAL_CUSTOM: {
      const { from, to } = dateRange;
      const diff = moment(to).diff(moment(from), 'days', true);

      let breakdown;
      let breakdownList;

      if (diff <= 2) {
        breakdownList = [DASHBOARD_BREAKDOWN_HOUR];
        breakdown = DASHBOARD_BREAKDOWN_HOUR;
      } else if (diff <= 30) {
        breakdownList = [DASHBOARD_BREAKDOWN_HOUR, DASHBOARD_BREAKDOWN_DAY];
        breakdown = DASHBOARD_BREAKDOWN_DAY;
      } else {
        breakdownList = [DASHBOARD_BREAKDOWN_HOUR, DASHBOARD_BREAKDOWN_DAY, DASHBOARD_BREAKDOWN_WEEK, DASHBOARD_BREAKDOWN_MONTH];
        breakdown = DASHBOARD_BREAKDOWN_MONTH;
      }

      return {
        breakdownList,
        breakdown
      };
    }
  }
};

export const metricChartOptions = {
  dataLabels: {
    enabled: false
  },
  // TODO For Bar Chart
  // plotOptions: {
  //   bar: {
  //     horizontal: false,
  //     columnWidth: '55%',
  //     endingShape: 'rounded'
  //   }
  // },
  stroke: {
    curve: 'smooth'
    // TODO For Bar Chart
    // show: true,
    // width: 2,
    // colors: ['transparent']
  },
  chart: {
    dropShadow: {
      enabled: true,
      enabledOnSeries: undefined,
      top: 13,
      left: 0,
      blur: 8,
      color: ['#fc7b99', '#e2759c', '#ce6f9a', '#a9659e', '#7a549a', '#534fa0'],
      opacity: 0.3
    }
  },
  fill: {
    // TODO For Bar Chart
    opacity: 0.5,
    type: 'gradient',
    gradient: {
      shadeIntensity: 0.5,
      gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
      inverseColors: false,
      opacityFrom: 0.35,
      opacityTo: 1,
      stops: [0, 50, 100],
      colorStops: [
        {
          offset: 0,
          color: '#fc7b99',
          opacity: 1
        },
        {
          offset: 20,
          color: '#e2759c',
          opacity: 1
        },
        {
          offset: 40,
          color: '#ce6f9a',
          opacity: 1
        },
        {
          offset: 60,
          color: '#a9659e',
          opacity: 1
        },
        {
          offset: 80,
          color: '#7a549a',
          opacity: 1
        },
        {
          offset: 100,
          color: '#534fa0',
          opacity: 1
        }
      ]
    }
  },
  grid: {
    borderColor: '#ECEEF2',
    strokeDashArray: 3
  },
  markers: {
    colors: ['#ff0000', '#ff0000', '#ff0000']
  },
  xaxis: {
    type: 'datetime',
    labels: {
      style: {
        color: '#6E7691',
        fontSize: '14px',
        cssClass: 'apexcharts-xaxis-label'
      }
    },
    axisBorder: {
      // x border solid
      show: false,
      color: '#ECEEF2',
      height: 1,
      width: '100%',
      offsetX: 0,
      offsetY: 0
    },
    axisTicks: {
      // x border
      show: true,
      borderType: 'dotted',
      color: '#ECEEF2',
      height: 6,
      offsetX: 0,
      offsetY: 0
    }
  },
  yaxis: {
    labels: {
      show: true,
      align: 'center',
      style: {
        color: '#6E7691',
        fontSize: '14px',
        cssClass: 'apexcharts-yaxis-label'
      },
      offsetX: -10,
      offsetY: 0,
      rotate: 0
    }
  },
  tooltip: {
    shared: true,
    intersect: false
  }
};

export const getGraphList = (isToday, isPartner) => {
  if (isPartner) {
    return [
      DASHBOARD_MAIN_CHART_EST_VS_APPROVED_EARNINGS,
      DASHBOARD_MAIN_CHART_REQUESTS_VS_CLICKS,
      DASHBOARD_MAIN_CHART_IMPRESSIONS_VS_CLICKS
    ];
  }

  return [
    isToday ? DASHBOARD_MAIN_CHART_EST_EARNINGS : DASHBOARD_MAIN_CHART_EARNINGS,
    DASHBOARD_MAIN_CHART_EST_VS_APPROVED_EARNINGS,
    DASHBOARD_MAIN_CHART_REQUESTS_VS_CLICKS,
    DASHBOARD_MAIN_CHART_IMPRESSIONS_VS_CLICKS
  ];
};

export const getFormatter = (selectedChart) => {
  switch (selectedChart) {
    case DASHBOARD_MAIN_CHART_EARNINGS:
    case DASHBOARD_MAIN_CHART_EST_EARNINGS:
    case DASHBOARD_MAIN_CHART_EST_VS_APPROVED_EARNINGS:
      return formatCurrency;

    default:
      return formatNumbers;
  }
};
