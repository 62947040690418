import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { TITLE_FIELD } from '../wizards-manager/constants';

import { useIsMountedRef } from '../wizards-manager/custom-hooks/useIsMountedRef';
import Popover from '../wizards-manager/wizards/shared/Popover';

import '../wizards-manager/wizard-base/WizardSharedStyles.scss';

const RulesTitle = ({
  propRef,
  error,
  id
}) => {
  const isMountedRef = useIsMountedRef();

  const [focusedTitle, setFocusedTitle] = useState(false);

  const handleOnBlur = () => {
    if (isMountedRef.current) {
      setFocusedTitle(false);
    }
  };

  const handleOnFocus = () => {
    if (isMountedRef.current) {
      setFocusedTitle(true);
    }
  };

  return (
    <div className="wizard-input-container">
      <Popover
        title={'Title is required'}
        open={error && focusedTitle}
      >
        <input
          type="type"
          name={TITLE_FIELD}
          ref={propRef}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          placeholder='New Rule'
          className={`form-title-input title-forms the-field ${error ? 'error' : ''}`}
          autoFocus={!id}
        />
      </Popover>
      {error && !focusedTitle && <FontAwesomeIcon className="field-error-icon" icon={faExclamationCircle}/>}
    </div>
  );
};

RulesTitle.propTypes = {
  propRef: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired
};

export default RulesTitle;
