import axios from 'axios';

import { AXIOS_CONFIG } from '../constant';

export const fetchAllEndpointDemands = () => axios.get('/api/endpoint-demands');

export const fetchEndpointDemandById = (id) => axios.get(`/api/endpoint-demands/${id}`);

export const fetchEndpointDemandByEndpointId = (id) => axios.get(`/api/endpoint-demands/epid/${id}`);

export const fetchEndpointDemandByDemandId = (id) => axios.get(`/api/endpoint-demands/dfid/${id}`);

export const saveEndpointDemand = (data) => axios.post('/api/endpoint-demands/', data, AXIOS_CONFIG);

export const modifyEndpointDemand = (id, data) => axios.put(`/api/endpoint-demands/${id}`, data, AXIOS_CONFIG);

export const removeEndpointDemand = (id) => axios.delete(`/api/endpoint-demands/${id}`, AXIOS_CONFIG);

export const getEndpointDemandOptions = (eId, dId) => axios.get(`/api/endpoint-demands/check/${eId}/${dId}`, AXIOS_CONFIG);
