import axios from 'axios';

import { AXIOS_CONFIG } from '../constant';

export const fetchEndpointById = (id) => axios.get(`/api/endpoint/${id}`);

export const saveEndpoint = (data) => axios.post('/api/endpoint/', data, AXIOS_CONFIG);

export const removeEndpoint = (id) => axios.delete(`/api/endpoint/${id}`);

export const modifyEndpoint = (id, data) => axios.put(`/api/endpoint/${id}`, data, AXIOS_CONFIG);

export const searchEndpoints = (data) => axios.post('/api/endpoint/search', data, AXIOS_CONFIG);
