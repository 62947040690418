import moment from 'moment';
import { toast } from 'react-toastify';

import { getAllUsers } from '../../../actions/settings';
import {
  DASHBOARD_INTERVAL_CUSTOM,
  DASHBOARD_INTERVAL_LAST_48_HOURS,
  DASHBOARD_INTERVAL_LAST_7_DAYS,
  DASHBOARD_INTERVAL_LAST_MONTH,
  DASHBOARD_INTERVAL_THIS_MONTH,
  DASHBOARD_INTERVAL_TODAY,
  DASHBOARD_INTERVAL_YESTERDAY,
  SETTINGS_INTERVAL_INTERVAL
} from '../../../actions/types';

import { searchDemands } from '../../../api/demand';
import { fetchAllEndpointDemands } from '../../../api/endpointDemand';
import { searchPartners } from '../../../api/partners';

import { allObject } from '../../../reducers/dashboard';

export const anyUserObject = {
  label: 'Any user',
  value: '*'
};

export const anyChangeObject = {
  label: 'Any change',
  value: '*'
};

export const timelineTypeOptions = [
  anyChangeObject,
  {
    label: 'Endpoint',
    value: 'endpoint'
  },
  {
    label: 'Demand',
    value: 'demand'
  },
  {
    label: 'Partner',
    value: 'partner'
  },
  {
    label: 'Endpoint_Demand',
    value: 'endpoint_demand'
  }
];

export const loadAllUserOptions = async () => {
  const res = await getAllUsers();

  const options = [anyUserObject, ...res];

  return {
    options,
    hasMore: false,
    additional: {
      page: 1
    }
  };
};

export const loadPartners = async (search, loadedOptions, { page }) => {
  const data = {
    type: ['isBuy', 'isSell', 'both'],
    filter: { page, limit: 10, search }
  };

  const response = await searchPartners(data);

  const options = [allObject, ...response.data.results.map(({ label, value }) => ({
    label: `${label} (${value})`,
    value
  }))];

  return {
    options,
    hasMore: response.data.has_more,
    additional: {
      page: page + 1
    }
  };
};

export const loadAllDemandOptions = async (search, loadedOptions, { page }) => {
  const data = {
    filter: { page, limit: 10, search }
  };

  const response = await searchDemands(data);

  const options = [...response.data.results.map(({ label, value }) => ({
    label: `${label} (${value})`,
    value
  }))];

  return {
    options,
    hasMore: response.data.has_more,
    additional: {
      page: page + 1
    }
  };
};

export const loadEndpointDemands = async () => {
  try {
    const res = await fetchAllEndpointDemands();

    const options = res.data.data.data
      .map((ed) => ({
        label: ed._id,
        value: ed._id
      }));

    return {
      options,
      hasMore: false,
      additional: {
        page: 1
      }
    };
  } catch (e) {
    toast.error('Fetching endpoint-demands errors', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    return {
      options: [],
      hasMore: false,
      additional: {
        page: 1
      }
    };
  }
};

const dateFormat = 'YYYY-MM-DD';

const buildIntervalQuery = (interval, customRange) => {
  switch (interval) {
    case DASHBOARD_INTERVAL_TODAY:
      return `createdAt[gte]=${moment().format(dateFormat)}`;

    case DASHBOARD_INTERVAL_YESTERDAY:
      return `from=${moment().add(-1, 'days').format(dateFormat)}&to=${moment().format(dateFormat)}`;

    case DASHBOARD_INTERVAL_LAST_48_HOURS:
      return `createdAt[gte]=${moment().add(-2, 'days').format(dateFormat)}`;

    case DASHBOARD_INTERVAL_LAST_7_DAYS:
      return `createdAt[gte]=${moment().add(-7, 'days').format(dateFormat)}`;

    case DASHBOARD_INTERVAL_THIS_MONTH:
      return `createdAt[gte]=${moment().startOf('month').format(dateFormat)}`;

    case DASHBOARD_INTERVAL_LAST_MONTH:
      return `createdAt[gte]=${moment().add(-1, 'months').format(dateFormat)}`;

    case DASHBOARD_INTERVAL_CUSTOM:
      return `from=${moment(customRange.from).format(dateFormat)}&to=${moment(customRange.to).format(dateFormat)}`;
  }
};

export const buildTimelineLogQuery = (selectedUser, selectedType, selectedAsset, interval, customRange) => {
  let result = '';

  if (selectedUser !== '*' || selectedType !== '*' || selectedAsset !== '*' || interval !== SETTINGS_INTERVAL_INTERVAL) {
    result = '?';
  }

  if (selectedUser !== '*') {
    result += `user=${selectedUser}`;
  }

  if (selectedType !== '*') {
    if (selectedUser !== '*') {
      result += '&';
    }

    result += `kindType=${selectedType}`;
  }

  if (selectedAsset !== '*') {
    if (selectedUser !== '*' || selectedType !== '*') {
      result += '&';
    }

    result += `kind=${selectedAsset}`;
  }

  if (interval !== SETTINGS_INTERVAL_INTERVAL) {
    if (selectedUser !== '*' || selectedType !== '*' || selectedAsset !== '*') {
      result += '&';
    }

    result += buildIntervalQuery(interval, customRange);
  }

  return result;
};
