import React from 'react';

import DonutChart from './DonutChart';
import MetricChart from './MetricChart';

const BlockChart = () => {
  return (
    <div className='block-chart'>
      <div className='row justify-content-md-center'>
        <div className='col-md-7 col-lg-8 block-item'>
          <MetricChart/>
        </div>
        <div className='col-md-5 col-lg-4 block-item'>
          <DonutChart/>
        </div>
      </div>
    </div>
  );
};

export default BlockChart;
