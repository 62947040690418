import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { connectDemandEndpointsAction, updateDemandEndpointsFeeds } from '../../../../../actions/demand';
import {
  fetchEndpointsAction
} from '../../../../../actions/endpoint';

import { DEMAND_WIZARD } from '../../../constants';

import { filterEndpointDemandsOptions } from '../../../helpers/wizard-helpers';

import WizardSelect from '../../shared/WizardSelect';

import '../../../../tables-manager/table-base/TableColumnsActions.scss';
import './DemandEndpointFeeds.scss';

const DemandEndpointFeeds = ({ isSubmitted, onSubmit }) => {
  const dispatch = useDispatch();

  const {
    endpoints,
    demandEndpointsState,
    demandID,
    duplicatedID
  } = useSelector(
    state => ({
      endpoints: state[DEMAND_WIZARD].endpoints,
      demandEndpointsState: state[DEMAND_WIZARD].demand_endpoints,
      demandID: state[DEMAND_WIZARD].formData._id || null,
      duplicatedID: state[DEMAND_WIZARD].duplicated_id
    })
  );

  const {
    control,
    errors
  } = useForm({
    mode: 'onBlur'
  });

  useEffect(() => {
    (async () => {
      if (duplicatedID) {
        await connectDemandEndpointsAction(duplicatedID, true)(dispatch);
      } else {
        if (demandID) {
          await connectDemandEndpointsAction(demandID, false)(dispatch);
        } else {
          await fetchEndpointsAction('all', true)(dispatch);
        }
      }
    })();
  }, [demandID]);

  const [demandEndpoints, setDemandEndpoints] = useState([]);
  const [dfMap, setDfMap] = useState({});
  const [serverOperations, setServerOperations] = useState({
    create: [],
    update: [],
    remove: []
  });

  useEffect(() => {
    const map = {};

    demandEndpointsState.forEach(df => {
      map[df.endpoint._id] = df._id;
    });

    setDfMap(map);

    setDemandEndpoints(demandEndpointsState.map(d => ({
      label: d.endpoint.title,
      value: d.endpoint._id,
      active: d.active
    })));

    if (duplicatedID) {
      setServerOperations({
        create: demandEndpointsState.map(ed => ({
          endpoint: ed.endpoint._id,
          demand: ed.demand._id,
          active: ed.active
        })),
        update: [],
        remove: []
      });
    } else {
      setServerOperations({
        create: [],
        update: demandEndpointsState.map(ed => ({
          endpoint: ed.endpoint._id,
          demand: ed.demand._id,
          active: ed.active
        })),
        remove: []
      });
    }
  }, [JSON.stringify(demandEndpointsState)]);

  const [endpointOptions, setEndpointOptions] = useState([]);

  useEffect(() => {
    setEndpointOptions(filterEndpointDemandsOptions(endpoints, demandEndpoints));
  }, [JSON.stringify(endpoints), JSON.stringify(demandEndpoints)]);

  useEffect(() => {
    if (isSubmitted) {
      updateDemandEndpointsFeeds({
        endpoint_feeds: serverOperations,
        df_map: dfMap
      })(dispatch);

      onSubmit(true);
    }
  }, [isSubmitted]);

  const [selectedEndpoints, setSelectedEndpoints] = useState(demandEndpoints);

  useEffect(() => {
    setSelectedEndpoints(demandEndpoints);
  }, [JSON.stringify(demandEndpoints)]);

  const handleAddEndpoint = (id) => {
    const [endpoint] = endpointOptions.filter(e => e.value === id);
    setSelectedEndpoints(state => [...state, { ...endpoint, active: false }]);

    setEndpointOptions(state => state.filter(e => e.value !== id));

    setServerOperations(state => ({
      ...state,
      create: dfMap[endpoint.value]
        ? state.create
        : [...state.create,
          {
            endpoint: endpoint.value,
            demand: demandID,
            active: false
          }],
      update: dfMap[endpoint.value]
        ? [...state.update,
          {
            endpoint: endpoint.value,
            demand: demandID,
            active: false
          }]
        : state.update.filter(df => df.endpoint !== endpoint.value),
      remove: state.remove.filter(df => df.endpoint !== endpoint.value)
    }));
  };

  const handleRemoveEndpoint = (id) => {
    const [endpoint] = selectedEndpoints.filter(e => e.value === id);
    setEndpointOptions(state => [...state, endpoint]);

    setSelectedEndpoints(state => state.filter(e => e.value !== id));

    setServerOperations(state => ({
      create: state.create.filter(df => df.endpoint !== endpoint.value),
      update: state.update.filter(df => df.endpoint !== endpoint.value),
      remove: dfMap[endpoint.value]
        ? [...state.remove,
          {
            endpoint: endpoint.value,
            demand: demandID,
            active: endpoint.active
          }]
        : state.remove
    }));
  };

  const handleChangeActive = (id, active) => {
    const updateSelectedEndpointStatus = (endpoint) => {
      if (endpoint.value === id) {
        return {
          ...endpoint,
          active
        };
      }

      return endpoint;
    };

    setSelectedEndpoints(state => state.map(updateSelectedEndpointStatus));

    const updateServerOperations = (df) => {
      if (df.endpoint === id) {
        return {
          ...df,
          active
        };
      }

      return df;
    };

    setServerOperations(state => ({
      create: state.create.map(updateServerOperations),
      update: state.update.map(updateServerOperations),
      remove: state.remove
    }));
  };

  return (
    <form className="demand-endpoints step-content">
      <div className="group">
        <h4>Endpoint Feeds</h4>

        <div className="form-group">
          <div className="row-list">
            <div className="row-item">
              <div className="row">
                <div className="col-sm-12">
                  <WizardSelect
                    name='selected_endpoints'
                    control={control}
                    error={!!errors.selected_endpoints}
                    errorMessage={errors.selected_endpoints ? errors.selected_endpoints.message : ''}
                    isMulti={true}
                    placeholder='Select endpoints to connect'
                    options={endpointOptions}
                    defaultValue={[]}
                    onChange={([selectedValues]) => {
                      if (selectedValues) {
                        const [endpoint] = selectedValues;
                        handleAddEndpoint(endpoint.value);
                        return null;
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row-item">
              <div className="row">
                <div className="col-sm-12">
                  {selectedEndpoints.map((endpoint) => {
                    return (
                      <div className="demand-endpoint" key={endpoint.value}>
                        <h4 className="endpoint-title">{endpoint.label}</h4>
                        <div className="form-group">
                          <div className="switch-list">
                            <label>
                              <input
                                type="checkbox"
                                onChange={e => handleChangeActive(endpoint.value, e.target.checked)}
                                defaultChecked={endpoint.active || false}
                              />
                            </label>
                          </div>
                        </div>
                        <span
                          className="action-icon endpoint-remove"
                          onClick={() => handleRemoveEndpoint(endpoint.value)}>
                          <i className="far fa-trash-alt"/>
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

DemandEndpointFeeds.propTypes = {
  isSubmitted: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default DemandEndpointFeeds;
