import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { showDrawerForm } from '../../actions/drawerForms';
import {
  DRAWER_FORM_CREATE_DEMAND,
  DRAWER_FORM_CREATE_ENDPOINT,
  DRAWER_FORM_CREATE_PARTNER,
  DRAWER_FORM_CREATE_RULE
} from '../../actions/types';

import logo from '../../assets/images/common/logo.svg';
import arrow from '../../assets/images/icons/ico_arrow_down.svg';

import { useAuth } from '../../context/auth/AuthState';

import { useNotifications } from '../../context/notifications/NotificationsState';
import Notification from '../notifications/Notification';
import HandleSidebar from './HandleSidebar';

import './Header.scss';

const Header = ({ showDrawerForm }) => {
  const { openToggle, onShowNavSide, wrapSidebar } = HandleSidebar();
  const { isPartner, logout, user } = useAuth();

  const [openNotifications, setOpenNotifications] = useState(false);

  const { notifications } = useNotifications();
  const unread = notifications.filter(n => !n.read);

  return (
    <header id="Header" className="d-flex">
      <div className="header-left mt-1 p-2">
        <p
          className={openToggle ? 'nav-toggle open' : 'nav-toggle'}
          onClick={onShowNavSide}
          ref={wrapSidebar}
        >
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
        </p>
        <p id="Logo">
          <a href="/dashboard">
            <img src={logo} alt=""/>
          </a>
        </p>
      </div>

      <div className="header-block p-2 mt-1 ml-auto">
        {!isPartner ? (
          <div className="add-new">
            <div className="dropdown dropdown-arrow-top pos-right">
              <button
                className="btn btn-pink dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  src={require('../../assets/images/icons/ico_plus_white.svg')}
                  alt=""
                />
                <span>New</span>
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li>
                  <Link
                    className="dropdown-item"
                    to="#!"
                    onClick={() => showDrawerForm(DRAWER_FORM_CREATE_PARTNER)}
                  >
                    <img
                      src={require('../../assets/images/icons/ico_partner.svg')}
                      alt=""
                    />
                    <img
                      className="img-active"
                      src={require('../../assets/images/icons/ico_partner_o.svg')}
                      alt=""
                    />
                    <span>Partner</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    onClick={() => showDrawerForm(DRAWER_FORM_CREATE_ENDPOINT)}
                    to="#!"
                  >
                    <img
                      src={require('../../assets/images/icons/ico_endpoint.svg')}
                      alt=""
                    />
                    <img
                      className="img-active"
                      src={require('../../assets/images/icons/ico_endpoint_o.svg')}
                      alt=""
                    />
                    <span>Endpoint</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    onClick={() => showDrawerForm(DRAWER_FORM_CREATE_DEMAND)}
                    to="#!"
                  >
                    <img
                      src={require('../../assets/images/icons/ico_deman.svg')}
                      alt=""
                    />
                    <img
                      className="img-active"
                      src={require('../../assets/images/icons/ico_deman_o.svg')}
                      alt=""
                    />
                    <span>Demand</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    onClick={() => showDrawerForm(DRAWER_FORM_CREATE_RULE)}
                    to="#!"
                  >
                    <img
                      src={require('../../assets/images/icons/ico_rule.svg')}
                      alt=""
                    />
                    <img
                      className="img-active"
                      src={require('../../assets/images/icons/ico_rule_o.svg')}
                      alt=""
                    />
                    <span>Optimization Rule</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>) : null}
        <div
          className="user-notifications"
        >
          <img
            className="notification-bell"
            src={require('../../assets/images/icons/notification_bell.svg')}
            alt=""
            onClick={() => setOpenNotifications(state => !state)}
          />

          <div className="notifications-count">{unread.length}</div>

          {openNotifications && (
            <div className="notifications-container">
              <Notification closeModal={() => setOpenNotifications(false)}/>
            </div>
          )}
        </div>
        <div className="block-user">
          <div className="dropdown dropdown-arrow-top pos-right">
            <button
              className="btn btn-user dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span>{user ? user.name : 'guest'}</span>
              <img className="arrow" src={arrow} alt="arrow"/>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {/*
              <li>
                <Link className="dropdown-item" to="/account">
                  Account
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/integrations">
                  Integrations
                </Link>
              </li>
            */}
              <li>
                <Link className="dropdown-item" to="" onClick={logout}>
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  showDrawerForm: PropTypes.func.isRequired
};

export default connect(null, { showDrawerForm })(Header);
