import React from 'react';
import { useDispatch } from 'react-redux';

import { getInitialFilterState } from '../../../actions/dashboard';
import { showForm } from '../../../actions/drawerForms';
import { DRAWER_FORM_FILTERS } from '../../../actions/types';

import { useAuth } from '../../../context/auth/AuthState';

import DashboardDatePickFilterForm from '../../wizards-manager/wizards/dashboard/DashboardDatePickFilterForm';

import './TopFilters.scss';

const TopFilters = () => {
  const dispatch = useDispatch();

  const { isPartner } = useAuth();

  const clickFilterOption = () => showForm(DRAWER_FORM_FILTERS)(dispatch);

  return (
    <div className='top-filters top-filter'>
      <div className='top-btn'>
        <ul>
          <li className='dropdown dropdown-arrow-top pos-right'>
            <DashboardDatePickFilterForm/>
          </li>
          {!isPartner ? (
            <>
              <li className='dropdown dropdown-arrow-top pos-right'>
                <button
                  className='btn btn-gray'
                  type='button'
                  id='dropdownMenuDashboardFilterOption'
                  onClick={clickFilterOption}
                >
                  <img src={require('../../../assets/images/icons/ico_filter.svg')} alt=''/>
                  <img className='img-active' src={require('../../../assets/images/icons/ico_filter_o.svg')} alt=''/>
                  <span>Filter</span>
                </button>
              </li>
              <li>
                <button
                  className='btn btn-pink'
                  type='button'
                  onClick={() => getInitialFilterState()(dispatch)}
                >
                  Reset Filter
                </button>
              </li>
            </>) : null}
        </ul>
      </div>
    </div>
  );
};

export default TopFilters;
