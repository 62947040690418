import axios from 'axios';

import { AXIOS_CONFIG } from '../constant';

export const fetchDemandById = (id) => axios.get(`/api/demand/${id}`);

export const saveDemand = (data) => axios.post('/api/demand', data, AXIOS_CONFIG);

export const modifyDemand = (id, data) =>
  axios.put(`/api/demand/${id}`, data, AXIOS_CONFIG);

export const removeDemand = (id) => axios.delete(`/api/demand/${id}`);

export const searchDemands = (data) => axios.post('/api/demand/search', data, AXIOS_CONFIG);
