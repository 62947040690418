import { toast } from 'react-toastify';

import {
  fetchPartnerById,
  modifyPartner,
  removePartner,
  savePartner
} from '../api/partners';

import {
  transformPartnersToTableData,
  transformPartnerToFormData
} from '../components/tables-manager/helpers/transformers';

import {
  PARTNER_CONFIRM_PASSWORD_FIELD,
  PARTNER_NAME_FIELD,
  PARTNER_PASSWORD_FIELD,
  PARTNER_USERNAME_FIELD,
  UPDATED_AT_FIELD
} from '../components/wizards-manager/constants';

import { transformPartnerData } from '../components/wizards-manager/helpers/transformers';
import { transformMultiSelect } from '../components/wizards-manager/helpers/wizard-helpers';

import store from '../store';

import {
  UPDATE_PARTNER_STATE,
  PARTNER_CREATE_PARTNER,
  FETCH_PARTNERS,
  START_FETCHING_PARTNERS,
  DELETE_PARTNER,
  DUPLICATE_PARTNER,
  EDIT_PARTNER,
  DRAWER_FORM_OPEN,
  DRAWER_FORM_CREATE_PARTNER,
  PARTNER_UPDATE_PARTNER,
  GET_INITIAL_PARTNER_FORM_STATE,
  PARTNER_UPDATE_TAGS
} from './types';

const dispatch = store.dispatch;

export const editPartnerAction = (data, staticList) => (dispatch) => {
  const payload = transformPartnerToFormData(data, staticList);

  payload[PARTNER_PASSWORD_FIELD] = '';
  payload[PARTNER_CONFIRM_PASSWORD_FIELD] = '';

  dispatch({ type: EDIT_PARTNER, payload });
  dispatch({ type: DRAWER_FORM_OPEN, payload: DRAWER_FORM_CREATE_PARTNER });
};

export const duplicatePartnerAction = (data, staticList) => (dispatch) => {
  const payload = transformPartnerToFormData(data, staticList);

  delete payload._id;

  payload[PARTNER_NAME_FIELD] = `${payload[PARTNER_NAME_FIELD]} (copy 1)`;
  payload[PARTNER_USERNAME_FIELD] = '';
  payload[PARTNER_PASSWORD_FIELD] = '';
  payload[PARTNER_CONFIRM_PASSWORD_FIELD] = '';

  dispatch({ type: DUPLICATE_PARTNER, payload });
  dispatch({ type: DRAWER_FORM_OPEN, payload: DRAWER_FORM_CREATE_PARTNER });
};

export const createPartnerAction = () => async (dispatch) => {
  try {
    const partner = store.getState().partner.formData;
    const data = transformPartnerData(partner);

    // Remove unnecessary fields
    delete data[PARTNER_CONFIRM_PASSWORD_FIELD];

    const res = await savePartner(data);

    toast.info('Partner Created Successfully', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    // Reset field
    res.data[PARTNER_PASSWORD_FIELD] = '';

    const transformedPartner = transformPartnersToTableData([res.data]);
    const payload = transformedPartner[0];
    // We clear the state here
    dispatch({
      type: PARTNER_CREATE_PARTNER,
      payload
    });

    return false;
  } catch (err) {
    toast.error('Create Partner Error', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    return err;
  }
};

export const updatePartnerAction = () => async (dispatch) => {
  try {
    const partner = store.getState().partner.formData;
    const id = partner._id;
    const data = transformPartnerData(partner);

    // Remove unnecessary fields
    delete data._id;
    delete data[PARTNER_CONFIRM_PASSWORD_FIELD];
    delete data[UPDATED_AT_FIELD];

    if (!data[PARTNER_PASSWORD_FIELD]) {
      delete data[PARTNER_PASSWORD_FIELD];
    }

    const res = await modifyPartner(id, data);

    const transformedRes = transformPartnersToTableData([res.data]);
    const payload = transformedRes[0];

    dispatch({
      type: PARTNER_UPDATE_PARTNER,
      payload
    });

    toast.info('Partner Updated Successfully', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    return false;
  } catch (err) {
    toast.error('Update Partner Error', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    return err;
  }
};

export const deletePartnerAction = (id) => async (dispatch) => {
  try {
    await removePartner(id);

    dispatch({
      type: DELETE_PARTNER,
      payload: id
    });

    toast.info('Partner Deleted Successfully', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  } catch (err) {
    toast.error('Delete Partner Failed', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};

export const fetchPartnersAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_FETCHING_PARTNERS });

    const res = await fetchPartnerById(id);

    const payload = transformPartnersToTableData(res.data);

    dispatch({
      type: FETCH_PARTNERS,
      payload
    });
  } catch (err) {
    toast.error('Fetching Partners Error', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};

export const editPartnerFromNotificationsAction = async (id) => {
  try {
    const staticList = store.getState().staticList;

    const res = await fetchPartnerById(id);
    const resTable = transformPartnersToTableData([res.data]);
    const payload = transformPartnerToFormData(resTable[0], staticList);

    payload[PARTNER_PASSWORD_FIELD] = '';
    payload[PARTNER_CONFIRM_PASSWORD_FIELD] = '';

    dispatch({ type: EDIT_PARTNER, payload });
    dispatch({ type: DRAWER_FORM_OPEN, payload: DRAWER_FORM_CREATE_PARTNER });
  } catch (err) {
    toast.error('Open edit partner error', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};

export const getInitialPartnersFormDataAction = () => (dispatch) => {
  dispatch({ type: GET_INITIAL_PARTNER_FORM_STATE });
};

export const updatePartnerStateAction = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_PARTNER_STATE,
    payload
  });
};

export const updatePartnerTagsAction = async (id, data) => {
  try {
    const tags = transformMultiSelect(data);

    await modifyPartner(id, { tags });

    const payload = {
      _id: id,
      tags: data
    };

    dispatch({ type: PARTNER_UPDATE_TAGS, payload });
  } catch (err) {
    toast.error('Partner tags update fail', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};
