import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import TablePanelSpinner from '../../shared/TablePanelSpinner';
import TablesManager from '../../TablesManager';

const DashboardTable = ({
  columns,
  hiddenColumns
}) => {
  const {
    items,
    loading
  } = useSelector(state => ({
    items: state.dashboard.table,
    loading: state.dashboard.tableLoading
  }));

  const searchFields = columns ? columns[0] ? [columns[0].accessor] : [] : [];

  return (
    <>
      {loading ? <TablePanelSpinner/>
        : <TablesManager
          searchFields={searchFields}
          fields={columns}
          tableName=''
          items={items}
          hiddenColumns={hiddenColumns}
          withoutSearchHeaders={true}
        />
      }
    </>
  );
};

DashboardTable.propTypes = {
  columns: PropTypes.array.isRequired,
  hiddenColumns: PropTypes.array.isRequired
};

export default DashboardTable;
