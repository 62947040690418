export const COUNTRIES = [
  {
    value: 'all',
    label: 'All countries'
  },
  {
    value: 'usa',
    label: 'USA'
  },
  {
    value: 'vn',
    label: 'Vietnam'
  },
  {
    value: 'fr',
    label: 'France'
  },
  {
    value: 'uk',
    label: 'UK'
  },
  {
    value: 'sgp',
    label: 'Singapore'
  }
];

export const setCountriesValue = selectedCountries => {
  const filterAll = selectedCountries.filter(item => item.value === '*');
  return filterAll.length > 0 ? filterAll : selectedCountries;
};

export const OS_LIST = [
  {
    value: '*',
    label: 'All'
  },
  {
    value: 'ios',
    label: 'iOS'
  },
  {
    value: 'android',
    label: 'Android'
  },
  {
    value: 'window',
    label: 'Window'
  }
];

export const getOSOptions = (osValues, osList) => {
  if (Array.isArray(osValues)) {
    const filteredOptions = [];

    osValues.forEach(item => {
      const filteredOne = osList.filter(itemOne => itemOne.value === item);

      if (filteredOne.length > 0) {
        filteredOptions.push(filteredOne[0]);
      }
    });

    return filteredOptions;
  }

  const filteredOne = osList.filter(item => item.value === osValues);

  if (filteredOne.length > 0) {
    return filteredOne[0];
  }

  return {};
};

export const devices = [
  {
    value: '*',
    label: 'All Mobile'
  },
  {
    value: 'samsung',
    label: 'Samsung'
  },
  {
    value: 'iphone',
    label: 'iPhone'
  }
];

export const getDeviceOption = value => {
  const filteredDevice = devices.filter(item => item.value === value);

  if (filteredDevice.length > 0) {
    return filteredDevice[0];
  }

  return {};
};

export const browsers = [
  {
    value: 'all',
    label: 'All browser'
  },
  {
    value: 'firefox',
    label: 'Firefox'
  },
  {
    value: 'chrome',
    label: 'Chrome'
  },
  {
    value: 'safari',
    label: 'Safari'
  }
];

export const getBrowserOptions = (brwoserValues, browserOptions) => {
  if (Array.isArray(brwoserValues)) {
    const filteredOptions = [];

    brwoserValues.forEach(item => {
      const filteredOne = browserOptions.filter(itemOne => itemOne.value === item);

      if (filteredOne.length > 0) {
        filteredOptions.push(filteredOne[0]);
      }
    });

    return filteredOptions;
  } else {
    const filteredOne = browserOptions.filter(item => item.value === brwoserValues);

    if (filteredOne.length > 0) {
      return filteredOne[0];
    }

    return {};
  }
};

// Better if we keep this list in the database
export const REDIRECT_DOMAINS = [
  {
    value: '1',
    label: 'REDIRECT_DOMAIN1'
  },
  {
    value: '2',
    label: 'REDIRECT_DOMAIN2'
  },
  {
    value: '3',
    label: 'REDIRECT_DOMAIN3'
  },
  {
    value: '4',
    label: 'REDIRECT_DOMAIN4'
  }
];

export const versions = [
  {
    value: '11',
    label: '11'
  },
  {
    value: '12',
    label: '12'
  },
  {
    value: '13',
    label: '13'
  }
];

export const getVersionOptions = versionArray => {
  const filteredOptions = [];

  versionArray.forEach(item => {
    const filteredOne = versions.filter(itemOne => itemOne.value === item);

    if (filteredOne.length > 0) {
      filteredOptions.push(filteredOne[0]);
    }
  });

  return filteredOptions;
};

export const PARTNER_BUSINESS_TYPES = [
  {
    label: 'Individual',
    value: 'individual'
  },
  {
    label: 'Company',
    value: 'company'
  }
];

export const PARTNER_SOURCE_TYPE = [
  {
    label: 'Traffic Source',
    value: 'isBuy'
  },
  {
    label: 'Demand Source',
    value: 'isSell'
  },
  {
    label: 'Traffic/Demand Source',
    value: 'both'
  }
];

export const AXIOS_CONFIG = {
  headers: {
    'Content-Type': 'application/json'
  }
};

export const DASHBOARD_NATIVE = 'native';
export const DASHBOARD_RTB = 'rtb';

export const FILTER_INTERVAL_TODAY = 'Today';
export const FILTER_INTERVAL_YESTERDAY = 'Yesterday';
export const FILTER_INTERVAL_LAST7DAY = 'Last 7 days';
export const FILTER_INTERVAL_THISMONTH = 'This Month';
export const FILTER_INTERVAL_LASTMONTH = 'Last Month';
export const FILTER_INTERVAL_CUSTOM = 'Custom';

export const getTimePickerOptions = interval => {
  const options = [];

  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60 / interval; j++) {
      let strAM = ' AM';
      if (i >= 12) strAM = ' PM';

      if (i === 12) {
        options.push({
          label: i + ':' + ('0' + j * interval).slice(-2) + strAM,
          value: i + ':' + ('0' + j * interval).slice(-2) + strAM
        });
      } else {
        options.push({
          label: (i % 12) + ':' + ('0' + j * interval).slice(-2) + strAM,
          value: (i % 12) + ':' + ('0' + j * interval).slice(-2) + strAM
        });
      }
    }
  }
  return options;
};

export const getSelectedOptions = (value, options) => {
  if (typeof value === 'string') {
    const newOne = options.filter(item => item.value === value);

    if (newOne.length > 0) {
      return newOne[0];
    }

    return {};
  }

  if (typeof value === 'object') {
    const returnFiltered = [];

    value.forEach(item => {
      const filteredOne = options.filter(itemOne => itemOne.value === item);

      if (filteredOne.length > 0) {
        returnFiltered.push(filteredOne[0]);
      }
    });

    return returnFiltered;
  }
};

export const emails = [
  {
    value: 'gmail',
    label: 'Gmail'
  },
  {
    value: 'hotmail',
    label: 'Hot mail'
  },
  {
    value: 'aol',
    label: 'AOL Mail'
  }
];

export const applyDoActions = [
  {
    value: 'email',
    label: 'Send Email'
  },
  {
    value: 'increase_price_pro',
    label: 'Increase Price By %'
  },
  {
    value: 'increate_price_dollar',
    label: 'Increase Price By $'
  }
];

export const FILTERS_KIND_LABEL_LIST = [
  {
    value: 'Requests',
    label: 'Requests'
  },
  {
    value: 'Bids',
    label: 'Bids'
  },
  {
    value: 'Clicks',
    label: 'Clicks'
  },
  {
    value: 'Filters',
    label: 'Filters'
  },
  {
    value: 'Errors',
    label: 'Errors'
  },
  {
    value: 'Est_Cost',
    label: 'Est_Cost'
  },
  {
    value: 'Net_Cost',
    label: 'Net_Cost'
  },
  {
    value: 'Est_Revenue',
    label: 'Est_Revenue'
  },
  {
    value: 'Net_Revenue',
    label: 'Net_Revenue'
  },
  {
    value: 'Profit',
    label: 'Profit'
  },
  {
    value: 'Roi',
    label: 'Roi'
  },
  {
    value: 'Coverage',
    label: 'Coverage'
  },
  {
    value: 'Ctr',
    label: 'Ctr'
  },
  {
    value: 'Avg_bid',
    label: 'Avg_bid'
  },
  {
    value: 'Avg_win_bid',
    label: 'Avg_win_bid'
  }
];

export const caculates = [
  {
    value: '>',
    label: '>'
  },
  {
    value: '<',
    label: '<'
  },
  {
    value: '=',
    label: '='
  }
];

export const endpoints = [
  {
    value: 'endpoints1',
    label: 'Endpoints 1'
  },
  {
    value: 'endpoints2',
    label: 'Endpoints 2'
  },
  {
    value: 'endpoints3',
    label: 'Endpoints 3'
  }
];

export const RULE_CONTEXT_KIND = [
  {
    value: 'endpoint',
    label: 'Endpoint'
  },
  {
    value: 'native',
    label: 'Native'
  },
  {
    value: 'demand',
    label: 'Demand'
  }
];

export const getModelOptionLabel = modelValue => {
  switch (modelValue) {
    case 'endpoint':
      return 'Endpoint';
    case 'native':
      return 'Native';
    case 'demand':
      return 'Demand';
    default:
      return 'Endpoint';
  }
};

export const getModelOptionValue = modelTitle => {
  switch (modelTitle) {
    case 'Endpoint':
      return 'endpoint';
    case 'Native':
      return 'native';
    case 'Demand':
      return 'demand';
    default:
      return 'endpoint';
  }
};

export const METRIC_DIMENSION_OPTIONS = [
  {
    value: 'metric',
    label: 'Metric'
  },
  {
    value: 'dimension',
    label: 'Dimension'
  }
];

export const getMetricOptionLabel = metricValue => {
  switch (metricValue) {
    case 'metric':
      return 'Metric';
    case 'dimension':
      return 'Diemension';
    default:
      return 'Metric';
  }
};

export const getMetricOptionValue = metricLabel => {
  switch (metricLabel) {
    case 'Metric':
      return 'metric';
    case 'Dimension':
      return 'dimension';
    default:
      return 'metric';
  }
};

export const RULE_CONTEXT_VALUES = {
  endpoint: [
    {
      value: 'Endpoint Blocklist Subids',
      label: 'Endpoint Blocklist Subids'
    },
    {
      value: 'Endpoint WhitelistSubids',
      label: 'Endpoint WhitelistSubids'
    },
    {
      value: 'Endpoint Change Bid By %',
      label: 'Endpoint Change Bid By %'
    },
    {
      value: 'Endpoint Change Budget By %',
      label: 'Endpoint Change Budget By %'
    }
  ],
  native: [
    {
      value: 'Native Blocklist Subids',
      label: 'Native Blocklist Subids'
    },
    {
      value: 'Native WhitelistSubids',
      label: 'Native WhitelistSubids'
    },
    {
      value: 'Native Change Bid By %',
      label: 'Native Change Bid By %'
    },
    {
      value: 'Native Change Budget By %',
      label: 'Native Change Budget By %'
    }
  ],
  demand: [
    {
      value: 'Demand Blocklist Subids',
      label: 'Demand Blocklist Subids'
    },
    {
      value: 'Demand WhitelistSubids',
      label: 'Demand WhitelistSubids'
    },
    {
      value: 'Demand Change Bid By %',
      label: 'Demand Change Bid By %'
    },
    {
      value: 'Demand Change Budget By %',
      label: 'Demand Change Budget By %'
    }
  ]
};

export const APPLY_DATA_FROM_OPTIONS = [
  {
    value: 'today',
    label: 'Today'
  },
  {
    value: 'last 2 days',
    label: 'Last 2 Days'
  },
  {
    value: 'last 7 days',
    label: 'Last 7 Days'
  },
  {
    value: 'this month',
    label: 'This Month'
  },
  {
    value: 'last month',
    label: 'Last Month'
  }
];

export const ACTION_INPUT_LIST = [
  {
    value: 'action_input1',
    label: 'Action Input 1'
  },
  {
    value: 'action_input2',
    label: 'Action Input 2'
  }
];

export const ACTION_INPUT_VALUES = {
  action_input1: {
    component: 'input',
    value: ''
  },
  action_input2: {
    component: 'select',
    value: [
      {
        value: 'action_input2_option1',
        label: 'Action Input2 Option1'
      },
      {
        value: 'action_input2_option2',
        label: 'Action Input2 Option2'
      },
      {
        value: 'action_input2_option3',
        label: 'Action Input2 Option3'
      },
      {
        value: 'action_input2_option4',
        label: 'Action Input2 Option4'
      },
      {
        value: 'action_input2_option5',
        label: 'Action Input2 Option5'
      }
    ]
  }
};

export const ALERT_SETTING_OPTIONS = [
  {
    label: 'Send Email',
    value: 'send_email'
  }
];

export const getValueFromObjectArray = (
  filterValue,
  objectArray,
  returnKey
) => {
  const filteredOne = objectArray.filter(
    item => item[filterValue.keyName] === filterValue.value
  );

  if (filteredOne.length > 0) {
    return filteredOne[0][returnKey];
  }

  return '';
};

export const BREAK_DOWN_OPTIONS = [
  {
    label: 'None',
    value: 'none'
  },
  {
    label: 'Hour',
    value: 'hour'
  },
  {
    label: 'Day',
    value: 'day'
  },
  {
    label: 'Month',
    value: 'month'
  }
];

export const getEnableBreakDownOptions = interval => {
  const newBreakDown = [];
  newBreakDown.push({ value: 'none', label: 'None' });

  switch (interval) {
    case 'today':
      newBreakDown.push({ value: 'hour', label: 'Hour' });
      break;
    case 'last 2 days':
    case 'last 7 days':
      newBreakDown.push({ value: 'hour', label: 'Hour' });
      newBreakDown.push({ value: 'day', label: 'Day' });
      break;
    case 'this month':
    case 'last month':
      newBreakDown.push({ value: 'hour', label: 'Hour' });
      newBreakDown.push({ value: 'day', label: 'Day' });
      newBreakDown.push({ value: 'month', label: 'Month' });
      break;
    default:
  }
  return newBreakDown;
};

export const PRICES_OPTIONS = [
  {
    value: 'fixedprice',
    label: 'Fixed price'
  },
  {
    value: 'monthly_price',
    label: 'Monthly price'
  }
];

export const PLATFORM_OPTIONS = [
  {
    value: 'desktop',
    label: 'Desktop'
  },
  {
    value: 'table',
    label: 'Table'
  },
  {
    value: 'mobile',
    label: 'Mobile'
  }
];

// Dynamic Filter functions
export const getCalcaulateOptions = (id, selectedMetrics) => {
  const filteredOne = selectedMetrics.filter(item => item.id === id);

  if (filteredOne.length > 0) {
    return filteredOne[0].condition.map(item => {
      return { value: item, label: item };
    });
  }

  return [];
};

export const getConditionOptions = (id, selectedMetrics) => {
  const filteredOne = selectedMetrics.filter(item => item.id === id);

  if (filteredOne.length > 0) {
    return filteredOne[0].options;
  }

  return [];
};

export const getConditionFieldType = (id, selectedMetrics) => {
  const filteredOne = selectedMetrics.filter(item => item.id === id);

  if (filteredOne.length > 0) {
    return filteredOne[0].field_type;
  }

  return 'text';
};

export const getDefaultValueOfCondition = (id, selectedMetrics) => {
  const fieldType = getConditionFieldType(id, selectedMetrics);

  switch (fieldType) {
    case 'string':
      return '';
    case 'date':
      return new Date();
    case 'number':
      return 0;
    case 'select': {
      const optionValues = getConditionOptions(id, selectedMetrics);

      if (optionValues.length > 0) {
        return optionValues[0];
      }

      return {};
    }
    case 'multi-select': {
      const optionValues = getConditionOptions(id, selectedMetrics);

      if (optionValues.length > 0) {
        return [optionValues[0]];
      }

      return [];
    }

    default:
      return 0;
  }
};

export const getValidMetricOptions = (selectedMetrics, metrics) => {
  const newOne = [];

  metrics.forEach(item => {
    const filteredOne = selectedMetrics.filter(
      itemOne => itemOne.id === item.value
    );

    if (filteredOne.length >= 2) {
      return;
    }

    if (filteredOne.length === 1) {
      if (filteredOne[0].condition === '=') {
        return;
      }

      const fieldType = getConditionFieldType(item.value, metrics);

      if (
        fieldType === 'select' ||
        fieldType === 'string' ||
        fieldType === 'multi-string'
      ) {
        return;
      }
    }

    newOne.push(item);
  });

  return newOne;
};

export const METRIC_FIELD_TYPE_OPTIONS = [
  {
    label: 'String',
    value: 'string'
  },
  {
    label: 'Number',
    value: 'number'
  },
  {
    label: 'Select',
    value: 'select'
  },
  {
    label: 'Multi Select',
    value: 'multi-select'
  },
  {
    label: 'Date',
    value: 'date'
  }
];

export const getMetricFieldTypeValue = fieldTypeLabel => {
  const filteredOne = METRIC_FIELD_TYPE_OPTIONS.filter(
    item => item.label === fieldTypeLabel
  );

  if (filteredOne.length > 0) {
    return filteredOne[0].value;
  }

  return METRIC_FIELD_TYPE_OPTIONS[0].value;
};

export const getMetricFieldTypeLabel = fieldTypeValue => {
  const filteredOne = METRIC_FIELD_TYPE_OPTIONS.filter(
    item => item.value === fieldTypeValue
  );

  if (filteredOne.length > 0) {
    return filteredOne[0].label;
  }

  return METRIC_FIELD_TYPE_OPTIONS[0].label;
};

export const getDefaultConditionOptions = fieldTypeValue => {
  switch (fieldTypeValue) {
    case 'number':
    case 'date':
      return ['<', '<=', '=', '>', '>='];
    case 'string':
    case 'select':
      return ['=', '<>'];
    case 'multi-select':
      return ['Is in', 'Not in'];
    default:
      return ['<', '<=', '=', '>', '>='];
  }
};

export const getValidCoditionOptions = (
  selectedOne,
  selectedMetrics,
  metrics
) => {
  const newOne = [];
  const selectedFieldType = getConditionFieldType(selectedOne.id, metrics);
  const filteredOne = selectedMetrics.filter(
    item => item.id === selectedOne.id
  );

  if (selectedFieldType === 'string' || selectedFieldType === 'select') {
    return [
      { value: '=', label: '=' },
      { value: '<>', label: '<>' }
    ];
  }

  if (selectedFieldType === 'multi-select') {
    return [
      { value: 'Is in', label: 'Is in' },
      { value: 'Not in', label: 'Not in' }
    ];
  }

  if (filteredOne.length >= 2) {
    switch (selectedFieldType) {
      case 'number':
      case 'date': {
        if (selectedOne.condition === '<' || selectedOne.condition === '<=') {
          return [
            { value: '<', label: '<' },
            { value: '<=', label: '<=' }
          ];
        } else if (
          selectedOne.condition === '>' ||
          selectedOne.condition === '>='
        ) {
          return [
            { value: '>', label: '>' },
            { value: '>=', label: '>=' }
          ];
        }

        return [];
      }
      default:
      // do nothing
    }
  } else {
    const newConditions = getDefaultConditionOptions(selectedFieldType);

    return newConditions.map(item => ({ value: item, label: item }));
  }

  return newOne;
};

export const setInitialConditionValue = (id, selectedMetrics, metrics) => {
  const fieldType = getConditionFieldType(id, metrics);

  switch (fieldType) {
    case 'string':
    case 'select':
      return '=';

    case 'multi-select':
      return 'Is in';

    case 'number':
    case 'date': {
      if (selectedMetrics.length === 0) {
        return '<';
      }

      const filteredOne = selectedMetrics.filter(item => item.id === id);

      if (filteredOne.length === 0) {
        return '<';
      }

      if (filteredOne.length >= 1) {
        if (
          filteredOne[0].condition === '<' ||
          filteredOne[0].condition === '<='
        ) {
          return '>';
        } else if (
          filteredOne[0].condition === '>' ||
          filteredOne[0].condition === '>='
        ) {
          return '<';
        }
      }

      return 'unknown metric field type';
    }
  }
};
