import { useRef, useState } from 'react';

import useEventListener from '../../utils/use-event-listener';

const HandleSidebar = () => {
  const [openToggle, setOpenToggle] = useState(false);
  const wrapSidebar = useRef(null);

  function onShowNavSide (e) {
    e.stopPropagation();

    if (e.currentTarget.className === 'sidebar') {
      document.body.classList.add('side-open');
      setOpenToggle(true);
    } else {
      if (!openToggle === true) {
        document.body.classList.add('side-open');
        setOpenToggle(true);
      } else {
        document.body.classList.remove('side-open');
        setOpenToggle(false);
      }
    }
  }

  function hideSideClick (e) {
    // e.stopPropagation();
    // document.body.classList.remove('side-open');
    console.log('click');
  }

  const handleClickOutSide = (e) => {
    const { target } = e;

    // Show hide sidebar
    if (wrapSidebar.current != null) {
      if (wrapSidebar.current && !wrapSidebar.current.contains(target)) {
        setOpenToggle(false);
        document.body.classList.remove('side-open');
      }
    }
  };

  useEventListener('click', handleClickOutSide);

  return {
    onShowNavSide,
    hideSideClick,
    openToggle,
    wrapSidebar
  };
};

export default HandleSidebar;
