import * as yup from 'yup';

import {
  BID_MODIFY_FIELD,
  LIMITS_FIELD,
  MAX_BID_FIELD,
  MIN_BID_FIELD,
  REV_SHARE_FIELD,
  REDIRECT_DOMAINS_FIELD,
  DMS_DB_FIELD,
  SUB_ID_DB_FIELD,
  CLICK_LIMITS_FIELD
} from '../../constants';

import { emptyStringToNull, optimizationArrayObjectSchema } from '../shared/optimization';
import { multiSelectSchema } from '../shared/shared-schemas';

export const endpointDemandsOptimizationSchema = yup.object().shape({
  [MIN_BID_FIELD]: yup.number().required('Min Bid is required').transform(emptyStringToNull).nullable(),
  [MAX_BID_FIELD]: yup.number().required('Max Bid is required').transform(emptyStringToNull).nullable(),
  [BID_MODIFY_FIELD]: optimizationArrayObjectSchema,
  [LIMITS_FIELD]: optimizationArrayObjectSchema,
  [CLICK_LIMITS_FIELD]: optimizationArrayObjectSchema,
  [REDIRECT_DOMAINS_FIELD]: multiSelectSchema,
  [DMS_DB_FIELD]: multiSelectSchema,
  [SUB_ID_DB_FIELD]: multiSelectSchema,
  [REV_SHARE_FIELD]: optimizationArrayObjectSchema
});

export const validateEndpointDemandsOptimizationData = (data) => {
  try {
    endpointDemandsOptimizationSchema.validateSync(data);

    return true;
  } catch (e) {
    return false;
  }
};
