import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from 'react-router-dom';

import {
  AUTHENTICATION_CONFIRM_PASSWORD_FIELD,
  AUTHENTICATION_PASSWORD_FIELD
} from '../../actions/types';

import '../../assets/style/login.scss';

import { resetPassword } from '../../api/users';

import WizardInputWithValidation from '../wizards-manager/wizards/shared/WizardInputWithValidation';

import { resetPasswordFormSchema } from './validators';

const ResetPasswordForm = () => {
  const history = useHistory();

  const {
    handleSubmit,
    register,
    errors
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: ''
    },
    validationSchema: resetPasswordFormSchema
  });

  const { token } = useParams();

  const onSubmit = async ({ password }) => {
    try {
      await resetPassword(token, { password });

      history.push('/login');
    } catch (err) {
      // TODO Handle error?
    }
  };

  return (
    <div className="minisite">
      <div className="login">
        <div className="container">
          <div className="row row-login">
            <div className="col-md-4 offset-md-7 mt-3 mobile-hero-background">
              <Link to="/">
                <img
                  className="footer-logo mt-5 mb-5"
                  src={require('../../assets/images/logo@2x.png')}
                  alt="DigitalFlare"
                />
              </Link>

              <h4 className="mt-5">Welcome back!</h4>
              <p className="p-privacy mb-5">Update your password</p>

              <form onSubmit={handleSubmit(onSubmit)}>
                <WizardInputWithValidation
                  type="password"
                  error={!!errors[AUTHENTICATION_PASSWORD_FIELD]}
                  errorMessage={errors[AUTHENTICATION_PASSWORD_FIELD] ? errors[AUTHENTICATION_PASSWORD_FIELD].message : ''}
                  name={AUTHENTICATION_PASSWORD_FIELD}
                  propRef={register}
                  placeholder="Password"
                />
                <WizardInputWithValidation
                  type="password"
                  error={!!errors[AUTHENTICATION_CONFIRM_PASSWORD_FIELD]}
                  errorMessage={errors[AUTHENTICATION_CONFIRM_PASSWORD_FIELD] ? errors[AUTHENTICATION_CONFIRM_PASSWORD_FIELD].message : ''}
                  name={AUTHENTICATION_CONFIRM_PASSWORD_FIELD}
                  propRef={register}
                  placeholder="Confirm Password"
                />

                <button className="btn button btn-primary btn-log mt-4">
                  Update password
                </button>
              </form>

              <p className="sm-font mt-5">
                Already have an account? <Link to="/login">Log in</Link>
              </p>

              <div className="bot-footer mt-5 mb-2">
                <p className="sm-font">
                  Copyright &copy; {new Date().getFullYear()} DigitalFlare.
                  All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
