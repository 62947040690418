import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIsMountedRef } from './useIsMountedRef';

export const useFormsEffect = (
  isSubmitting,
  triggerValidation,
  getValues,
  updateState,
  onSubmit,
  nestedField
) => {
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    (async () => {
      if (isSubmitting) {
        const isValid = await triggerValidation();

        if (isValid) {
          const values = getValues({ nest: true });

          if (isMountedRef.current) {
            if (nestedField) {
              updateState({ [nestedField]: values })(dispatch);
            } else {
              updateState(values)(dispatch);
            }
          }
        }

        if (isMountedRef.current) {
          onSubmit(isValid);
        }
      }
    })();
  }, [isSubmitting]);
};
