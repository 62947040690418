import {
  ENDPOINT_DEMAND_GET_INITIAL_ENDPOINT_DEMANDS_FORM_STATE,
  ENDPOINT_DEMAND_OPEN_ENDPOINT_DEMAND_EDIT,
  ENDPOINT_DEMAND_UPDATE_ENDPOINT_DEMAND_STATE
} from '../actions/types';

import { transformNumberFields } from '../components/wizards-manager/helpers/transformers-helpers';

export const initialEndpointDemandsFormState = {
  targeting: {
    geos: {
      include: true,
      list: []
    },
    ctypes: {
      include: true,
      list: []
    },
    carriers: {
      include: true,
      list: []
    },
    device_types: {
      include: true,
      list: []
    },
    browsers: {
      include: true,
      list: []
    },
    os: {
      include: true,
      list: []
    },
    custom_tags: {
      include: true,
      list: []
    }
  },
  filters: {
    bad_ips: true,
    proxies: null,
    ip: '',
    subid: '',
    domain: '',
    user_agents: '',
    ip_include: false,
    subid_include: false,
    domain_include: false,
    user_agents_include: false
  },
  optimization: {
    min_bid: null,
    max_bid: null,
    bid_modify: [],
    limits: []
  }
};

const initialEndpointDemandsState = {
  formData: initialEndpointDemandsFormState,
  possibleOptions: {}
};

export default function (state = initialEndpointDemandsState, action) {
  const { type, payload } = action;

  switch (type) {
    case ENDPOINT_DEMAND_UPDATE_ENDPOINT_DEMAND_STATE:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...transformNumberFields(payload)
        }
      };

    case ENDPOINT_DEMAND_OPEN_ENDPOINT_DEMAND_EDIT:
      return {
        ...state,
        ...payload
      };

    case ENDPOINT_DEMAND_GET_INITIAL_ENDPOINT_DEMANDS_FORM_STATE:
      return {
        ...state,
        formData: initialEndpointDemandsFormState
      };

    default:
      return state;
  }
};
