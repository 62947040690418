import { toast } from 'react-toastify';

import {
  calculateManualReportById, createManualRevenueReport,
  deleteManualRevenueReportById,
  fetchAllAppEvents,
  fetchAllManualRevenueReports,
  fetchAllRevenueReports,
  updateManualRevenueReport
} from '../api/settings';
import { fetchAllUsers } from '../api/users';

import {
  SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD,
  SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD,
  SETTINGS_MANUAL_REVENUE_STATUS_FIELD,
  SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD
} from './types';

export const getAllRevenueReports = async () => {
  try {
    const res = await fetchAllRevenueReports();

    return res.data.data;
  } catch (e) {
    toast.error('Fetching revenue reports error', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    return [];
  }
};

export const getAllAppEvents = async (query = '') => {
  try {
    const res = await fetchAllAppEvents(query);

    return res.data.data;
  } catch (e) {
    toast.error('Fetching timeline logs error', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    return [];
  }
};

export const getAllUsers = async () => {
  try {
    const res = await fetchAllUsers();

    return res.data.data.data
      .map((u) => ({
        label: u.name,
        value: u._id
      }));
  } catch (e) {
    toast.error('Fetching users error', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    return [];
  }
};

export const getAllManualRevenueReports = async () => {
  try {
    const res = await fetchAllManualRevenueReports();

    return res.data.data;
  } catch (e) {
    toast.error('Fetching manual revenue reports error', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    return [];
  }
};

export const updateManualRevenueReportAction = async (id, data) => {
  try {
    const res = await updateManualRevenueReport(id, data);

    const item = res.data.data;

    return {
      [SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD]: item[SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD],
      [SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD]: item[SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD],
      [SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD]: item[SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD],
      [SETTINGS_MANUAL_REVENUE_STATUS_FIELD]: item[SETTINGS_MANUAL_REVENUE_STATUS_FIELD]
    };
  } catch (e) {
    toast.error('Updating manual revenue report error', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    return false;
  }
};

export const calculateManualRevenueReport = async (id) => {
  try {
    await calculateManualReportById(id);

    return true;
  } catch (e) {
    toast.error('Calculating manual revenue report error', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    return false;
  }
};

export const createManualRevenueReportAction = async (data) => {
  try {
    const res = await createManualRevenueReport(data);

    return res.data.data;
  } catch (e) {
    toast.error('Creating manual revenue report error', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    return false;
  }
};

export const deleteManualRevenueReport = async (id) => {
  try {
    await deleteManualRevenueReportById(id);

    return true;
  } catch (e) {
    toast.error('Deleting manual revenue report error', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    return false;
  }
};
