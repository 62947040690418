import { toast } from 'react-toastify';

import { fetchClientReport } from '../api/client-report';

import { checkIsDemand } from '../components/dashboard/charts/helpers/donut-chart-helpers';
import { checkIsDemandFiltered } from '../components/dashboard/charts/helpers/top-charts-helpers';
import {
  transformFilters,
  transformResponseToTableData,
  transformTableMetrics
} from '../components/dashboard/table/helpers/table-manager-helpers';

import store from '../store';

import {
  SET_DASHBOARD_OPTION,
  DASHBOARD_START_FETCHING_TABLE_DATA,
  DASHBOARD_UPDATE_TABLE,
  DASHBOARD_GET_INITIAL_FILTER_STATE,
  DASHBOARD_UPDATE_FILTER_STATE,
  DASHBOARD_UPDATE_INTERVAL,
  DASHBOARD_UPDATE_DATA_RANGE,
  DASHBOARD_TIMEZONE_FIELD,
  DASHBOARD_INTERVAL_CUSTOM,
  DASHBOARD_STATUS,
  DASHBOARD_DATE_FIELD,
  DASHBOARD_BREAKDOWN_DAY,
  DASHBOARD_DEMAND_FEEDS_FIELD,
  DASHBOARD_START_FETCHING_MAIN_CHART_DATA,
  DASHBOARD_REQUESTS_COUNT,
  DASHBOARD_INT_REQUESTS_COUNT,
  DASHBOARD_APPROVED_REVENUE,
  DASHBOARD_APPROVED_PAYOUTS,
  DASHBOARD_EST_REVENUE,
  DASHBOARD_EST_PAYOUT,
  DASHBOARD_IMPS_COUNT,
  DASHBOARD_CLICKS_COUNT,
  DASHBOARD_FETCH_MAIN_CHART,
  DASHBOARD_START_FETCHING_PIE_CHART_DATA,
  DASHBOARD_INT_REQUESTS_COVERAGE,
  DASHBOARD_REQUESTS_COVERAGE,
  DASHBOARD_INT_REQUESTS_BIDS,
  DASHBOARD_REQUESTS_BIDS,
  DASHBOARD_INT_REQUESTS_AVG_GROSS_BID,
  DASHBOARD_REQUESTS_AVG_GROSS_BID,
  DASHBOARD_INT_REQUESTS_AVG_NET_BID,
  DASHBOARD_REQUESTS_AVG_NET_BID,
  DASHBOARD_FETCH_PIE_CHART,
  DASHBOARD_PROFIT,
  DASHBOARD_ROI,
  DASHBOARD_AVG_NET_BID,
  DASHBOARD_DF_SOURCE_FIELD,
  DASHBOARD_INTERVAL_TODAY,
  DASHBOARD_BREAKDOWN_HOUR,
  DASHBOARD_UPDATE_TIMEZONE,
  DASHBOARD_INTERVAL_LAST_48_HOURS, DASHBOARD_INTERVAL_YESTERDAY
} from './types';

export const fetchTableData = (table, interval, filters, dateRange, isPartner) => async dispatch => {
  dispatch({ type: DASHBOARD_START_FETCHING_TABLE_DATA });

  try {
    const timeZone = filters[DASHBOARD_TIMEZONE_FIELD].value;
    // // Delete unnecessary timezone
    delete filters[DASHBOARD_TIMEZONE_FIELD];
    delete filters[DASHBOARD_STATUS];

    const isDemand = checkIsDemand(table, filters);

    const data = {
      timeZone,
      dimensions: [],
      metrics: transformTableMetrics(table, isDemand, isPartner)
    };

    const isToday = interval === DASHBOARD_INTERVAL_TODAY ||
      interval === DASHBOARD_INTERVAL_LAST_48_HOURS ||
      interval === DASHBOARD_INTERVAL_YESTERDAY;

    if (table !== DASHBOARD_DATE_FIELD) {
      data.dimensions.push(table);
    } else {
      data.breakdown = isToday ? DASHBOARD_BREAKDOWN_HOUR : DASHBOARD_BREAKDOWN_DAY;
    }

    if (interval === DASHBOARD_INTERVAL_CUSTOM) {
      data.dateRange = dateRange;
    } else {
      data.interval = interval;
    }

    const filtersData = transformFilters(filters);

    if (Object.keys(filtersData).length) {
      data.filters = filtersData;
    }

    const res = await fetchClientReport(data);

    const payload = transformResponseToTableData(res.data, table, isDemand, isPartner, isToday);

    dispatch({ type: DASHBOARD_UPDATE_TABLE, payload });
  } catch (err) {
    toast.error('Fail to load table data', {
      position: toast.POSITION.BOTTOM_RIGHT
    });
  }
};

export const fetchMainChartData = (filters, breakdown, interval, dateRange) => async dispatch => {
  dispatch({ type: DASHBOARD_START_FETCHING_MAIN_CHART_DATA });

  try {
    const timeZone = filters[DASHBOARD_TIMEZONE_FIELD].value;
    // // Delete unnecessary timezone
    delete filters[DASHBOARD_TIMEZONE_FIELD];
    delete filters[DASHBOARD_STATUS];

    const isDemand = checkIsDemandFiltered(filters[DASHBOARD_DEMAND_FEEDS_FIELD], filters[DASHBOARD_DF_SOURCE_FIELD]);

    const data = {
      timeZone,
      metrics: [
        isDemand ? DASHBOARD_INT_REQUESTS_COUNT : DASHBOARD_REQUESTS_COUNT,
        DASHBOARD_APPROVED_REVENUE,
        DASHBOARD_APPROVED_PAYOUTS,
        DASHBOARD_EST_REVENUE,
        DASHBOARD_EST_PAYOUT,
        DASHBOARD_IMPS_COUNT,
        DASHBOARD_CLICKS_COUNT
      ],
      breakdown
    };

    if (interval === DASHBOARD_INTERVAL_CUSTOM) {
      data.dateRange = dateRange;
    } else {
      data.interval = interval;
    }

    const filtersData = transformFilters(filters);

    if (Object.keys(filtersData).length) {
      data.filters = filtersData;
    }

    const res = await fetchClientReport(data);

    const payload = res.data
      .map(d => {
        d.date = new Date(d.date).getTime();
        return d;
      })
      .sort((a, b) => b.date - a.date);

    dispatch({ type: DASHBOARD_FETCH_MAIN_CHART, payload });
  } catch (err) {
    toast.error('Fail to load main chart data', {
      position: toast.POSITION.BOTTOM_RIGHT
    });
  }
};

export const fetchPieChartData = (dimension, filters, interval, dateRange, isPartner) => async dispatch => {
  dispatch({ type: DASHBOARD_START_FETCHING_PIE_CHART_DATA });

  try {
    const timeZone = filters[DASHBOARD_TIMEZONE_FIELD].value;
    // // Delete unnecessary timezone
    delete filters[DASHBOARD_TIMEZONE_FIELD];
    delete filters[DASHBOARD_STATUS];

    const isDemand = checkIsDemand(dimension, filters);

    const buildMetrics = (isDemand, isPartner) => {
      if (isPartner) {
        return [
          isDemand ? DASHBOARD_INT_REQUESTS_COUNT : DASHBOARD_REQUESTS_COUNT,
          isDemand ? DASHBOARD_INT_REQUESTS_COVERAGE : DASHBOARD_REQUESTS_COVERAGE,
          DASHBOARD_APPROVED_PAYOUTS,
          DASHBOARD_EST_PAYOUT,
          DASHBOARD_AVG_NET_BID
        ];
      }

      return [
        isDemand ? DASHBOARD_INT_REQUESTS_COUNT : DASHBOARD_REQUESTS_COUNT,
        isDemand ? DASHBOARD_INT_REQUESTS_COVERAGE : DASHBOARD_REQUESTS_COVERAGE,
        isDemand ? DASHBOARD_INT_REQUESTS_BIDS : DASHBOARD_REQUESTS_BIDS,
        DASHBOARD_APPROVED_REVENUE,
        DASHBOARD_APPROVED_PAYOUTS,
        DASHBOARD_EST_REVENUE,
        DASHBOARD_AVG_NET_BID,
        isDemand ? DASHBOARD_INT_REQUESTS_AVG_GROSS_BID : DASHBOARD_REQUESTS_AVG_GROSS_BID,
        isDemand ? DASHBOARD_INT_REQUESTS_AVG_NET_BID : DASHBOARD_REQUESTS_AVG_NET_BID,
        DASHBOARD_PROFIT,
        DASHBOARD_ROI
      ];
    };

    const data = {
      timeZone,
      metrics: buildMetrics(isDemand, isPartner),
      dimensions: [dimension]
    };

    if (interval === DASHBOARD_INTERVAL_CUSTOM) {
      data.dateRange = dateRange;
    } else {
      data.interval = interval;
    }

    const filtersData = transformFilters(filters);

    if (Object.keys(filtersData).length) {
      data.filters = filtersData;
    }

    const res = await fetchClientReport(data);

    dispatch({ type: DASHBOARD_FETCH_PIE_CHART, payload: res.data });
  } catch (err) {
    toast.error('Fail to load pie chart data', {
      position: toast.POSITION.BOTTOM_RIGHT
    });
  }
};

export const getInitialFilterState = () => dispatch => {
  dispatch({ type: DASHBOARD_GET_INITIAL_FILTER_STATE });
};

export const updateFilterState = (payload) => dispatch => {
  dispatch({ type: DASHBOARD_UPDATE_FILTER_STATE, payload });
};

export const updateIntervalState = (payload) => dispatch => {
  dispatch({ type: DASHBOARD_UPDATE_INTERVAL, payload });
};

export const updateDataRangeState = (payload) => dispatch => {
  dispatch({ type: DASHBOARD_UPDATE_DATA_RANGE, payload });
};

export const updateTimezoneState = (payload) => {
  const dispatch = store.dispatch;

  dispatch({ type: DASHBOARD_UPDATE_TIMEZONE, payload });
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export const setDashboardOption = (newOption) => async dispatch => {
  dispatch({
    type: SET_DASHBOARD_OPTION,
    payload: newOption
  });
};
