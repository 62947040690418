import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  createPartnerAction,
  getInitialPartnersFormDataAction,
  updatePartnerAction,
  updatePartnerStateAction
} from '../../../../actions/partners';

import { PARTNER_NAME_FIELD, PARTNER_WIZARD } from '../../constants';

import { validatePartnerFormData, validatePartnerUpdateFormData } from '../../validators/partner';

import Wizard from '../../wizard-base/Wizard';
import PartnerAuthenticationForm from './forms/PartnerAuthenticationForm';
import PartnerContactForm from './forms/PartnerContactForm';

const PartnerWizard = () => {
  const {
    name,
    id
  } = useSelector(
    state => ({
      name: state[PARTNER_WIZARD].formData[PARTNER_NAME_FIELD],
      id: state[PARTNER_WIZARD].formData._id
    })
  );

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    let error;

    if (id) {
      error = await updatePartnerAction()(dispatch);
    } else {
      error = await createPartnerAction()(dispatch);
    }

    if (error) {
      // TODO Handle error
      return false;
    }

    return true;
  };

  const handleName = async (event) => {
    const name = event.target.value;

    updatePartnerStateAction({ name })(dispatch);
  };

  const stepsForms = [
    {
      label: 'Contact',
      component: PartnerContactForm
    },
    {
      label: 'Auth & Stats',
      component: PartnerAuthenticationForm
    }
  ];

  return (
    <Wizard
      clearFormState={getInitialPartnersFormDataAction}
      stepsForms={stepsForms}
      handleSubmit={handleSubmit}
      updateTitle={handleName}
      title={name}
      id={id}
      validateForms={id ? validatePartnerUpdateFormData : validatePartnerFormData}
      titlePlaceholder={`${id ? 'Update' : 'New'} Partner`}
      wizardName={`${id ? 'Update' : 'New'} Partner`}
    />
  );
};

export default PartnerWizard;
