import { toast } from 'react-toastify';

import { fetchDemandById } from '../api/demand';
import {
  fetchEndpointById,
  modifyEndpoint,
  removeEndpoint,
  saveEndpoint
} from '../api/endpoint';

import {
  fetchEndpointDemandByEndpointId,
  fetchEndpointDemandById,
  getEndpointDemandOptions,
  modifyEndpointDemand
} from '../api/endpointDemand';

import {
  createEndpointDemands,
  deleteEndpointDemand,
  updateEndpointDemand
} from '../api/helpers/endpoint-demand';
import {
  transformEndpointsOrDemandsToTableData,
  transformEndpointToFormData
} from '../components/tables-manager/helpers/transformers';

import { ACTIVE_FIELD, ENDPOINT_WIZARD } from '../components/wizards-manager/constants';

import { transformEndpointData } from '../components/wizards-manager/helpers/transformers';
import { transformMultiSelect } from '../components/wizards-manager/helpers/wizard-helpers';

import store from '../store';

import {
  ENDPOINT_CREATE_ENDPOINT,
  ENDPOINT_UPDATE_ENDPOINT,
  ENDPOINT_DELETE_ENDPOINT,
  ENDPOINT_START_FETCHING_ENDPOINTS,
  ENDPOINT_FETCH_ENDPOINTS,
  ENDPOINT_UPDATE_ENDPOINT_STATE,
  ENDPOINT_GET_INITIAL_ENDPOINT_FORM_STATE,
  ENDPOINT_EDIT_ENDPOINT,
  DRAWER_FORM_OPEN,
  DRAWER_FORM_CREATE_ENDPOINT,
  ENDPOINT_DUPLICATE_ENDPOINT,
  ENDPOINT_CONNECT_ENDPOINT_DEMANDS_STATE,
  DRAWER_FORM_ENDPOINT_DEMAND,
  ENDPOINT_DEMAND_OPEN_ENDPOINT_DEMAND_EDIT,
  ENDPOINT_UPDATE_UPDATED_AT,
  ENDPOINT_DEMAND_FEEDS,
  DEMAND_UPDATE_UPDATED_AT,
  ENDPOINT_UPDATE_STATUS,
  DEMAND_FETCH_ENDPOINTS,
  ENDPOINT_UPDATE_TAGS
} from './types';

const dispatch = store.dispatch;

export const editEndpointAction = (data, staticList) => (dispatch) => {
  const transformedData = transformEndpointToFormData(data, staticList);

  const payload = {
    formData: transformedData,
    duplicated_id: null
  };

  dispatch({ type: ENDPOINT_EDIT_ENDPOINT, payload });
  dispatch({ type: DRAWER_FORM_OPEN, payload: DRAWER_FORM_CREATE_ENDPOINT });
};

export const editEndpointFromTableAction = async (id) => {
  try {
    const staticList = store.getState().staticList;
    const endpointRes = await fetchEndpointById(id);
    const endpoint = transformEndpointsOrDemandsToTableData([endpointRes.data]);
    const data = transformEndpointToFormData(endpoint[0], staticList);

    const payload = {
      formData: data,
      duplicated_id: null
    };

    dispatch({ type: ENDPOINT_EDIT_ENDPOINT, payload });
    dispatch({ type: DRAWER_FORM_OPEN, payload: DRAWER_FORM_CREATE_ENDPOINT });
  } catch (err) {
    toast.error('Open edit endpoint error', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};

export const duplicateEndpointAction = (data, staticList) => (dispatch) => {
  const transformedData = transformEndpointToFormData(data, staticList);
  const duplicatedId = transformedData._id;

  transformedData.title = `${transformedData.title} (copy 1)`;

  delete transformedData._id;

  const payload = {
    formData: transformedData,
    duplicated_id: duplicatedId
  };

  dispatch({ type: ENDPOINT_DUPLICATE_ENDPOINT, payload });
  dispatch({ type: DRAWER_FORM_OPEN, payload: DRAWER_FORM_CREATE_ENDPOINT });
};

export const createEndpointAction = () => async (dispatch) => {
  try {
    const endpoint = store.getState()[ENDPOINT_WIZARD].formData;
    const data = transformEndpointData(endpoint);

    const res = await saveEndpoint(data);

    const demandFeeds = store.getState()[ENDPOINT_WIZARD].demand_feeds;
    const duplicatedID = store.getState()[ENDPOINT_WIZARD].duplicated_id;
    const visited = store.getState()[ENDPOINT_WIZARD].visited_endpoint_demand_form;

    if (duplicatedID) {
      if (visited) {
        if (demandFeeds) {
          const createPromises = demandFeeds.create.map((ed) =>
            createEndpointDemands({
              ...ed,
              endpoint: res.data._id
            })
          );

          await Promise.all(createPromises);
        }
      } else {
        const duplicateDemandFeeds = await fetchEndpointDemandByEndpointId(duplicatedID);

        const createPromises = duplicateDemandFeeds.data.map((ed) =>
          createEndpointDemands({
            active: ed[ACTIVE_FIELD],
            demand: ed.demand._id,
            endpoint: res.data._id
          })
        );

        await Promise.all(createPromises);
      }
    } else {
      if (demandFeeds) {
        const createPromises = demandFeeds.create.map((ed) =>
          createEndpointDemands({
            ...ed,
            endpoint: res.data._id
          })
        );

        await Promise.all(createPromises);
      }
    }

    toast.info('Endpoint Created', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    const transformedEndpoint = transformEndpointsOrDemandsToTableData([res.data]);
    const payload = transformedEndpoint[0];

    dispatch({
      type: ENDPOINT_CREATE_ENDPOINT,
      payload
    });

    return false;
  } catch (err) {
    toast.error('Create Endpoint Failed', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    return err;
  }
};

export const updateEndpointAction = () => async (dispatch) => {
  try {
    const endpoint = store.getState()[ENDPOINT_WIZARD].formData;
    const data = transformEndpointData(endpoint);

    const id = data._id;
    const res = await modifyEndpoint(id, data);

    const endpointDemands = store.getState()[ENDPOINT_WIZARD].demand_feeds;

    if (endpointDemands) {
      const edMap = store.getState()[ENDPOINT_WIZARD].ed_map;

      const createPromises = endpointDemands.create.map((ed) =>
        createEndpointDemands(ed)
      );

      const updatePromises = endpointDemands.update.map((ed) =>
        updateEndpointDemand(edMap[ed.demand], {
          active: ed.active
        })
      );

      const removePromises = endpointDemands.remove.map((ed) =>
        deleteEndpointDemand(edMap[ed.demand])
      );

      await Promise.all([
        ...createPromises,
        ...updatePromises,
        ...removePromises
      ]);
    }

    const transformedRes = transformEndpointsOrDemandsToTableData([res.data]);
    const payload = transformedRes[0];

    dispatch({
      type: ENDPOINT_UPDATE_ENDPOINT,
      payload
    });

    toast.info('Endpoint Updated', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    return false;
  } catch (err) {
    console.log(err);
    toast.error('Update Endpoint Error', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    return err;
  }
};

export const updateEndpointStatus = (id, active) => async (dispatch) => {
  try {
    await modifyEndpoint(id, { active });

    dispatch({
      type: ENDPOINT_UPDATE_STATUS,
      payload: {
        _id: id,
        active: active ? 'Active' : 'Inactive'
      }
    });

    toast.info(`Endpoint ${active ? 'activated' : 'deactivated'}`, {
      position: toast.POSITION.BOTTOM_CENTER
    });
  } catch (e) {
    toast.error(`${active ? 'Activating' : 'Deactivating'} endpoint fail`, {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};

export const deleteEndpointAction = (id) => async (dispatch) => {
  try {
    await removeEndpoint(id);

    dispatch({
      type: ENDPOINT_DELETE_ENDPOINT,
      payload: id
    });

    toast.info('Endpoint Deleted Successfully', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  } catch (err) {
    toast.error('Delete Endpoint Failed', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};

export const fetchEndpointsAction = (id, demand = false) => async (dispatch) => {
  try {
    dispatch({ type: ENDPOINT_START_FETCHING_ENDPOINTS });

    const res = await fetchEndpointById(id);
    const payload = transformEndpointsOrDemandsToTableData(res.data);

    if (demand) {
      dispatch({
        type: DEMAND_FETCH_ENDPOINTS,
        payload
      });
    } else {
      dispatch({
        type: ENDPOINT_FETCH_ENDPOINTS,
        payload
      });
    }
  } catch (err) {
    toast.error('Fetching Endpoints Error', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};

export const connectEndpointDemandsAction = (id, duplicated) => async (dispatch) => {
  try {
    const endpointDemands = await fetchEndpointDemandByEndpointId(id);
    const fetchedDemands = await fetchDemandById('all');

    const payload = {
      demands: fetchedDemands.data,
      endpoint_id: id,
      endpoint_demands: endpointDemands.data,
      visited_endpoint_demand_form: duplicated
    };

    dispatch({
      type: ENDPOINT_CONNECT_ENDPOINT_DEMANDS_STATE,
      payload
    });
  } catch (err) {
    toast.error('Fetching EndpointDemands Error', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};

export const deleteConnectEndpointDemandAction = ({ id, sourceID }) => async (
  dispatch
) => {
  const res = await deleteEndpointDemand(id);

  if (res) {
    dispatch({
      type: ENDPOINT_UPDATE_UPDATED_AT,
      payload: sourceID
    });

    toast.info('Connection Deleted Successfully', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  } else {
    toast.error('Connection Deleted Fail', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};

export const openEditEndpointDemandsAction = (id) => async (dispatch) => {
  try {
    const staticList = store.getState().staticList;
    const endpointDemandData = await fetchEndpointDemandById(id);

    const endpointDemand = transformEndpointToFormData(
      endpointDemandData.data,
      staticList
    );
    const possibleOptionsResponse = await getEndpointDemandOptions(
      endpointDemand.endpoint._id,
      endpointDemand.demand._id
    );
    const possibleOptions = possibleOptionsResponse.data.data;

    const payload = {
      formData: endpointDemand,
      possibleOptions
    };

    dispatch({ type: ENDPOINT_DEMAND_OPEN_ENDPOINT_DEMAND_EDIT, payload });
    dispatch({ type: DRAWER_FORM_OPEN, payload: DRAWER_FORM_ENDPOINT_DEMAND });
  } catch (err) {
    toast.error('Open edit EndpointDemands Error', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};

export const updateEndpointDemandsActivation = (
  id,
  active,
  source,
  payload
) => async (dispatch) => {
  try {
    await modifyEndpointDemand(id, { active });

    if (source === 'endpoint') {
      dispatch({ type: DEMAND_UPDATE_UPDATED_AT, payload });
    } else {
      dispatch({ type: ENDPOINT_UPDATE_UPDATED_AT, payload });
    }

    toast.info(`Demand ${active ? 'activated' : 'deactivated'}`, {
      position: toast.POSITION.BOTTOM_CENTER
    });
  } catch (err) {
    toast.error(`${active ? 'Activating' : 'Deactivating'} demand fail`, {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};

export const updateEndpointDemandFeeds = (payload) => (dispatch) => {
  dispatch({ type: ENDPOINT_DEMAND_FEEDS, payload });
};

export const updateEndpointFormDataAction = (payload) => (dispatch) => {
  dispatch({ type: ENDPOINT_UPDATE_ENDPOINT_STATE, payload });
};

export const getInitialEndpointFormDataAction = () => (dispatch) => {
  dispatch({ type: ENDPOINT_GET_INITIAL_ENDPOINT_FORM_STATE });
};

export const updateEndpointTagsAction = async (id, data) => {
  try {
    const tags = transformMultiSelect(data);

    await modifyEndpoint(id, { tags });

    const payload = {
      _id: id,
      tags: data
    };

    dispatch({ type: ENDPOINT_UPDATE_TAGS, payload });
  } catch (err) {
    toast.error('Endpoint tags update fail', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};
