import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { getAllRevenueReports } from '../../actions/settings';
import {
  SETTINGS_REVENUE_SYNC_DEMAND_ID_FIELD,
  SETTINGS_REVENUE_SYNC_REPORT_DATE,
  SETTINGS_REVENUE_SYNC_REPORTED_AMOUNT,
  SETTINGS_REVENUE_SYNC_STATUS,
  SETTINGS_REVENUE_SYNC_UPDATED_AMOUNT
} from '../../actions/types';

import { formatCurrency } from '../dashboard/numeric-helpers';
import { transformToCapitalized } from '../dashboard/table/helpers/table-manager-helpers';
import TablePanelSpinner from '../tables-manager/shared/TablePanelSpinner';
import TablesManager from '../tables-manager/TablesManager';
import { ID_FIELD } from '../wizards-manager/constants';
import { useIsMountedRef } from '../wizards-manager/custom-hooks/useIsMountedRef';

const revenueSyncStatusColumns = [
  {
    Header: 'Id',
    accessor: ID_FIELD
  },
  {
    Header: 'Date',
    accessor: SETTINGS_REVENUE_SYNC_REPORT_DATE,
    Cell: ({ value }) => moment(value).format('DD/MM/YYYY')
  },
  {
    Header: 'Demand',
    accessor: SETTINGS_REVENUE_SYNC_DEMAND_ID_FIELD,
    Cell: ({ value }) => value.toString()
    //  TODO Add name of demand
  },
  {
    Header: 'Status',
    accessor: SETTINGS_REVENUE_SYNC_STATUS,
    Cell: ({ value }) => transformToCapitalized(value)
  },
  {
    Header: 'Reported',
    accessor: SETTINGS_REVENUE_SYNC_REPORTED_AMOUNT,
    Cell: ({ value }) => formatCurrency(value)
  },
  {
    Header: 'Synced',
    accessor: SETTINGS_REVENUE_SYNC_UPDATED_AMOUNT,
    Cell: ({ value }) => formatCurrency(value)
  },
  {
    Header: '%Diff',
    id: 'diff',
    Cell: (data) => {
      const item = data.row.original;

      const synced = item[SETTINGS_REVENUE_SYNC_UPDATED_AMOUNT];

      if (!synced) {
        return '0%';
      }

      const reported = item[SETTINGS_REVENUE_SYNC_REPORTED_AMOUNT];

      const res = Math.round(((1 - (reported / synced)) * 100));

      // eslint-disable-next-line no-compare-neg-zero
      if (res === -0) {
        return '0%';
      }

      return `${((1 - (reported / synced)) * 100).toFixed(0)}%`;
    }
  },
  {
    Header: '',
    id: 'all',
    width: 0
  }
];

const RevenueSyncStatus = () => {
  const isMounted = useIsMountedRef();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setLoading(true);

    (async () => {
      const res = await getAllRevenueReports();

      if (isMounted.current) {
        setItems(res);
        setLoading(false);
      }
    })();
  }, []);

  return (
    <div className="settings-table">
      <h4>Revenue Sync Status</h4>
      {loading ? <TablePanelSpinner/>
        : <TablesManager
          searchFields={[]}
          fields={revenueSyncStatusColumns}
          tableName=''
          withoutSearchHeaders={true}
          items={items}
        />
      }
    </div>
  );
};

export default RevenueSyncStatus;
