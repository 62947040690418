import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { updateEndpointDemandsFormStateAction } from '../../../../../actions/endpointDemands';

import {
  SUBID_BID_MODIFY_FIELD,
  BID_MODIFY_FIELD,
  CLICK_LIMITS_FIELD,
  ENDPOINT_DEMANDS_WIZARD,
  GEOS_FIELD,
  LIMITS_FIELD,
  MAX_BID_FIELD,
  MIN_BID_FIELD,
  OPTIMIZATION_FIELD,
  REV_SHARE_FIELD,
  VALUE_FIELD
} from '../../../constants';

import { useClearArrayError } from '../../../custom-hooks/useClearArrayError';
import { useFormsEffect } from '../../../custom-hooks/useFormsEffect';
import { useFormsOptions } from '../../../custom-hooks/useFormsOptions';
import { useIsValidForm } from '../../../custom-hooks/useIsValidForm';

import {
  filterAllList,
  handleArrayHaveError,
  getOptions, handleArrayErrorMessage
} from '../../../helpers/wizard-helpers';

import {
  endpointDemandsOptimizationSchema,
  validateEndpointDemandsOptimizationData
} from '../../../validators/endpoint-demands/optimization';

import WizardInputWithValidation from '../../shared/WizardInputWithValidation';
import WizardSelectWithValidation, {CREATE_TYPE, SELECT_TYPE} from '../../shared/WizardSelectWithValidation';

import '../../../wizard-base/WizardSharedStyles.scss';

const EndpointDemandsOptimizationForm = ({
  isSubmitted,
  onSubmit,
  setIsValid
}) => {
  const {
    defaultValues,
    countryOptions
  } = useSelector(
    state => ({
      defaultValues: state[ENDPOINT_DEMANDS_WIZARD].formData[OPTIMIZATION_FIELD],
      countryOptions: state.staticList.countryOptions
    })
  );

  const {
    register,
    errors,
    triggerValidation,
    control,
    getValues,
    watch,
    clearError
  } = useForm({
    mode: 'onBlur',
    defaultValues,
    validationSchema: endpointDemandsOptimizationSchema
  });

  useFormsEffect(isSubmitted, triggerValidation, getValues, updateEndpointDemandsFormStateAction, onSubmit, OPTIMIZATION_FIELD);

  const bidModifyArray = useFieldArray({
    control,
    name: BID_MODIFY_FIELD
  });

  const subidBidModifyArray = useFieldArray({
    control,
    name: SUBID_BID_MODIFY_FIELD
  });

  const subidBidModify = subidBidModifyArray.fields;
  const appendSubidBid = subidBidModifyArray.append;
  const removeSubidBid = subidBidModifyArray.remove;

  const bidModify = bidModifyArray.fields;
  const appendBid = bidModifyArray.append;
  const removeBid = bidModifyArray.remove;

  const limitsArray = useFieldArray({
    control,
    name: LIMITS_FIELD
  });

  const limits = limitsArray.fields;
  const appendLimit = limitsArray.append;
  const removeLimit = limitsArray.remove;

  const clickLimitsArray = useFieldArray({
    control,
    name: CLICK_LIMITS_FIELD
  });

  const clickLimits = clickLimitsArray.fields;
  const appendClickLimit = clickLimitsArray.append;
  const removeClickLimit = clickLimitsArray.remove;

  const revSharesArray = useFieldArray({
    control,
    name: REV_SHARE_FIELD
  });

  const revShares = revSharesArray.fields;
  const appendRevShare = revSharesArray.append;
  const removeRevShare = revSharesArray.remove;

  const [limitsCountries, setLimitsCountries] = useState(countryOptions);
  const [clickLimitsCountries, setClickLimitsCountries] = useState(countryOptions);
  const [bidCountries, setBidCountries] = useState(countryOptions);
  const [revShareCountries, setRevShareCountries] = useState(countryOptions);

  const watchLimits = watch(LIMITS_FIELD, defaultValues[LIMITS_FIELD]);
  const watchClickLimits = watch(CLICK_LIMITS_FIELD, defaultValues[CLICK_LIMITS_FIELD]);
  const watchBidModify = watch(BID_MODIFY_FIELD, defaultValues[BID_MODIFY_FIELD]);
  const watchSubidBidModify = watch(SUBID_BID_MODIFY_FIELD, defaultValues[SUBID_BID_MODIFY_FIELD]);
  const watchRevShare = watch(REV_SHARE_FIELD, defaultValues[REV_SHARE_FIELD]);

  const values = getValues({ nest: true });

  if (watchLimits) {
    values[LIMITS_FIELD] = watchLimits.map((limit) => ({
      [GEOS_FIELD]: limit[GEOS_FIELD],
      [VALUE_FIELD]: limit[VALUE_FIELD]
    }));
  } else {
    values[LIMITS_FIELD] = [];
  }

  if (watchClickLimits) {
    values[CLICK_LIMITS_FIELD] = watchClickLimits.map((limit) => ({
      [GEOS_FIELD]: limit[GEOS_FIELD],
      [VALUE_FIELD]: limit[VALUE_FIELD]
    }));
  } else {
    values[CLICK_LIMITS_FIELD] = [];
  }

  if (watchBidModify) {
    values[BID_MODIFY_FIELD] = watchBidModify.map((bid) => ({
      [GEOS_FIELD]: bid[GEOS_FIELD],
      [VALUE_FIELD]: bid[VALUE_FIELD]
    }));
  } else {
    values[BID_MODIFY_FIELD] = [];
  }

  if (watchSubidBidModify) {
    values[SUBID_BID_MODIFY_FIELD] = watchSubidBidModify.map((bid) => ({
      ['subids']: bid['subids'],
      [VALUE_FIELD]: bid[VALUE_FIELD]
    }));
  } else {
    values[SUBID_BID_MODIFY_FIELD] = [];
  }

  if (watchRevShare) {
    values[REV_SHARE_FIELD] = watchRevShare.map((rev) => ({
      [GEOS_FIELD]: rev[GEOS_FIELD],
      [VALUE_FIELD]: rev[VALUE_FIELD]
    }));
  } else {
    values[REV_SHARE_FIELD] = [];
  }

  const isValid = validateEndpointDemandsOptimizationData(Object.keys(values).length ? values : defaultValues);
  useIsValidForm(isValid, setIsValid);

  useFormsOptions(getOptions(watchLimits || []), countryOptions, setLimitsCountries, GEOS_FIELD, true);
  useFormsOptions(getOptions(watchClickLimits || []), countryOptions, setClickLimitsCountries, GEOS_FIELD, true);
  useFormsOptions(getOptions(watchBidModify || []), countryOptions, setBidCountries, GEOS_FIELD, true);
  useFormsOptions(getOptions(watchRevShare || []), countryOptions, setRevShareCountries, GEOS_FIELD, true);

  useClearArrayError(LIMITS_FIELD, watchLimits, clearError);
  useClearArrayError(CLICK_LIMITS_FIELD, watchLimits, clearError);
  useClearArrayError(BID_MODIFY_FIELD, watchBidModify, clearError);
  useClearArrayError(SUBID_BID_MODIFY_FIELD, watchSubidBidModify, clearError);
  useClearArrayError(REV_SHARE_FIELD, watchRevShare, clearError);

  return (
    <form className="step-content">
      <div className="group action-right">
        <h4>Daily Limits</h4>

        <div className="group-item pt10">
          <div className="form-group">
            <div className="header-width-add">
              <label className={errors[LIMITS_FIELD] ? 'error' : ''}>
                Max Requests (/day)
              </label>

              <div className="add-new">
                <p onClick={() => appendLimit({
                  value: 0,
                  geos: []
                })}>
                  <i className="fas fa-plus"/>
                  <span className="add-item">Add new</span>
                </p>
              </div>
            </div>

            <div className="row-list">
              {limits.map((limit, index) => {
                return (
                  <div className="row-item" key={limit.id}>
                    <div className="row">
                      <div className="col-sm-4">
                        <WizardInputWithValidation
                          type='number'
                          error={handleArrayHaveError(errors, LIMITS_FIELD, VALUE_FIELD, index)}
                          errorMessage={handleArrayErrorMessage(errors, LIMITS_FIELD, VALUE_FIELD, index)}
                          name={`${LIMITS_FIELD}[${index}][${VALUE_FIELD}]`}
                          propRef={register()}
                          placeholder='Ex: 1000'
                        />
                      </div>

                      <div className="col-sm-8">
                        <WizardSelectWithValidation
                          control={control}
                          error={handleArrayHaveError(errors, LIMITS_FIELD, GEOS_FIELD, index)}
                          errorMessage={handleArrayErrorMessage(errors, LIMITS_FIELD, GEOS_FIELD, index)}
                          isMulti={true}
                          name={`${LIMITS_FIELD}[${index}][${GEOS_FIELD}]`}
                          options={limitsCountries}
                          selectType={SELECT_TYPE}
                          filterSelected={filterAllList}
                          defaultValue={limit[GEOS_FIELD]}
                        />
                      </div>
                    </div>
                    <span
                      className="action-icon delete-item"
                      onClick={() => removeLimit(index)}
                    >
                      <i className="far fa-trash-alt"/>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="group-item pt10">
          <div className="form-group">
            <div className="header-width-add">
              <label className={errors[CLICK_LIMITS_FIELD] ? 'error' : ''}>
                Max Clicks (/day)
              </label>

              <div className="add-new">
                <p onClick={() => appendClickLimit({
                  value: 0,
                  geos: []
                })}>
                  <i className="fas fa-plus"/>
                  <span className="add-item">Add new</span>
                </p>
              </div>
            </div>

            <div className="row-list">
              {clickLimits.map((click, index) => {
                return (
                  <div className="row-item" key={click.id}>
                    <div className="row">
                      <div className="col-sm-4">
                        <WizardInputWithValidation
                          type='number'
                          error={handleArrayHaveError(errors, CLICK_LIMITS_FIELD, VALUE_FIELD, index)}
                          errorMessage={handleArrayErrorMessage(errors, CLICK_LIMITS_FIELD, VALUE_FIELD, index)}
                          name={`${CLICK_LIMITS_FIELD}[${index}][${VALUE_FIELD}]`}
                          propRef={register()}
                          placeholder='Ex: 1000'
                        />
                      </div>

                      <div className="col-sm-8">
                        <WizardSelectWithValidation
                          control={control}
                          error={handleArrayHaveError(errors, CLICK_LIMITS_FIELD, GEOS_FIELD, index)}
                          errorMessage={handleArrayErrorMessage(errors, CLICK_LIMITS_FIELD, GEOS_FIELD, index)}
                          isMulti={true}
                          name={`${CLICK_LIMITS_FIELD}[${index}][${GEOS_FIELD}]`}
                          options={clickLimitsCountries}
                          selectType={SELECT_TYPE}
                          filterSelected={filterAllList}
                          defaultValue={click[GEOS_FIELD]}
                        />
                      </div>
                    </div>
                    <span
                      className="action-icon delete-item"
                      onClick={() => removeClickLimit(index)}
                    >
                      <i className="far fa-trash-alt"/>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="group action-right">
        <div className="group action-right">
          <h4>Bid Optimization</h4>
          <div className="group-item">
            <div className="row-list">
              <div className="row-item">
                <div className="row">
                  <div className="col-sm-6">
                    <WizardInputWithValidation
                        label="Min Bid"
                        type="number"
                        error={!!errors[MIN_BID_FIELD]}
                        errorMessage={''}
                        name={MIN_BID_FIELD}
                        propRef={register}
                        placeholder="Ex: 10"
                    />
                  </div>

                  <div className="col-sm-6">
                    <WizardInputWithValidation
                        label="Max Bid"
                        type="number"
                        error={!!errors[MAX_BID_FIELD]}
                        errorMessage={''}
                        name={MAX_BID_FIELD}
                        propRef={register}
                        placeholder="Ex: 1,000"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="group-item pt10">
            <div className="form-group">
              <div className="header-width-add">
                <label className={errors[REV_SHARE_FIELD] ? 'error' : ''}>
                  Rev Share
                </label>
                <div className="add-new">
                  <p onClick={() => appendRevShare({
                    value: 0,
                    geos: []
                  })}
                  >
                    <i className="fas fa-plus"/>
                    <span className="add-item">Add new</span>
                  </p>
                </div>
              </div>

              <div className="row-list">
                {revShares.map((revShare, index) => {
                  return (
                      <div className="row-item" key={revShare.id}>
                        <div className="row">
                          <div className="col-sm-4">
                            <WizardInputWithValidation
                                type='number'
                                error={handleArrayHaveError(errors, REV_SHARE_FIELD, VALUE_FIELD, index)}
                                errorMessage={'Value is required'}
                                name={`${REV_SHARE_FIELD}[${index}][${VALUE_FIELD}]`}
                                propRef={register()}
                                placeholder='Ex: 1000'
                            />
                          </div>
                          <div className="col-sm-8">
                            <WizardSelectWithValidation
                                control={control}
                                error={handleArrayHaveError(errors, REV_SHARE_FIELD, GEOS_FIELD, index)}
                                errorMessage={'Country is required'}
                                isMulti={true}
                                name={`${REV_SHARE_FIELD}[${index}][${GEOS_FIELD}]`}
                                options={revShareCountries}
                                selectType={SELECT_TYPE}
                                filterSelected={filterAllList}
                                defaultValue={revShare[GEOS_FIELD]}
                            />
                          </div>
                        </div>
                        {revShares.length > 1 && <span
                            onClick={() => removeRevShare(index)}
                            className="action-icon delete-item"
                        >
                        <i className="far fa-trash-alt"/>
                      </span>}
                      </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="group-item pt10">
            <div className="form-group">
              <div className="header-width-add">
                <label>Bid modify</label>
                <div className="add-new">
                  <p onClick={() => appendBid({
                    value: 0,
                    geos: null
                  })}
                  >
                    <i className="fas fa-plus"/>
                    <span className="add-item">Add new</span>
                  </p>
                </div>
              </div>

              <div className="row-list">
                {bidModify.map((bid, index) => {
                  return (
                      <div className="row-item" key={bid.id}>
                        <div className="row">
                          <div className="col-sm-4">
                            <WizardInputWithValidation
                                type='number'
                                error={handleArrayHaveError(errors, BID_MODIFY_FIELD, VALUE_FIELD, index)}
                                errorMessage={'Value is required'}
                                name={`${BID_MODIFY_FIELD}[${index}][${VALUE_FIELD}]`}
                                propRef={register()}
                                placeholder='Ex: 1000'
                            />
                          </div>
                          <div className="col-sm-8">
                            <WizardSelectWithValidation
                                control={control}
                                error={handleArrayHaveError(errors, BID_MODIFY_FIELD, GEOS_FIELD, index)}
                                errorMessage={'Country is required'}
                                isMulti={true}
                                name={`${BID_MODIFY_FIELD}[${index}][${GEOS_FIELD}]`}
                                options={bidCountries}
                                selectType={SELECT_TYPE}
                                filterSelected={filterAllList}
                                defaultValue={bid[GEOS_FIELD]}
                            />
                          </div>
                        </div>
                        <span
                            onClick={() => removeBid(index)}
                            className="action-icon delete-item"
                        >
                        <i className="far fa-trash-alt"/>
                      </span>
                      </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="group-item pt10">
            <div className="form-group">
              <div className="header-width-add">
                <label>SubId Bid modify</label>
                <div className="add-new">
                  <p onClick={() => appendSubidBid({
                    value: 0,
                    subids: []
                  })}
                  >
                    <i className="fas fa-plus"/>
                    <span className="add-item">Add new</span>
                  </p>
                </div>
              </div>
              <div className="row-list">
                {subidBidModify.map((bid, index) => {
                  return (
                      <div className="row-item" key={bid.id}>
                        <div className="row">
                          <div className="col-sm-4">
                            <WizardInputWithValidation
                                type='number'
                                error={handleArrayHaveError(errors, SUBID_BID_MODIFY_FIELD, VALUE_FIELD, index)}
                                errorMessage={handleArrayErrorMessage(errors, SUBID_BID_MODIFY_FIELD, VALUE_FIELD, index)}
                                name={`${SUBID_BID_MODIFY_FIELD}[${index}][${VALUE_FIELD}]`}
                                propRef={register()}
                                placeholder='Ex: 1.1'
                            />
                          </div>
                          <div className="col-sm-8">
                            <WizardSelectWithValidation
                                control={control}
                                error={handleArrayHaveError(errors, SUBID_BID_MODIFY_FIELD, 'subids', index)}
                                errorMessage={handleArrayErrorMessage(errors, SUBID_BID_MODIFY_FIELD, 'subids', index)}
                                isMulti={true}
                                name={`${SUBID_BID_MODIFY_FIELD}[${index}][${'subids'}]`}
                                options={[]}
                                selectType={CREATE_TYPE}
                                filterSelected={filterAllList}
                                defaultValue={bid['subids']}
                            />
                          </div>
                        </div>
                        <span
                            onClick={() => removeSubidBid(index)}
                            className="action-icon delete-item"
                        >
                          <i className="far fa-trash-alt"/>
                        </span>
                      </div>
                  );
                })}
              </div>
            </div>
          </div>


        </div>
      </div>
    </form>
  );
};

EndpointDemandsOptimizationForm.propTypes = {
  isSubmitted: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setIsValid: PropTypes.func.isRequired
};

export default EndpointDemandsOptimizationForm;
