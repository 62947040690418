import {
  DRAWER_FORM_OPEN,
  DRAWER_FORM_CLOSE
} from '../actions/types';

const initialState = {
  isOpen: false,
  activeForm: '',
  filters: [],
  interval: 'Yesterday',
  start: false,
  end: false
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case DRAWER_FORM_CLOSE:
      return {
        ...state,
        isOpen: !state.isOpen,
        activeForm: ''
      };

    case DRAWER_FORM_OPEN:
      return {
        ...state,
        isOpen: true,
        activeForm: payload
      };

    default:
      return state;
  }
};
