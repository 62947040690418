import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import Moment from 'react-moment';

import {
  DASHBOARD_INTERVAL_CUSTOM,
  DASHBOARD_INTERVAL_LAST_3_MONTHS,
  DASHBOARD_INTERVAL_LAST_7_DAYS,
  DASHBOARD_INTERVAL_LAST_MONTH,
  DASHBOARD_INTERVAL_THIS_MONTH,
  DASHBOARD_INTERVAL_TODAY,
  DASHBOARD_INTERVAL_YESTERDAY,
  REPORTS_INTERVAL_LAST_48_HOURS,
  REPORTS_INTERVAL_LAST_4_HOURS
} from '../../actions/types';

import 'react-datepicker/dist/react-datepicker.css';
import './ReportsDatePicker.scss';

const intervalObject = {
  [REPORTS_INTERVAL_LAST_4_HOURS]: 'Last 4 hours',
  [DASHBOARD_INTERVAL_TODAY]: 'Today',
  [DASHBOARD_INTERVAL_YESTERDAY]: 'Yesterday',
  [REPORTS_INTERVAL_LAST_48_HOURS]: 'Last 48 hours',
  [DASHBOARD_INTERVAL_LAST_7_DAYS]: 'Last 7 days',
  [DASHBOARD_INTERVAL_THIS_MONTH]: 'This Month',
  [DASHBOARD_INTERVAL_LAST_MONTH]: 'Last Month',
  [DASHBOARD_INTERVAL_LAST_3_MONTHS]: 'Last 3 months'
};

const ReportsDatePicker = ({
  interval,
  setInterval,
  fromDate,
  setFromDate,
  toDate,
  setToDate
}) => {
  const divRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const handleClickOutside = e => {
    if (!divRef.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  const handleOpenDropdown = () => setShowDropdown(!showDropdown);

  return (
    <div className="form-group">
      <label>Date</label>
      <div className="reports-date-picker-container top-btn">
        <ul>
          <li className="dropdown dropdown-arrow-top">
            <div className="reports-date-picker" ref={divRef}>
              <div
                className='btn btn-gray dropdown-toggle'
                id='dropdownMenuButton'
                onClick={handleOpenDropdown}
              >
                {interval !== DASHBOARD_INTERVAL_CUSTOM ? (
                  <span>{intervalObject[interval]}</span>
                ) : (
                  <span>
                    <Moment format='YYYY/MM/DD'>
                      {fromDate}
                    </Moment>
                    &nbsp;-&nbsp;
                    <Moment format='YYYY/MM/DD'>
                      {toDate}
                    </Moment>
                  </span>
                )}
                <div>
                  <img src={require('../../assets/images/icons/ico_calendar.svg')} alt=''/>
                  <img className='img-active' src={require('../../assets/images/icons/ico_calendar_o.svg')} alt=''/>
                </div>
              </div>
              <ul
                className={`dashboard-dropdown-menu dropdown-menu ${showDropdown ? 'show' : ''}`}
                aria-labelledby='dropdownMenuButton'
              >
                {Object.keys(intervalObject).map((i, index) => (
                  <li key={index}>
                    <a
                      onClick={() => {
                        setInterval(i);
                        setShowDropdown(false);
                      }}
                      className={`dropdown-item ${interval === i ? 'active' : ''}`}
                    >
                      {intervalObject[i]}
                    </a>
                  </li>
                ))}
                <li className='custom-filter'>
                  <a
                    onClick={() => setInterval(DASHBOARD_INTERVAL_CUSTOM)}
                    className={`dropdown-item ${interval === DASHBOARD_INTERVAL_CUSTOM ? 'active show-box' : ''}`}
                  >
                    Custom
                  </a>
                  <div className='box-custom'>
                    <div className='box-item'>
                      <p className='label'>From</p>
                      <div className='box-date'>
                        <DatePicker
                          dateFormat='dd/MM/yyyy'
                          selected={fromDate}
                          selectsStart
                          startDate={fromDate}
                          endDate={fromDate}
                          onChange={(date) => setFromDate(date)}
                        />
                        <img className="date-picker-icon" src={require('../../assets/images/icons/ico_calendar.svg')} alt=''/>
                      </div>
                    </div>

                    <div className='box-item'>
                      <p className='label'>To</p>
                      <div className='box-date'>
                        <DatePicker
                          dateFormat='dd/MM/yyyy'
                          selected={toDate}
                          selectsEnd
                          startDate={toDate}
                          endDate={toDate}
                          onChange={(date) => setToDate(date)}
                        />
                        <img className="date-picker-icon" src={require('../../assets/images/icons/ico_calendar.svg')} alt=''/>
                      </div>
                    </div>
                    <p className='apply'>
                      <a onClick={() => setShowDropdown(false)} className='btn btn-pink'>
                        Apply
                      </a>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

ReportsDatePicker.propTypes = {
  interval: PropTypes.string.isRequired,
  setInterval: PropTypes.func.isRequired,
  fromDate: PropTypes.object.isRequired,
  setFromDate: PropTypes.func.isRequired,
  toDate: PropTypes.object.isRequired,
  setToDate: PropTypes.func.isRequired
};

export default ReportsDatePicker;
