import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';

import {
  AUTHENTICATION_CONFIRM_PASSWORD_FIELD,
  AUTHENTICATION_EMAIL_FIELD,
  AUTHENTICATION_NAME_FIELD,
  AUTHENTICATION_PASSWORD_FIELD
} from '../../actions/types';

import AuthContext from '../../context/auth/authContext';

import '../../assets/style/login.scss';

import WizardInputWithValidation from '../wizards-manager/wizards/shared/WizardInputWithValidation';

import { registerSchema } from './validators';

const RegisterForm = () => {
  const history = useHistory();
  const { handleRegister, error, clearErrors, isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/dashboard');
    }
  }, [isAuthenticated, history]);

  const defaultValues = {
    [AUTHENTICATION_NAME_FIELD]: '',
    [AUTHENTICATION_EMAIL_FIELD]: '',
    [AUTHENTICATION_PASSWORD_FIELD]: '',
    [AUTHENTICATION_CONFIRM_PASSWORD_FIELD]: ''
  };

  const {
    handleSubmit,
    register,
    errors,
    setError,
    clearError
  } = useForm({
    mode: 'onBlur',
    defaultValues,
    validationSchema: registerSchema
  });

  useEffect(() => {
    if (error) {
      setError(AUTHENTICATION_EMAIL_FIELD, 'exists', 'User with this emails exists.');
    } else {
      clearError(AUTHENTICATION_EMAIL_FIELD);
    }
  }, [error]);

  const onSubmit = ({ name, email, password }) => handleRegister({ name, email, password });

  return (
    <div className="minisite">
      <div className="login">
        <section className="top-header" style={{ height: '90vmin' }}>
          <div className="container">
            <div className="row row-login">
              <div className="col-md-4 offset-md-7 mt-3 mobile-hero-background">
                <Link to="/">
                  <img
                    className="footer-logo mt-5 mb-5"
                    src={require('../../assets/images/logo@2x.png')}
                    alt="DigitalFlare"
                  />
                </Link>

                <h4>Sign Up</h4>
                <p className="p-privacy mb-5">
                  Enter your information to create an account
                </p>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <WizardInputWithValidation
                    type="text"
                    error={!!errors[AUTHENTICATION_NAME_FIELD]}
                    errorMessage={errors[AUTHENTICATION_NAME_FIELD] ? errors[AUTHENTICATION_NAME_FIELD].message : ''}
                    name={AUTHENTICATION_NAME_FIELD}
                    propRef={register}
                    placeholder="Name"
                  />
                  <WizardInputWithValidation
                    type="email"
                    error={!!errors[AUTHENTICATION_EMAIL_FIELD]}
                    errorMessage={errors[AUTHENTICATION_EMAIL_FIELD] ? errors[AUTHENTICATION_EMAIL_FIELD].message : ''}
                    name={AUTHENTICATION_EMAIL_FIELD}
                    propRef={register}
                    placeholder="Email"
                  />
                  <WizardInputWithValidation
                    type="password"
                    error={!!errors[AUTHENTICATION_PASSWORD_FIELD]}
                    errorMessage={errors[AUTHENTICATION_PASSWORD_FIELD] ? errors[AUTHENTICATION_PASSWORD_FIELD].message : ''}
                    name={AUTHENTICATION_PASSWORD_FIELD}
                    propRef={register}
                    placeholder="Password"
                  />
                  <WizardInputWithValidation
                    type="password"
                    error={!!errors[AUTHENTICATION_CONFIRM_PASSWORD_FIELD]}
                    errorMessage={errors[AUTHENTICATION_CONFIRM_PASSWORD_FIELD] ? errors[AUTHENTICATION_CONFIRM_PASSWORD_FIELD].message : ''}
                    name={AUTHENTICATION_CONFIRM_PASSWORD_FIELD}
                    propRef={register}
                    placeholder="Confirm Password"
                  />

                  <button
                    className="btn button btn-primary btn-log mt-4"
                  >
                    Create account
                  </button>
                </form>

                <p className="sm-font mt-5">
                  Already have an account? <Link to="/login">Log in</Link>
                </p>

                <div className="bot-footer mt-5 mb-2">
                  <p className="sm-font">
                    Copyright &copy; {new Date().getFullYear()} DigitalFlare.
                    All Rights Reserved  <a href="https://srcflare.com/terms.html">Terms</a>&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;<a href="https://srcflare.com/privacy.html">Privacy</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default RegisterForm;
