import axios from 'axios';

import { AXIOS_CONFIG } from '../constant';

export const fetchAllRules = () => axios.get('/api/rules');

export const fetchRuleById = (id) => axios.get(`/api/rules/${id}`);

export const saveRule = (data) => axios.post('/api/rules', data, AXIOS_CONFIG);

export const modifyRule = (id, data) => axios.patch(`/api/rules/${id}`, data, AXIOS_CONFIG);

export const removeRule = (id) => axios.delete(`/api/rules/${id}`);

export const runRuleById = (id) => axios.get(`api/rules/run/${id}`);
