import * as yup from 'yup';

import {
  RULES_ACTION_DATA_FIELD,
  RULES_ACTION_FIELD,
  RULES_ANALYZE_INTERVAL_FIELD,
  RULES_DIMENSION_FILTERS_FIELD,
  RULES_FREQUENCY_DAYS_FIELD,
  RULES_FREQUENCY_INTERVAL_FIELD,
  RULES_METRIC_FILTERS_FIELD,
  RULES_OUTCOME_FIELD,
  RULES_SELECTED_FIELD,
  RULES_TAGS_FIELD
} from '../../../actions/types';

import { dimensionFiltersSchema, metricFiltersSchema } from '../../reports/validators';
import { LABEL_FIELD, TITLE_FIELD, VALUE_FIELD } from '../../wizards-manager/constants';
import { emptyStringToNull } from '../../wizards-manager/validators/shared/optimization';
import {
  multiSelectSchema,
  selectSchema
} from '../../wizards-manager/validators/shared/shared-schemas';

const frequencyDatesSchema = yup.array().of(yup.object().shape({
  [LABEL_FIELD]: yup.string().required('Label is required'),
  [VALUE_FIELD]: yup.string().required('Value is required'),
  [RULES_SELECTED_FIELD]: yup.boolean().required()
})).required('At least 1 element is required').min(1, 'At least 1 element is required');

export const rulesSchema = yup.object().shape({
  [RULES_DIMENSION_FILTERS_FIELD]: dimensionFiltersSchema,
  [RULES_METRIC_FILTERS_FIELD]: metricFiltersSchema,
  [TITLE_FIELD]: yup.string().required('Title is required'),
  [RULES_TAGS_FIELD]: multiSelectSchema,
  [RULES_ANALYZE_INTERVAL_FIELD]: selectSchema.required('Analyze Data from is required'),
  [RULES_ACTION_FIELD]: selectSchema.required('Action is required').nullable(),
  [RULES_ACTION_DATA_FIELD]: yup.number().required('Value is required').transform(emptyStringToNull).nullable(),
  [RULES_OUTCOME_FIELD]: yup.string().required(),
  [RULES_FREQUENCY_DAYS_FIELD]: frequencyDatesSchema,
  [RULES_FREQUENCY_INTERVAL_FIELD]: selectSchema.required('Frequency interval is required')
});
