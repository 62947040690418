import * as yup from 'yup';

import { LABEL_FIELD, VALUE_FIELD } from '../../constants';

export const selectSchema = yup.object().shape({
  [LABEL_FIELD]: yup.string().required('Label is required'),
  [VALUE_FIELD]: yup.string().required('Value is required')
});

export const multiSelectSchema = yup.array().of(selectSchema);

export const requiredMultiSelectSchema = yup.array().of(selectSchema).required('At least 1 element is required').min(1, 'At least 1 element is required');
