import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';

import { AUTHENTICATION_EMAIL_FIELD, AUTHENTICATION_PASSWORD_FIELD } from '../../actions/types';
import AuthContext from '../../context/auth/authContext';

import '../../assets/style/login.scss';

import WizardInputWithValidation from '../wizards-manager/wizards/shared/WizardInputWithValidation';

import { loginSchema } from './validators';

const LoginForm = () => {
  const { login, error, clearErrors, isAuthenticated } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    clearErrors();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/dashboard');
    }
  }, [isAuthenticated, history]);

  const defaultValues = {
    [AUTHENTICATION_EMAIL_FIELD]: '',
    [AUTHENTICATION_PASSWORD_FIELD]: ''
  };

  const {
    handleSubmit,
    register,
    errors
  } = useForm({
    mode: 'onBlur',
    defaultValues,
    validationSchema: loginSchema
  });

  const onSubmit = ({ email, password }) => {
    login({ email, password });
  };

  return (
    <div className="minisite">
      <div className="login">
        <div className="container">
          <div className="row row-login">
            <div className="col-md-4 offset-md-7 mt-3 mobile-hero-background">
              <Link to="/">
                <img
                  className="footer-logo mt-5 mb-5"
                  src={require('../../assets/images/logo@2x.png')}
                  alt="DigitalFlare"
                />
              </Link>

              <h4 className="mt-5">Welcome back!</h4>
              <p className="p-privacy mb-5">Enter your credentials to log in</p>

              {error && (
                <>
                  <p style={{ color: 'red' }}>Invalid Credentials</p>
                  <p className="sm-font">
                    <Link to="/forgot-password">Forgotten password? &gt;</Link>
                  </p>
                </>
              )}

              <form onSubmit={handleSubmit(onSubmit)}>
                <WizardInputWithValidation
                  type="email"
                  error={!!errors[AUTHENTICATION_EMAIL_FIELD]}
                  errorMessage={errors[AUTHENTICATION_EMAIL_FIELD] ? errors[AUTHENTICATION_EMAIL_FIELD].message : ''}
                  name={AUTHENTICATION_EMAIL_FIELD}
                  propRef={register}
                />
                <WizardInputWithValidation
                  type="password"
                  error={!!errors[AUTHENTICATION_PASSWORD_FIELD]}
                  errorMessage={errors[AUTHENTICATION_PASSWORD_FIELD] ? errors[AUTHENTICATION_PASSWORD_FIELD].message : ''}
                  name={AUTHENTICATION_PASSWORD_FIELD}
                  propRef={register}
                />

                <button className="btn button btn-primary btn-log mt-4">
                  Log in
                </button>
              </form>

              <p className="sm-font mt-5">
                Don’t have an account? <Link to="/register">Sign up &gt;</Link>
              </p>

              <div>
                <div className="col text-center sm-font mb-2 mt-5">
                  Copyright &copy; {new Date().getFullYear()} DigitalFlare. All
                  Rights Reserved <a href="https://srcflare.com/terms.html">Terms</a>&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;<a href="https://srcflare.com/privacy.html">Privacy</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
