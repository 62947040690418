import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  deleteRuleAction,
  duplicateRuleAction,
  editRuleAction,
  editRuleFromTableAction,
  fetchRulesAction,
  runNowRuleAction,
  updateRuleStatus
} from '../../actions/rules';

import {
  RULES_ACTION_FIELD,
  RULES_ACTIVE_FIELD,
  RULES_KEY_FIELD,
  RULES_LAST_RUN_FIELD,
  RULES_LAST_STATUS_FIELD,
  RULES_METRIC_FILTERS_FIELD,
  RULES_OPERATION_FIELD,
  RULES_TAGS_FIELD
} from '../../actions/types';

import { useTablesEffect } from '../tables-manager/custom-hooks/useTablesEffect';
import { titleColumn } from '../tables-manager/helpers';
import TablePanelSpinner from '../tables-manager/shared/TablePanelSpinner';
import TableTagsCell from '../tables-manager/shared/TableTagsCell';
import TableColumnsActions from '../tables-manager/table-base/TableColumnsActions';
import TablesManager from '../tables-manager/TablesManager';

import { ACTIVE_FIELD, ID_FIELD } from '../wizards-manager/constants';
import { rulesTempActions } from './helpers';

const getRulesColumns = (staticList, dispatch) => [
  {
    Header: 'Rule',
    accessor: ID_FIELD,
    width: 150,
    Cell: titleColumn(editRuleFromTableAction)
  },
  {
    Header: 'Active',
    accessor: RULES_ACTIVE_FIELD,
    width: 55,
    Cell: (data) => {
      const rule = data.row.original;

      const handleActivating = async (id, active) => {
        await updateRuleStatus(id, active)(dispatch);
      };

      return (
        <div className="switch-list">
          <label>
            <input
              type="checkbox"
              onChange={e => handleActivating(rule._id, e.target.checked)}
              checked={rule[ACTIVE_FIELD]}
            />
          </label>
        </div>
      );
    }
  },
  {
    Header: 'Action',
    accessor: RULES_ACTION_FIELD,
    width: 110,
    Cell: ({ value }) => {
      const action = rulesTempActions.find(a => a.value === value);

      return action ? action.label : value;
    }
  },
  {
    Header: 'Tags',
    accessor: RULES_TAGS_FIELD,
    minWidth: 110,
    Cell: TableTagsCell
  },
  {
    Header: 'Rule',
    accessor: RULES_METRIC_FILTERS_FIELD,
    width: 200,
    Cell: ({ value }) => value.map((v, index) => (
      <div key={index}>{`${v[RULES_KEY_FIELD].label} ${v[RULES_OPERATION_FIELD].label} ${v.value}`}</div>
    ))
  },
  {
    Header: 'Last Run',
    accessor: RULES_LAST_RUN_FIELD,
    width: 100,
    Cell: ({ value }) => {
      if (value) {
        return moment(value).format('DD/MM hh:mm');
      }

      return 'Never';
    }
  },
  {
    Header: 'Run Status',
    width: 70,
    accessor: RULES_LAST_STATUS_FIELD,
    // eslint-disable-next-line react/prop-types
    Cell: ({ value }) => {
      if (value === 'success') {
        return (
          <i style={{
            color: '#00b54a',
            fontSize: 18
          }} className="far fa-check-circle"/>
        );
      }

      if (value === 'failed') {
        return (
          <i style={{
            color: '#FF6984',
            fontSize: 18
          }} className="far fa-times-circle"/>
        );
      }

      return (
        <i style={{
          color: '#00b54a',
          fontSize: 18
        }} className="far fa-check-circle"/>
      );
    }
  },
  {
    Header: 'Actions',
    id: 'actions',
    width: 50,
    Cell: (data) => {
      const rule = data.row.original;

      return (
        <TableColumnsActions
          editItem={editRuleAction}
          duplicateItem={duplicateRuleAction}
          deleteItem={deleteRuleAction}
          data={rule}
          rule={true}
          runNow={runNowRuleAction}
        />
      );
    }
  },
  {
    Header: '',
    id: 'all',
    width: 0
  }
];

const RulesTable = () => {
  const dispatch = useDispatch();

  const {
    rules,
    loading,
    staticList
  } = useSelector(
    state => ({
      rules: state.rules.rules,
      loading: state.rules.loading,
      staticList: state.staticList
    })
  );

  useTablesEffect(fetchRulesAction, '');

  const rulesColumns = getRulesColumns(staticList, dispatch);

  return (
    <>
      {loading ? <TablePanelSpinner/>
        : <TablesManager
          searchFields={[]}
          tableName="Rules"
          fields={rulesColumns}
          items={rules}
          hiddenColumns={[]}
        />
      }
    </>
  );
};

export default RulesTable;
