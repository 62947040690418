import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TableConfirmation from '../shared/TableConfirmation';
import TableColumnsInfo from './TableColumnsInfo';

import './TableColumnsActions.scss';

export const TableColumnsActions = ({
  data,
  editItem,
  duplicateItem,
  deleteItem,
  endpoint = false,
  rule = false,
  runNow
}) => {
  const dispatch = useDispatch();

  const {
    staticList
  } = useSelector(state => ({
    staticList: state.staticList
  }));

  const [openInfo, setOpenInfo] = useState(false);
  const actionsRef = useRef(null);
  const [open, setOpen] = useState(false);

  const deleteRef = useRef(null);
  const [showDelete, setShowDelete] = useState(false);

  const runRef = useRef(null);
  const [showRun, setShowRun] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideDelete);
    document.addEventListener('mousedown', handleClickOutsideActions);
    document.addEventListener('mousedown', handleClickOutsideRun);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDelete);
      document.removeEventListener('mousedown', handleClickOutsideActions);
      document.removeEventListener('mousedown', handleClickOutsideRun);
    };
  });

  const handleClickOutsideDelete = e => {
    if (deleteRef && deleteRef.current) {
      if (!deleteRef.current.contains(e.target)) {
        setShowDelete(false);
      }
    }
  };

  const handleClickOutsideActions = e => {
    if (actionsRef && actionsRef.current) {
      if (!actionsRef.current.contains(e.target)) {
        setShowDelete(false);
        setShowRun(false);
        setOpen(false);
      }
    }
  };

  const handleClickOutsideRun = e => {
    if (runRef && runRef.current) {
      if (!runRef.current.contains(e.target)) {
        setShowRun(false);
      }
    }
  };

  return (
    <div className="table-columns-action-box">
      <div onClick={() => setOpen(state => !state)} className="table-action-trigger">
        <img style={{ height: 21, width: 21 }} src={require('../../../assets/images/icons/kebab_menu.svg')} alt=''/>
        <img style={{ height: 21, width: 21 }} className='img-active'
          src={require('../../../assets/images/icons/kebab_menu_o.svg')} alt=''/>
      </div>

      {open && (
        <div ref={actionsRef} className="table-actions-box">
          {rule && (
            <div ref={runRef} className="table-action-trigger the-action">
              <img onClick={() => setShowRun(state => !state)} style={{ height: 21, width: 21 }}
                src={require('../../../assets/images/icons/play_button.svg')}
                alt=''/>
              <img onClick={() => setShowRun(state => !state)} style={{ height: 21, width: 21 }} className='img-active'
                src={require('../../../assets/images/icons/play_button_o.svg')} alt=''/>
              <span onClick={() => setShowRun(state => !state)}>Run now</span>
              {showRun && (
                <TableConfirmation
                  onDelete={() => {
                    setShowRun(false);
                    setOpen(false);
                  }}
                  onSubmit={runNow}
                  handleCancel={() => setShowRun(false)}
                  title='Are you sure to run now?'
                  data={data._id}
                />
              )}
            </div>
          )}

          <div
            className="table-action-trigger the-action"
            onClick={() => editItem(data, staticList)(dispatch)}
          >
            <img src={require('../../../assets/images/icons/ico_edit.svg')} alt=""/>
            <img className='img-active' src={require('../../../assets/images/icons/ico_edit_o.svg')} alt=''/>
            <span>Edit</span>
          </div>

          <div
            className="table-action-trigger the-action"
            onClick={() => duplicateItem(data, staticList)(dispatch)}
          >
            <img src={require('../../../assets/images/icons/ico_duplicate.svg')} alt=""/>
            <img className='img-active' src={require('../../../assets/images/icons/ico_duplicate_o.svg')} alt=''/>
            <span>Duplicate</span>
          </div>

          <div ref={deleteRef} className="table-action-trigger the-action">
            <img onClick={() => setShowDelete(state => !state)}
              src={require('../../../assets/images/icons/ico_delete.svg')} alt=''/>
            <img onClick={() => setShowDelete(state => !state)} className='img-active'
              src={require('../../../assets/images/icons/ico_delete_o.svg')} alt=''/>
            <span onClick={() => setShowDelete(state => !state)}>Delete</span>

            {showDelete && (
              <TableConfirmation
                onSubmit={deleteItem}
                onDelete={() => {
                  setOpen(false);
                  setShowDelete(false);
                }}
                handleCancel={() => setShowDelete(false)}
                title='Are you sure to delete?'
                data={data._id}
              />
            )}
          </div>

          {endpoint && (
            <div
              className="table-action-trigger the-action"
              onClick={() => setOpenInfo(true)}
            >
              <img className="info-img" src={require('../../../assets/images/icons/info_square.svg')} alt=""/>
              <img className='info-img img-active' src={require('../../../assets/images/icons/info_square_o.svg')}
                alt=''/>
              <span>Info</span>
            </div>
          )}

          {openInfo && (
            <TableColumnsInfo name={data.title} id={data._id} auth={data.auth} handleClose={() => setOpenInfo(false)}/>
          )}
        </div>
      )}
    </div>
  );
};

TableColumnsActions.propTypes = {
  data: PropTypes.object.isRequired,
  editItem: PropTypes.func.isRequired,
  duplicateItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  endpoint: PropTypes.bool,
  rule: PropTypes.bool,
  runNow: PropTypes.func
};

export default TableColumnsActions;
