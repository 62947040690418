import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { updateEndpointDemandsFormStateAction } from '../../../../../actions/endpointDemands';

import {
  BROWSERS_FIELD,
  DEVICE_TYPES_FIELD,
  OS_FIELD,
  LIST_FIELD,
  SELECTED_FIELD,
  GEOS_FIELD,
  INCLUDE_FIELD,
  VERSIONS_FIELD,
  CTYPES_FIELD,
  CARRIERS_FIELD,
  VALUE_FIELD,
  TARGETING_FIELD,
  ENDPOINT_DEMANDS_WIZARD,
  CUSTOM_TAGS_FIELD
} from '../../../constants';

import { useClearArrayError } from '../../../custom-hooks/useClearArrayError';
import { useFormsEffect } from '../../../custom-hooks/useFormsEffect';
import { useFormsOptions } from '../../../custom-hooks/useFormsOptions';
import { useIsValidForm } from '../../../custom-hooks/useIsValidForm';
import { deviceTypesOptions } from '../../../helpers/staticData';

import {
  filterAllList,
  handleArrayHaveError,
  handleNestedHaveError,
  getOptions
} from '../../../helpers/wizard-helpers';
import {
  endpointDemandsTargetingSchema,
  validateEndpointDemandsTargetingData
} from '../../../validators/endpoint-demands/targeting';

import WizardCustomCheckbox from '../../shared/WizardCustomCheckbox';
import WizardSelectWithValidation, { CREATE_TYPE, SELECT_TYPE } from '../../shared/WizardSelectWithValidation';

import { addAllObject, filterPossibleOptions, validatePossibleOptions } from './helpers/possible-options';

import '../../../wizard-base/WizardSharedStyles.scss';

const getVersions = (list) =>
  list.reduce((acc, l) => {
    acc[l[VALUE_FIELD]] = l[VERSIONS_FIELD];

    return acc;
  }, {});

const getVersionsOptions = (data, index, options) => {
  if (data) {
    if (data[index]) {
      const selected = data[index][SELECTED_FIELD];

      if (selected) {
        return options[selected[VALUE_FIELD]];
      }
    }
  }

  return [];
};

const EndpointDemandsTargetingForm = ({
  isSubmitted,
  onSubmit,
  setIsValid
}) => {
  const {
    defaultValues,
    connectionTypeOptions,
    carrierOptions,
    countryOptions,
    browserOptionsState,
    osOptionsState,
    possibleOptions
  } = useSelector(
    state => ({
      defaultValues: state[ENDPOINT_DEMANDS_WIZARD].formData[TARGETING_FIELD],
      countryOptions: state.staticList.countryOptions,
      browserOptionsState: state.staticList.browserOptions,
      connectionTypeOptions: state.staticList.connectionTypeOptions,
      carrierOptions: state.staticList.carrierOptions,
      osOptionsState: state.staticList.osOptions,
      possibleOptions: state.endpointDemand.possibleOptions.targeting
    })
  );

  const {
    register,
    errors,
    triggerValidation,
    control,
    getValues,
    setValue,
    watch,
    clearError
  } = useForm({
    mode: 'onBlur',
    defaultValues,
    validationSchema: endpointDemandsTargetingSchema
  });

  useFormsEffect(isSubmitted, triggerValidation, getValues, updateEndpointDemandsFormStateAction, onSubmit, TARGETING_FIELD);

  const watchValues = watch({ nest: true }, defaultValues);
  const isValid = validateEndpointDemandsTargetingData(Object.keys(watchValues).length ? watchValues : defaultValues);
  useIsValidForm(isValid, setIsValid);

  const browsersArray = useFieldArray({
    control,
    name: `${BROWSERS_FIELD}[${LIST_FIELD}]`
  });

  const browsers = browsersArray.fields;
  const addBrowser = browsersArray.append;
  const removeBrowser = browsersArray.remove;

  const [browserOptions, setBrowserOptions] = useState([]);

  useFormsOptions(getOptions(watchValues[BROWSERS_FIELD][LIST_FIELD] || []), browserOptionsState, setBrowserOptions, SELECTED_FIELD, false);

  const osArray = useFieldArray({
    control,
    name: `${OS_FIELD}[${LIST_FIELD}]`
  });

  const oses = osArray.fields;
  const addOs = osArray.append;
  const removeOs = osArray.remove;

  const [osesOptions, setOsesOptions] = useState([]);

  useFormsOptions(getOptions(watchValues[OS_FIELD][LIST_FIELD] || []), osOptionsState, setOsesOptions, SELECTED_FIELD, false);

  const browsersVersions = getVersions(browserOptionsState);
  const osesVersions = getVersions(osOptionsState);

  useClearArrayError(BROWSERS_FIELD, watchValues[BROWSERS_FIELD][LIST_FIELD], clearError, LIST_FIELD);
  useClearArrayError(OS_FIELD, watchValues[OS_FIELD][LIST_FIELD], clearError, LIST_FIELD);

  const handleChangeInclude = (field, value) => setValue(field, value);

  const handleVersions = (field, index) => {
    switch (field) {
      case BROWSERS_FIELD:
        return getVersionsOptions(watchValues[field][LIST_FIELD], index, browsersVersions);

      case OS_FIELD:
        return getVersionsOptions(watchValues[field][LIST_FIELD], index, osesVersions);
    }
  };

  return (
    <form className="step-content">
      <div className="group action-right">
        <div className="group-item">
          <div className="form-group">
            <div className="mb5 gray">
              <WizardCustomCheckbox
                propRef={register}
                label="Country List"
                defaultValue={defaultValues[GEOS_FIELD][INCLUDE_FIELD]}
                name={`${GEOS_FIELD}[${INCLUDE_FIELD}]`}
                changeChecked={handleChangeInclude}
              />
            </div>

            <WizardSelectWithValidation
              control={control}
              error={handleNestedHaveError(errors, GEOS_FIELD, LIST_FIELD)}
              errorMessage={'Country is required'}
              isMulti={true}
              name={`${GEOS_FIELD}[${LIST_FIELD}]`}
              options={addAllObject(filterPossibleOptions(countryOptions, possibleOptions[GEOS_FIELD]))}
              rules={{ validate: validatePossibleOptions(possibleOptions[GEOS_FIELD]) }}
              selectType={SELECT_TYPE}
              filterSelected={filterAllList}
              defaultValue={defaultValues[GEOS_FIELD][LIST_FIELD]}
            />
          </div>
        </div>

        <div className="group-item">
          <div className="form-group">
            <div className="mb5 gray">
              <WizardCustomCheckbox
                propRef={register}
                label="Platform"
                defaultValue={defaultValues[DEVICE_TYPES_FIELD][INCLUDE_FIELD]}
                name={`${DEVICE_TYPES_FIELD}[${INCLUDE_FIELD}]`}
                changeChecked={handleChangeInclude}
              />
            </div>

            <WizardSelectWithValidation
              control={control}
              error={handleNestedHaveError(errors, DEVICE_TYPES_FIELD, LIST_FIELD)}
              isMulti={true}
              errorMessage={''}
              name={`${DEVICE_TYPES_FIELD}[${LIST_FIELD}]`}
              options={deviceTypesOptions}
              selectType={SELECT_TYPE}
              filterSelected={filterAllList}
              defaultValue={defaultValues[DEVICE_TYPES_FIELD][LIST_FIELD]}
            />
          </div>
        </div>

        <div className="group-item">
          <div className="row-list">
            <div className="row-item">
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-group">
                    <div className="mb5 gray">
                      <WizardCustomCheckbox
                        propRef={register}
                        label="Browsers"
                        defaultValue={defaultValues[BROWSERS_FIELD][INCLUDE_FIELD]}
                        name={`${BROWSERS_FIELD}[${INCLUDE_FIELD}]`}
                        changeChecked={handleChangeInclude}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-8">
                  <div className="form-group add-new-form-group no-label">
                    <div className="add-new">
                      <p onClick={() => addBrowser({
                        [SELECTED_FIELD]: null,
                        [VERSIONS_FIELD]: []
                      })}>
                        <i className="fas fa-plus"/>
                        <span className="add-item">Add new</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {browsers.map((item, index) => {
              return (
                <div className="row-item" key={item.id}>
                  <div className="row">
                    <div className="col-sm-4">
                      <WizardSelectWithValidation
                        control={control}
                        error={handleArrayHaveError(errors, BROWSERS_FIELD, SELECTED_FIELD, index, LIST_FIELD)}
                        errorMessage={'Browser is required'}
                        isMulti={false}
                        name={`${BROWSERS_FIELD}[${LIST_FIELD}][${index}][${SELECTED_FIELD}]`}
                        options={browserOptions}
                        selectType={SELECT_TYPE}
                      />
                    </div>
                    <div className="col-sm-8">
                      <WizardSelectWithValidation
                        control={control}
                        error={handleArrayHaveError(errors, BROWSERS_FIELD, VERSIONS_FIELD, index, LIST_FIELD)}
                        errorMessage={'Version is required'}
                        isMulti={true}
                        name={`${BROWSERS_FIELD}[${LIST_FIELD}][${index}][${VERSIONS_FIELD}]`}
                        options={handleVersions(BROWSERS_FIELD, index)}
                        selectType={SELECT_TYPE}
                        filterSelected={filterAllList}
                      />
                    </div>
                  </div>

                  <span
                    className="action-icon delete-item"
                    onClick={() => removeBrowser(index)}
                  >
                    <i className="far fa-trash-alt"/>
                  </span>
                </div>
              );
            })}
          </div>
        </div>

        <div className="group-item">
          <div className="row-list">
            <div className="row-item">
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-group">
                    <div className="mb5 gray">
                      <WizardCustomCheckbox
                        propRef={register}
                        label="OS"
                        defaultValue={defaultValues[OS_FIELD][INCLUDE_FIELD]}
                        name={`${OS_FIELD}[${INCLUDE_FIELD}]`}
                        changeChecked={handleChangeInclude}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-8">
                  <div className="form-group add-new-form-group no-label">
                    <div className="add-new">
                      <p onClick={() => addOs({
                        [SELECTED_FIELD]: null,
                        [VERSIONS_FIELD]: []
                      })}>
                        <i className="fas fa-plus"/>
                        <span className="add-item">Add new</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {oses.map((item, index) => {
              return (
                <div className="row-item" key={item.id}>
                  <div className="row">
                    <div className="col-sm-4">
                      <WizardSelectWithValidation
                        control={control}
                        error={handleArrayHaveError(errors, OS_FIELD, SELECTED_FIELD, index, LIST_FIELD)}
                        errorMessage={'OS is required'}
                        isMulti={false}
                        name={`${OS_FIELD}[${LIST_FIELD}][${index}][${SELECTED_FIELD}]`}
                        options={osesOptions}
                        selectType={SELECT_TYPE}
                      />
                    </div>
                    <div className="col-sm-8">
                      <WizardSelectWithValidation
                        control={control}
                        error={handleArrayHaveError(errors, OS_FIELD, VERSIONS_FIELD, index, LIST_FIELD)}
                        errorMessage={'Version is required'}
                        isMulti={true}
                        name={`${OS_FIELD}[${LIST_FIELD}][${index}][${VERSIONS_FIELD}]`}
                        options={handleVersions(OS_FIELD, index)}
                        selectType={SELECT_TYPE}
                        filterSelected={filterAllList}
                      />
                    </div>
                  </div>

                  <span
                    className="action-icon delete-item"
                    onClick={() => removeOs(index)}
                  >
                    <i className="far fa-trash-alt"/>
                  </span>
                </div>
              );
            })}
          </div>
        </div>

        <div className="group-item">
          <div className="form-group">
            <div className="mb5 gray">
              <WizardCustomCheckbox
                propRef={register}
                label="Custom tags"
                defaultValue={defaultValues[CUSTOM_TAGS_FIELD][INCLUDE_FIELD]}
                name={`${CUSTOM_TAGS_FIELD}[${INCLUDE_FIELD}]`}
                changeChecked={handleChangeInclude}
              />
            </div>

            <WizardSelectWithValidation
              control={control}
              error={handleNestedHaveError(errors, CUSTOM_TAGS_FIELD, LIST_FIELD)}
              errorMessage=''
              placeholder='#mainstream,...'
              isMulti={true}
              name={`${CUSTOM_TAGS_FIELD}[${LIST_FIELD}]`}
              options={[]}
              selectType={CREATE_TYPE}
              defaultValue={defaultValues[CUSTOM_TAGS_FIELD][LIST_FIELD]}
            />
          </div>
        </div>

        <div className="group-item">
          <div className="form-group">
            <div className="mb5 gray">
              <WizardCustomCheckbox
                propRef={register}
                label="Connection Type"
                defaultValue={defaultValues[CTYPES_FIELD][INCLUDE_FIELD]}
                name={`${CTYPES_FIELD}[${INCLUDE_FIELD}]`}
                changeChecked={handleChangeInclude}
              />
            </div>

            <WizardSelectWithValidation
              control={control}
              error={handleNestedHaveError(errors, CTYPES_FIELD, LIST_FIELD)}
              errorMessage={''}
              isMulti={true}
              name={`${CTYPES_FIELD}[${LIST_FIELD}]`}
              options={filterPossibleOptions(connectionTypeOptions, possibleOptions[CTYPES_FIELD])}
              rules={{ validate: validatePossibleOptions(possibleOptions[CTYPES_FIELD]) }}
              selectType={SELECT_TYPE}
              filterSelected={filterAllList}
            />
          </div>
        </div>

        <div className="group-item">
          <div className="form-group">
            <div className="mb5 gray">
              <WizardCustomCheckbox
                propRef={register}
                label="Mobile Carrier"
                defaultValue={defaultValues[CARRIERS_FIELD][INCLUDE_FIELD]}
                name={`${CARRIERS_FIELD}[${INCLUDE_FIELD}]`}
                changeChecked={handleChangeInclude}
              />
            </div>

            <WizardSelectWithValidation
              control={control}
              error={handleNestedHaveError(errors, CARRIERS_FIELD, LIST_FIELD)}
              errorMessage={''}
              isMulti={true}
              name={`${CARRIERS_FIELD}[${LIST_FIELD}]`}
              options={filterPossibleOptions(carrierOptions, possibleOptions[CARRIERS_FIELD])}
              rules={{ validate: validatePossibleOptions(possibleOptions[CARRIERS_FIELD]) }}
              selectType={SELECT_TYPE}
              filterSelected={filterAllList}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

EndpointDemandsTargetingForm.propTypes = {
  isSubmitted: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setIsValid: PropTypes.func.isRequired
};

export default EndpointDemandsTargetingForm;
