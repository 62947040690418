import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import {
  SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD,
  SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD,
  SETTINGS_MANUAL_REVENUE_STATUS_FIELD,
  SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD
} from '../../actions/types';
import TableConfirmation from '../tables-manager/shared/TableConfirmation';

import '../tables-manager/table-base/TableColumnsActions.scss';
import './SettingsActionCell.scss';

export const SettingsActionCell = ({
  row,
  column,
  updateDataFromCell,
  updateDataFieldFromCell,
  deleteItem,
  updateItem,
  calculateManualRevenueReport
}) => {
  const actionsRef = useRef(null);
  const [open, setOpen] = useState(false);

  const deleteRef = useRef(null);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideDelete);
    document.addEventListener('mousedown', handleClickOutsideActions);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDelete);
      document.removeEventListener('mousedown', handleClickOutsideActions);
    };
  });

  const handleClickOutsideDelete = e => {
    if (deleteRef && deleteRef.current) {
      if (!deleteRef.current.contains(e.target)) {
        setShowDelete(false);
      }
    }
  };

  const handleClickOutsideActions = e => {
    if (actionsRef && actionsRef.current) {
      if (!actionsRef.current.contains(e.target)) {
        setShowDelete(false);
        setOpen(false);
      }
    }
  };

  const [editing, setEditing] = useState(false);

  const handleEditItem = useCallback(() => {
    row.setState((s) => ({
      ...s,
      editing: true
    }));

    setEditing(true);
    setOpen(false);
  }, []);

  const handleCancel = useCallback(() => {
    updateDataFromCell(row.index, column.id, {
      [SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD]: row.state[SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD],
      [SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD]: row.state[SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD],
      [SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD]: row.state[SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD]
    });

    row.setState((s) => ({
      ...s,
      editing: false
    }));

    setEditing(false);
  }, [row.state, row.index, column.id]);

  const handleUpdateItem = useCallback(async () => {
    const data = {
      [SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD]: row.original[SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD],
      [SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD]: row.original[SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD],
      [SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD]: row.original[SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD]
    };

    if (!data[SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD] & data[SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD] !== 0 ||
      !data[SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD] & data[SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD] !== 0) {
      return toast.error('Clicks count and Revenue are required', {
        position: toast.POSITION.BOTTOM_CENTER
      });
    }

    const res = await updateItem(row.original._id, data);

    if (res) {
      updateDataFromCell(row.index, column.id, res);

      row.setState((s) => ({
        ...s,
        ...res,
        editing: false
      }));

      setEditing(false);
    } else {
      // TODO Did we need to revert changes and close editing mode?
      const oldData = {
        [SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD]: row.state[SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD],
        [SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD]: row.state[SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD],
        [SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD]: row.state[SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD]
      };

      updateDataFromCell(row.index, column.id, oldData);
    }
  }, [row, column.id]);

  const handleCalculate = useCallback(async () => {
    const res = await calculateManualRevenueReport(row.original._id);

    if (res) {
      updateDataFieldFromCell(row.index, SETTINGS_MANUAL_REVENUE_STATUS_FIELD, 'processing');
    } else {
      //  TODO What happening here?
    }

    setOpen(false);
  }, [row.original._id]);

  return (
    <>
      {editing ? (
        <div className="table-columns-action-editing-box">
          <div className="editing-cancel" onClick={handleCancel}><i className="fas fa-times"/></div>
          <div className="editing-update" onClick={handleUpdateItem}><i className="fas fa-check"/></div>
        </div>
      ) : row.original.status !== 'processing' ? (
        <div className="table-columns-action-box action-editing-box">
          <div onClick={() => setOpen(state => !state)} className="table-action-trigger">
            <img style={{ height: 21, width: 21 }} src={require('../../assets/images/icons/kebab_menu.svg')} alt=''/>
            <img style={{ height: 21, width: 21 }} className='img-active' src={require('../../assets/images/icons/kebab_menu_o.svg')} alt=''/>
          </div>

          {open && (
            <div ref={actionsRef} className="table-actions-box">
              <div
                className="table-action-trigger the-action"
                onClick={handleEditItem}
              >
                <img src={require('../../assets/images/icons/ico_edit.svg')} alt=""/>
                <img className='img-active' src={require('../../assets/images/icons/ico_edit_o.svg')} alt=''/>
                <span>Edit</span>
              </div>

              {row.original.status !== 'success' && (
                <div
                  className="table-action-trigger the-action"
                  onClick={handleCalculate}
                >
                  <img src={require('../../assets/images/icons/ico_edit.svg')} alt=""/>
                  <img className='img-active' src={require('../../assets/images/icons/ico_edit_o.svg')} alt=''/>
                  <span>Calculate</span>
                </div>
              )}

              <div ref={deleteRef} className="table-action-trigger the-action">
                <img onClick={() => setShowDelete(state => !state)} src={require('../../assets/images/icons/ico_delete.svg')} alt=''/>
                <img onClick={() => setShowDelete(state => !state)} className='img-active' src={require('../../assets/images/icons/ico_delete_o.svg')} alt=''/>
                <span onClick={() => setShowDelete(state => !state)}>Delete</span>

                {showDelete && (
                  <TableConfirmation
                    withoutDispatch={true}
                    onSubmit={deleteItem}
                    onDelete={() => {
                      setOpen(false);
                      setShowDelete(false);
                    }}
                    handleCancel={() => setShowDelete(false)}
                    title='Are you sure to delete?'
                    data={row.original._id}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

SettingsActionCell.propTypes = {
  row: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  updateDataFromCell: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  calculateManualRevenueReport: PropTypes.func.isRequired,
  updateDataFieldFromCell: PropTypes.func.isRequired
};

export default SettingsActionCell;
