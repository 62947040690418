import {
  DASHBOARD_INTERVAL_LAST_3_MONTHS,
  DASHBOARD_INTERVAL_LAST_7_DAYS,
  DASHBOARD_INTERVAL_LAST_MONTH,
  DASHBOARD_INTERVAL_THIS_MONTH,
  DASHBOARD_INTERVAL_TODAY,
  DASHBOARD_INTERVAL_YESTERDAY,
  REPORTS_INTERVAL_LAST_48_HOURS,
  REPORTS_INTERVAL_LAST_4_HOURS
} from '../../../actions/types';

export const rulesFrequencyDays = [
  {
    label: 'Sun',
    value: 'sun',
    selected: true
  },
  {
    label: 'Mon',
    value: 'mon',
    selected: true
  },
  {
    label: 'Tue',
    value: 'tue',
    selected: true
  },
  {
    label: 'Wed',
    value: 'wed',
    selected: true
  },
  {
    label: 'Thu',
    value: 'thu',
    selected: true
  },
  {
    label: 'Fri',
    value: 'fri',
    selected: true
  },
  {
    label: 'Sat',
    value: 'sat',
    selected: true
  }
];

export const rulesOutcomeOptions = [
  {
    label: 'Alert only',
    value: 'alert'
  },
  {
    label: 'Execute & Alert',
    value: 'all'
  },
  {
    label: 'Execute',
    value: 'execute'
  }
];

export const rulesFrequencyIntervals = [
  {
    label: 'Every 1 hour',
    value: '1h'
  },
  {
    label: 'Every 2 hours',
    value: '2h'
  },
  {
    label: 'Every 3 hours',
    value: '3h'
  },
  {
    label: 'Every 4 hours',
    value: '4h'
  },
  {
    label: 'Every 6 hours',
    value: '6h'
  },
  {
    label: 'Every 12 hours',
    value: '12h'
  },
  {
    label: 'Every 15 minutes',
    value: '15m'
  },
  {
    label: 'Every 30 minutes',
    value: '30m'
  },
  {
    label: 'Every 45 minutes',
    value: '45m'
  },
  {
    label: 'Custom',
    value: 'custom'
  }
];

export const rulesAnalyzeIntervals = [
  {
    label: 'Today',
    value: DASHBOARD_INTERVAL_TODAY
  },
  {
    label: 'Yesterday',
    value: DASHBOARD_INTERVAL_YESTERDAY
  },
  {
    label: 'Last 7 days',
    value: DASHBOARD_INTERVAL_LAST_7_DAYS
  },
  {
    label: 'Last 4 hours',
    value: REPORTS_INTERVAL_LAST_4_HOURS
  },
  {
    label: 'Last 48 hours',
    value: REPORTS_INTERVAL_LAST_48_HOURS
  },
  {
    label: 'This Month',
    value: DASHBOARD_INTERVAL_THIS_MONTH
  },
  {
    label: 'Last Month',
    value: DASHBOARD_INTERVAL_LAST_MONTH
  },
  {
    label: 'Last 3 months',
    value: DASHBOARD_INTERVAL_LAST_3_MONTHS
  }
];

export const rulesTempActions = [
  {
    label: 'blacklist subid',
    hasData: true,
    data_type: 'number',
    _id: 'blacklist_subid',
    value: 'blacklist_subid'
  },
  {
    label: 'whitelist subid',
    hasData: false,
    data_type: 'number',
    _id: 'blacklist_subid',
    value: 'whitelist_subid'
  }
];

export const rulesTempTags = [
  {
    label: 'Rule Tag',
    value: 'rule_tag1'
  },
  {
    label: 'Rule Tag 2',
    value: 'rule_tag2'
  },
  {
    label: 'Rule Tag 3',
    value: 'rule_tag3'
  }
];
