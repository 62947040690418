import { useEffect } from 'react';

import { useIsMountedRef } from './useIsMountedRef';

export const useFormsOptions = (
  deps,
  optionsState,
  setOptions,
  field,
  isArray
) => {
  const isMountedRef = useIsMountedRef();
  let selectedOptions = [];

  useEffect(() => {
    deps.forEach((d) => {
      if (d[field]) {
        if (isArray) {
          selectedOptions = selectedOptions.concat(d[field].map(s => s.value));
        } else {
          selectedOptions.push(d[field].value);
        }
      }
    });

    const newList = optionsState.filter(o => !selectedOptions.includes(o.value));

    if (isMountedRef.current) {
      setOptions(newList.map(l => ({ label: l.label, value: l.value })));
    }
  }, [JSON.stringify(deps)]);
};
