import {
  GET_INITIAL_PARTNER_STATE,
  UPDATE_PARTNER_STATE,
  PARTNER_CREATE_PARTNER,
  FETCH_PARTNERS,
  START_FETCHING_PARTNERS,
  DELETE_PARTNER,
  DUPLICATE_PARTNER,
  EDIT_PARTNER,
  PARTNER_UPDATE_PARTNER,
  GET_INITIAL_PARTNER_FORM_STATE, PARTNER_UPDATE_TAGS
} from '../actions/types';

const initialFormState = {
  name: '', // required
  business_type: {
    label: 'Individual',
    value: 'individual'
  }, // required: individual | company
  type: '', // supply, demand or both.
  address: '', // required
  username: '', // required, unique
  password: '', // require
  confirm_password: '',
  comments: '',
  tags: [], // optional. Free text tags.
  email: '' // required, email, unique.
};

const initialState = {
  partners: [],
  formData: initialFormState,
  loading: true
};

const partner = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case PARTNER_CREATE_PARTNER:
      return {
        ...state,
        partners: [payload, ...state.partners],
        formData: initialFormState
      };

    case PARTNER_UPDATE_PARTNER:
      return {
        ...state,
        formData: initialFormState,
        partners: state.partners.map(p => {
          if (p._id === payload._id) {
            return payload;
          }

          return p;
        })
      };

    case PARTNER_UPDATE_TAGS:
      return {
        ...state,
        partners: state.partners.map(p => {
          if (p._id === payload._id) {
            return {
              ...p,
              tags: payload.tags
            };
          }

          return p;
        })
      };

    case DELETE_PARTNER:
      return {
        ...state,
        partners: state.partners.filter(item => item._id !== payload)
      };

    case START_FETCHING_PARTNERS:
      return {
        ...state,
        loading: true
      };

    case FETCH_PARTNERS:
      return {
        ...state,
        loading: false,
        partners: payload
      };

    case UPDATE_PARTNER_STATE:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...payload
        }
      };

    case DUPLICATE_PARTNER:
    case EDIT_PARTNER:
      return {
        ...state,
        formData: payload
      };

    case GET_INITIAL_PARTNER_FORM_STATE:
      return {
        ...state,
        formData: initialFormState
      };

    case GET_INITIAL_PARTNER_STATE:
      return initialState;

    default:
      return {
        ...state
      };
  }
};

export default partner;
