import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { fetchDemandsAction } from '../../../../../actions/demand';
import {
  connectEndpointDemandsAction,
  updateEndpointDemandFeeds
} from '../../../../../actions/endpoint';

import { ENDPOINT_WIZARD } from '../../../constants';

import { filterEndpointDemandsOptions } from '../../../helpers/wizard-helpers';

import WizardSelect from '../../shared/WizardSelect';

import '../../../../tables-manager/table-base/TableColumnsActions.scss';
import './EndpointDemandFeeds.scss';

const EndpointDemandFeeds = ({ isSubmitted, onSubmit }) => {
  const dispatch = useDispatch();

  const {
    demands,
    endpointDemandsState,
    endpointID,
    duplicatedID
  } = useSelector(
    state => ({
      demands: state[ENDPOINT_WIZARD].demands,
      endpointDemandsState: state[ENDPOINT_WIZARD].endpoint_demands,
      endpointID: state[ENDPOINT_WIZARD].formData._id || null,
      duplicatedID: state[ENDPOINT_WIZARD].duplicated_id
    })
  );

  const {
    control,
    errors
  } = useForm({
    mode: 'onBlur'
  });

  useEffect(() => {
    (async () => {
      if (duplicatedID) {
        await connectEndpointDemandsAction(duplicatedID, true)(dispatch);
      } else {
        if (endpointID) {
          await connectEndpointDemandsAction(endpointID, false)(dispatch);
        } else {
          await fetchDemandsAction('all', true)(dispatch);
        }
      }
    })();
  }, [endpointID]);

  const [endpointDemands, setEndpointDemands] = useState([]);
  const [edMap, setEdMap] = useState({});
  const [serverOperations, setServerOperations] = useState({
    create: [],
    update: [],
    remove: []
  });

  useEffect(() => {
    const map = {};

    endpointDemandsState.forEach(ed => {
      map[ed.demand._id] = ed._id;
    });

    setEdMap(map);

    setEndpointDemands(endpointDemandsState.map(d => ({
      label: d.demand.title,
      value: d.demand._id,
      active: d.active
    })));

    if (duplicatedID) {
      setServerOperations({
        create: endpointDemandsState.map(ed => ({
          endpoint: ed.endpoint._id,
          demand: ed.demand._id,
          active: ed.active
        })),
        update: [],
        remove: []
      });
    } else {
      setServerOperations({
        create: [],
        update: endpointDemandsState.map(ed => ({
          endpoint: ed.endpoint._id,
          demand: ed.demand._id,
          active: ed.active
        })),
        remove: []
      });
    }
  }, [JSON.stringify(endpointDemandsState)]);

  const [demandOptions, setDemandOptions] = useState([]);

  useEffect(() => {
    setDemandOptions(filterEndpointDemandsOptions(demands, endpointDemands));
  }, [JSON.stringify(demands), JSON.stringify(endpointDemands)]);

  useEffect(() => {
    if (isSubmitted) {
      updateEndpointDemandFeeds({
        demand_feeds: serverOperations,
        ed_map: edMap
      })(dispatch);

      onSubmit(true);
    }
  }, [isSubmitted]);

  const [selectedDemands, setSelectedDemands] = useState(endpointDemands);

  useEffect(() => {
    setSelectedDemands(endpointDemands);
  }, [JSON.stringify(endpointDemands)]);

  const handleAddDemand = (id) => {
    const [demand] = demandOptions.filter(d => d.value === id);
    setSelectedDemands(state => [...state, { ...demand, active: false }]);

    setDemandOptions(state => state.filter(d => d.value !== id));

    setServerOperations(state => ({
      ...state,
      create: edMap[demand.value]
        ? state.create
        : [...state.create,
          {
            endpoint: endpointID,
            demand: demand.value,
            active: false
          }],
      update: edMap[demand.value]
        ? [...state.update,
          {
            endpoint: endpointID,
            demand: demand.value,
            active: false
          }]
        : state.update.filter(ed => ed.demand !== demand.value),
      remove: state.remove.filter(ed => ed.demand !== demand.value)
    }));
  };

  const handleRemoveDemand = (id) => {
    const [demand] = selectedDemands.filter(d => d.value === id);
    setDemandOptions(state => [...state, demand]);

    setSelectedDemands(state => state.filter(d => d.value !== id));

    setServerOperations(state => ({
      create: state.create.filter(ed => ed.demand !== demand.value),
      update: state.update.filter(ed => ed.demand !== demand.value),
      remove: edMap[demand.value]
        ? [...state.remove,
          {
            endpoint: endpointID,
            demand: demand.value,
            active: demand.active
          }]
        : state.remove
    }));
  };

  const handleChangeActive = (id, active) => {
    const updateSelectedDemandStatus = (demand) => {
      if (demand.value === id) {
        return {
          ...demand,
          active
        };
      }

      return demand;
    };

    setSelectedDemands(state => state.map(updateSelectedDemandStatus));

    const updateServerOperations = (ed) => {
      if (ed.demand === id) {
        return {
          ...ed,
          active
        };
      }

      return ed;
    };

    setServerOperations(state => ({
      create: state.create.map(updateServerOperations),
      update: state.update.map(updateServerOperations),
      remove: state.remove
    }));
  };

  return (
    <form className="endpoint-demands-form step-content">
      <div className="group">
        <h4>Demand Feeds</h4>

        <div className="form-group">
          <div className="row-list">
            <div className="row-item">
              <div className="row">
                <div className="col-sm-12">
                  <WizardSelect
                    name={'selected_demands'}
                    control={control}
                    error={!!errors.selected_demands}
                    errorMessage={errors.selected_demands ? errors.selected_demands.message : ''}
                    isMulti={true}
                    placeholder={'Select demands to connect'}
                    options={demandOptions}
                    defaultValue={[]}
                    onChange={([selectedValues]) => {
                      if (selectedValues) {
                        const [demand] = selectedValues;
                        handleAddDemand(demand.value);
                        return null;
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row-item">
              <div className="row">
                <div className="col-sm-12">
                  {selectedDemands.map((demand) => {
                    return (
                      <div className="endpoint-demand" key={demand.value}>
                        <h4 className="demand-title">{demand.label}</h4>
                        <div className="form-group">
                          <div className="switch-list">
                            <label>
                              <input
                                type="checkbox"
                                onChange={e => handleChangeActive(demand.value, e.target.checked)}
                                defaultChecked={demand.active || false}
                              />
                            </label>
                          </div>
                        </div>
                        <span
                          className="action-icon demand-remove"
                          onClick={() => handleRemoveDemand(demand.value)}>
                          <i className="far fa-trash-alt"/>
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

EndpointDemandFeeds.propTypes = {
  isSubmitted: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default EndpointDemandFeeds;
