import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';

import ForgotPasswordForm from '../auth/ForgotPasswordForm';
import LoginForm from '../auth/LoginForm';
import RegisterForm from '../auth/RegisterForm';
import ResetPasswordForm from '../auth/ResetPasswordForm';
import NotFound from '../layout/NotFound';

import PrivateRoute from './PrivateRoute';
import PrivateRoutesTree from './PrivateRoutesTree';

const Routes = () => {
  return (
    <Fragment>
      <Switch>
        <Route exact path="/register" component={RegisterForm}/>
        <Route exact path="/login" component={LoginForm}/>
        <Route exact path="/forgot-password" component={ForgotPasswordForm}/>
        <Route exact path="/api/auth/resetPassword/:token" component={ResetPasswordForm}/>
        <PrivateRoute component={PrivateRoutesTree}/>
        <Route component={NotFound}/>
      </Switch>
    </Fragment>
  );
};

export default Routes;
