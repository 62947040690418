//
// ====== SHARED =========
export const ID_FIELD = '_id';
export const TITLE_FIELD = 'title';
export const GEOS_FIELD = 'geos';
export const CTYPES_FIELD = 'ctypes';
export const CARRIERS_FIELD = 'carriers';
export const DEVICE_TYPES_FIELD = 'device_types';
export const BROWSERS_FIELD = 'browsers';
export const OS_FIELD = 'os';
export const BID_MODIFY_FIELD = 'bid_modify';
export const SUBID_BID_MODIFY_FIELD = 'subid_bid_modify';
export const LIMITS_FIELD = 'limits';
export const CLICK_LIMITS_FIELD = 'click_limits';
export const REDIRECT_DOMAINS_FIELD = 'redirect_domains';
export const PARTNER_FIELD = 'partner';
export const PARTNER_ID_FIELD = 'partner_id';
export const AUTH_FIELD = 'auth';
export const REV_SHARE_FIELD = 'rev_share';
export const DMS_DB_FIELD = 'dms_db';
export const SUB_ID_FIELD = 'subid';
export const SUB_ID_DB_FIELD = 'subid_db';
export const IAB_CAT_FIELD = 'iab_cat';
export const TAGS_FIELD = 'tags';
export const BAD_IPS_FIELD = 'bad_ips';
export const PROXIES_FIELD = 'proxies';
export const IP_INCLUDE_FIELD = 'ip_include';
export const IP_FIELD = 'ip';
export const USER_AGENTS_FIELD = 'user_agents';
export const USER_AGENTS_INCLUDE_FIELD = 'user_agents_include';
export const SUBID_INCLUDE_FIELD = 'subid_include';
export const DOMAIN_INCLUDE_FIELD = 'domain_include';
export const DOMAIN_FIELD = 'domain';
export const VALUE_FIELD = 'value';
export const MIN_BID_FIELD = 'min_bid';
export const MAX_BID_FIELD = 'max_bid';
export const LIST_FIELD = 'list';
export const SELECTED_FIELD = 'selected';
export const INCLUDE_FIELD = 'include';
export const VERSIONS_FIELD = 'versions';
export const OPTIMIZATION_FIELD = 'optimization';
export const FILTERS_FIELD = 'filters';
export const TARGETING_FIELD = 'targeting';
export const STATUS_FIELD = 'status';
export const CREATED_AT_FIELD = 'createdAt';
export const UPDATED_AT_FIELD = 'updatedAt';
export const SOURCE_FIELD = 'source';
export const ACTIVE_FIELD = 'active';
export const LABEL_FIELD = 'label';
export const CUSTOM_TAGS_FIELD = 'custom_tags';
export const REQUEST_TIMEOUT_FIELD = 'request_timeout';
export const OUTPUT_FIELD = 'output';
export const REDIRECT_TYPE_FIELD = 'redirect_type';
export const RENDER_TYPE_FIELD = 'render_type';

// TODO To be removed?
export const SETTINGS_FIELD = 'settings';
//
// ====== FORMS =========
export const GENERAL_FORM = 'General';
export const OPTIMIZATION_FORM = 'Optimization';
export const PROTECTION_FORM = 'Protection';
export const TARGETING_FORM = 'Targeting';
// ====== END FORMS =========

// ====== END SHARED =========

//
// ====== WIZARDS =========
export const ENDPOINT_WIZARD = 'endpoint';
export const DEMAND_WIZARD = 'demand';
export const PARTNER_WIZARD = 'partner';
export const ENDPOINT_DEMANDS_WIZARD = 'endpointDemand';
// ====== END WIZARDS =========

//
// ====== PARTNERS =========
export const PARTNER_NAME_FIELD = 'name';
export const PARTNER_BUSINESS_FIELD = 'business_type';
export const PARTNER_TYPE_FIELD = 'type';
export const PARTNER_ADDRESS_FIELD = 'address';
export const PARTNER_USERNAME_FIELD = 'username';
export const PARTNER_PASSWORD_FIELD = 'password';
export const PARTNER_CONFIRM_PASSWORD_FIELD = 'confirm_password';
export const PARTNER_COMMENTS_FIELD = 'comments';
export const PARTNER_EMAIL_FIELD = 'email';
// ====== END PARTNERS =========

//
// ====== ENDPOINTS =========
export const ENDPOINTS_FALLBACK_FORM = 'Fallback';
export const ENDPOINTS_DEMAND_FEEDS_FORM = 'Demand Feeds';
export const ENDPOINT_FALLBACK_DOMAINS_FIELD = 'fallback_domains';
export const ENDPOINT_DEFAULT_DOMAIN_FIELD = 'default_domain';
export const ENDPOINT_NO_BID_FIELD = 'no_bid';
export const ENDPOINT_NO_BID_USE_DEFAULT_FIELD = 'no_bid_use_default';
export const ENDPOINT_TARGETING_MISMATCH_FIELD = 'targeting_mismatch';
export const ENDPOINT_TARGETING_MISMATCH_USE_DEFAULT_FIELD = 'targeting_mismatch_use_default';
export const ENDPOINT_IP_MISMATCH_FIELD = 'ip_mismatch';
export const ENDPOINT_IP_MISMATCH_USE_DEFAULT_FIELD = 'ip_mismatch_use_default';
export const ENDPOINT_OTHER_CLICK_FILTERS_FIELD = 'other_click_filters';
export const ENDPOINT_OTHER_CLICK_FILTERS_USE_DEFAULT_FIELD = 'other_click_filters_use_default';
export const ENDPOINT_SUB_ID_TOKEN_FIELD = 'subid_token';
export const ENDPOINT_SUB_ID_ENCRYPTION_FIELD = 'subid_enc';
export const ENDPOINT_SUB_ID_STRATEGY_FIELD = 'subid_strategy';
export const BRANDING_TYPE_FIELD = 'branding';
// ====== END ENDPOINTS =========

//
// ====== DEMANDS =========
export const DEMAND_ENDPOINT_FEEDS = 'Endpoint Feeds';
export const DEMAND_SETUP_FORM = 'Setup';
export const DEMAND_SETUP_DATA_FIELD = 'setup_data';
export const DEMAND_FEED_SETUP_FIELD = 'feed_setup';
export const DEMAND_STATS_SETUP_FIELD = 'stats_setup';
export const DEMAND_INTEGRATION_FIELD = 'integration';
export const DEMAND_FIELDS_FIELD = 'fields';
export const DEMAND_NAME_FIELD = 'name';
export const DEMAND_VALUE_FIELD = 'value';
// ====== END DEMANDS =========
