import axios from 'axios';

import { AXIOS_CONFIG } from '../constant';

export const fetchAllReports = () => axios.get('/api/report-template');

export const fetchReportById = (id) => axios.get(`/api/report-template/${id}`);

export const saveReport = (data) => axios.post('/api/report-template', data, AXIOS_CONFIG);

export const removeReport = (id) => axios.delete(`/api/report-template/${id}`);
