import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { editDemandFromTableAction } from '../../actions/demand';
import { editEndpointFromTableAction } from '../../actions/endpoint';
import { editPartnerFromNotificationsAction } from '../../actions/partners';
import {
  NOTIFICATIONS_CTA_DATA_FIELD,
  NOTIFICATIONS_CTA_FIELD,
  NOTIFICATIONS_DATA_FIELD,
  NOTIFICATIONS_READ_FIELD,
  NOTIFICATIONS_TYPE_FIELD,
  NOTIFICATIONS_VIEW_DEMAND_FIELD,
  NOTIFICATIONS_VIEW_ENDPOINT_FIELD,
  NOTIFICATIONS_VIEW_PARTNER_FIELD
} from '../../actions/types';

import { useNotifications } from '../../context/notifications/NotificationsState';

import { CREATED_AT_FIELD } from '../wizards-manager/constants';

import './Notification.scss';

const Notification = ({ closeModal }) => {
  const {
    notifications,
    markNotificationAsRead,
    markNotificationsAsRead
  } = useNotifications();

  const unreadCount = notifications.filter(n => !n[NOTIFICATIONS_READ_FIELD]).length;

  const renderAction = (id, read, cta, ctaData) => {
    let text = 'View';
    let action;

    if (cta) {
      switch (cta) {
        case NOTIFICATIONS_VIEW_ENDPOINT_FIELD:
          action = async () => {
            if (!read) {
              await markNotificationAsRead(id);
            }

            closeModal();
            await editEndpointFromTableAction(ctaData);
          };
          break;

        case NOTIFICATIONS_VIEW_DEMAND_FIELD:
          action = async () => {
            if (!read) {
              await markNotificationAsRead(id);
            }

            closeModal();
            await editDemandFromTableAction(ctaData);
          };
          break;

        case NOTIFICATIONS_VIEW_PARTNER_FIELD:
          action = async () => {
            if (!read) {
              await markNotificationAsRead(id);
            }

            closeModal();
            await editPartnerFromNotificationsAction(ctaData);
          };
          break;
      }
    } else {
      if (read) {
        return null;
      }

      text = 'Mark as Read';
      action = () => markNotificationAsRead(id);
    }

    return (
      <div className="notification-time-action" onClick={action}>{text}</div>
    );
  };

  return (
    <div className="notification-box">
      {unreadCount ? (
        <div className="notification-unread">
          <div className="notification-unread-title">You have {unreadCount} new notifications</div>
          <div onClick={markNotificationsAsRead} className="notification-unread-button">Mark all Read</div>
        </div>
      ) : null}
      {notifications.map((notification, index) => (
        <div key={index} className="notification-item">
          <div className="notification-icon">
            <img
              src={require(`../../assets/images/icons/${notification[NOTIFICATIONS_TYPE_FIELD]}.svg`)}
              alt=""
            />
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: notification[NOTIFICATIONS_DATA_FIELD]
            }}
            className={`notification-data ${notification[NOTIFICATIONS_READ_FIELD] ? '' : 'unread'}`}
          />
          <div className="notification-time">
            <div>{moment(notification[CREATED_AT_FIELD]).add(-2, 'hours').fromNow()}</div>
            {renderAction(
              notification._id,
              notification[NOTIFICATIONS_READ_FIELD],
              notification[NOTIFICATIONS_CTA_FIELD],
              notification[NOTIFICATIONS_CTA_DATA_FIELD]
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

Notification.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default Notification;
