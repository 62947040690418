'use strict';

import numeral from 'numeral';

export const formatMillions = (number) =>
  formatNumbers(number);

export const formatNumbers = (number) => {
  if (number < 1) {
    return numeral(number).format('0.000');
  }

  if (number < 1000) {
    return numeral(number).format('0.00');
  }

  if (number < 1000000) {
    return numeral(number).format('0,0');
  }

  return numeral(number).format('0,0.000a').toUpperCase();
};

export const formatCurrency = (number) => {
  if (number === 0) {
    return numeral(number).format('$0');
  }

  if (number < 1) {
    return numeral(number).format('$0.000');
  }

  return numeral(number).format('$0,0.00');
};

export const formatPercentage = (number) => {
  if (number === 0) {
    return numeral(number).format('0%');
  }

  if (number * 100 < 1) {
    return numeral(number).format('0.000%');
  }

  return numeral(number).format('0.00%');
};
