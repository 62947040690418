import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';

import './TableColumnsControl.scss';

const TableColumnsControl = ({
  columns
}) => {
  const divRef = useRef(null);
  const [showFilter, setShowFilter] = useState(false);

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleClickOutside = (e) => {
    if (divRef.current && !divRef.current.contains(e.target)) {
      setShowFilter(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  return columns.length ? (
    <tbody
      className="table-columns-control"
      ref={divRef}
    >
      <tr>
        <td className="table-columns-control-view">
          <span onClick={handleShowFilter}>{''}</span>
        </td>
      </tr>

      <tr>
        <td className="table-columns-control-list">
          <ul className={showFilter ? '' : 'd-none'}>
            {columns.map((column) => {
              if (column.Header) {
                return (
                  <li key={column.id}>
                    <label>
                      <input
                        type="checkbox"
                        {...column.getToggleHiddenProps()}
                      />
                      {column.Header}
                    </label>
                  </li>
                );
              }
            })}
          </ul>
        </td>
      </tr>
    </tbody>) : null;
};

TableColumnsControl.propTypes = {
  columns: PropTypes.array.isRequired
};

export default TableColumnsControl;
