import * as yup from 'yup';

import {
  BAD_IPS_FIELD,
  PROXIES_FIELD,
  IP_INCLUDE_FIELD,
  IP_FIELD,
  SUBID_INCLUDE_FIELD,
  SUB_ID_FIELD,
  DOMAIN_INCLUDE_FIELD,
  DOMAIN_FIELD,
  USER_AGENTS_INCLUDE_FIELD,
  USER_AGENTS_FIELD
} from '../../constants';

import { domainsPattern, ipsPattern } from '../../constants/patterns';

import { selectSchema } from './shared-schemas';

export const protectionSchema = yup.object().shape({
  [BAD_IPS_FIELD]: yup.bool().required('IP Filter is required'),
  [PROXIES_FIELD]: selectSchema.required('Filter proxy is required').nullable(),
  [IP_INCLUDE_FIELD]: yup.bool(),
  [IP_FIELD]: yup.string().matches(ipsPattern, 'Invalid data. You need to enter valid ips separated by new lines'),
  [SUBID_INCLUDE_FIELD]: yup.bool(),
  [SUB_ID_FIELD]: yup.string(),
  [USER_AGENTS_INCLUDE_FIELD]: yup.bool(),
  [USER_AGENTS_FIELD]: yup.string(),
  [DOMAIN_INCLUDE_FIELD]: yup.bool(),
  [DOMAIN_FIELD]: yup.string().matches(domainsPattern, 'Invalid data. You need to enter valid domains without http:// or https:// , separated by new lines')
});

export const validateProtectionData = (data) => {
  try {
    protectionSchema.validateSync(data);

    return true;
  } catch (e) {
    return false;
  }
};
