import PropTypes from 'prop-types';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { updateDemandFormDataAction } from '../../../../../actions/demand';

import {
  DEMAND_FEED_SETUP_FIELD,
  DEMAND_FIELDS_FIELD,
  DEMAND_INTEGRATION_FIELD,
  DEMAND_NAME_FIELD,
  DEMAND_SETUP_DATA_FIELD,
  DEMAND_STATS_SETUP_FIELD,
  DEMAND_VALUE_FIELD,
  DEMAND_WIZARD
} from '../../../constants';

import { useClearArrayError } from '../../../custom-hooks/useClearArrayError';
import { useFormsEffect } from '../../../custom-hooks/useFormsEffect';
import { useIsValidForm } from '../../../custom-hooks/useIsValidForm';

import {
  handleArrayErrorMessage,
  handleArrayHaveError,
  handleNestedErrorMessage,
  handleNestedHaveError
} from '../../../helpers/wizard-helpers';

import { setupDataSchema, validateSetupData } from '../../../validators/demand/setup-data';

import WizardInputWithValidation from '../../shared/WizardInputWithValidation';
import WizardSelectWithValidation, { SELECT_TYPE } from '../../shared/WizardSelectWithValidation';

const DemandSetupForm = ({
  isSubmitted,
  onSubmit,
  setIsValid
}) => {
  const {
    defaultValues,
    feedOptions,
    statsOptions
  } = useSelector(
    state => ({
      defaultValues: state[DEMAND_WIZARD].formData[DEMAND_SETUP_DATA_FIELD],
      feedOptions: state.staticList.feedOptions,
      statsOptions: state.staticList.statsOptions
    })
  );

  const {
    register,
    errors,
    triggerValidation,
    getValues,
    control,
    watch,
    clearError
  } = useForm({
    mode: 'onBlur',
    defaultValues,
    validationSchema: setupDataSchema
  });

  useFormsEffect(isSubmitted, triggerValidation, getValues, updateDemandFormDataAction, onSubmit, DEMAND_SETUP_DATA_FIELD);

  const values = getValues({ nest: true });
  const isValid = validateSetupData(Object.keys(values).length ? values : defaultValues);
  useIsValidForm(isValid, setIsValid);

  const setupFieldsArray = useFieldArray({
    control,
    name: `${DEMAND_FEED_SETUP_FIELD}[${DEMAND_FIELDS_FIELD}]`
  });

  const setupFields = setupFieldsArray.fields;
  const addSetupFields = setupFieldsArray.append;
  const removeSetupFields = setupFieldsArray.remove;

  const statsFieldsArray = useFieldArray({
    control,
    name: `${DEMAND_STATS_SETUP_FIELD}[${DEMAND_FIELDS_FIELD}]`
  });

  const statsFields = statsFieldsArray.fields;
  const addStatsFields = statsFieldsArray.append;
  const removeStatsFields = statsFieldsArray.remove;

  const feedSetup = watch(`${DEMAND_FEED_SETUP_FIELD}[${DEMAND_FIELDS_FIELD}]`);
  const statsSetup = watch(`${DEMAND_STATS_SETUP_FIELD}[${DEMAND_FIELDS_FIELD}]`);

  useClearArrayError(DEMAND_FEED_SETUP_FIELD, feedSetup, clearError, DEMAND_FIELDS_FIELD);
  useClearArrayError(DEMAND_STATS_SETUP_FIELD, statsSetup, clearError, DEMAND_FIELDS_FIELD);

  return (
    <form className="step-content">
      <div className="group action-right">
        <h4>Feed Setup</h4>

        <div className="group-item">
          <WizardSelectWithValidation
            label='Integration'
            control={control}
            error={handleNestedHaveError(errors, DEMAND_FEED_SETUP_FIELD, DEMAND_INTEGRATION_FIELD)}
            errorMessage={handleNestedErrorMessage(errors, DEMAND_FEED_SETUP_FIELD, DEMAND_INTEGRATION_FIELD)}
            isMulti={false}
            name={`${DEMAND_FEED_SETUP_FIELD}[${DEMAND_INTEGRATION_FIELD}]`}
            options={feedOptions}
            selectType={SELECT_TYPE}
            defaultValue={defaultValues[DEMAND_FEED_SETUP_FIELD][DEMAND_INTEGRATION_FIELD]}
          />
        </div>

        <div className="group-item pt10">
          <label htmlFor=''>Fields</label>

          {setupFields.map((item, index) => {
            return (
              <div className="row-item" key={item.id}>
                <div className="row">
                  <div className="col-sm-4">
                    <WizardInputWithValidation
                      type="text"
                      error={handleArrayHaveError(errors, DEMAND_FEED_SETUP_FIELD, DEMAND_NAME_FIELD, index, DEMAND_FIELDS_FIELD)}
                      errorMessage={handleArrayErrorMessage(errors, DEMAND_FEED_SETUP_FIELD, DEMAND_NAME_FIELD, index, DEMAND_FIELDS_FIELD)}
                      name={`${DEMAND_FEED_SETUP_FIELD}[${DEMAND_FIELDS_FIELD}][${index}][${DEMAND_NAME_FIELD}]`}
                      placeholder='Field name'
                      propRef={register()}
                    />
                  </div>
                  <div className="col-sm-8">
                    <WizardInputWithValidation
                      type="text"
                      error={handleArrayHaveError(errors, DEMAND_FEED_SETUP_FIELD, DEMAND_VALUE_FIELD, index, DEMAND_FIELDS_FIELD)}
                      errorMessage={handleArrayErrorMessage(errors, DEMAND_FEED_SETUP_FIELD, DEMAND_VALUE_FIELD, index, DEMAND_FIELDS_FIELD)}
                      name={`${DEMAND_FEED_SETUP_FIELD}[${DEMAND_FIELDS_FIELD}][${index}][${DEMAND_VALUE_FIELD}]`}
                      placeholder='Field value'
                      propRef={register()}
                    />
                  </div>
                </div>
                {setupFields.length > 1 && (
                  <span
                    className="action-icon delete-item"
                    onClick={() => removeSetupFields(index)}
                  >
                    <i className="far fa-trash-alt"/>
                  </span>
                )}
              </div>
            );
          })}
          <div className="row-item">
            <div className="add-new">
              <p onClick={() => addSetupFields({ name: '', value: '' })}>
                <i className="fas fa-plus"/><span className="add-item">Add new</span>
              </p>
            </div>
          </div>
        </div>

        <h4>Stats Setup</h4>

        <div className="group-item">
          <WizardSelectWithValidation
            label='Integration'
            control={control}
            error={handleNestedHaveError(errors, DEMAND_STATS_SETUP_FIELD, DEMAND_INTEGRATION_FIELD)}
            errorMessage={handleNestedErrorMessage(errors, DEMAND_STATS_SETUP_FIELD, DEMAND_INTEGRATION_FIELD)}
            isMulti={false}
            name={`${DEMAND_STATS_SETUP_FIELD}[${DEMAND_INTEGRATION_FIELD}]`}
            options={statsOptions}
            selectType={SELECT_TYPE}
            defaultValue={defaultValues[DEMAND_STATS_SETUP_FIELD][DEMAND_INTEGRATION_FIELD]}
          />
        </div>

        <div className="group-item pt10">
          <label htmlFor=''>Fields</label>

          {statsFields.map((item, index) => {
            return (
              <div className="row-item" key={item.id}>
                <div className="row">
                  <div className="col-sm-4">
                    <WizardInputWithValidation
                      type="text"
                      error={handleArrayHaveError(errors, DEMAND_STATS_SETUP_FIELD, DEMAND_NAME_FIELD, index, DEMAND_FIELDS_FIELD)}
                      errorMessage={handleArrayErrorMessage(errors, DEMAND_STATS_SETUP_FIELD, DEMAND_NAME_FIELD, index, DEMAND_FIELDS_FIELD)}
                      name={`${DEMAND_STATS_SETUP_FIELD}[${DEMAND_FIELDS_FIELD}][${index}][${DEMAND_NAME_FIELD}]`}
                      placeholder='Field name'
                      propRef={register()}
                    />
                  </div>
                  <div className="col-sm-8">
                    <WizardInputWithValidation
                      type="text"
                      error={handleArrayHaveError(errors, DEMAND_STATS_SETUP_FIELD, DEMAND_VALUE_FIELD, index, DEMAND_FIELDS_FIELD)}
                      errorMessage={handleArrayErrorMessage(errors, DEMAND_STATS_SETUP_FIELD, DEMAND_VALUE_FIELD, index, DEMAND_FIELDS_FIELD)}
                      name={`${DEMAND_STATS_SETUP_FIELD}[${DEMAND_FIELDS_FIELD}][${index}][${DEMAND_VALUE_FIELD}]`}
                      placeholder='Field value'
                      propRef={register()}
                    />
                  </div>
                </div>
                {statsFields.length > 1 && (
                  <span
                    className="action-icon delete-item"
                    onClick={() => removeStatsFields(index)}
                  >
                    <i className="far fa-trash-alt"/>
                  </span>
                )}
              </div>
            );
          })}
          <div className="row-item">
            <div className="add-new">
              <p onClick={() => addStatsFields({ name: '', value: '' })}>
                <i className="fas fa-plus"/><span className="add-item">Add new</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

DemandSetupForm.propTypes = {
  isSubmitted: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setIsValid: PropTypes.func.isRequired
};

export default DemandSetupForm;
