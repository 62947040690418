import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  createDemandAction,
  updateDemandFormDataAction,
  getInitialDemandFormDataAction,
  updateDemandAction
} from '../../../../actions/demand';

import {
  DEMAND_ENDPOINT_FEEDS,
  DEMAND_SETUP_FORM,
  DEMAND_WIZARD,
  GENERAL_FORM,
  OPTIMIZATION_FORM,
  PARTNER_FIELD,
  PROTECTION_FORM,
  TARGETING_FORM,
  TITLE_FIELD
} from '../../constants';

import { validateDemandFormData } from '../../validators/demand';

import Wizard from '../../wizard-base/Wizard';
import WizardGeneralForm from '../shared/forms/WizardGeneralForm';
import WizardOptimizationForm from '../shared/forms/WizardOptimizationFrom';
import WizardProtectionForm from '../shared/forms/WizardProtectionForm';
import WizardTargetingForm from '../shared/forms/WizardTargetingForm';

import DemandEndpointFeeds from './forms/DemandEndpointFeeds';
import DemandSetupForm from './forms/DemandSetupForm';

const DemandWizard = () => {
  const {
    title,
    id,
    partner
  } = useSelector(
    state => ({
      title: state[DEMAND_WIZARD].formData[TITLE_FIELD],
      id: state[DEMAND_WIZARD].formData._id,
      partner: state[DEMAND_WIZARD].formData[PARTNER_FIELD]
    })
  );

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    let error;

    if (id) {
      error = await updateDemandAction()(dispatch);
    } else {
      error = await createDemandAction()(dispatch);
    }

    if (error) {
      // TODO Handle error
      return false;
    }

    return true;
  };

  const handleTitle = async (event) => {
    const title = event.target.value;

    updateDemandFormDataAction({ title })(dispatch);
  };

  const stepsForms = [
    {
      label: GENERAL_FORM,
      component: WizardGeneralForm,
      parentWizard: DEMAND_WIZARD
    },
    {
      label: DEMAND_SETUP_FORM,
      component: DemandSetupForm
    },
    {
      label: OPTIMIZATION_FORM,
      component: WizardOptimizationForm,
      parentWizard: DEMAND_WIZARD
    },
    {
      label: TARGETING_FORM,
      component: WizardTargetingForm,
      parentWizard: DEMAND_WIZARD
    },
    {
      label: PROTECTION_FORM,
      component: WizardProtectionForm,
      parentWizard: DEMAND_WIZARD
    },
    {
      label: DEMAND_ENDPOINT_FEEDS,
      component: DemandEndpointFeeds
    }
  ];

  return (
    <Wizard
      clearFormState={getInitialDemandFormDataAction}
      stepsForms={stepsForms}
      handleSubmit={handleSubmit}
      updateTitle={handleTitle}
      title={title}
      id={id}
      validateForms={validateDemandFormData}
      partner={partner}
      titlePlaceholder={`${id ? 'Update' : 'New'} Demand`}
      wizardName={`${id ? 'Update' : 'New'} Demand`}
    />
  );
};

export default DemandWizard;
