import PropTypes from 'prop-types';
import React from 'react';

const TableGlobalSearch = ({
  globalFilter,
  setGlobalFilter
}) => {
  return (
    <div className="t-search-fields-item">
      <div className="search-text-container">
        <input
          type="text"
          className="search-text-input"
          placeholder="Search"
          onChange={(e) => setGlobalFilter(e.target.value || undefined)}
          value={globalFilter}
          autoFocus={false}
        />
        <img className="search-icon" src={require('../../../assets/images/icons/ico_search.svg')} alt=''/>
      </div>
    </div>
  );
};

TableGlobalSearch.propTypes = {
  globalFilter: PropTypes.string.isRequired,
  setGlobalFilter: PropTypes.func.isRequired
};

export default TableGlobalSearch;
