import {
  NOTIFICATIONS_FETCH_NOTIFICATIONS,
  NOTIFICATIONS_MARK_ALL_NOTIFICATIONS_AS_READ,
  NOTIFICATIONS_MARK_NOTIFICATION_AS_READ,
  NOTIFICATIONS_MARK_NOTIFICATION_AS_UNREAD
} from '../types';

export default (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case NOTIFICATIONS_FETCH_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload
      };

    case NOTIFICATIONS_MARK_ALL_NOTIFICATIONS_AS_READ:
      return {
        ...state,
        notifications: state.notifications.map((notification) => {
          notification.read = true;
          return notification;
        })
      };

    case NOTIFICATIONS_MARK_NOTIFICATION_AS_READ:
      return {
        ...state,
        notifications: state.notifications.map((notification) => {
          if (notification._id === payload) {
            notification.read = true;
          }

          return notification;
        })
      };

    case NOTIFICATIONS_MARK_NOTIFICATION_AS_UNREAD:
      return {
        ...state,
        notifications: state.notifications.map((notification) => {
          if (notification._id === payload) {
            notification.read = false;
          }

          return notification;
        })
      };

    default:
      return state;
  }
};
