import {
  DMS_DB_FIELD,
  IAB_CAT_FIELD,
  PARTNER_BUSINESS_FIELD,
  PARTNER_TYPE_FIELD,
  PARTNER_FIELD,
  TAGS_FIELD,
  TARGETING_FIELD,
  OPTIMIZATION_FIELD,
  FILTERS_FIELD,
  DEMAND_SETUP_DATA_FIELD,
  DEMAND_FEED_SETUP_FIELD,
  DEMAND_STATS_SETUP_FIELD,
  SUB_ID_DB_FIELD, OUTPUT_FIELD, RENDER_TYPE_FIELD, REDIRECT_TYPE_FIELD, ENDPOINT_SUB_ID_ENCRYPTION_FIELD, ENDPOINT_SUB_ID_STRATEGY_FIELD
} from '../constants';

import {
  transformDemandSetupData,
  transformFiltersData,
  transformOptimizationsData,
  transformTargetingData
} from './transformers-helpers';

import { transformMultiSelect, transformSingleSelect } from './wizard-helpers';

export const transformPartnerData = (data) =>
  Object.keys(data)
    .reduce((acc, field) => {
      if (field === PARTNER_BUSINESS_FIELD || field === PARTNER_TYPE_FIELD) {
        acc[field] = transformSingleSelect(data[field]);

        return acc;
      }

      if (field === TAGS_FIELD) {
        acc[field] = transformMultiSelect(data[field]);

        return acc;
      }

      acc[field] = data[field];

      return acc;
    }, {});

export const transformEndpointData = (data) =>
  Object.keys(data)
    .reduce((acc, field) => {
      switch (field) {
        case ENDPOINT_SUB_ID_ENCRYPTION_FIELD:
        case ENDPOINT_SUB_ID_STRATEGY_FIELD:
        case PARTNER_FIELD:
        case IAB_CAT_FIELD:
          acc[field] = transformSingleSelect(data[field]);
          break;

        case TAGS_FIELD:
          acc[field] = transformMultiSelect(data[field]);
          break;

        case TARGETING_FIELD:
          acc[field] = transformTargetingData(data[field]);
          break;

        case OPTIMIZATION_FIELD:
          acc[field] = transformOptimizationsData(data[field]);
          break;

        case FILTERS_FIELD:
          acc[field] = transformFiltersData(data[field]);
          break;

        case OUTPUT_FIELD: {
          const redirectType = transformSingleSelect(data[field][REDIRECT_TYPE_FIELD]);

          acc[field] = {
            [RENDER_TYPE_FIELD]: transformSingleSelect(data[field][RENDER_TYPE_FIELD]),
            [REDIRECT_TYPE_FIELD]: redirectType === 'default' ? '' : redirectType
          };
          break;
        }

        default:
          acc[field] = data[field];
          break;
      }

      return acc;
    }, {});

export const transformDemandData = (data) =>
  Object.keys(data)
    .reduce((acc, field) => {
      switch (field) {
        case PARTNER_FIELD:
        case IAB_CAT_FIELD:
          acc[field] = transformSingleSelect(data[field]);
          break;

        case DMS_DB_FIELD:
        case TAGS_FIELD:
        case SUB_ID_DB_FIELD:
          acc[field] = transformMultiSelect(data[field]);
          break;

        case TARGETING_FIELD:
          acc[field] = transformTargetingData(data[field]);
          break;

        case OPTIMIZATION_FIELD:
          acc[field] = transformOptimizationsData(data[field]);
          break;

        case FILTERS_FIELD:
          acc[field] = transformFiltersData(data[field]);
          break;

        case DEMAND_SETUP_DATA_FIELD: {
          const setupData = transformDemandSetupData(data[field]);

          acc[DEMAND_FEED_SETUP_FIELD] = setupData[DEMAND_FEED_SETUP_FIELD];
          acc[DEMAND_STATS_SETUP_FIELD] = setupData[DEMAND_STATS_SETUP_FIELD];
          break;
        }

        default:
          acc[field] = data[field];
          break;
      }

      return acc;
    }, {});
