import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip } from 'react-tippy';

const Popover = (props) => {
  return (
    <Tooltip
      position="top"
      arrow={true}
      arrowSize={7}
      theme={'light'}
      {...props}
    >
      {props.children}
    </Tooltip>
  );
};

Popover.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default Popover;
