import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { AUTHENTICATION_EMAIL_FIELD } from '../../actions/types';

import { sendForgotPassword } from '../../api/users';

import '../../assets/style/login.scss';

import WizardInputWithValidation from '../wizards-manager/wizards/shared/WizardInputWithValidation';

import { forgotPasswordFormSchema } from './validators';

const ForgotPasswordForm = () => {
  const {
    handleSubmit,
    register,
    errors,
    setError
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: ''
    },
    validationSchema: forgotPasswordFormSchema
  });

  const [sent, setSent] = useState(false);

  const onSubmit = async (data) => {
    try {
      await sendForgotPassword(data);

      setSent(true);
    } catch (err) {
      const message = err.response.data.message;

      setError(AUTHENTICATION_EMAIL_FIELD, 'wrongEmail', message);
    }
  };

  return (
    <div className="minisite">
      <div className="login">
        <div className="container">
          <div className="row row-login">
            <div className="col-md-4 offset-md-7 mt-3 mobile-hero-background">
              <Link to="/">
                <img
                  className="footer-logo mt-5 mb-5"
                  src={require('../../assets/images/logo@2x.png')}
                  alt="DigitalFlare"
                />
              </Link>

              <h4 className="mt-5">Welcome back!</h4>
              <p className="p-privacy mb-5">Enter your email</p>

              {sent ? (
                <p className="p-privacy mb-5">Email was sent successfully</p>
              ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <WizardInputWithValidation
                    type="email"
                    error={!!errors[AUTHENTICATION_EMAIL_FIELD]}
                    errorMessage={errors[AUTHENTICATION_EMAIL_FIELD] ? errors[AUTHENTICATION_EMAIL_FIELD].message : ''}
                    name={AUTHENTICATION_EMAIL_FIELD}
                    propRef={register}
                  />

                  <button className="btn button btn-primary btn-log mt-4">
                    Reset password
                  </button>
                </form>
              )}

              <p className="sm-font mt-5">
                Don’t have an account? <Link to="/login">Sign up &gt;</Link>
              </p>

              <div>
                <div className="col text-center sm-font mb-2 mt-5">
                  Copyright &copy; {new Date().getFullYear()} DigitalFlare. All
                  Rights Reserved
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
