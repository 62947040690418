import * as yup from 'yup';

import {
  BROWSERS_FIELD,
  CARRIERS_FIELD,
  CTYPES_FIELD,
  CUSTOM_TAGS_FIELD,
  DEVICE_TYPES_FIELD,
  GEOS_FIELD,
  INCLUDE_FIELD,
  LIST_FIELD,
  OS_FIELD,
  SELECTED_FIELD,
  VERSIONS_FIELD
} from '../../constants';

import { multiSelectSchema, requiredMultiSelectSchema, selectSchema } from './shared-schemas';

const listSchema = yup.array().of(
  yup.object().shape({
    [SELECTED_FIELD]: selectSchema.required(),
    [VERSIONS_FIELD]: requiredMultiSelectSchema
  })
);

export const targetIncludeSchema = yup.object().shape({
  [INCLUDE_FIELD]: yup.bool().required('Include/exclude is required'),
  [LIST_FIELD]: listSchema
});

export const simpleTargetIncludeSchema = yup.object().shape({
  [INCLUDE_FIELD]: yup.bool().required('Include/exclude is required'),
  [LIST_FIELD]: requiredMultiSelectSchema
});

export const targetingSchema = yup.object().shape({
  [GEOS_FIELD]: simpleTargetIncludeSchema,
  [DEVICE_TYPES_FIELD]: simpleTargetIncludeSchema,
  [BROWSERS_FIELD]: targetIncludeSchema,
  [OS_FIELD]: targetIncludeSchema,
  [CTYPES_FIELD]: simpleTargetIncludeSchema,
  [CARRIERS_FIELD]: simpleTargetIncludeSchema,
  [CUSTOM_TAGS_FIELD]: yup.object().shape({
    [INCLUDE_FIELD]: yup.bool().required('Include/exclude is required'),
    [LIST_FIELD]: multiSelectSchema
  })
});

export const validateTargetingData = (data) => {
  try {
    targetingSchema.validateSync(data);

    return true;
  } catch (e) {
    return false;
  }
};
