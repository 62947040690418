import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import '../../wizard-base/WizardSharedStyles.scss';
import Popover from './Popover';

const WizardInputWithValidation = ({
  name,
  propRef,
  label,
  error,
  errorMessage,
  type,
  placeholder,
  disabled,
  isTextarea,
  withStep,
  defaultValue
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleBlur = () => setIsFocused(false);
  const handleFocus = () => setIsFocused(true);

  return (
    <div className="form-group">
      {label ? <label>{label}</label> : null}
      <Popover
        title={errorMessage || ' '}
        open={error && isFocused}
      >
        <div className="wizard-input-container">
          {isTextarea ? (
            <textarea
              name={name}
              ref={propRef}
              className={`the-field ${error ? 'error' : ''}`}
              placeholder={placeholder || ''}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled={disabled}
            />
          ) : (
            withStep ? (
              <input
                name={name}
                ref={propRef}
                className={`the-field ${error ? 'error' : ''}`}
                type={type}
                placeholder={placeholder || ''}
                onFocus={handleFocus}
                onBlur={handleBlur}
                disabled={disabled}
                step={0.001}
              />
            ) : (
              <input
                name={name}
                ref={propRef}
                className={`the-field ${error ? 'error' : ''}`}
                type={type}
                placeholder={placeholder || ''}
                onFocus={handleFocus}
                onBlur={handleBlur}
                disabled={disabled}
                defaultValue={defaultValue || null}
              />)
          )}

          {(error && !isFocused) && <FontAwesomeIcon className="field-error-icon" icon={faExclamationCircle}/>}
        </div>
      </Popover>
    </div>
  );
};

WizardInputWithValidation.propTypes = {
  name: PropTypes.string.isRequired,
  propRef: PropTypes.func.isRequired,
  label: PropTypes.string,
  error: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'password', 'email', 'number']).isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isTextarea: PropTypes.bool,
  withStep: PropTypes.bool,
  defaultValue: PropTypes.any
};

export default WizardInputWithValidation;
