import React from 'react';

import BlockChart from './charts/BlockChart';
import TopCharts from './charts/TopCharts';
import TopFilters from './filters/TopFilters';

import DashboardTableManager from './table/DashboardTableManager';

import './Dashboard.scss';

const Dashboard = () => {
  return (
    <>
      <TopFilters/>
      <>
        <TopCharts/>
        <BlockChart/>
        <DashboardTableManager/>
      </>
    </>
  );
};

export default Dashboard;
