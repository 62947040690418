import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

import { getFilterTableSelected, transformOptionsForTables } from '../helpers/transormers-helpers';

const TableSelectFilter = ({
  column: { filterValue = { label: 'All', value: '' }, preFilteredRows, setFilter, id }
}) => {
  const options = React.useMemo(() => {
    const options = new Set();

    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });

    return transformOptionsForTables([...options.values()].filter(o => !!o), id);
  }, [id, preFilteredRows]);

  const value = typeof filterValue === 'string' ? getFilterTableSelected(id, filterValue) : filterValue;

  return (
    <Select
      className='react-select-container-no-border'
      classNamePrefix='react-select-no-border'
      options={[{ label: 'All', value: '' }, ...options]}
      value={value}
      onChange={(selected) => setFilter(selected.value || undefined)}
    />
  );
};

TableSelectFilter.propTypes = {
  column: PropTypes.object.isRequired
};

export default TableSelectFilter;
