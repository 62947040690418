import moment from 'moment';

import {
  SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD,
  SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD,
  SETTINGS_MANUAL_REVENUE_DEMAND_ID,
  SETTINGS_MANUAL_REVENUE_REPORT_DATE_FIELD,
  SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD
} from '../../../actions/types';

import { transformSingleSelect } from '../../wizards-manager/helpers/wizard-helpers';

export const transformManualRevenueData = (data) =>
  Object.keys(data)
    .reduce((acc, field) => {
      switch (field) {
        case SETTINGS_MANUAL_REVENUE_DEMAND_ID:
        case SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD:
          acc[field] = transformSingleSelect(data[field]);
          break;

        case SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD:
        case SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD:
          acc[field] = parseFloat(data[field]);
          break;
        case SETTINGS_MANUAL_REVENUE_REPORT_DATE_FIELD:
          acc[field] = moment(data[field]).format('Y-MM-DD');
          break;

        default:
          acc[field] = data[field];
      }

      return acc;
    }, {});
