import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  deleteDemandAction,
  duplicateDemandAction,
  editDemandAction,
  editDemandFromTableAction,
  fetchDemandsAction,
  updateDemandStatus
} from '../../../../actions/demand';

import { editPartnerFromNotificationsAction } from '../../../../actions/partners';

import {
  DEMAND_WIZARD,
  ACTIVE_FIELD,
  PARTNER_FIELD,
  TAGS_FIELD,
  SOURCE_FIELD,
  ID_FIELD,
  CREATED_AT_FIELD,
  PARTNER_ID_FIELD
} from '../../../wizards-manager/constants';

import { useTablesEffect } from '../../custom-hooks/useTablesEffect';

import { titleColumn } from '../../helpers';
import TablePanelSpinner from '../../shared/TablePanelSpinner';
import TableSelectFilter from '../../shared/TableSelectFilter';
import TableTagsCell from '../../shared/TableTagsCell';
import TableColumnsActions from '../../table-base/TableColumnsActions';
import TablesManager from '../../TablesManager';
import EndpointsDemandFeeds from '../endpoints/EndpointsDemandFeeds';

const getDemandColumns = (dispatch) => [
  {
    Header: 'Demand',
    accessor: ID_FIELD,
    width: 140,
    Cell: titleColumn(editDemandFromTableAction)
  },
  {
    Header: 'Active',
    accessor: ACTIVE_FIELD,
    minWidth: 70,
    Filter: TableSelectFilter,
    filter: 'equals',
    Cell: (data) => {
      const demand = data.row.original;

      const handleActivating = async (id, active) => {
        await updateDemandStatus(id, active)(dispatch);
      };

      return (
        <div className="switch-list">
          <label>
            <input
              type="checkbox"
              onChange={(e) => handleActivating(demand._id, e.target.checked)}
              checked={demand.active === 'Active'}
            />
          </label>
        </div>
      );
    }
  },
  {
    Header: 'Demand Source',
    accessor: PARTNER_FIELD,
    minWidth: 110,
    Filter: TableSelectFilter,
    filter: 'equals',
    Cell: (data) => {
      const endpoint = data.row.original;
      const partnerID = endpoint[PARTNER_ID_FIELD];

      return (
        <a
          style={{
            textDecoration: 'dashed underline',
            cursor: 'pointer',
            width: 'max-content'
          }}
          onClick={() => editPartnerFromNotificationsAction(partnerID)}
        >
          {endpoint[PARTNER_FIELD]}
        </a>
      );
    }
  },
  {
    Header: 'Tags',
    accessor: TAGS_FIELD,
    minWidth: 110,
    Cell: TableTagsCell
  },
  {
    Header: 'Endpoint feeds',
    id: 'endpoint_feeds',
    width: 200,
    Cell: (data) => {
      const demand = data.row.original;

      return (
        <EndpointsDemandFeeds
          source="endpoint"
          id={demand._id}
          updatedAt={demand.updatedAt}
        />
      );
    }
  },
  {
    Header: 'Assigned To',
    accessor: SOURCE_FIELD,
    Filter: TableSelectFilter,
    filter: 'equals',
    minWidth: 120
  },
  {
    Header: 'Created Date',
    id: 'createdDate',
    accessor: (d) => d[CREATED_AT_FIELD],
    Cell: ({ value }) => moment(value).format('DD/MM/YYYY')
  },
  {
    Header: 'Actions',
    id: 'action',
    width: 50,
    Cell: (data) => {
      const demand = data.row.original;

      return (
        <TableColumnsActions
          editItem={editDemandAction}
          duplicateItem={duplicateDemandAction}
          deleteItem={deleteDemandAction}
          data={demand}
        />
      );
    }
  },
  {
    Header: '',
    id: 'all',
    width: 0
  }
];

const DemandsTable = () => {
  const {
    demands,
    loading
  } = useSelector((state) => ({
    demands: state[DEMAND_WIZARD].demands,
    loading: state[DEMAND_WIZARD].loading
  }));

  const dispatch = useDispatch();

  useTablesEffect(fetchDemandsAction, 'all');

  const searchFields = [ACTIVE_FIELD, PARTNER_FIELD, SOURCE_FIELD];
  const hiddenColumns = [SOURCE_FIELD, CREATED_AT_FIELD];
  const demandColumns = getDemandColumns(dispatch);

  return (
    <>
      {loading ? (
        <TablePanelSpinner/>
      ) : (
        <TablesManager
          searchFields={searchFields}
          tableName="Demands"
          fields={demandColumns}
          items={demands}
          hiddenColumns={hiddenColumns}
        />
      )}
    </>
  );
};

export default DemandsTable;
