import React from 'react';
import { useSelector } from 'react-redux';

import {
  DRAWER_FORM_CREATE_ENDPOINT,
  DRAWER_FORM_CREATE_DEMAND,
  DRAWER_FORM_CREATE_PARTNER,
  DRAWER_FORM_ENDPOINT_DEMAND,
  DRAWER_FORM_FILTERS,
  DRAWER_FORM_REPORTS, DRAWER_FORM_CREATE_RULE
} from '../../actions/types';

import ReportsWizard from '../reports/ReportsWizard';
import RulesWizard from '../rules/RulesWizard';
import DashboardFilterForm from './wizards/dashboard/DashboardFilterForm';
import DemandWizard from './wizards/demand/DemandWizard';
import EndpointDemandsWizard from './wizards/endpoint-demands/EndpointDemandsWizard';
import EndpointWizard from './wizards/endpoint/EndpointWizard';
import PartnerWizard from './wizards/partner/PartnerWizard';

import './wizards.scss';

const WizardsManager = () => {
  const { activeForm } = useSelector(state => ({
    activeForm: state.drawerForms.activeForm
  }));

  const renderWizard = (activeForm) => {
    switch (activeForm) {
      case DRAWER_FORM_CREATE_ENDPOINT:
        return <EndpointWizard/>;

      case DRAWER_FORM_CREATE_DEMAND:
        return <DemandWizard/>;

      case DRAWER_FORM_CREATE_PARTNER:
        return <PartnerWizard/>;

      case DRAWER_FORM_ENDPOINT_DEMAND:
        return <EndpointDemandsWizard/>;

      case DRAWER_FORM_FILTERS:
        return <DashboardFilterForm/>;

      case DRAWER_FORM_REPORTS:
        return <ReportsWizard/>;

      case DRAWER_FORM_CREATE_RULE:
        return <RulesWizard/>;
    }
  };

  return (
    <>
      {renderWizard(activeForm)}
    </>
  );
};

export default WizardsManager;
