import moment from 'moment';

import {
  DASHBOARD_START_FETCHING_TABLE_DATA,
  DASHBOARD_UPDATE_TABLE,
  DASHBOARD_ENDPOINT_ID_FIELD,
  DASHBOARD_ENDPOINT_TAGS_FIELD,
  DASHBOARD_DEMAND_FEEDS_FIELD,
  DASHBOARD_COUNTRIES_FIELD,
  DASHBOARD_GET_INITIAL_FILTER_STATE,
  DASHBOARD_UPDATE_FILTER_STATE,
  DASHBOARD_UPDATE_INTERVAL,
  DASHBOARD_UPDATE_DATA_RANGE,
  DASHBOARD_INTERVAL_CUSTOM,
  DASHBOARD_TIMEZONE_FIELD,
  DASHBOARD_DEMAND_TAGS_FIELD,
  DASHBOARD_ENDPOINT_STATUS_FIELD,
  DASHBOARD_ASSIGNEE_FIELD,
  DASHBOARD_FETCH_MAIN_CHART,
  DASHBOARD_START_FETCHING_MAIN_CHART_DATA,
  DASHBOARD_START_FETCHING_PIE_CHART_DATA,
  DASHBOARD_FETCH_PIE_CHART,
  DASHBOARD_EP_SOURCE_FIELD,
  DASHBOARD_DF_SOURCE_FIELD,
  DASHBOARD_INTERVAL_TODAY,
  DASHBOARD_UPDATE_TIMEZONE
} from '../actions/types';

export const allObject = { label: 'All', value: '*' };

const initialFilter = {
  [DASHBOARD_ENDPOINT_ID_FIELD]: [allObject],
  [DASHBOARD_ENDPOINT_TAGS_FIELD]: [allObject],
  [DASHBOARD_DEMAND_TAGS_FIELD]: [allObject],
  [DASHBOARD_DEMAND_FEEDS_FIELD]: [allObject],
  [DASHBOARD_COUNTRIES_FIELD]: [allObject],
  [DASHBOARD_TIMEZONE_FIELD]: {
    label: '(GMT+00:00) UTC',
    value: '+0000'
  },
  [DASHBOARD_EP_SOURCE_FIELD]: [allObject],
  [DASHBOARD_DF_SOURCE_FIELD]: [allObject],
  [DASHBOARD_ENDPOINT_STATUS_FIELD]: allObject,
  [DASHBOARD_ASSIGNEE_FIELD]: [allObject]
};

const initialState = {
  tableLoading: true,
  table: null,
  filters: initialFilter,
  interval: DASHBOARD_INTERVAL_TODAY,
  dateRange: {
    from: moment(new Date()).startOf('day').toDate(),
    to: moment(new Date()).endOf('day').toDate()
  },
  mainChart: [],
  loadingMainChart: false,

  pieChart: [],
  loadingPieChart: true
};

const dashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case DASHBOARD_START_FETCHING_TABLE_DATA:
      return {
        ...state,
        tableLoading: true
      };

    case DASHBOARD_UPDATE_TABLE:
      return {
        ...state,
        tableLoading: false,
        table: payload
      };

    case DASHBOARD_GET_INITIAL_FILTER_STATE:
      return {
        ...state,
        filters: initialFilter,
        interval: DASHBOARD_INTERVAL_TODAY,
        dateRange: {
          from: moment(new Date()).startOf('day').toDate(),
          to: moment(new Date()).endOf('day').toDate()
        }
      };

    case DASHBOARD_UPDATE_FILTER_STATE:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload
        }
      };

    case DASHBOARD_UPDATE_INTERVAL:
      return {
        ...state,
        interval: payload
      };

    case DASHBOARD_UPDATE_DATA_RANGE:
      return {
        ...state,
        dateRange: payload,
        interval: DASHBOARD_INTERVAL_CUSTOM
      };

    case DASHBOARD_START_FETCHING_MAIN_CHART_DATA:
      return {
        ...state,
        loadingMainChart: true
      };

    case DASHBOARD_FETCH_MAIN_CHART:
      return {
        ...state,
        mainChart: payload,
        loadingMainChart: false
      };

    case DASHBOARD_START_FETCHING_PIE_CHART_DATA:
      return {
        ...state,
        loadingPieChart: true
      };

    case DASHBOARD_FETCH_PIE_CHART:
      return {
        ...state,
        pieChart: payload,
        loadingPieChart: false
      };

    case DASHBOARD_UPDATE_TIMEZONE:
      return {
        ...state,
        filters: {
          ...state.filters,
          [DASHBOARD_TIMEZONE_FIELD]: payload
        }
      };

    default:
      return {
        ...state
      };
  }
};

export default dashboardReducer;
