import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Landing from './components/layout/Landing';
import RoutesTree from './components/routing/RoutesTree';
import AuthState from './context/auth/AuthState';

import NotificationsState from './context/notifications/NotificationsState';
import store from './store';

import './App.scss';

const App = () => {
  return (
    <AuthState>
      <NotificationsState>
        <Provider store={store}>
          <Router>
            <Switch>
              <Route exact path="/" component={Landing}/>
              <Route component={RoutesTree}/>
            </Switch>
          </Router>
        </Provider>
      </NotificationsState>
    </AuthState>
  );
};

export default App;
