import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { fetchMainChartData } from '../../../actions/dashboard';

import {
  DASHBOARD_BREAKDOWN_DAY,
  DASHBOARD_BREAKDOWN_WEEK,
  DASHBOARD_FILTERS,
  DASHBOARD_INTERVAL,
  DASHBOARD_INTERVAL_LAST_48_HOURS,
  DASHBOARD_INTERVAL_TODAY, DASHBOARD_INTERVAL_YESTERDAY,
  DASHBOARD_MAIN_CHART_EST_EARNINGS,
  DASHBOARD_MAIN_CHART_EST_VS_APPROVED_EARNINGS
} from '../../../actions/types';

import arrow from '../../../assets/images/icons/ico_arrow_down.svg';
import { useAuth } from '../../../context/auth/AuthState';
import TablePanelSpinner from '../../tables-manager/shared/TablePanelSpinner';

import {
  breakdownLabels,
  buildBreakdown,
  buildItems,
  getFormatter,
  getGraphList,
  metricChartOptions
} from './helpers/metirc-chart-helpers';

import './MetricChart.scss';

const MetricChart = () => {
  const { isPartner } = useAuth();

  const [selectedChart, setSelectedChart] = useState(isPartner ? DASHBOARD_MAIN_CHART_EST_VS_APPROVED_EARNINGS : DASHBOARD_MAIN_CHART_EST_EARNINGS);

  const chartOptions = {
    ...metricChartOptions,
    yaxis: {
      labels: {
        ...metricChartOptions.yaxis.labels,
        formatter: (value) => getFormatter(selectedChart)(value)
      }
    },
    tooltip: {
      ...metricChartOptions.tooltip,
      y: {
        formatter: (value) => getFormatter(selectedChart)(value)
      }
    }
  };

  const dispatch = useDispatch();

  const {
    data,
    loading,
    filters,
    interval,
    dateRange
  } = useSelector(
    state => ({
      data: state.dashboard.mainChart,
      loading: state.dashboard.loadingMainChart,
      filters: state.dashboard[DASHBOARD_FILTERS],
      interval: state.dashboard[DASHBOARD_INTERVAL],
      dateRange: state.dashboard.dateRange
    })
  );

  const [items, setItems] = useState([]);

  const [breakdownData, setBreakdownData] = useState({
    breakdownList: [DASHBOARD_BREAKDOWN_DAY, DASHBOARD_BREAKDOWN_WEEK],
    breakdown: DASHBOARD_BREAKDOWN_DAY
  });

  // Handle items
  useEffect(() => {
    setItems(buildItems(data, selectedChart, isPartner));
  }, [selectedChart, JSON.stringify(data)]);

  // Handle categories and breakdown
  useEffect(() => {
    setBreakdownData(buildBreakdown(interval, dateRange));
  }, [JSON.stringify(dateRange), interval]);

  useEffect(() => {
    const isToday = interval === DASHBOARD_INTERVAL_TODAY ||
      interval === DASHBOARD_INTERVAL_LAST_48_HOURS ||
      interval === DASHBOARD_INTERVAL_YESTERDAY;

    if (isToday) {
      setSelectedChart(DASHBOARD_MAIN_CHART_EST_EARNINGS);
    }

    if (!isToday) {
      setSelectedChart(DASHBOARD_MAIN_CHART_EST_VS_APPROVED_EARNINGS);
    }
  }, [interval]);

  // Handle data for chart
  useEffect(() => {
    (async () => {
      const breakdownData = buildBreakdown(interval, dateRange);
      await fetchMainChartData({ ...filters }, breakdownData.breakdown, interval, dateRange)(dispatch);
    })();
  }, [JSON.stringify(filters), interval, JSON.stringify(dateRange)]);

  const handleManualBreakdownChange = async (newBreakdownData) => {
    if (breakdownData.breakdown === newBreakdownData.breakdown) {
      return;
    }

    setBreakdownData(newBreakdownData);
    await fetchMainChartData({ ...filters }, newBreakdownData.breakdown, interval, dateRange)(dispatch);
  };

  return (
    <div className='metric-chart-box block-inner'>
      <div className="metric-chart">
        <div className="metric-chart-top clearfix">
          <div className="metric-chart-top-left">
            <Dropdown>
              <Dropdown.Toggle variant='metrics' id='dropdown-metric'>
                <span>{selectedChart}</span>
                <img className='arrow' src={arrow} alt='arrow'/>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {getGraphList(
                  interval === DASHBOARD_INTERVAL_TODAY ||
                  interval === DASHBOARD_INTERVAL_LAST_48_HOURS ||
                  interval === DASHBOARD_INTERVAL_YESTERDAY,
                  isPartner)
                  .map((item, index) => (
                    <Dropdown.Item
                      href="#!"
                      key={index}
                      onClick={() => setSelectedChart(item)}
                    >
                      {item}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="metric-chart-top-right">
            {breakdownData.breakdownList.map((breakdown, index) => (
              <div
                onClick={() => handleManualBreakdownChange({ ...breakdownData, breakdown })}
                className={`metric-chart-right-item ${breakdownData.breakdown === breakdown ? 'active' : ''}`}
                key={index}
              >
                {breakdownLabels[breakdown]}
              </div>
            ))}
          </div>
        </div>

        <div className="metric-chart-chart block-content">
          {loading ? (
            <TablePanelSpinner/>
          ) : (
            <ReactApexChart
              options={{ ...chartOptions, [selectedChart]: true }}
              series={items}
              type="line"
              height="300"/>
          )}
        </div>
      </div>
    </div>
  );
};

export default MetricChart;
