import * as yup from 'yup';

import {
  ENDPOINT_DEFAULT_DOMAIN_FIELD,
  ENDPOINT_IP_MISMATCH_FIELD,
  ENDPOINT_IP_MISMATCH_USE_DEFAULT_FIELD,
  ENDPOINT_NO_BID_FIELD,
  ENDPOINT_NO_BID_USE_DEFAULT_FIELD,
  ENDPOINT_OTHER_CLICK_FILTERS_FIELD,
  ENDPOINT_OTHER_CLICK_FILTERS_USE_DEFAULT_FIELD,
  ENDPOINT_TARGETING_MISMATCH_FIELD,
  ENDPOINT_TARGETING_MISMATCH_USE_DEFAULT_FIELD
} from '../../constants';

import { urlPattern } from '../../constants/patterns';

export const fallbackSchema = yup.object().shape({
  // TODO replace with string.url(message?: string | function): Schema ?
  [ENDPOINT_DEFAULT_DOMAIN_FIELD]: yup.string().matches(urlPattern, 'Invalid url address'),
  [ENDPOINT_NO_BID_FIELD]: yup.string().matches(urlPattern, 'Invalid url address'),
  [ENDPOINT_NO_BID_USE_DEFAULT_FIELD]: yup.bool(),
  [ENDPOINT_TARGETING_MISMATCH_FIELD]: yup.string().matches(urlPattern, 'Invalid url address'),
  [ENDPOINT_TARGETING_MISMATCH_USE_DEFAULT_FIELD]: yup.bool(),
  [ENDPOINT_IP_MISMATCH_FIELD]: yup.string().matches(urlPattern, 'Invalid url address'),
  [ENDPOINT_IP_MISMATCH_USE_DEFAULT_FIELD]: yup.bool(),
  [ENDPOINT_OTHER_CLICK_FILTERS_FIELD]: yup.string().matches(urlPattern, 'Invalid url address'),
  [ENDPOINT_OTHER_CLICK_FILTERS_USE_DEFAULT_FIELD]: yup.bool()
});

export const validateFallbackData = (data) => {
  try {
    fallbackSchema.validateSync(data);

    return true;
  } catch (e) {
    return false;
  }
};
