import { searchDemands } from '../../../../../api/demand';
import { searchEndpoints } from '../../../../../api/endpoint';
import { searchPartners } from '../../../../../api/partners';
import { allObject } from '../../../../../reducers/dashboard';

export const loadTrafficSourceOptions = async (search, loadedOptions, { page }) => {
  const data = {
    type: ['isBuy', 'both'],
    filter: { page, limit: 10, search }
  };

  const response = await searchPartners(data);

  const options = [allObject, ...response.data.results.map(({ label, value }) => ({
    label: `${label} (${value})`,
    value
  }))];

  return {
    options,
    hasMore: response.data.has_more,
    additional: {
      page: page + 1
    }
  };
};

export const loadDemandSourceOptions = async (search, loadedOptions, { page }) => {
  const data = {
    type: ['isSell', 'both'],
    filter: { page, limit: 10, search }
  };

  const response = await searchPartners(data);

  const options = [allObject, ...response.data.results.map(({ label, value }) => ({
    label: `${label} (${value})`,
    value
  }))];

  return {
    options,
    hasMore: response.data.has_more,
    additional: {
      page: page + 1
    }
  };
};

export const loadEndpointOptions = async (search, loadedOptions, { page }) => {
  const data = {
    filter: { page, limit: 10, search }
  };

  const response = await searchEndpoints(data);

  const options = [allObject, ...response.data.results.map(({ label, value }) => ({
    label: `${label} (${value})`,
    value
  }))];

  return {
    options,
    hasMore: response.data.has_more,
    additional: {
      page: page + 1
    }
  };
};

export const loadDemandOptions = async (search, loadedOptions, { page }) => {
  const data = {
    filter: { page, limit: 10, search }
  };

  const response = await searchDemands(data);

  const options = [allObject, ...response.data.results.map(({ label, value }) => ({
    label: `${label} (${value})`,
    value
  }))];

  return {
    options,
    hasMore: response.data.has_more,
    additional: {
      page: page + 1
    }
  };
};
