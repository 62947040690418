import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Creatable from 'react-select/creatable';

import { updateDemandTagsAction } from '../../../actions/demand';
import { updateEndpointTagsAction } from '../../../actions/endpoint';
import { updatePartnerTagsAction } from '../../../actions/partners';

import { updateRuleTagsAction } from '../../../actions/rules';
import { rulesTempTags } from '../../rules/helpers';
import { LABEL_FIELD, VALUE_FIELD } from '../../wizards-manager/constants';

import { transformMultiSelect } from '../../wizards-manager/helpers/wizard-helpers';
import { transformTagsToFormTags, transformTagsToTableTags } from '../helpers/transormers-helpers';

import './TableTagsCell.scss';

const customStyles = {
  indicatorsContainer: (styles) => ({
    ...styles,
    display: 'none'
  })
};

const TableTagsCell = (data) => {
  const {
    staticList
  } = useSelector(state => ({
    staticList: state.staticList
  }));

  const value = data.value;
  const firstColumn = data.allColumns[0].Header;
  const id = data.row.original._id;

  const [options, setOptions] = useState(firstColumn === 'Rule' ? rulesTempTags : staticList.tagOptions);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    setOptions(firstColumn === 'Rule' ? rulesTempTags : staticList.tagOptions);
  }, [staticList.staticListLoading, firstColumn]);

  useEffect(() => {
    let data = value;

    if (data.length) {
      const first = data[0];

      if (typeof first !== 'string') {
        data = transformMultiSelect(data);
      }
    }

    setTags(transformTagsToFormTags(data, options));
  }, [JSON.stringify(value), JSON.stringify(options)]);

  const handleOnSave = useCallback((e) => {
    e.preventDefault();

    let fn;

    switch (firstColumn) {
      case 'Endpoint':
        fn = updateEndpointTagsAction;
        break;

      case 'Demand':
        fn = updateDemandTagsAction;
        break;

      case 'Partner':
        fn = updatePartnerTagsAction;
        break;

      case 'Rule':
        fn = updateRuleTagsAction;
        break;
    }

    fn(id, tags);

    setEditing(false);
  }, [firstColumn, id, JSON.stringify(tags)]);

  const handleOnChange = useCallback((selected) => {
    if (!selected) {
      return setTags([]);
    }

    setTags(selected.map(t => ({
      [LABEL_FIELD]: t[LABEL_FIELD],
      [VALUE_FIELD]: t[VALUE_FIELD]
    })));
  }, []);

  const [editing, setEditing] = useState(false);

  const handleOnCancel = useCallback(() => {
    let data = value;

    if (data.length) {
      const first = data[0];

      if (typeof first !== 'string') {
        data = transformMultiSelect(data);
      }
    }

    setTags(transformTagsToFormTags(data, options));
    setEditing(false);
  }, [JSON.stringify(value), JSON.stringify(options)]);

  return (
    <div className="table-tags-cell">
      {editing ? (
        <div className="tags-cell-box">
          <Creatable
            components={{ DropdownIndicator: () => null }}
            styles={customStyles}
            className='the-field selectable'
            classNamePrefix='react-select'
            onChange={handleOnChange}
            value={tags}
            isMulti={true}
            options={options}
            closeMenuOnSelect={false}
          />
          <div className="tags-cell-actions">
            <div onClick={handleOnCancel} className="tags-cell-closing"><i className="fas fa-times"/></div>
            <div onClick={handleOnSave} className="tags-cell-submit"><i className="fas fa-check"/></div>
          </div>
        </div>
      ) : (
        <div className="tags-cell-box">
          <div>{transformTagsToTableTags(transformMultiSelect(tags), options)}</div>
          <div onClick={() => setEditing(true)} className="tags-cell-action">
            <img title="Edit tags" src={require('../../../assets/images/icons/ico_edit.svg')} alt=""/>
            <img title="Edit tags" className='img-active' src={require('../../../assets/images/icons/ico_edit_o.svg')} alt=''/>
          </div>
        </div>
      )}
    </div>
  );
};

TableTagsCell.propTypes = {
  data: PropTypes.any.isRequired
};

export default TableTagsCell;
