import * as yup from 'yup';

import {
  SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD,
  SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD,
  SETTINGS_MANUAL_REVENUE_DEMAND_ID,
  SETTINGS_MANUAL_REVENUE_REPORT_DATE_FIELD,
  SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD
} from '../../../actions/types';

import { emptyStringToNull } from '../../wizards-manager/validators/shared/optimization';
import { selectSchema } from '../../wizards-manager/validators/shared/shared-schemas';

export const manualRevenueUpdateSchema = yup.object().shape({
  [SETTINGS_MANUAL_REVENUE_REPORT_DATE_FIELD]: yup.date().required('Date is required'),
  [SETTINGS_MANUAL_REVENUE_DEMAND_ID]: selectSchema.required('Demand is required').nullable(),
  [SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD]: selectSchema.required('Timezone is required').nullable(),
  [SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD]: yup.number().required('Clicks count is required').transform(emptyStringToNull).nullable(),
  [SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD]: yup.number().required('Revenue is required').transform(emptyStringToNull).nullable()
});
