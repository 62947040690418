import axios from 'axios';

import { AXIOS_CONFIG } from '../constant';

export const fetchAllNotifications = () => axios.get('/api/notifications');

export const fetchNotificationById = (id) => axios.get(`/api/notifications/${id}`);

export const markNotificationAsReadById = (id) => axios.patch(`/api/notifications/read/${id}`, AXIOS_CONFIG);

export const markAllNotificationsAsRead = () => axios.patch('/api/notifications/read-all');
