import axios from 'axios';

import { AXIOS_CONFIG } from '../constant';

export const fetchStaticList = () =>
  axios
    .all([
      axios.get('/api/data/tags'),
      axios.get('/api/data/carrier'),
      axios.get('/api/data/iabcats'),
      axios.get('/api/data/connectiontype'),
      axios.get('/api/data/domainmasking'),
      axios.get('/api/data/metrics'),
      axios.get('/api/data/country'),
      axios.get('/api/data/action'),
      axios.get('/api/data/redirect-domain'),
      axios.get('/api/data/os'),
      axios.get('/api/data/browser'),
      axios.get('/api/data/device'),
      axios.get('/api/data/plugin'),
      axios.get('/api/data/rule-tags'),
      axios.get('/api/data/rule-actions')
    ]);

export const saveCarrier = (data) => axios.post('/api/data/carrier', data, AXIOS_CONFIG);

export const modifyCarrier = (data) => axios.put('/api/data/carrier', data, AXIOS_CONFIG);

export const removeCarrier = (id) => axios.delete(`/api/data/carrier/${id}`);

export const saveCountry = (data) => axios.post('/api/data/country', data, AXIOS_CONFIG);

export const modifyCountry = (data) => axios.put('/api/data/country', data, AXIOS_CONFIG);

export const removeCountry = (id) => axios.delete(`/api/data/country/${id}`);

export const saveRuleContext = (data) => axios.post('/api/data/action', data, AXIOS_CONFIG);

export const modifyRuleContext = (id, data) => axios.put(`/api/data/action/${id}`, data, AXIOS_CONFIG);

export const removeRuleContext = (id) => axios.delete(`/api/data/action/${id}`);

export const saveMetricDimension = (data) => axios.post('/api/data/metrics', data, AXIOS_CONFIG);

export const modifyMetricDimension = (id, data) => axios.put(`/api/data/metrics/${id}`, data, AXIOS_CONFIG);

export const removeMetricDimension = (id) => axios.delete(`/api/data/metrics/${id}`);

export const saveTag = (data) => axios.post('/api/data/tags', data, AXIOS_CONFIG);

export const modifyTag = (id, data) => axios.put(`/api/data/tags/${id}`, data, AXIOS_CONFIG);

export const removeTag = (id) => axios.delete(`/api/data/tags/${id}`);

export const saveIABCAT = (data) => axios.post('/api/data/iabcats', data, AXIOS_CONFIG);

export const modifyIABCAT = (id, data) => axios.put(`/api/data/iabcats/${id}`, data, AXIOS_CONFIG);

export const removeIABCAT = (id) => axios.delete(`/api/data/iabcats/${id}`);

export const saveDomainMasking = (data) => axios.post('/api/data/domainmasking', data, AXIOS_CONFIG);

export const modifyDomainMasking = (id, data) => axios.put(`/api/data/domainmasking/${id}`, data, AXIOS_CONFIG);

export const removeDomainMasking = (id) => axios.delete(`/api/data/domainmasking/${id}`);

export const saveRedirectDomain = (data) => axios.post('/api/data/redirect-domain', data, AXIOS_CONFIG);

export const modifyRedirectDomain = (id, data) => axios.put(`/api/data/redirect-domain/${id}`, data, AXIOS_CONFIG);

export const removeRedirectDomain = (id) => axios.delete(`/api/data/redirect-domain/${id}`);

export const saveOS = (data) => axios.post('/api/data/os', data, AXIOS_CONFIG);

export const modifyOS = (id, data) => axios.put(`/api/data/os/${id}`, data, AXIOS_CONFIG);

export const removeOS = (id) => axios.delete(`/api/data/os/${id}`);

export const saveBrowser = (data) => axios.post('/api/data/browser', data, AXIOS_CONFIG);

export const modifyBrowser = (id, data) => axios.put(`/api/data/browser/${id}`, data, AXIOS_CONFIG);

export const removeBrowser = (id) => axios.delete(`/api/data/browser/${id}`);
