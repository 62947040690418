import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useAuth } from '../../context/auth/AuthState';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isPartner, isAuthenticated, loading } = useAuth();

  return (
    <Route
      {...rest}
      render={props => {
        if (!isAuthenticated && !loading) {
          return <Redirect to="/login"/>;
        }

        if (isPartner) {
          // eslint-disable-next-line react/prop-types
          const path = props.location.pathname;

          if (path !== '/dashboard') {
            return <Redirect to="/dashboard"/>;
          }
        }

        return <Component {...props}/>;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType
};

export default PrivateRoute;
