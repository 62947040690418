import axios from 'axios';

import { AXIOS_CONFIG } from '../constant';

export const fetchPartnerById = (id) => axios.get(`/api/partners/${id}`);

export const savePartner = (data) => axios.post('/api/partners', data, AXIOS_CONFIG);

export const modifyPartner = (id, data) => axios.put(`/api/partners/${id}`, data, AXIOS_CONFIG);

export const removePartner = (id) => axios.delete(`/api/partners/${id}`);

export const searchPartners = (data) => axios.post('/api/partners/search', data, AXIOS_CONFIG);
