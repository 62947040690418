export const transformSingleSelect = (selected) => selected.value;

export const transformMultiSelect = (selected) => selected.map(s => s.value);

export const filterAllList = (list, operation) => {
  if (operation.action === 'select-option') {
    const value = operation.option.value;

    if (value === '*') {
      return [operation.option];
    }
  }

  return list.filter(item => item.value !== '*');
};

export const handleArrayHaveError = (errors, field, subField, index, secondField) => {
  if (errors[field]) {
    if (secondField) {
      if (errors[field][secondField]) {
        if (errors[field][secondField][index]) {
          if (errors[field][secondField][index][subField]) {
            return true;
          }
        }
      }
    } else {
      if (errors[field][index]) {
        if (errors[field][index][subField]) {
          return true;
        }
      }
    }
  }

  return false;
};

export const handleArrayErrorMessage = (errors, field, subField, index, secondField) => {
  if (errors[field]) {
    if (secondField) {
      if (errors[field][secondField]) {
        if (errors[field][secondField][index]) {
          if (errors[field][secondField][index][subField]) {
            return errors[field][secondField][index][subField].message;
          }
        }
      }
    } else {
      if (errors[field][index]) {
        if (errors[field][index][subField]) {
          return errors[field][index][subField].message;
        }
      }
    }
  }

  return '';
};

export const handleNestedHaveError = (errors, field, subField) => {
  if (errors[field]) {
    return !!errors[field][subField];
  }

  return false;
};

export const handleNestedErrorMessage = (errors, field, subField) => {
  if (errors[field]) {
    if (errors[field][subField]) {
      return errors[field][subField].message;
    }
  }

  return '';
};

export const filterEndpointDemandsOptions = (allDemands, selectedDemands) => {
  const selectedIds = selectedDemands.map(s => s.value);

  return allDemands
    .filter(d => !selectedIds.includes(d._id))
    .map(d => ({
      label: d.title,
      value: d._id,
      active: d.active || false
    }));
};

export const getOptions = (options) =>
  Object.keys(options).length ? options : [];
