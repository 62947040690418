import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { updateDemandFormDataAction } from '../../../../../actions/demand';
import { updateEndpointFormDataAction } from '../../../../../actions/endpoint';
import { searchPartners } from '../../../../../api/partners';
import generate from '../../../../../utils/string-generator';

import TableConfirmation from '../../../../tables-manager/shared/TableConfirmation';
import {
  AUTH_FIELD,
  DEMAND_WIZARD,
  DMS_DB_FIELD,
  ENDPOINT_WIZARD,
  IAB_CAT_FIELD,
  PARTNER_FIELD,
  TAGS_FIELD,
  SUB_ID_DB_FIELD,
  OUTPUT_FIELD,
  REDIRECT_TYPE_FIELD,
  RENDER_TYPE_FIELD,
  ENDPOINT_SUB_ID_ENCRYPTION_FIELD,
  ENDPOINT_SUB_ID_STRATEGY_FIELD,
  BRANDING_TYPE_FIELD 
} from '../../../constants';

import { useFormsEffect } from '../../../custom-hooks/useFormsEffect';
import { useIsValidForm } from '../../../custom-hooks/useIsValidForm';
import { redirectTypeOptions, renderOutputOptions, segmentationOptions, segmentationEncOptions} from '../../../helpers/staticData';
import {
  generalEndpointSchema,
  generalSchema,
  validateGeneralData,
  validateGeneralEndpointData
} from '../../../validators/shared/general';

import WizardInputWithValidation from '../../shared/WizardInputWithValidation';
import WizardSelectWithValidation, { ASYNC_TYPE, CREATE_TYPE, SELECT_TYPE } from '../WizardSelectWithValidation';

import '../../../wizard-base/WizardSharedStyles.scss';
import './WizardGeneralForm.scss';

const WizardGeneralForm = ({
  isSubmitted,
  onSubmit,
  parentWizard,
  setIsValid,
  setPartner
}) => {
  const {
    defaultValues,
    tagOptions,
    iabcatOptions,
    id
  } = useSelector(
    state => ({
      defaultValues: parentWizard === ENDPOINT_WIZARD ? {
        [PARTNER_FIELD]: state[parentWizard].formData[PARTNER_FIELD],
        [TAGS_FIELD]: state[parentWizard].formData[TAGS_FIELD],
        [IAB_CAT_FIELD]: state[parentWizard].formData[IAB_CAT_FIELD],
        [AUTH_FIELD]: state[parentWizard].formData[AUTH_FIELD],
        [DMS_DB_FIELD]: state[parentWizard].formData[DMS_DB_FIELD],
        [SUB_ID_DB_FIELD]: state[parentWizard].formData[SUB_ID_DB_FIELD],
        [BRANDING_TYPE_FIELD]: state[parentWizard].formData[BRANDING_TYPE_FIELD],
        [ENDPOINT_SUB_ID_ENCRYPTION_FIELD]: state[parentWizard].formData[ENDPOINT_SUB_ID_ENCRYPTION_FIELD],
        [ENDPOINT_SUB_ID_STRATEGY_FIELD]: state[parentWizard].formData[ENDPOINT_SUB_ID_STRATEGY_FIELD],
        [OUTPUT_FIELD]: {
          [REDIRECT_TYPE_FIELD]: state[parentWizard].formData[OUTPUT_FIELD][REDIRECT_TYPE_FIELD],
          [RENDER_TYPE_FIELD]: state[parentWizard].formData[OUTPUT_FIELD][RENDER_TYPE_FIELD]
        }
      } : {
        [PARTNER_FIELD]: state[parentWizard].formData[PARTNER_FIELD],
        [TAGS_FIELD]: state[parentWizard].formData[TAGS_FIELD],
        [IAB_CAT_FIELD]: state[parentWizard].formData[IAB_CAT_FIELD],
        [AUTH_FIELD]: state[parentWizard].formData[AUTH_FIELD],
        [DMS_DB_FIELD]: state[parentWizard].formData[DMS_DB_FIELD],
        [SUB_ID_DB_FIELD]: state[parentWizard].formData[SUB_ID_DB_FIELD]
      },
      id: state[parentWizard].formData._id,
      tagOptions: state.staticList.tagOptions,
      iabcatOptions: state.staticList.iabcatOptions,
      redirectDomainOptions: state.staticList.redirectDomainOptions,
      domainMaskingOptions: state.staticList.domainMaskingOptions,
      segmentationEncOptions: state.staticList.segmentationEncOptions,
      segmentationOptions: state.staticList.segmentationOptions
    })
  );

  const {
    register,
    errors,
    triggerValidation,
    control,
    getValues,
    setValue,
    watch
  } = useForm({
    mode: 'onBlur',
    defaultValues,
    validationSchema: parentWizard === ENDPOINT_WIZARD ? generalEndpointSchema : generalSchema
  });

  const updateState = parentWizard === ENDPOINT_WIZARD ? updateEndpointFormDataAction : updateDemandFormDataAction;

  useFormsEffect(isSubmitted, triggerValidation, getValues, updateState, onSubmit);
  const values = getValues({ nest: true });
  const isValid = parentWizard === ENDPOINT_WIZARD
    ? validateGeneralEndpointData(Object.keys(values).length ? values : defaultValues)
    : validateGeneralData(Object.keys(values).length ? values : defaultValues);

  useIsValidForm(isValid, setIsValid);

  const [showAuth, setShowAuth] = useState(false);

  const handleChangeAuth = () => {
    setValue(AUTH_FIELD, generate(12));
    setShowAuth(false);
  };

  const loadOptions = async (search, loadedOptions, { page }) => {
    const type = ['both'];

    if (parentWizard === ENDPOINT_WIZARD) {
      type.push('isBuy');
    } else {
      type.push('isSell');
    }

    const data = {
      type,
      filter: { page, limit: 10, search }
    };

    const response = await searchPartners(data);

    return {
      options: response.data.results,
      hasMore: response.data.has_more,
      additional: {
        page: page + 1
      }
    };
  };

  const partner = watch(PARTNER_FIELD, defaultValues[PARTNER_FIELD]);

  useEffect(() => {
    if (setPartner) {
      setPartner(partner);
    }
  }, [partner]);

  return (
    <form className="wizard-general-form">
      {id && (
        <div className="wizard-id-box"><span>Ref Id:</span>&nbsp;{id}</div>
      )}

      <WizardSelectWithValidation
        name={PARTNER_FIELD}
        control={control}
        label='Source'
        selectType={ASYNC_TYPE}
        error={!!errors[PARTNER_FIELD]}
        errorMessage={errors[PARTNER_FIELD] ? errors[PARTNER_FIELD].message : ''}
        isMulti={false}
        options={[]}
        defaultValue={defaultValues[PARTNER_FIELD]}
        loadOptions={loadOptions}
      />

      <WizardSelectWithValidation
        name={TAGS_FIELD}
        control={control}
        label='Tags'
        placeholder='#mainstream,...'
        selectType={CREATE_TYPE}
        error={!!errors[TAGS_FIELD]}
        errorMessage={errors[TAGS_FIELD] ? errors[TAGS_FIELD].message : ''}
        isMulti={true}
        options={tagOptions}
        defaultValue={defaultValues[TAGS_FIELD]}
      />

      <WizardSelectWithValidation
        name={IAB_CAT_FIELD}
        control={control}
        label='iAB Category'
        selectType={SELECT_TYPE}
        error={!!errors[IAB_CAT_FIELD]}
        errorMessage={errors[IAB_CAT_FIELD] ? errors[IAB_CAT_FIELD].message : ''}
        isMulti={false}
        options={iabcatOptions}
        defaultValue={defaultValues[IAB_CAT_FIELD]}
      />

      <div className="form-group">
        <div className="row">
          <div className="col-sm-11">
            <WizardInputWithValidation
              label="Auth"
              type="text"
              error={!!errors[AUTH_FIELD]}
              errorMessage={errors[AUTH_FIELD] ? errors[AUTH_FIELD].message : ''}
              name={AUTH_FIELD}
              propRef={register}
              placeholder="No Auth"
            />
          </div>

          <div className="col-sm-1" style={{ marginTop: 20 }}>
            <div className="form-group wizard-general-form-auth">
              <label htmlFor=""/>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShowAuth(state => !state);
                }}
                className="btn"
                style={{ padding: 0 }}
              >
                <img
                  style={{ marginLeft: 8, width: 33, height: 33 }}
                  className='img-active'
                  src={require('../../../../../assets/images/icons/ico_refresh_o.svg')}
                  alt='sdg'
                  onMouseOver={(e) => {
                    e.currentTarget.src = require('../../../../../assets/images/icons/ico_refresh.svg');
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.src = require('../../../../../assets/images/icons/ico_refresh_o.svg');
                  }}
                />
              </button>

              {showAuth && (
                <TableConfirmation
                  onSubmit={handleChangeAuth}
                  withoutDispatch={true}
                  handleCancel={() => setShowAuth(false)}
                  title='Generate a New Auth Token?'
                  data={getValues({ nest: true })[AUTH_FIELD] || generate(12)}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {parentWizard === ENDPOINT_WIZARD && (
        <>
        
          <WizardSelectWithValidation
            name={ENDPOINT_SUB_ID_STRATEGY_FIELD}
            control={control}
            label='Segmentation Strategy'
            selectType={SELECT_TYPE}
            error={!!errors[ENDPOINT_SUB_ID_STRATEGY_FIELD]}
            errorMessage={errors[ENDPOINT_SUB_ID_STRATEGY_FIELD] ? errors[ENDPOINT_SUB_ID_STRATEGY_FIELD].message : ''}
            isMulti={false}
            options={segmentationOptions}
            defaultValue={defaultValues[ENDPOINT_SUB_ID_STRATEGY_FIELD]}
          />

          <WizardSelectWithValidation
            name={ENDPOINT_SUB_ID_ENCRYPTION_FIELD}
            control={control}
            label='Segmentation Encryption'
            selectType={SELECT_TYPE}
            error={!!errors[ENDPOINT_SUB_ID_ENCRYPTION_FIELD]}
            errorMessage={errors[ENDPOINT_SUB_ID_ENCRYPTION_FIELD] ? errors[ENDPOINT_SUB_ID_ENCRYPTION_FIELD].message : ''}
            isMulti={false}
            options={segmentationEncOptions}
            defaultValue={defaultValues[ENDPOINT_SUB_ID_ENCRYPTION_FIELD]}
          />
         
          <WizardSelectWithValidation
            name={`${OUTPUT_FIELD}[${RENDER_TYPE_FIELD}]`}
            control={control}
            label='Bid Request Output'
            selectType={SELECT_TYPE}
            error={errors[OUTPUT_FIELD] ? !!errors[OUTPUT_FIELD][RENDER_TYPE_FIELD] : false}
            errorMessage={errors[OUTPUT_FIELD] ? errors[OUTPUT_FIELD][RENDER_TYPE_FIELD] ? errors[OUTPUT_FIELD][RENDER_TYPE_FIELD].message : '' : ''}
            isMulti={false}
            options={renderOutputOptions}
            defaultValue={defaultValues[OUTPUT_FIELD][RENDER_TYPE_FIELD]}
          />

          <WizardSelectWithValidation
            name={`${OUTPUT_FIELD}[${REDIRECT_TYPE_FIELD}]`}
            control={control}
            label='Ad Redirect Type'
            selectType={SELECT_TYPE}
            error={errors[OUTPUT_FIELD] ? !!errors[OUTPUT_FIELD][REDIRECT_TYPE_FIELD] : false}
            errorMessage={errors[OUTPUT_FIELD] ? errors[OUTPUT_FIELD][REDIRECT_TYPE_FIELD] ? errors[OUTPUT_FIELD][REDIRECT_TYPE_FIELD].message : '' : ''}
            isMulti={false}
            options={redirectTypeOptions}
            defaultValue={defaultValues[OUTPUT_FIELD][REDIRECT_TYPE_FIELD]}
          />

          <WizardInputWithValidation
            label="Branding Class"
            type='text'
            error={!!errors[BRANDING_TYPE_FIELD]}
            errorMessage={errors[BRANDING_TYPE_FIELD]? errors[BRANDING_TYPE_FIELD].message:''}
            xerror={false}
            xerrorMessage={''}
            name={BRANDING_TYPE_FIELD}
            propRef={register}
            placeholder='eg: srcflare, outbrain, taboola'
          />
         
        </>
      )}
    </form>
  );
};

WizardGeneralForm.propTypes = {
  isSubmitted: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  parentWizard: PropTypes.oneOf([ENDPOINT_WIZARD, DEMAND_WIZARD]).isRequired,
  setIsValid: PropTypes.func.isRequired,
  setPartner: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
};

export default WizardGeneralForm;
