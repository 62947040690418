import PropTypes from 'prop-types';
import React, { useContext, useReducer } from 'react';

import {
  fetchAllNotifications,
  markAllNotificationsAsRead,
  markNotificationAsReadById
} from '../../api/notifications';
import {
  NOTIFICATIONS_FETCH_NOTIFICATIONS,
  NOTIFICATIONS_MARK_ALL_NOTIFICATIONS_AS_READ,
  NOTIFICATIONS_MARK_NOTIFICATION_AS_READ,
  NOTIFICATIONS_MARK_NOTIFICATION_AS_UNREAD
} from '../types';

import useInterval from './helpers';
import NotificationsContext from './notificationsContext';
import notificationsReducer from './notificationsReducer';

const initialState = {
  notifications: []
};

const NotificationsState = ({ children }) => {
  const [state, dispatch] = useReducer(notificationsReducer, initialState);

  const getAllNotifications = async () => {
    try {
      const res = await fetchAllNotifications();

      dispatch({
        type: NOTIFICATIONS_FETCH_NOTIFICATIONS,
        payload: res.data.data.data
      });
    } catch (err) {
      dispatch({
        type: NOTIFICATIONS_FETCH_NOTIFICATIONS,
        payload: []
      });
    }
  };

  useInterval(getAllNotifications, 60000, true);

  const markNotificationAsRead = async (id) => {
    try {
      dispatch({
        type: NOTIFICATIONS_MARK_NOTIFICATION_AS_READ,
        payload: id
      });

      await markNotificationAsReadById(id);
    } catch (err) {
      dispatch({
        type: NOTIFICATIONS_MARK_NOTIFICATION_AS_UNREAD,
        payload: id
      });
    }
  };

  const markNotificationsAsRead = async () => {
    try {
      await markAllNotificationsAsRead();

      dispatch({
        type: NOTIFICATIONS_MARK_ALL_NOTIFICATIONS_AS_READ
      });
    } catch (err) {
      // TODO what will do here?
    }
  };

  return (
    <NotificationsContext.Provider
      value={{
        notifications: state.notifications,
        markNotificationAsRead,
        markNotificationsAsRead
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

NotificationsState.propTypes = {
  children: PropTypes.node
};

export default NotificationsState;

export const useNotifications = () => {
  const contextState = useContext(NotificationsContext);

  return {
    ...contextState
  };
};
