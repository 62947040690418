import * as yup from 'yup';

import {
  AUTH_FIELD,
  IAB_CAT_FIELD,
  OUTPUT_FIELD,
  PARTNER_FIELD,
  REDIRECT_TYPE_FIELD,
  RENDER_TYPE_FIELD,
  TAGS_FIELD,
  ENDPOINT_SUB_ID_ENCRYPTION_FIELD, ENDPOINT_SUB_ID_STRATEGY_FIELD,BRANDING_TYPE_FIELD
} from '../../constants';

import { multiSelectSchema, selectSchema } from './shared-schemas';

export const generalSchema = yup.object().shape({
  [PARTNER_FIELD]: selectSchema.required('Source is required').nullable(),
  [TAGS_FIELD]: multiSelectSchema,
  [IAB_CAT_FIELD]: selectSchema,
  [AUTH_FIELD]: yup.string().required('Auth is required')
});

export const generalEndpointSchema = yup.object().shape({
  [PARTNER_FIELD]: selectSchema.required('Source is required').nullable(),
  [TAGS_FIELD]: multiSelectSchema,
  [IAB_CAT_FIELD]: selectSchema,
  [BRANDING_TYPE_FIELD]: yup.string().matches(/(srcflare|outbrain|taboola)/, { excludeEmptyString: true, message: 'class can be one of: srcflare, outbrain or taboola' }),
  [AUTH_FIELD]: yup.string().required('Auth is required'),
  [OUTPUT_FIELD]: yup.object().shape({
    [RENDER_TYPE_FIELD]: selectSchema,
    [REDIRECT_TYPE_FIELD]: selectSchema
  })
});

export const validateGeneralData = (data) => {
  try {
    generalSchema.validateSync(data);

    return true;
  } catch (e) {
    return false;
  }
};

export const validateGeneralEndpointData = (data) => {
  try {
    generalEndpointSchema.validateSync(data);

    return true;
  } catch (e) {
    return false;
  }
};
