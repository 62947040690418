// ========= Create Forms =========
export const DRAWER_FORM_CLOSE = 'drawer_form_close';
export const DRAWER_FORM_OPEN = 'drawer_form_open';

// Open Forms
export const DRAWER_FORM_CREATE_ENDPOINT = 'drawer_form_endpoint_form';
export const DRAWER_FORM_CREATE_DEMAND = 'drawer_form_create_demand';
export const DRAWER_FORM_CREATE_PARTNER = 'drawer_form_create_partner';
export const DRAWER_FORM_CREATE_RULE = 'drawer_form_create_rule';
export const DRAWER_FORM_ENDPOINT_DEMAND = 'drawer_form_endpoint_demand';
export const DRAWER_FORM_FILTERS = 'drawer_form_filters';
export const DRAWER_FORM_REPORTS = 'drawer_form_reports';
// End Open Forms

// ========= End Create Forms =========

// ========= Partners =========
export const PARTNER_CREATE_PARTNER = 'create_partner';
export const PARTNER_UPDATE_PARTNER = 'update_partner';
export const DELETE_PARTNER = 'delete_partner';
export const START_FETCHING_PARTNERS = 'start_fetching_partners';
export const FETCH_PARTNERS = 'fetch_partners';
export const EDIT_PARTNER = 'edit_partner';
export const DUPLICATE_PARTNER = 'duplicate_partner';
export const UPDATE_PARTNER_STATE = 'update_partner_state';
export const GET_INITIAL_PARTNER_STATE = 'initial_partner_state';
export const GET_INITIAL_PARTNER_FORM_STATE = 'initial_partner_form_state';
export const PARTNER_UPDATE_TAGS = 'partner_update_tags';
// ========= End Partners =========

// ========= Endpoints =========
export const ENDPOINT_CREATE_ENDPOINT = 'create_endpoint';
export const ENDPOINT_UPDATE_ENDPOINT = 'update_endpoint';
export const ENDPOINT_DELETE_ENDPOINT = 'delete_endpoint';
export const ENDPOINT_START_FETCHING_ENDPOINTS = 'start_fetching_endpoints';
export const ENDPOINT_FETCH_ENDPOINTS = 'fetch_endpoints';
export const ENDPOINT_EDIT_ENDPOINT = 'edit_endpoint';
export const ENDPOINT_DUPLICATE_ENDPOINT = 'duplicate_endpoint';
export const ENDPOINT_UPDATE_ENDPOINT_STATE = 'update_endpoint_state';
export const ENDPOINT_GET_INITIAL_ENDPOINT_STATE = 'initial_endpoint_state';
export const ENDPOINT_GET_INITIAL_ENDPOINT_FORM_STATE = 'initial_endpoint_form_state';
export const ENDPOINT_CONNECT_ENDPOINT_DEMANDS_STATE = 'connect_endpoint_demands_state';
export const ENDPOINT_UPDATE_UPDATED_AT = 'endpoint_update_updatedAt';
export const ENDPOINT_FETCH_DEMANDS = 'endpoint_fetch_demands';
export const ENDPOINT_DEMAND_FEEDS = 'endpoint_demand_feeds';
export const ENDPOINT_UPDATE_STATUS = 'endpoint_update_status';
export const ENDPOINT_UPDATE_TAGS = 'endpoint_update_tags';
// ========= End Endpoints =========

// ========= Demands =========
export const DEMAND_CREATE_DEMAND = 'create_demand';
export const DEMAND_UPDATE_DEMAND = 'update_demand';
export const DEMAND_DELETE_DEMAND = 'delete_demand';
export const DEMAND_START_FETCHING_DEMANDS = 'start_fetching_demands';
export const DEMAND_FETCH_DEMANDS = 'fetch_demands';
export const DEMAND_EDIT_DEMAND = 'edit_demand';
export const DEMAND_DUPLICATE_DEMAND = 'duplicate_demand';
export const DEMAND_UPDATE_DEMAND_STATE = 'update_demand_state';
export const DEMAND_GET_INITIAL_DEMAND_STATE = 'initial_demand_state';
export const DEMAND_GET_INITIAL_DEMAND_FORM_STATE = 'initial_demand_form_state';
export const DEMAND_UPDATE_UPDATED_AT = 'demand_update_updatedAt';
export const DEMAND_UPDATE_STATUS = 'endpoint_update_status';
export const DEMAND_CONNECT_DEMAND_ENDPOINTS_STATE = 'connect_demand_endpoints_state';
export const DEMAND_ENDPOINTS_FEEDS = 'endpoint_feeds';
export const DEMAND_FETCH_ENDPOINTS = 'demand_fetch_endpoints';
export const DEMAND_UPDATE_TAGS = 'demand_update_tags';
// ========= End Demands =========

// ========= Endpoint Demands =========
export const ENDPOINT_DEMAND_UPDATE_ENDPOINT_DEMAND_STATE = 'update_endpoint_demand_state';
export const ENDPOINT_DEMAND_OPEN_ENDPOINT_DEMAND_EDIT = 'open_endpoint_demand_edit';
export const ENDPOINT_DEMAND_GET_INITIAL_ENDPOINT_DEMANDS_STATE = 'initial_endpoint_demands_state';
export const ENDPOINT_DEMAND_GET_INITIAL_ENDPOINT_DEMANDS_FORM_STATE = 'initial_endpoint_demands_form_state';
// ========= End Endpoint Demands =========

// ========= Dashboard =========
//
// ====== TABLES =========
export const DASHBOARD_ENDPOINT_ID_FIELD = 'epid';
export const DASHBOARD_ENDPOINT_NAME_FIELD = 'endpoint_name';
export const DASHBOARD_DEMAND_NAME_FIELD = 'demand_name';
export const DASHBOARD_EP_SOURCE_NAME_FIELD = 'ep_source_name';
export const DASHBOARD_DF_SOURCE_NAME_FIELD = 'df_source_name';
export const DASHBOARD_ENDPOINT_TAGS_FIELD = 'endpoint_tags';
export const DASHBOARD_DEMAND_TAGS_FIELD = 'demand_tags';
export const DASHBOARD_ENDPOINT_STATUS_FIELD = 'endpoint_status';
export const DASHBOARD_PLATFORM_FIELD = 'device_type';
export const DASHBOARD_COUNTRIES_FIELD = 'geo';
export const DASHBOARD_OS_FIELD = 'os';
export const DASHBOARD_DEMAND_FEEDS_FIELD = 'dfid';
export const DASHBOARD_EP_SOURCE_FIELD = 'ep_source';
export const DASHBOARD_DF_SOURCE_FIELD = 'df_source';
export const DASHBOARD_SUB_BIDS_FIELD = 'o_subid';
export const DASHBOARD_TIMEZONE_FIELD = 'timeZone';
export const DASHBOARD_DATE_FIELD = 'date';
// ====== END TABLES =========
export const DASHBOARD_ASSIGNEE_FIELD = 'assignee';

// ====== INTERVALS =========
export const DASHBOARD_INTERVAL_TODAY = 'today';
export const DASHBOARD_INTERVAL_YESTERDAY = 'yesterday';
export const DASHBOARD_INTERVAL_LAST_48_HOURS = 'last_48_hours';
export const DASHBOARD_INTERVAL_LAST_7_DAYS = 'last_7_days';
export const DASHBOARD_INTERVAL_THIS_MONTH = 'this_month';
export const DASHBOARD_INTERVAL_LAST_MONTH = 'last_month';
export const DASHBOARD_INTERVAL_CUSTOM = 'custom';
export const DASHBOARD_INTERVAL_LAST_3_MONTHS = 'last_3_months';
// ====== END INTERVALS =========

// ====== TOP CHARTS =========
export const DASHBOARD_TOP_CHARTS_REQUESTS = 'Requests';
export const DASHBOARD_TOP_CHARTS_CLICKS = 'Clicks';
export const DASHBOARD_TOP_CHARTS_PAYOUT = 'Payout';
export const DASHBOARD_TOP_CHARTS_EARNINGS = 'Earnings';
export const DASHBOARD_TOP_CHARTS_PROFIT = 'Profit';
// ====== END TOP CHARTS =========

// ====== MAIN CHARTS =========
export const DASHBOARD_MAIN_CHART_EARNINGS = 'Earnings';
export const DASHBOARD_MAIN_CHART_EST_EARNINGS = 'Est. Earnings';
export const DASHBOARD_MAIN_CHART_EST_VS_APPROVED_EARNINGS = 'Est. vs Approved Earnings';
export const DASHBOARD_MAIN_CHART_REQUESTS_VS_CLICKS = 'Requests vs Clicks';
export const DASHBOARD_MAIN_CHART_IMPRESSIONS_VS_CLICKS = 'Impressions vs Clicks';
// ====== END MAIN CHARTS =========

export const DASHBOARD_INTERVAL = 'interval';
export const DASHBOARD_STATUS = 'status';
export const DASHBOARD_FILTERS = 'filters';
export const DASHBOARD_BREAKDOWN_MONTH = 'month';
export const DASHBOARD_BREAKDOWN_DAY = 'day';
export const DASHBOARD_BREAKDOWN_HOUR = 'hour';
export const DASHBOARD_BREAKDOWN_WEEK = 'week';

export const DASHBOARD_REQUESTS_COUNT = 'requests_count';
export const DASHBOARD_REQUESTS_ERRORS = 'requests_errors';
export const DASHBOARD_REQUESTS_BIDS = 'requests_bids';
export const DASHBOARD_REQUESTS_AVG_NET_BID = 'requests_avg_net_bid';
export const DASHBOARD_REQUESTS_AVG_GROSS_BID = 'requests_avg_gross_bid';
export const DASHBOARD_REQUESTS_COVERAGE = 'request_coverage';

export const DASHBOARD_E_CPM = 'e_cpm';
export const DASHBOARD_CLICKS_COUNT = 'clicks_count';
export const DASHBOARD_CLICK_ERRORS = 'click_errors';
export const DASHBOARD_APPROVED_COUNT = 'approved_count';
export const DASHBOARD_EST_PAYOUT = 'est_payout';
export const DASHBOARD_EST_REVENUE = 'est_revenue';
export const DASHBOARD_AVG_NET_BID = 'avg_net_bid';
export const DASHBOARD_PROFIT = 'profit';
export const DASHBOARD_EST_PROFIT = 'est_profit';
export const DASHBOARD_ROI = 'roi';
export const DASHBOARD_EST_ROI = 'est_roi';
export const DASHBOARD_IMPS_COUNT = 'imps_count';
export const DASHBOARD_APPROVED_PAYOUTS = 'approved_payout';
export const DASHBOARD_APPROVED_REVENUE = 'approved_revenue';

export const DASHBOARD_REQUESTS_ERRORS_PCT = 'request_errors_pct';
export const DASHBOARD_INT_REQUESTS_ERRORS = 'int_requests_errors';
export const DASHBOARD_INT_REQUESTS_COUNT = 'int_requests_count';
export const DASHBOARD_INT_REQUESTS_BIDS = 'int_requests_bids';
export const DASHBOARD_INT_REQUESTS_NO_BIDS = 'int_requests_no_bids';
export const DASHBOARD_INT_REQUESTS_WINS = 'int_requests_wins';
export const DASHBOARD_INT_REQUESTS_AVG_NET_BID = 'int_requests_avg_net_bid';
export const DASHBOARD_INT_REQUESTS_AVG_GROSS_BID = 'int_requests_avg_gross_bid';
export const DASHBOARD_INT_REQUESTS_COVERAGE = 'int_requests_coverage';
export const DASHBOARD_INT_REQUESTS_ERRORS_PCT = 'int_requests_error_pct';

export const DASHBOARD_UPDATE_TABLE = 'dashboard_update_table';
export const DASHBOARD_START_FETCHING_TABLE_DATA = 'dashboard_start_fetching_table_data';
export const DASHBOARD_GET_INITIAL_FILTER_STATE = 'get_initial_filter_state';
export const DASHBOARD_UPDATE_FILTER_STATE = 'update_filter_state';
export const DASHBOARD_UPDATE_INTERVAL = 'update_interval';
export const DASHBOARD_UPDATE_DATA_RANGE = 'update_data_range';
export const DASHBOARD_FETCH_MAIN_CHART = 'dashboard_fetch_main_chart';
export const DASHBOARD_START_FETCHING_MAIN_CHART_DATA = 'dashboard_start_fetching_main_chart_data';
export const DASHBOARD_START_FETCHING_PIE_CHART_DATA = 'dashboard_start_fetching_pie_chart_data';
export const DASHBOARD_FETCH_PIE_CHART = 'dashboard_fetch_pie_chart';
export const DASHBOARD_UPDATE_TIMEZONE = 'dashboard_update_timezone';
// ========= End Dashboard =========

// ========= Alerts =========
export const SET_ALERT = 'set_alert';
export const REMOVE_ALERT = 'remove_alert';
// ========= End Alerts =========

export const SET_DASHBOARD_OPTION = 'set_dashboard_option';

// ========= StaticList =========
export const REQUEST_LOAD_STATIC_LIST = 'request_load_static_list';
export const GET_STATIC_LIST_ERROR = 'get_static_list_error';
export const GET_STATIC_LIST = 'get_static_list';
// ========= End StaticList =========

// ========= Reports =========
export const REPORTS_CREATE_REPORT = 'create_report';
export const REPORTS_FETCH_REPORTS = 'fetch_reports';
export const REPORTS_GENERATE_REPORT = 'generate_report';
export const REPORTS_DELETE_REPORT = 'delete_report';
export const REPORTS_START_FETCHING_REPORT_TABLE_DATA = 'start_fetching_report_table_data';
export const REPORTS_FETCH_REPORT_TABLE_DATA = 'fetch_report_table_data';
export const REPORTS_GET_INITIAL_FORM_STATE = 'get_initial_form_state';
export const REPORTS_INTERVAL_LAST_4_HOURS = 'last_4_hours';
export const REPORTS_INTERVAL_LAST_48_HOURS = 'last_48_hours';
export const REPORTS_BREAKDOWN_FIELD = 'breakdown';
export const REPORTS_BROWSER_FIELD = 'browser';
export const REPORTS_DEMAND_STATUS_FIELD = 'demand_status';
export const REPORTS_TITLE_FIELD = 'title';
export const REPORTS_DIMENSIONS_FIELD = 'dimensions';
export const REPORTS_DATE_RANGE_FIELD = 'dateRange';
export const REPORTS_METRICS_FIELD = 'metrics';
export const REPORTS_METRIC_FILTERS_FIELD = 'metricFilters';
export const REPORTS_DIMENSION_FILTERS_FIELD = 'dimensionFilters';
export const REPORTS_AVG_GROSS_BID_FIELD = 'avg_gross_bid';
export const REPORTS_CTR_FIELD = 'ctr';
export const REPORTS_CLICKS_COUNT_DISCP_FIELD = 'clicks_count_discp';
export const REPORTS_PAYOUT_DISCP_FIELD = 'payout_discp';
export const REPORTS_REVENUE_DISCP_FIELD = 'revenue_discp';
export const REPORTS_CLICKS_ERRORS_PCT_FIELD = 'clicks_errors_pct';
export const REPORTS_CLICKS_COVERAGE_FIELD = 'clicks_coverage';
export const REPORTS_IMPS_COVERAGE_FIELD = 'imps_coverage';
export const REPORTS_OS_VERSION_FIELD = 'os_version';
export const REPORTS_BROWSER_VERSION = 'browser_version';
export const REPORTS_KEY_FIELD = 'key';
export const REPORTS_VALUE_FIELD = 'value';
export const REPORTS_OPERATION_FIELD = 'operation';
export const REPORTS_LOAD_REPORT = 'load_report';
export const REPORTS_EDIT_REPORT = 'edit_report';
// ========= End Reports =========

// ========= Users =========
export const USER_PARTNER_ROLE = 'partner';
export const USER_ADMIN_ROLE = 'admin';
export const USER_SUPER_ADMIN_ROLE = 'superadmin';
// ========= End Users =========

// ========= Rules =========
export const RULES_START_FETCHING_RULES = 'start_fetching_rules';
export const RULES_FETCH_RULES = 'fetch_rules';
export const RULES_CREATE_RULE = 'create_rule';
export const RULES_UPDATE_RULE = 'update_rule';
export const RULES_DELETE_RULE = 'delete_rule';
export const RULES_EDIT_RULE = 'edit_rule';
export const RULES_DUPLICATE_RULE = 'duplicate_rule';
export const RULES_RUN_RULE = 'run_rule';
export const RULES_UPDATE_RULE_STATUS = 'update_rule_status';
export const RULES_UPDATE_TAGS = 'rules_update_tags';
// FIELDS
export const RULES_ACTION_FIELD = 'action';
export const RULES_ACTION_DATA_FIELD = 'actionData';
export const RULES_ANALYZE_INTERVAL_FIELD = 'analyzeInterval';
export const RULES_LAST_RUN_FIELD = 'lastRun';
export const RULES_LAST_STATUS_FIELD = 'lastStatus';
export const RULES_METRIC_FILTERS_FIELD = 'metricFilters';
export const RULES_DIMENSION_FILTERS_FIELD = 'dimensionFilters';
export const RULES_FREQUENCY_DAYS_FIELD = 'frequencyDays';
export const RULES_FREQUENCY_INTERVAL_FIELD = 'frequencyInterval';
export const RULES_FREQUENCY_CUSTOM_FIELD = 'frequencyCustom';
export const RULES_OUTCOME_FIELD = 'outcome';
export const RULES_TAGS_FIELD = 'tags';
export const RULES_KEY_FIELD = 'key';
export const RULES_VALUE_FIELD = 'value';
export const RULES_OPERATION_FIELD = 'operation';
export const RULES_SELECTED_FIELD = 'selected';
export const RULES_ACTIVE_FIELD = 'active';
// END FIELDS
// ========= End Rules =========

// ========= Authentication =========
export const AUTHENTICATION_NAME_FIELD = 'name';
export const AUTHENTICATION_EMAIL_FIELD = 'email';
export const AUTHENTICATION_PASSWORD_FIELD = 'password';
export const AUTHENTICATION_CONFIRM_PASSWORD_FIELD = 'confirmPassword';
// ========= End Authentication =========

// ========= Notifications =========
export const NOTIFICATIONS_VIEW_ENDPOINT_FIELD = 'view_endpoint';
export const NOTIFICATIONS_VIEW_DEMAND_FIELD = 'view_demand';
export const NOTIFICATIONS_VIEW_PARTNER_FIELD = 'view_partner';
export const NOTIFICATIONS_READ_FIELD = 'read';
export const NOTIFICATIONS_CTA_FIELD = 'cta';
export const NOTIFICATIONS_CTA_DATA_FIELD = 'cta_data';
export const NOTIFICATIONS_TYPE_FIELD = 'type';
export const NOTIFICATIONS_DATA_FIELD = 'data';
// ========= End Notifications =========

// ========= Settings =========
export const SETTINGS_TIMELINE_LOG_TABLE = 'timeline_log_table';
export const SETTINGS_MANUAL_REVENUE_TABLE = 'manual_revenue_table';
export const SETTINGS_REVENUE_SYNC_TABLE = 'revenue_sync_table';
export const SETTINGS_REVENUE_SYNC_DEMAND_ID_FIELD = 'demandId';
export const SETTINGS_REVENUE_SYNC_STATUS = 'status';
export const SETTINGS_REVENUE_SYNC_UPDATED_AMOUNT = 'updatedAmount';
export const SETTINGS_REVENUE_SYNC_REPORTED_AMOUNT = 'reportedAmount';
export const SETTINGS_REVENUE_SYNC_REPORT_DATE = 'reportDate';
export const SETTINGS_TIMELINE_LOG_KIND_FIELD = 'kind';
export const SETTINGS_TIMELINE_LOG_KING_TYPE_FIELD = 'kindType';
export const SETTINGS_TIMELINE_LOG_DATA_FIELD = 'data';
export const SETTINGS_TIMELINE_LOG_TYPE_FIELD = 'type';
export const SETTINGS_TIMELINE_LOG_USER_FIELD = 'user';
export const SETTINGS_INTERVAL_INTERVAL = 'interval';
export const SETTINGS_MANUAL_REVENUE_REPORT_DATE_FIELD = 'reportDate';
export const SETTINGS_MANUAL_REVENUE_REPORTED_AMOUNT_FIELD = 'reportedAmount';
export const SETTINGS_MANUAL_REVENUE_TIMEZONE_FIELD = 'timeZone';
export const SETTINGS_MANUAL_REVENUE_STATUS_FIELD = 'status';
export const SETTINGS_MANUAL_REVENUE_APPROVED_REVENUE_FIELD = 'approvedRevenue';
export const SETTINGS_MANUAL_REVENUE_APPROVED_COUNT_FIELD = 'approvedCount';
export const SETTINGS_MANUAL_REVENUE_DEMAND_ID = 'demandId';
// ========= End Settings =========
