import { transformToCapitalized } from '../../dashboard/table/helpers/table-manager-helpers';
import {
  PARTNER_BUSINESS_FIELD,
  CREATED_AT_FIELD,
  PARTNER_TYPE_FIELD,
  TAGS_FIELD,
  PARTNER_FIELD,
  PARTNER_ID_FIELD,
  SOURCE_FIELD,
  ENDPOINT_SUB_ID_TOKEN_FIELD,
  UPDATED_AT_FIELD,
  IAB_CAT_FIELD,
  OPTIMIZATION_FIELD,
  SETTINGS_FIELD,
  TARGETING_FIELD,
  FILTERS_FIELD,
  PARTNER_PASSWORD_FIELD,
  VALUE_FIELD,
  DEMAND_FEED_SETUP_FIELD,
  DEMAND_STATS_SETUP_FIELD,
  DEMAND_SETUP_DATA_FIELD,
  ACTIVE_FIELD, OUTPUT_FIELD,
  REDIRECT_TYPE_FIELD,
  RENDER_TYPE_FIELD,
  ENDPOINT_SUB_ID_ENCRYPTION_FIELD,
  ENDPOINT_SUB_ID_STRATEGY_FIELD
} from '../../wizards-manager/constants';

import { redirectTypeOptions, renderOutputOptions, segmentationOptions, segmentationEncOptions} from '../../wizards-manager/helpers/staticData';
import { businessTypes, sourceTypes } from '../../wizards-manager/wizards/partner/partner-fake-data';

import {
  transformTagsToFormTags,
  transformOptimizationToFormData,
  transformTargetingToFormData,
  transformFilteringToFormData,
  transformDemandSetupToFormData
} from './transormers-helpers';

export const transformPartnersToTableData = (partners) =>
  partners.reduce((acc, partner) => {
    delete partner[PARTNER_PASSWORD_FIELD];

    const data = Object.keys(partner)
      .reduce((acc, field) => {
        switch (field) {
          case CREATED_AT_FIELD:
            acc[field] = new Date(partner[field]);
            break;

          default:
            acc[field] = partner[field];
            break;
        }

        return acc;
      }, {});

    acc.push(data);
    return acc;
  }, []);

export const transformPartnerToFormData = (partner, staticList) =>
  Object.keys(partner)
    .reduce((acc, field) => {
      switch (field) {
        case PARTNER_TYPE_FIELD: {
          const value = partner[field];
          const data = sourceTypes.find(s => s[VALUE_FIELD] === value);

          acc[field] = data || {
            label: value,
            value
          };
          break;
        }

        case PARTNER_BUSINESS_FIELD: {
          const value = partner[field];
          const data = businessTypes.find(s => s[VALUE_FIELD] === value);

          acc[field] = data || {
            label: value,
            value
          };
          break;
        }

        case TAGS_FIELD: {
          acc[field] = transformTagsToFormTags(partner[field], staticList.tagOptions);
          break;
        }

        case SOURCE_FIELD:
        case UPDATED_AT_FIELD:
        case CREATED_AT_FIELD:
          break;

        default:
          acc[field] = partner[field];
          break;
      }

      return acc;
    }, {});

export const transformEndpointsOrDemandsToTableData = (items) =>
  items.reduce((acc, item) => {
    const data = Object.keys(item)
      .reduce((acc, field) => {
        switch (field) {
          case PARTNER_FIELD:
            acc[field] = item[field] ? item[field].name : '';
            acc[PARTNER_ID_FIELD] = item[field] ? item[field]._id : '';
            break;

          case SOURCE_FIELD:
            acc[field] = item[field].name;
            break;

          case CREATED_AT_FIELD:
          case UPDATED_AT_FIELD:
            acc[field] = new Date(item[field]);
            break;

          case ACTIVE_FIELD:
            acc[field] = item[field] ? 'Active' : 'Inactive';
            break;

          default:
            acc[field] = item[field];
            break;
        }

        return acc;
      }, {});

    acc.push(data);
    return acc;
  }, []);

export const transformEndpointToFormData = (endpoint, staticList) =>
  Object.keys(endpoint)
    .reduce((acc, field) => {
      switch (field) {
        case IAB_CAT_FIELD: {
          const data = staticList.iabcatOptions.find(o => o.value === endpoint[field]);

          acc[field] = {
            label: data ? data.label : transformToCapitalized(endpoint[field]),
            value: data ? data._id : endpoint[field].toLowerCase()
          };

          break;
        }

        case TAGS_FIELD:
          acc[field] = transformTagsToFormTags(endpoint[field], staticList.tagOptions);
          break;

        case OPTIMIZATION_FIELD:
          acc[field] = transformOptimizationToFormData(endpoint[field], staticList);
          break;

        case TARGETING_FIELD:
          acc[field] = transformTargetingToFormData(endpoint[field], staticList);
          break;

        case FILTERS_FIELD:
          acc[field] = transformFilteringToFormData(endpoint[field]);
          break;

        case PARTNER_FIELD:
          acc[field] = {
            label: endpoint[field],
            [VALUE_FIELD]: endpoint[PARTNER_ID_FIELD]
          };
          break;

        case ACTIVE_FIELD:
          acc[field] = endpoint[field] === 'Active';
          break;

        case PARTNER_ID_FIELD:
        case ENDPOINT_SUB_ID_TOKEN_FIELD:
        case SOURCE_FIELD:
        case UPDATED_AT_FIELD:
        case CREATED_AT_FIELD:
        case SETTINGS_FIELD:
          break;
        
        case ENDPOINT_SUB_ID_ENCRYPTION_FIELD:
          if (!endpoint[field] || endpoint[field] == '') acc[field] = segmentationEncOptions[0];

          acc[field] = segmentationEncOptions.find(o => o[VALUE_FIELD] === endpoint[field])

          break;
          
        case ENDPOINT_SUB_ID_STRATEGY_FIELD:
          if (!endpoint[field] || endpoint[field] == '') acc[field] = segmentationOptions[0];

          acc[field] = segmentationOptions.find(o => o[VALUE_FIELD] === endpoint[field])
          break;

        case OUTPUT_FIELD:
          acc[field] = {
            [RENDER_TYPE_FIELD]: renderOutputOptions
              .find(o => o[VALUE_FIELD] === endpoint[field][RENDER_TYPE_FIELD]),
            [REDIRECT_TYPE_FIELD]: endpoint[field][REDIRECT_TYPE_FIELD] === ''
              ? {
                label: 'Default',
                value: 'default'
              } : redirectTypeOptions.find(o => o[VALUE_FIELD] === endpoint[field][REDIRECT_TYPE_FIELD])
          };
          break;

        default:
          acc[field] = endpoint[field];
          break;
      }
      return acc;
    }, {});

export const transformDemandToFormData = (demand, staticList) =>
  Object.keys(demand)
    .reduce((acc, field) => {
      switch (field) {
        case IAB_CAT_FIELD: {
          const data = staticList.iabcatOptions.find(o => o.value === demand[field]);

          acc[field] = {
            label: data ? data.label : transformToCapitalized(demand[field]),
            value: data ? data._id : demand[field].toLowerCase()
          };

          break;
        }

        case TAGS_FIELD:
          acc[field] = transformTagsToFormTags(demand[field], staticList.tagOptions);
          break;

        case OPTIMIZATION_FIELD:
          acc[field] = transformOptimizationToFormData(demand[field], staticList);
          break;

        case TARGETING_FIELD:
          acc[field] = transformTargetingToFormData(demand[field], staticList);
          break;

        case FILTERS_FIELD:
          acc[field] = transformFilteringToFormData(demand[field]);
          break;

        case DEMAND_FEED_SETUP_FIELD: {
          if (!acc[DEMAND_SETUP_DATA_FIELD]) {
            acc[DEMAND_SETUP_DATA_FIELD] = {};
          }

          acc[DEMAND_SETUP_DATA_FIELD][DEMAND_FEED_SETUP_FIELD] = transformDemandSetupToFormData(demand[field], staticList.feedOptions);
          break;
        }

        case DEMAND_STATS_SETUP_FIELD:
          if (!acc[DEMAND_SETUP_DATA_FIELD]) {
            acc[DEMAND_SETUP_DATA_FIELD] = {};
          }

          acc[DEMAND_SETUP_DATA_FIELD][DEMAND_STATS_SETUP_FIELD] = transformDemandSetupToFormData(demand[field], staticList.statsOptions);
          break;

        case PARTNER_FIELD:
          acc[field] = {
            label: demand[field],
            [VALUE_FIELD]: demand[PARTNER_ID_FIELD]
          };
          break;

        case ACTIVE_FIELD:
          acc[field] = demand[field] === 'Active';
          break;

        case PARTNER_ID_FIELD:
        case ENDPOINT_SUB_ID_TOKEN_FIELD:
        case SOURCE_FIELD:
        case UPDATED_AT_FIELD:
        case CREATED_AT_FIELD:
        case SETTINGS_FIELD:
          break;

        default:
          acc[field] = demand[field];
          break;
      }

      return acc;
    }, {});
