import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { updateDataRangeState, updateIntervalState, updateTimezoneState } from '../../../../actions/dashboard';

import {
  DASHBOARD_INTERVAL_CUSTOM,
  DASHBOARD_INTERVAL_LAST_48_HOURS,
  DASHBOARD_INTERVAL_LAST_7_DAYS,
  DASHBOARD_INTERVAL_LAST_MONTH,
  DASHBOARD_INTERVAL_THIS_MONTH,
  DASHBOARD_INTERVAL_TODAY,
  DASHBOARD_INTERVAL_YESTERDAY,
  DASHBOARD_TIMEZONE_FIELD
} from '../../../../actions/types';

import { timezonesOptions } from './helpers/timezones';

import 'react-datepicker/dist/react-datepicker.css';
import './DashboardDatePickFilterForm.scss';

const customStyles = {
  menuList: (styles) => ({
    ...styles,
    textAlign: 'left'
  }),
  control: (styles, state) => ({
    ...styles,
    height: 38,
    border: '1px solid #e1e9ef',
    '&:hover': {
      border: '1px solid #ff6984'
    }
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    display: 'none'
  })
};

const intervalObject = {
  [DASHBOARD_INTERVAL_TODAY]: 'Today',
  [DASHBOARD_INTERVAL_YESTERDAY]: 'Yesterday',
  [DASHBOARD_INTERVAL_LAST_48_HOURS]: 'Last 48 hours',
  [DASHBOARD_INTERVAL_LAST_7_DAYS]: 'Last 7 days',
  [DASHBOARD_INTERVAL_THIS_MONTH]: 'This Month',
  [DASHBOARD_INTERVAL_LAST_MONTH]: 'Last Month'
};

const DashboardDatePickFilterForm = () => {
  const {
    loading,
    interval,
    dateRange,
    timeZone
  } = useSelector(
    state => ({
      loading: state.dashboard.loading,
      interval: state.dashboard.interval,
      dateRange: state.dashboard.dateRange,
      timeZone: state.dashboard.filters[DASHBOARD_TIMEZONE_FIELD]
    })
  );

  const dispatch = useDispatch();

  const divRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [fromDate, setFromDate] = useState(new Date(dateRange.from));
  const [toDate, setToDate] = useState(new Date(dateRange.to));
  const [customInterval, setCustomInterval] = useState(interval === DASHBOARD_INTERVAL_CUSTOM);

  const handleApplyRange = () => {
    updateDataRangeState({
      from: moment(fromDate).startOf('day').format('Y-MM-DD HH:mm:ss'),
      to: moment(toDate).endOf('day').format('Y-MM-DD HH:mm:ss')
    })(dispatch);

    setShowDropdown(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const handleClickOutside = e => {
    if (!divRef.current.contains(e.target)) {
      setShowDropdown(false);

      if (interval !== DASHBOARD_INTERVAL_CUSTOM && customInterval) {
        setCustomInterval(false);
      }
    }
  };

  const handleOpenDropdown = () => setShowDropdown(!showDropdown);

  const isActiveInterval = (currentInterval, interval, customInterval) => currentInterval === interval && !customInterval;

  const handleChangeTimezone = (selected) => {
    updateTimezoneState(selected);
  };

  return (
    <div className="dashboard-date-pick-filter" ref={divRef}>
      <div className="dashboard-timezone-box">
        <Select
          components={{ DropdownIndicator: () => null }}
          options={timezonesOptions}
          onChange={handleChangeTimezone}
          closeMenuOnSelect={true}
          defaultValue={timeZone}
          styles={customStyles}
          className='the-field selectable'
          classNamePrefix="react-select"
        />
      </div>

      <div>
        <button
          className='btn btn-gray dropdown-toggle'
          type='button'
          id='dropdownMenuButton'
          disabled={loading}
          onClick={handleOpenDropdown}
        >
          <img src={require('../../../../assets/images/icons/ico_calendar.svg')} alt=''/>
          <img className='img-active' src={require('../../../../assets/images/icons/ico_calendar_o.svg')} alt=''/>
          {interval !== DASHBOARD_INTERVAL_CUSTOM ? (
            <span>{intervalObject[interval]}</span>
          ) : (
            <span>
              <Moment format='YYYY/MM/DD'>
                {dateRange.from}
              </Moment>
              &nbsp;-&nbsp;
              <Moment format='YYYY/MM/DD'>
                {dateRange.to}
              </Moment>
            </span>
          )}
        </button>
        <ul
          className={`dashboard-dropdown-menu dropdown-menu ${showDropdown ? 'show' : ''}`}
          aria-labelledby='dropdownMenuButton'
        >
          <li>
            <a
              onClick={() => {
                updateIntervalState(DASHBOARD_INTERVAL_YESTERDAY)(dispatch);
                setCustomInterval(false);
                setShowDropdown(false);
              }}
              className={`dropdown-item ${isActiveInterval(interval, DASHBOARD_INTERVAL_YESTERDAY, customInterval) ? 'active' : ''}`}
            >
              {intervalObject[DASHBOARD_INTERVAL_YESTERDAY]}
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                updateIntervalState(DASHBOARD_INTERVAL_TODAY)(dispatch);
                setCustomInterval(false);
                setShowDropdown(false);
              }}
              className={`dropdown-item ${isActiveInterval(interval, DASHBOARD_INTERVAL_TODAY, customInterval) ? 'active' : ''}`}
            >
              {intervalObject[DASHBOARD_INTERVAL_TODAY]}
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                updateIntervalState(DASHBOARD_INTERVAL_LAST_48_HOURS)(dispatch);
                setCustomInterval(false);
                setShowDropdown(false);
              }}
              className={`dropdown-item ${isActiveInterval(interval, DASHBOARD_INTERVAL_LAST_48_HOURS, customInterval) ? 'active' : ''}`}
            >
              {intervalObject[DASHBOARD_INTERVAL_LAST_48_HOURS]}
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                updateIntervalState(DASHBOARD_INTERVAL_LAST_7_DAYS)(dispatch);
                setCustomInterval(false);
                setShowDropdown(false);
              }}
              className={`dropdown-item ${isActiveInterval(interval, DASHBOARD_INTERVAL_LAST_7_DAYS, customInterval) ? 'active' : ''}`}
            >
              {intervalObject[DASHBOARD_INTERVAL_LAST_7_DAYS]}
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                updateIntervalState(DASHBOARD_INTERVAL_THIS_MONTH)(dispatch);
                setCustomInterval(false);
                setShowDropdown(false);
              }}
              className={`dropdown-item ${isActiveInterval(interval, DASHBOARD_INTERVAL_THIS_MONTH, customInterval) ? 'active' : ''}`}
            >
              {intervalObject[DASHBOARD_INTERVAL_THIS_MONTH]}
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                updateIntervalState(DASHBOARD_INTERVAL_LAST_MONTH)(dispatch);
                setCustomInterval(false);
                setShowDropdown(false);
              }}
              className={`dropdown-item ${isActiveInterval(interval, DASHBOARD_INTERVAL_LAST_MONTH, customInterval) ? 'active' : ''}`}
            >
              {intervalObject[DASHBOARD_INTERVAL_LAST_MONTH]}
            </a>
          </li>
          <li className='custom-filter'>
            <a
              onClick={() => setCustomInterval(true)}
              className={`dropdown-item ${customInterval ? 'active show-box' : ''}`}
            >
              Custom
            </a>
            <div className='box-custom'>
              <div className='box-item'>
                <p className='label'>From</p>
                <div className='box-date'>
                  <DatePicker
                    dateFormat='dd/MM/yyyy'
                    selected={fromDate}
                    selectsStart
                    startDate={fromDate}
                    endDate={fromDate}
                    onChange={(date) => setFromDate(date)}
                  />
                  <img className="date-picker-icon" src={require('../../../../assets/images/icons/ico_calendar.svg')}
                    alt=''/>
                </div>
              </div>

              <div className='box-item'>
                <p className='label'>To</p>
                <div className='box-date'>
                  <DatePicker
                    dateFormat='dd/MM/yyyy'
                    selected={toDate}
                    selectsEnd
                    startDate={toDate}
                    endDate={toDate}
                    onChange={(date) => setToDate(date)}
                  />
                  <img className="date-picker-icon" src={require('../../../../assets/images/icons/ico_calendar.svg')}
                    alt=''/>
                </div>
              </div>
              <p className='apply'>
                <a onClick={handleApplyRange} className='btn btn-pink'>
                  Apply
                </a>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DashboardDatePickFilterForm;
