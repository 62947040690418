import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { updateDemandFormDataAction } from '../../../../../actions/demand';
import { updateEndpointFormDataAction } from '../../../../../actions/endpoint';

import {
  DEMAND_WIZARD,
  DOMAIN_FIELD,
  DOMAIN_INCLUDE_FIELD,
  ENDPOINT_WIZARD,
  BAD_IPS_FIELD,
  FILTERS_FIELD,
  IP_FIELD,
  IP_INCLUDE_FIELD,
  SUB_ID_FIELD,
  SUBID_INCLUDE_FIELD,
  PROXIES_FIELD,
  USER_AGENTS_INCLUDE_FIELD,
  USER_AGENTS_FIELD
} from '../../../constants';

import { useFormsEffect } from '../../../custom-hooks/useFormsEffect';
import { useIsValidForm } from '../../../custom-hooks/useIsValidForm';
import { proxiesOptions } from '../../../helpers/staticData';
import { protectionSchema, validateProtectionData } from '../../../validators/shared/protection';

import WizardCheckboxInput from '../WizardCheckboxInput';
import WizardCustomCheckbox from '../WizardCustomCheckbox';
import WizardInputWithValidation from '../WizardInputWithValidation';
import WizardSelectWithValidation, { SELECT_TYPE } from '../WizardSelectWithValidation';

const WizardProtectionForm = ({
  isSubmitted,
  onSubmit,
  parentWizard,
  setIsValid
}) => {
  const {
    defaultValues
  } = useSelector(
    state => ({
      defaultValues: state[parentWizard].formData[FILTERS_FIELD]
    })
  );

  const {
    register,
    errors,
    triggerValidation,
    getValues,
    setValue,
    control
  } = useForm({
    mode: 'onBlur',
    defaultValues,
    validationSchema: protectionSchema
  });

  const updateState = parentWizard === ENDPOINT_WIZARD ? updateEndpointFormDataAction : updateDemandFormDataAction;

  useFormsEffect(isSubmitted, triggerValidation, getValues, updateState, onSubmit, FILTERS_FIELD);

  const values = getValues({ nest: true });
  const isValid = validateProtectionData(Object.keys(values).length ? values : defaultValues);
  useIsValidForm(isValid, setIsValid);

  const handleChangeInclude = (field, value) => setValue(field, value);

  return (
    <form className="wizard-protection-form step-content">
      <div className="group">
        <div className="group-item">
          <WizardCheckboxInput
            propRef={register}
            label="Enforce Suspicious IP Filter"
            subLabel="(internal system lists of potential suspicious IPs)"
            name={BAD_IPS_FIELD}
            className="mt10"
          />
        </div>
        <div className="group-item">
          <WizardSelectWithValidation
            control={control}
            error={!!errors[PROXIES_FIELD]}
            errorMessage={errors[PROXIES_FIELD] ? errors[PROXIES_FIELD].message : ''}
            isMulti={false}
            name={PROXIES_FIELD}
            options={proxiesOptions}
            selectType={SELECT_TYPE}
            label='Filter Proxy'
            defaultValue={defaultValues[PROXIES_FIELD]}
          />
        </div>
      </div>

      <div className="group">
        <div className="group-item">
          <WizardCustomCheckbox
            changeChecked={handleChangeInclude}
            name={IP_INCLUDE_FIELD}
            label="Custom IP"
            defaultValue={defaultValues[IP_INCLUDE_FIELD]}
            propRef={register}
          />
        </div>
        <div className="group-item">
          <WizardInputWithValidation
            type="text"
            error={!!errors[IP_FIELD]}
            errorMessage={errors[IP_FIELD] ? errors[IP_FIELD].message : ''}
            name={IP_FIELD}
            placeholder="Enter custom"
            propRef={register}
            isTextarea={true}
          />
        </div>
      </div>

      <div className="group">
        <div className="group-item">
          <WizardCustomCheckbox
            changeChecked={handleChangeInclude}
            name={SUBID_INCLUDE_FIELD}
            label="Custom SubId"
            defaultValue={defaultValues[SUBID_INCLUDE_FIELD]}
            propRef={register}
          />
        </div>
        <div className="group-item">
          <WizardInputWithValidation
            type="text"
            error={!!errors[SUB_ID_FIELD]}
            errorMessage={errors[SUB_ID_FIELD] ? errors[SUB_ID_FIELD].message : ''}
            name={SUB_ID_FIELD}
            placeholder="Enter custom"
            propRef={register}
            isTextarea={true}
          />
        </div>
      </div>

      <div className="group">
        <div className="group-item">
          <WizardCustomCheckbox
            changeChecked={handleChangeInclude}
            name={USER_AGENTS_INCLUDE_FIELD}
            label="User Agents"
            defaultValue={defaultValues[USER_AGENTS_INCLUDE_FIELD]}
            propRef={register}
          />
        </div>
        <div className="group-item">
          <WizardInputWithValidation
            type="text"
            error={!!errors[USER_AGENTS_FIELD]}
            errorMessage={errors[USER_AGENTS_FIELD] ? errors[USER_AGENTS_FIELD].message : ''}
            name={USER_AGENTS_FIELD}
            placeholder="Enter custom"
            propRef={register}
            isTextarea={true}
          />
        </div>
      </div>

      <div className="group">
        <div className="group-item">
          <WizardCustomCheckbox
            changeChecked={handleChangeInclude}
            name={DOMAIN_INCLUDE_FIELD}
            label="Custom Domains"
            defaultValue={defaultValues[DOMAIN_INCLUDE_FIELD]}
            propRef={register}
          />
        </div>
        <div className="group-item">
          <WizardInputWithValidation
            type="text"
            error={!!errors[DOMAIN_FIELD]}
            errorMessage={errors[DOMAIN_FIELD] ? errors[DOMAIN_FIELD].message : ''}
            name={DOMAIN_FIELD}
            placeholder="Enter custom"
            propRef={register}
            isTextarea={true}
          />
        </div>
      </div>
    </form>
  );
};

WizardProtectionForm.propTypes = {
  isSubmitted: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  parentWizard: PropTypes.oneOf([ENDPOINT_WIZARD, DEMAND_WIZARD]).isRequired,
  setIsValid: PropTypes.func.isRequired
};

export default WizardProtectionForm;
