import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { updatePartnerStateAction } from '../../../../../actions/partners';

import {
  PARTNER_ADDRESS_FIELD,
  PARTNER_BUSINESS_FIELD,
  PARTNER_COMMENTS_FIELD,
  PARTNER_EMAIL_FIELD,
  TAGS_FIELD,
  PARTNER_TYPE_FIELD,
  PARTNER_WIZARD, USER_AGENTS_FIELD
} from '../../../constants';

import { useFormsEffect } from '../../../custom-hooks/useFormsEffect';
import { useIsValidForm } from '../../../custom-hooks/useIsValidForm';
import { contactSchema, validateContactData } from '../../../validators/partner/contact';

import WizardInputWithValidation from '../../shared/WizardInputWithValidation';

import WizardSelectWithValidation, {
  CREATE_TYPE,
  SELECT_TYPE
} from '../../shared/WizardSelectWithValidation';
import { businessTypes, sourceTypes } from '../partner-fake-data';

import '../../../wizard-base/WizardSharedStyles.scss';

const PartnerContactForm = ({
  isSubmitted,
  onSubmit,
  setIsValid
}) => {
  const {
    defaultValues,
    tagOptions,
    id
  } = useSelector(
    state => ({
      defaultValues: {
        [PARTNER_EMAIL_FIELD]: state[PARTNER_WIZARD].formData[PARTNER_EMAIL_FIELD],
        [PARTNER_ADDRESS_FIELD]: state[PARTNER_WIZARD].formData[PARTNER_ADDRESS_FIELD],
        [PARTNER_COMMENTS_FIELD]: state[PARTNER_WIZARD].formData[PARTNER_COMMENTS_FIELD],
        [PARTNER_TYPE_FIELD]: state[PARTNER_WIZARD].formData[PARTNER_TYPE_FIELD],
        [PARTNER_BUSINESS_FIELD]: state[PARTNER_WIZARD].formData[PARTNER_BUSINESS_FIELD],
        [TAGS_FIELD]: state[PARTNER_WIZARD].formData[TAGS_FIELD]
      },
      id: state[PARTNER_WIZARD].formData._id,
      tagOptions: state.staticList.tagOptions
    })
  );

  const {
    register,
    errors,
    triggerValidation,
    getValues,
    control
  } = useForm({
    mode: 'onBlur',
    defaultValues,
    validationSchema: contactSchema
  });

  useFormsEffect(isSubmitted, triggerValidation, getValues, updatePartnerStateAction, onSubmit);

  const values = getValues({ nest: true });
  const isValid = validateContactData(Object.keys(values).length ? values : defaultValues);
  useIsValidForm(isValid, setIsValid);

  return (
    <form>
      {id && (
        <div className="wizard-id-box"><span>Ref Id:</span>&nbsp;{id}</div>
      )}

      <WizardInputWithValidation
        propRef={register}
        name={PARTNER_EMAIL_FIELD}
        label="Email"
        error={!!errors[PARTNER_EMAIL_FIELD]}
        errorMessage={errors[PARTNER_EMAIL_FIELD] ? errors[PARTNER_EMAIL_FIELD].message : ''}
        type='email'
      />

      <WizardSelectWithValidation
        name={TAGS_FIELD}
        control={control}
        label='Tags'
        selectType={CREATE_TYPE}
        error={!!errors[TAGS_FIELD]}
        errorMessage={errors[TAGS_FIELD] ? errors[TAGS_FIELD].message : ''}
        isMulti={true}
        options={tagOptions}
        defaultValue={defaultValues[TAGS_FIELD]}
      />

      <WizardSelectWithValidation
        control={control}
        name={PARTNER_BUSINESS_FIELD}
        label='Business Type'
        errorMessage={errors[PARTNER_BUSINESS_FIELD] ? errors[PARTNER_BUSINESS_FIELD].message : ''}
        error={!!errors[PARTNER_BUSINESS_FIELD]}
        selectType={SELECT_TYPE}
        isMulti={false}
        options={businessTypes}
        defaultValue={defaultValues[PARTNER_BUSINESS_FIELD]}
      />

      <WizardSelectWithValidation
        control={control}
        name={PARTNER_TYPE_FIELD}
        label='Source Type'
        errorMessage={errors[PARTNER_TYPE_FIELD] ? errors[PARTNER_TYPE_FIELD].message : ''}
        error={!!errors[PARTNER_TYPE_FIELD]}
        selectType={SELECT_TYPE}
        isMulti={false}
        options={sourceTypes}
        defaultValue={defaultValues[PARTNER_TYPE_FIELD]}
      />

      <WizardInputWithValidation
        label="Address"
        type="text"
        error={!!errors[PARTNER_ADDRESS_FIELD]}
        errorMessage={errors[PARTNER_ADDRESS_FIELD] ? errors[PARTNER_ADDRESS_FIELD].message : ''}
        name={PARTNER_ADDRESS_FIELD}
        propRef={register}
        placeholder="Enter Your Address..."
      />

      <div className='form-group'>
        <label>Comments</label>
        <WizardInputWithValidation
          type="text"
          error={!!errors[PARTNER_COMMENTS_FIELD]}
          errorMessage={errors[PARTNER_COMMENTS_FIELD] ? errors[PARTNER_COMMENTS_FIELD].message : ''}
          name={PARTNER_COMMENTS_FIELD}
          placeholder="Enter comments"
          propRef={register}
          isTextarea={true}
        />
        {/* <textarea */}
        {/*  name={PARTNER_COMMENTS_FIELD} */}
        {/*  ref={register} */}
        {/*  placeholder="Enter comments" */}
        {/* /> */}
      </div>
    </form>
  );
};

PartnerContactForm.propTypes = {
  isSubmitted: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setIsValid: PropTypes.func.isRequired
};

export default PartnerContactForm;
