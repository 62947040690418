import moment from 'moment';
import React, { useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useFieldArray, useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import { hideCurrentForm } from '../../actions/drawerForms';

import { createRuleAction, updateRuleAction } from '../../actions/rules';

import {
  RULES_OPERATION_FIELD,
  RULES_VALUE_FIELD,
  RULES_DIMENSION_FILTERS_FIELD,
  RULES_KEY_FIELD,
  RULES_METRIC_FILTERS_FIELD,
  RULES_TAGS_FIELD,
  RULES_FREQUENCY_INTERVAL_FIELD,
  RULES_ACTION_FIELD,
  RULES_ACTION_DATA_FIELD,
  RULES_OUTCOME_FIELD,
  RULES_ANALYZE_INTERVAL_FIELD,
  RULES_FREQUENCY_CUSTOM_FIELD,
  RULES_FREQUENCY_DAYS_FIELD
} from '../../actions/types';

import {
  filterDimensionsList,
  getFilterDimensionsOperatorOptions,
  handleFilterDimensionsChange,
  metricsList,
  operationsList
} from '../reports/helpers';

import { renderDimensionsValueField } from '../reports/ReportsWizard';

import { TITLE_FIELD } from '../wizards-manager/constants';

import { useClearArrayError } from '../wizards-manager/custom-hooks/useClearArrayError';
import { useFormsOptions } from '../wizards-manager/custom-hooks/useFormsOptions';
import { getOptions, handleArrayErrorMessage, handleArrayHaveError } from '../wizards-manager/helpers/wizard-helpers';

import WizardConfirmation from '../wizards-manager/wizards/shared/WizardConfirmation';
import WizardInputWithValidation from '../wizards-manager/wizards/shared/WizardInputWithValidation';
import WizardSelectWithValidation, {
  CREATE_TYPE,
  SELECT_TYPE
} from '../wizards-manager/wizards/shared/WizardSelectWithValidation';

import {
  rulesAnalyzeIntervals,
  rulesFrequencyIntervals,
  rulesOutcomeOptions,
  rulesTempTags,
  rulesTempActions
} from './helpers';

import RulesTitle from './RulesTitle';

import { rulesSchema } from './validators';

import './RulesWizard.scss';

const RulesWizard = () => {
  const dispatch = useDispatch();

  const {
    defaultValues,
    id,
    staticList,
    isOpen
  } = useSelector(
    state => ({
      defaultValues: state.rules.formData,
      id: state.rules.formData._id,
      staticList: state.staticList,
      isOpen: state.drawerForms.isOpen
    })
  );

  const stateFrequencyCustom = defaultValues[RULES_FREQUENCY_CUSTOM_FIELD].split(':');
  const customDate = moment().toDate();
  customDate.setHours(parseInt(stateFrequencyCustom[0], 10));
  customDate.setMinutes(parseInt(stateFrequencyCustom[1], 10));

  const [frequencyCustom, setFrequencyCustom] = useState(customDate);

  const {
    control,
    getValues,
    setValue,
    watch,
    errors,
    clearError,
    register,
    triggerValidation
  } = useForm({
    mode: 'onChange',
    defaultValues,
    validationSchema: rulesSchema
  });

  const [frequencyDays, setFrequencyDays] = useState([...defaultValues[RULES_FREQUENCY_DAYS_FIELD]]);

  const handleSelectDay = useCallback((day) => {
    setFrequencyDays(state => state.map(s => {
      const selected = state.filter(s => s.selected);
      const newValue = !s.selected;

      if (!newValue) {
        if (selected.length === 1) {
          return s;
        }
      }

      if (s.value === day) {
        s.selected = newValue;
      }

      return s;
    }));
  }, [JSON.stringify(frequencyDays)]);

  const filterDimensionsArray = useFieldArray({
    control,
    name: RULES_DIMENSION_FILTERS_FIELD
  });

  const filterDimensions = filterDimensionsArray.fields;
  const appendFilterDimensions = filterDimensionsArray.append;
  const removeFilterDimension = filterDimensionsArray.remove;

  const filterMetricsArray = useFieldArray(({
    control,
    name: RULES_METRIC_FILTERS_FIELD
  }));

  const filterMetrics = filterMetricsArray.fields;
  const appendFilterMetrics = filterMetricsArray.append;
  const removeFilterMetrics = filterMetricsArray.remove;

  const [dimensionsOptions, setDimensionsOptions] = useState(filterDimensionsList);
  const [metricsOptions, setMetricsOptions] = useState(metricsList);

  const watchFilterDimensions = watch(RULES_DIMENSION_FILTERS_FIELD, defaultValues[RULES_DIMENSION_FILTERS_FIELD]);
  const watchFilterMetrics = watch(RULES_METRIC_FILTERS_FIELD, defaultValues[RULES_METRIC_FILTERS_FIELD]);

  useFormsOptions(getOptions(watchFilterDimensions || []), filterDimensionsList, setDimensionsOptions, RULES_KEY_FIELD, false);
  useFormsOptions(getOptions(watchFilterMetrics || []), metricsList, setMetricsOptions, RULES_KEY_FIELD, false);

  useClearArrayError(RULES_DIMENSION_FILTERS_FIELD, watchFilterDimensions, clearError);
  useClearArrayError(RULES_METRIC_FILTERS_FIELD, watchFilterMetrics, clearError);

  const watchAction = watch(RULES_ACTION_FIELD, defaultValues[RULES_ACTION_FIELD]);
  const watchFrequencyInterval = watch(RULES_FREQUENCY_INTERVAL_FIELD, defaultValues[RULES_FREQUENCY_INTERVAL_FIELD]);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);

    const isValid = await triggerValidation();

    if (!isValid) {
      setLoading(false);
      return;
    }

    const values = getValues({ nest: true });

    if (values[RULES_FREQUENCY_INTERVAL_FIELD].value === 'custom') {
      values[RULES_FREQUENCY_CUSTOM_FIELD] = moment(frequencyCustom).format('HH:MM');
    }

    if (!values[RULES_METRIC_FILTERS_FIELD]) {
      values[RULES_METRIC_FILTERS_FIELD] = [];
    }

    if (!values[RULES_DIMENSION_FILTERS_FIELD]) {
      values[RULES_DIMENSION_FILTERS_FIELD] = [];
    }

    values[RULES_FREQUENCY_DAYS_FIELD] = frequencyDays;

    let res;

    if (id) {
      res = await updateRuleAction(id, values)(dispatch);
    } else {
      res = await createRuleAction(values)(dispatch);
    }

    if (res) {
      setLoading(false);
      hideCurrentForm()(dispatch);
    } else {
      setLoading(false);
    }
  };

  return (
    <Modal
      appElement={document.getElementById('modal-wrapper')}
      style={{
        overlay: {
          zIndex: 9998
        },
        content: {
          padding: 0,
          top: 80,
          bottom: 80,
          left: 80,
          right: 80
        }
      }}
      isOpen={isOpen}
    >
      <div
        className="rules-wizard panel panel-create drawer-form-lg panel-show"
      >
        <div className="panel-title">
          <div className="rules-wizard-title">
            <RulesTitle
              error={!!errors[TITLE_FIELD]}
              propRef={register}
              id={''}
            />
          </div>

          <p className="btn-close">
            <span onClick={() => {
              hideCurrentForm()(dispatch);
            }}/>
          </p>
        </div>

        <div className="rules-wizard-container panel-content">
          <div className="rules-wizard-form-content">
            <WizardSelectWithValidation
              name={RULES_TAGS_FIELD}
              control={control}
              label='Tags'
              placeholder='#mainstream,...'
              selectType={CREATE_TYPE}
              error={!!errors[RULES_TAGS_FIELD]}
              errorMessage={errors[RULES_TAGS_FIELD] ? errors[RULES_TAGS_FIELD].message : ''}
              isMulti={true}
              // options={staticList.rulesTags}
              options={rulesTempTags}
              defaultValue={defaultValues[RULES_TAGS_FIELD]}
            />

            <WizardSelectWithValidation
              control={control}
              name={RULES_ANALYZE_INTERVAL_FIELD}
              label="Analyze Data From"
              isMulti={false}
              selectType={SELECT_TYPE}
              options={rulesAnalyzeIntervals}
              errorMessage={errors[RULES_ANALYZE_INTERVAL_FIELD] ? errors[RULES_ANALYZE_INTERVAL_FIELD].message : ''}
              error={!!errors[RULES_ANALYZE_INTERVAL_FIELD]}
            />

            <div className="rules-do-when-container">
              <h5>DO</h5>
            </div>

            <div className="rules-action-container">
              <WizardSelectWithValidation
                control={control}
                name={RULES_ACTION_FIELD}
                label="Select Action"
                isMulti={false}
                selectType={SELECT_TYPE}
                // options={staticList.rulesActions}
                options={rulesTempActions}
                errorMessage={errors[RULES_ACTION_FIELD] ? errors[RULES_ACTION_FIELD].message : ''}
                error={!!errors[RULES_ACTION_FIELD]}
              />

              {watchAction && watchAction.hasData ? (
                <div className="rules-action-has-data">
                  <WizardInputWithValidation
                    type="number"
                    error={!!errors[RULES_ACTION_DATA_FIELD]}
                    errorMessage={errors[RULES_ACTION_DATA_FIELD] ? errors[RULES_ACTION_DATA_FIELD].message : ''}
                    name={RULES_ACTION_DATA_FIELD}
                    propRef={register}
                    withStep={true}
                  />
                </div>) : null}
            </div>

            <div className="form-group rules-radio-container">
              {rulesOutcomeOptions.map((outcome, index) => (
                <label key={index}>
                  {outcome.label}
                  <input name={RULES_OUTCOME_FIELD} value={outcome.value} type="radio" ref={register}/>
                </label>
              ))}
            </div>

            <div className="rules-do-when-container">
              <h5>When</h5>
            </div>

            <div className="group action-right filters-box">
              <div className="header-width-add">
                <h4>Filter Dimensions</h4>

                <div className="add-new">
                  <p onClick={() => appendFilterDimensions({
                    [RULES_KEY_FIELD]: null,
                    [RULES_OPERATION_FIELD]: {
                      label: 'is in',
                      value: 'is_in'
                    },
                    [RULES_VALUE_FIELD]: null
                  })}>
                    <i className="fas fa-plus"/>
                    <span className="add-item">Add new</span>
                  </p>
                </div>
              </div>

              <div className="group-item">
                <div className="form-group">
                  <div className="row-list">
                    {filterDimensions.map((dimension, index) => (
                      <div className="row-item" key={dimension.id}>
                        <div className="row">
                          <div className="col-sm-4">
                            <WizardSelectWithValidation
                              control={control}
                              isMulti={false}
                              error={handleArrayHaveError(errors, RULES_DIMENSION_FILTERS_FIELD, RULES_KEY_FIELD, index)}
                              errorMessage={handleArrayErrorMessage(errors, RULES_DIMENSION_FILTERS_FIELD, RULES_KEY_FIELD, index)}
                              name={`${RULES_DIMENSION_FILTERS_FIELD}[${index}][${RULES_KEY_FIELD}]`}
                              options={dimensionsOptions}
                              selectType={SELECT_TYPE}
                              label=''
                              defaultValue={dimension[RULES_KEY_FIELD]}
                              onChange={handleFilterDimensionsChange(index, setValue)}
                            />
                          </div>
                          <div className="col-sm-2">
                            <WizardSelectWithValidation
                              control={control}
                              isMulti={false}
                              error={handleArrayHaveError(errors, RULES_DIMENSION_FILTERS_FIELD, RULES_OPERATION_FIELD, index)}
                              errorMessage={handleArrayErrorMessage(errors, RULES_DIMENSION_FILTERS_FIELD, RULES_OPERATION_FIELD, index)}
                              name={`${RULES_DIMENSION_FILTERS_FIELD}[${index}][${RULES_OPERATION_FIELD}]`}
                              options={getFilterDimensionsOperatorOptions(watchFilterDimensions, index)}
                              selectType={SELECT_TYPE}
                              label=''
                              defaultValue={dimension[RULES_OPERATION_FIELD]}
                            />
                          </div>
                          <div className="col-sm-6">
                            {renderDimensionsValueField(
                              watchFilterDimensions,
                              index,
                              dimension[RULES_VALUE_FIELD],
                              control,
                              errors,
                              staticList
                            )}
                          </div>
                        </div>
                        <span
                          className="action-icon delete-item"
                          onClick={() => removeFilterDimension(index)}
                        >
                          <i className="far fa-trash-alt"/>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="group action-right filters-box last-filter-box">
              <div className="header-width-add">
                <h4>Filter Metrics</h4>

                <div className="add-new">
                  <p onClick={() => appendFilterMetrics({
                    [RULES_KEY_FIELD]: null,
                    [RULES_OPERATION_FIELD]: {
                      label: '>',
                      value: 'gt'
                    },
                    [RULES_VALUE_FIELD]: 0
                  })}>
                    <i className="fas fa-plus"/>
                    <span className="add-item">Add new</span>
                  </p>
                </div>
              </div>

              <div className="group-item">
                <div className="form-group">
                  <div className="row-list">
                    {filterMetrics.map((metric, index) => (
                      <div className="row-item" key={metric.id}>
                        <div className="row">
                          <div className="col-sm-4">
                            <WizardSelectWithValidation
                              control={control}
                              isMulti={false}
                              error={handleArrayHaveError(errors, RULES_METRIC_FILTERS_FIELD, RULES_KEY_FIELD, index)}
                              errorMessage={handleArrayErrorMessage(errors, RULES_METRIC_FILTERS_FIELD, RULES_KEY_FIELD, index)}
                              name={`${RULES_METRIC_FILTERS_FIELD}[${index}][${RULES_KEY_FIELD}]`}
                              options={metricsOptions}
                              selectType={SELECT_TYPE}
                              label=''
                              defaultValue={metric[RULES_KEY_FIELD]}
                            />
                          </div>
                          <div className="col-sm-2">
                            <WizardSelectWithValidation
                              control={control}
                              isMulti={false}
                              error={handleArrayHaveError(errors, RULES_METRIC_FILTERS_FIELD, RULES_OPERATION_FIELD, index)}
                              errorMessage={handleArrayErrorMessage(errors, RULES_METRIC_FILTERS_FIELD, RULES_OPERATION_FIELD, index)}
                              name={`${RULES_METRIC_FILTERS_FIELD}[${index}][${RULES_OPERATION_FIELD}]`}
                              options={operationsList}
                              selectType={SELECT_TYPE}
                              label=''
                              defaultValue={metric[RULES_OPERATION_FIELD]}
                            />
                          </div>
                          <div className="col-sm-6 filter-metrics-value">
                            <WizardInputWithValidation
                              type='number'
                              error={handleArrayHaveError(errors, RULES_METRIC_FILTERS_FIELD, RULES_VALUE_FIELD, index)}
                              errorMessage={handleArrayErrorMessage(errors, RULES_METRIC_FILTERS_FIELD, RULES_VALUE_FIELD, index)}
                              name={`${RULES_METRIC_FILTERS_FIELD}[${index}][${RULES_VALUE_FIELD}]`}
                              propRef={register()}
                              placeholder='value'
                            />
                          </div>
                        </div>
                        <span
                          className="action-icon delete-item"
                          onClick={() => removeFilterMetrics(index)}
                        >
                          <i className="far fa-trash-alt"/>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="rules-frequency-container">
              <h4>Run frequency</h4>

              <div className="rules-frequency-interval">
                <WizardSelectWithValidation
                  control={control}
                  name={RULES_FREQUENCY_INTERVAL_FIELD}
                  isMulti={false}
                  selectType={SELECT_TYPE}
                  options={rulesFrequencyIntervals}
                  errorMessage={errors[RULES_FREQUENCY_INTERVAL_FIELD] ? errors[RULES_FREQUENCY_INTERVAL_FIELD].message : ''}
                  error={!!errors[RULES_FREQUENCY_INTERVAL_FIELD]}
                />
              </div>

              {watchFrequencyInterval && watchFrequencyInterval.value === 'custom' ? (
                <div className="rules-frequency-custom">
                  <DatePicker
                    selected={frequencyCustom}
                    onChange={date => setFrequencyCustom(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeCaption={false}
                    timeIntervals={15}
                    dateFormat="hh:mm"
                  />
                </div>) : null}
            </div>

            <div className="rules-frequency-days">
              {frequencyDays.map((day, index) => (
                <div
                  onClick={() => handleSelectDay(day.value)}
                  key={index}
                  className={`rules-frequency-day ${day.selected ? 'day-selected' : ''}`}
                >
                  {day.label}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="panel-footer">
          {!watchFilterDimensions.length && !watchFilterMetrics.length
            ? (
              <WizardConfirmation
                data='rule'
                onSubmit={handleSubmit}
                title='Are you sure you want to save a rule with no filters?'
                withoutDispatch={true}
                action={(onClick) => (
                  <button disabled={loading} onClick={onClick} className="btn btn-dark-violet">Create rule</button>
                )}
              />)
            : (<button disabled={loading} onClick={handleSubmit} className="btn btn-dark-violet">Create rule</button>)
          }
        </div>
      </div>
    </Modal>
  );
};

export default RulesWizard;
