import * as yup from 'yup';

import {
  PARTNER_CONFIRM_PASSWORD_FIELD,
  PARTNER_PASSWORD_FIELD,
  PARTNER_USERNAME_FIELD
} from '../../constants';

export const authSchema = yup.object().shape({
  [PARTNER_USERNAME_FIELD]: yup.string().required(),
  [PARTNER_PASSWORD_FIELD]: yup.string().required(),
  [PARTNER_CONFIRM_PASSWORD_FIELD]: yup.string().required()
});

export const validateAuthData = (data) => {
  try {
    authSchema.validateSync(data);

    return true;
  } catch (e) {
    return false;
  }
};

export const authSchemaUpdate = yup.object().shape({
  [PARTNER_USERNAME_FIELD]: yup.string(),
  [PARTNER_PASSWORD_FIELD]: yup.string(),
  [PARTNER_CONFIRM_PASSWORD_FIELD]: yup.string()
});

export const validateAuthDataUpdate = (data) => {
  try {
    authSchemaUpdate.validateSync(data);

    return true;
  } catch (e) {
    return false;
  }
};
