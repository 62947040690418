import moment from 'moment';

import {
  DASHBOARD_APPROVED_PAYOUTS,
  DASHBOARD_APPROVED_REVENUE,
  DASHBOARD_AVG_NET_BID,
  DASHBOARD_CLICKS_COUNT,
  DASHBOARD_COUNTRIES_FIELD,
  DASHBOARD_DATE_FIELD,
  DASHBOARD_DEMAND_FEEDS_FIELD,
  DASHBOARD_DEMAND_NAME_FIELD,
  DASHBOARD_DF_SOURCE_FIELD,
  DASHBOARD_DF_SOURCE_NAME_FIELD,
  DASHBOARD_E_CPM,
  DASHBOARD_ENDPOINT_ID_FIELD,
  DASHBOARD_ENDPOINT_NAME_FIELD,
  DASHBOARD_ENDPOINT_STATUS_FIELD,
  DASHBOARD_EP_SOURCE_FIELD,
  DASHBOARD_EP_SOURCE_NAME_FIELD,
  DASHBOARD_EST_PAYOUT,
  DASHBOARD_EST_PROFIT,
  DASHBOARD_EST_REVENUE,
  DASHBOARD_EST_ROI,
  DASHBOARD_FILTERS,
  DASHBOARD_IMPS_COUNT,
  DASHBOARD_INT_REQUESTS_AVG_GROSS_BID,
  DASHBOARD_INT_REQUESTS_AVG_NET_BID,
  DASHBOARD_INT_REQUESTS_COUNT,
  DASHBOARD_INT_REQUESTS_COVERAGE,
  DASHBOARD_INTERVAL,
  DASHBOARD_INTERVAL_CUSTOM,
  DASHBOARD_INTERVAL_TODAY,
  DASHBOARD_INTERVAL_YESTERDAY,
  DASHBOARD_OS_FIELD,
  DASHBOARD_PLATFORM_FIELD,
  DASHBOARD_PROFIT,
  DASHBOARD_REQUESTS_AVG_GROSS_BID,
  DASHBOARD_REQUESTS_AVG_NET_BID,
  DASHBOARD_REQUESTS_COUNT,
  DASHBOARD_REQUESTS_COVERAGE,
  DASHBOARD_ROI,
  DASHBOARD_TIMEZONE_FIELD,
  REPORTS_AVG_GROSS_BID_FIELD,
  REPORTS_BREAKDOWN_FIELD,
  REPORTS_BROWSER_FIELD,
  REPORTS_CLICKS_COUNT_DISCP_FIELD,
  REPORTS_CLICKS_COVERAGE_FIELD,
  REPORTS_CLICKS_ERRORS_PCT_FIELD,
  REPORTS_CTR_FIELD,
  REPORTS_DATE_RANGE_FIELD,
  REPORTS_DEMAND_STATUS_FIELD,
  REPORTS_DIMENSION_FILTERS_FIELD,
  REPORTS_DIMENSIONS_FIELD,
  REPORTS_IMPS_COVERAGE_FIELD,
  REPORTS_INTERVAL_LAST_48_HOURS,
  REPORTS_KEY_FIELD,
  REPORTS_METRIC_FILTERS_FIELD,
  REPORTS_METRICS_FIELD,
  REPORTS_OPERATION_FIELD,
  REPORTS_PAYOUT_DISCP_FIELD,
  REPORTS_REVENUE_DISCP_FIELD,
  REPORTS_VALUE_FIELD
} from '../../../actions/types';

import { formatCurrency, formatNumbers, formatPercentage } from '../../dashboard/numeric-helpers';
import { transformMultiSelect, transformSingleSelect } from '../../wizards-manager/helpers/wizard-helpers';

import { reportsBreakdownObject, reportsDimensionsObject, reportsMetricsObject, reportsPlatformsObject } from './index';

const transformReportFilterDimensions = (dimensions) =>
  dimensions.reduce((acc, dimension) => {
    const key = transformSingleSelect(dimension[REPORTS_KEY_FIELD]);

    if (key === DASHBOARD_ENDPOINT_STATUS_FIELD || key === REPORTS_DEMAND_STATUS_FIELD) {
      acc[key] = transformSingleSelect(dimensions[REPORTS_VALUE_FIELD]);
    } else {
      acc[key] = transformMultiSelect(dimension[REPORTS_VALUE_FIELD]);
    }

    return acc;
  }, {});

const transformReportFilterMetrics = (metrics, isDemand) =>
  metrics.map((metric) => {
    let key = transformSingleSelect(metric[REPORTS_KEY_FIELD]);
    const operation = transformSingleSelect(metric[REPORTS_OPERATION_FIELD]);
    const value = parseInt(metric[REPORTS_VALUE_FIELD], 10);

    if (isDemand) {
      switch (key) {
        case DASHBOARD_REQUESTS_COUNT:
          key = DASHBOARD_INT_REQUESTS_COUNT;
          break;

        case DASHBOARD_REQUESTS_AVG_NET_BID:
          key = DASHBOARD_INT_REQUESTS_AVG_NET_BID;
          break;

        case DASHBOARD_REQUESTS_AVG_GROSS_BID:
          key = DASHBOARD_INT_REQUESTS_AVG_GROSS_BID;
          break;

        case DASHBOARD_REQUESTS_COVERAGE:
          key = DASHBOARD_INT_REQUESTS_COVERAGE;
          break;
      }
    }

    return {
      [key]: {
        [operation]: value
      }
    };
  });

const transformReportMetrics = (metrics, isDemand) =>
  transformMultiSelect(metrics).map((metric) => {
    if (isDemand) {
      switch (metric) {
        case DASHBOARD_REQUESTS_COUNT:
          return DASHBOARD_INT_REQUESTS_COUNT;

        case DASHBOARD_REQUESTS_AVG_NET_BID:
          return DASHBOARD_INT_REQUESTS_AVG_NET_BID;

        case DASHBOARD_REQUESTS_AVG_GROSS_BID:
          return DASHBOARD_INT_REQUESTS_AVG_GROSS_BID;

        case DASHBOARD_REQUESTS_COVERAGE:
          return DASHBOARD_INT_REQUESTS_COVERAGE;

        default:
          return metric;
      }
    }

    return metric;
  });

export const transformReportQuery = (query, isDemand) =>
  Object.keys(query)
    .reduce((acc, field) => {
      switch (field) {
        case REPORTS_DIMENSIONS_FIELD:
          acc[field] = transformMultiSelect(query[field]);
          break;

        case REPORTS_METRICS_FIELD:
          acc[field] = transformReportMetrics(query[field], isDemand);
          break;

        case REPORTS_BREAKDOWN_FIELD:
          acc[field] = transformSingleSelect(query[field]);
          break;

        case DASHBOARD_INTERVAL: {
          const interval = query[field];

          if (interval === DASHBOARD_INTERVAL_CUSTOM) {
            acc[REPORTS_DATE_RANGE_FIELD] = {
              from: moment(query[REPORTS_DATE_RANGE_FIELD].from).startOf('day').format('Y-MM-DD HH:mm:ss'),
              to: moment(query[REPORTS_DATE_RANGE_FIELD].to).endOf('day').format('Y-MM-DD HH:mm:ss')
            };
          } else {
            acc[field] = query[field];
          }

          break;
        }

        case REPORTS_DIMENSION_FILTERS_FIELD: {
          const dimensions = transformReportFilterDimensions(query[field]);

          if (Object.keys(dimensions).length && !acc[DASHBOARD_FILTERS]) {
            acc[DASHBOARD_FILTERS] = {};
          }

          if (Object.keys(dimensions).length) {
            Object.keys(dimensions)
              .forEach((field) => {
                acc[DASHBOARD_FILTERS][field] = dimensions[field];
              });
          }

          break;
        }

        case REPORTS_METRIC_FILTERS_FIELD: {
          const metrics = transformReportFilterMetrics(query[field], isDemand);

          if (metrics.length && !acc[DASHBOARD_FILTERS]) {
            acc[DASHBOARD_FILTERS] = {};
          }

          if (metrics.length) {
            acc[DASHBOARD_FILTERS][REPORTS_METRICS_FIELD] = metrics;
          }

          break;
        }

        case DASHBOARD_TIMEZONE_FIELD:
          acc[field] = transformSingleSelect(query[field]);
          break;
      }

      return acc;
    }, {});

export const buildReportsTableData = (dimensions, metrics, hasBreakdown) => {
  const columns = [];

  if (hasBreakdown) {
    columns.push({
      Header: 'Date',
      accessor: 'date'
    });
  }

  dimensions.forEach((accessor) => {
    columns.push({
      Header: reportsDimensionsObject[accessor],
      accessor
    });
  });

  metrics.forEach((metric) => {
    columns.push({
      Header: reportsMetricsObject[metric],
      accessor: metric
    });
  });

  const hiddenColumns = [];

  columns.forEach((column, index) => {
    if (index > 5) {
      hiddenColumns.push(column.accessor);
    }
  });

  columns.push({
    Header: '',
    id: 'all',
    width: 0
  });

  return {
    columns,
    hiddenColumns
  };
};

const reportsGetFieldLabel = (value, options) => {
  const option = options.find(o => o[REPORTS_VALUE_FIELD] === value);

  return option ? option.label : value;
};

export const reportsTransformTableData = (data, staticList, interval) =>
  data.map((item) =>
    Object.keys(item)
      .reduce((acc, field) => {
        switch (field) {
          case DASHBOARD_ENDPOINT_ID_FIELD:
            acc[field] = item[DASHBOARD_ENDPOINT_NAME_FIELD];
            break;

          case DASHBOARD_EP_SOURCE_FIELD:
            acc[field] = item[DASHBOARD_EP_SOURCE_NAME_FIELD];
            break;

          case DASHBOARD_DEMAND_FEEDS_FIELD:
            acc[field] = item[DASHBOARD_DEMAND_NAME_FIELD];
            break;

          case DASHBOARD_DF_SOURCE_FIELD:
            acc[field] = item[DASHBOARD_DF_SOURCE_NAME_FIELD];
            break;

          case DASHBOARD_DATE_FIELD:
            acc[field] = moment(new Date(item[field]))
              .format(interval === DASHBOARD_INTERVAL_YESTERDAY ||
                interval === DASHBOARD_INTERVAL_TODAY ||
                interval === REPORTS_INTERVAL_LAST_48_HOURS ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY');
            break;

          case DASHBOARD_COUNTRIES_FIELD:
            acc[field] = reportsGetFieldLabel(item[field], staticList.countryOptions);
            break;

          case REPORTS_BROWSER_FIELD:
            acc[field] = reportsGetFieldLabel(item[field], staticList.browserOptions);
            break;

          case DASHBOARD_OS_FIELD:
            acc[field] = reportsGetFieldLabel(item[field], staticList.osOptions);
            break;

          case DASHBOARD_PLATFORM_FIELD:
            acc[field] = reportsPlatformsObject[item[field]];
            break;

          case DASHBOARD_REQUESTS_AVG_NET_BID:
          case DASHBOARD_INT_REQUESTS_AVG_NET_BID:
          case DASHBOARD_REQUESTS_AVG_GROSS_BID:
          case DASHBOARD_INT_REQUESTS_AVG_GROSS_BID:
          case DASHBOARD_APPROVED_REVENUE:
          case DASHBOARD_APPROVED_PAYOUTS:
          case DASHBOARD_EST_PAYOUT:
          case DASHBOARD_EST_REVENUE:
          case REPORTS_AVG_GROSS_BID_FIELD:
          case DASHBOARD_AVG_NET_BID:
          case DASHBOARD_PROFIT:
          case DASHBOARD_EST_PROFIT:
          case DASHBOARD_EST_ROI:
          case DASHBOARD_E_CPM:
          case DASHBOARD_ROI:
            acc[field] = formatCurrency(item[field]);
            break;

          case REPORTS_CTR_FIELD:
          case DASHBOARD_REQUESTS_COVERAGE:
          case DASHBOARD_INT_REQUESTS_COVERAGE:
          case REPORTS_CLICKS_COUNT_DISCP_FIELD:
          case REPORTS_PAYOUT_DISCP_FIELD:
          case REPORTS_REVENUE_DISCP_FIELD:
          case REPORTS_CLICKS_ERRORS_PCT_FIELD:
          case REPORTS_CLICKS_COVERAGE_FIELD:
          case REPORTS_IMPS_COVERAGE_FIELD:
            acc[field] = formatPercentage(item[field]);
            break;

          case DASHBOARD_REQUESTS_COUNT:
          case DASHBOARD_INT_REQUESTS_COUNT:
          case DASHBOARD_CLICKS_COUNT:
          case DASHBOARD_IMPS_COUNT:
            acc[field] = formatNumbers(item[field]);
            break;

          default:
            acc[field] = item[field];
            break;
        }

        return acc;
      }, {}));

export const reportsTransformReportTemplateData = (item) =>
  Object.keys(item)
    .reduce((acc, field) => {
      switch (field) {
        case REPORTS_BREAKDOWN_FIELD:
          acc[field] = reportsBreakdownObject[item[field]];
          break;

        case REPORTS_DIMENSIONS_FIELD:
          acc[field] = item[field].map(value => ({
            label: reportsDimensionsObject[value],
            value
          }));
          break;

        case REPORTS_METRICS_FIELD:
          acc[field] = item[field].map(value => ({
            label: reportsMetricsObject[value],
            value
          }));
          break;

        case REPORTS_METRIC_FILTERS_FIELD:
        case REPORTS_DIMENSION_FILTERS_FIELD:
        case DASHBOARD_INTERVAL:
          acc[field] = item[field];
          break;
      }

      return acc;
    }, {});
