import {
  fetchEndpointDemandByEndpointId,
  modifyEndpointDemand,
  saveEndpointDemand,
  removeEndpointDemand,
  fetchEndpointDemandByDemandId
} from '../endpointDemand';

export const fetchEndpointDemandsByEndpoint = async (id) => {
  try {
    const endpointDemands = await fetchEndpointDemandByEndpointId(id);

    return endpointDemands.data;
  } catch (err) {
    // TODO Handle error
    return [];
  }
};

export const fetchEndpointDemandsByDemand = async (id) => {
  try {
    const endpointDemands = await fetchEndpointDemandByDemandId(id);

    return endpointDemands.data;
  } catch (err) {
    // TODO Handle error
    return [];
  }
};

export const createEndpointDemands = async (data) => {
  try {
    return await saveEndpointDemand(data);
  } catch (err) {
    // TODO Handle error
    return false;
  }
};

export const updateEndpointDemand = async (id, data) => {
  try {
    await modifyEndpointDemand(id, data);

    return true;
  } catch (err) {
    // TODO Handle error
    return false;
  }
};

export const deleteEndpointDemand = async (id) => {
  try {
    await removeEndpointDemand(id);

    return true;
  } catch (err) {
    // TODO Handle error
    return false;
  }
};
