import { toast } from 'react-toastify';

import { fetchClientReport } from '../api/client-report';
import { fetchAllReports, fetchReportById, removeReport, saveReport } from '../api/report';

import { reportsCheckIsDemand } from '../components/reports/helpers';
import {
  buildReportsTableData,
  reportsTransformReportTemplateData,
  reportsTransformTableData,
  transformReportQuery
} from '../components/reports/helpers/transformers';
import { transformMultiSelect, transformSingleSelect } from '../components/wizards-manager/helpers/wizard-helpers';

import store from '../store';

import {
  DASHBOARD_INTERVAL,
  DRAWER_FORM_CLOSE,
  DRAWER_FORM_OPEN,
  DRAWER_FORM_REPORTS,
  REPORTS_BREAKDOWN_FIELD,
  REPORTS_CREATE_REPORT,
  REPORTS_DELETE_REPORT,
  REPORTS_DIMENSION_FILTERS_FIELD,
  REPORTS_DIMENSIONS_FIELD,
  REPORTS_EDIT_REPORT,
  REPORTS_FETCH_REPORT_TABLE_DATA,
  REPORTS_FETCH_REPORTS,
  REPORTS_GENERATE_REPORT,
  REPORTS_GET_INITIAL_FORM_STATE,
  REPORTS_LOAD_REPORT,
  REPORTS_METRIC_FILTERS_FIELD,
  REPORTS_METRICS_FIELD,
  REPORTS_START_FETCHING_REPORT_TABLE_DATA,
  REPORTS_TITLE_FIELD
} from './types';

export const createReportAction = (data, title) => async (dispatch) => {
  try {
    const query = {
      [REPORTS_DIMENSIONS_FIELD]: transformMultiSelect(data[REPORTS_DIMENSIONS_FIELD]),
      [REPORTS_METRICS_FIELD]: transformMultiSelect(data[REPORTS_METRICS_FIELD]),
      [REPORTS_BREAKDOWN_FIELD]: transformSingleSelect(data[REPORTS_BREAKDOWN_FIELD]),
      [DASHBOARD_INTERVAL]: data[DASHBOARD_INTERVAL],
      [REPORTS_DIMENSION_FILTERS_FIELD]: data[REPORTS_DIMENSION_FILTERS_FIELD],
      [REPORTS_METRIC_FILTERS_FIELD]: data[REPORTS_METRIC_FILTERS_FIELD],
      [REPORTS_TITLE_FIELD]: title
    };

    const res = await saveReport(query);

    const payload = {
      formData: data,
      report: {
        id: res.data.data._id,
        title
      }
    };

    dispatch({ type: REPORTS_CREATE_REPORT, payload });

    toast.info('Report created', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    return true;
  } catch (err) {
    toast.error('Creating report failed', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    return false;
  }
};

export const fetchReportCSVDataAction = async (data) => {
  try {
    const isDemand = reportsCheckIsDemand(data);
    const query = transformReportQuery(data, isDemand);

    const res = await fetchClientReport(query);

    return res.data;
  } catch (err) {
    toast.error('Downloading CSV report failed', {
      position: toast.POSITION.BOTTOM_CENTER
    });

    return false;
  }
};

export const fetchReportTableDataAction = (data) => async (dispatch) => {
  try {
    dispatch({ type: REPORTS_START_FETCHING_REPORT_TABLE_DATA });

    const staticList = store.getState().staticList;
    const isDemand = reportsCheckIsDemand(data);

    const query = transformReportQuery(data, isDemand);

    const res = await fetchClientReport(query);

    const dimensions = query[REPORTS_DIMENSIONS_FIELD];
    const metrics = query[REPORTS_METRICS_FIELD];
    const hasBreakdown = !!query[REPORTS_BREAKDOWN_FIELD] && query[REPORTS_BREAKDOWN_FIELD] !== 'none';

    const { columns, hiddenColumns } = buildReportsTableData(dimensions, metrics, hasBreakdown);

    const payload = {
      columns,
      hiddenColumns,
      items: reportsTransformTableData(res.data, staticList, data.interval)
    };

    dispatch({ type: REPORTS_FETCH_REPORT_TABLE_DATA, payload });
  } catch (err) {
    toast.error('Applying report failed', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};

export const fetchReportsAction = () => async (dispatch) => {
  try {
    const res = await fetchAllReports();

    const payload = res.data.data.data.map(r => ({
      title: r.title,
      id: r._id
    }));

    dispatch({ type: REPORTS_FETCH_REPORTS, payload });
  } catch (err) {
    toast.error('Fetching reports failed', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};

export const fetchReportAction = (id) => async (dispatch) => {
  try {
    const res = await fetchReportById(id);
    const payload = reportsTransformReportTemplateData(res.data.data);

    dispatch({ type: REPORTS_LOAD_REPORT, payload });
  } catch (err) {
    toast.error('Fetching report failed', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};

export const editReportAction = (id) => async (dispatch) => {
  try {
    const res = await fetchReportById(id);
    const payload = reportsTransformReportTemplateData(res.data.data);

    dispatch({ type: DRAWER_FORM_CLOSE });
    dispatch({ type: REPORTS_EDIT_REPORT, payload });
    dispatch({ type: DRAWER_FORM_OPEN, payload: DRAWER_FORM_REPORTS });
  } catch (err) {
    toast.error('Fetching report failed', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};

export const deleteReportAction = (id) => async (dispatch) => {
  try {
    await removeReport(id);

    dispatch({ type: REPORTS_DELETE_REPORT, payload: id });

    toast.info('Report deleted successfully', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  } catch (err) {
    toast.info('Report deleting fail', {
      position: toast.POSITION.BOTTOM_CENTER
    });
  }
};

export const generateReportAction = (payload) => (dispatch) => {
  dispatch({ type: REPORTS_GENERATE_REPORT, payload });
};

export const getInitialReportFormDataStateAction = () => (dispatch) => {
  dispatch({ type: REPORTS_GET_INITIAL_FORM_STATE });
};
