import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import './WizardSteps.scss';

const WizardSteps = ({
  steps,
  currentStep,
  onChangeStep,
  wizardName
}) => {
  const renderSteps = useCallback((steps) => (
    steps.map((step, index) => {
      let className = '';
      let divider = false;

      if (index <= currentStep) {
        className = 'activated';

        if ((index + 1) <= currentStep) {
          divider = true;
        }
      }

      let current = false;

      if (index === currentStep) {
        current = true;
      }

      return (
        <React.Fragment key={index}>
          <li
            onClick={onChangeStep.bind(null, index)}
            className={`wizard-step-switcher ${current ? 'current-step' : ''}`}
          >
            {current && (<div className='current-step-border'/>)}
            <div
              className={`node ${className}`}
            />
            <p>{step}</p>
          </li>
          {index < (steps.length - 1) && (
            <li className="wizard-steps-divider">
              <div className={`divider ${divider ? 'activated' : ''}`}/>
            </li>
          )}
        </React.Fragment>
      );
    })
  ), [currentStep]);

  return (
    <div className="wizard-steps-container">
      <div className="wizard-steps-title">{wizardName}</div>
      <ul className="wizard-steps">
        {renderSteps(steps)}
      </ul>
    </div>
  );
};

WizardSteps.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentStep: PropTypes.number.isRequired,
  onChangeStep: PropTypes.func.isRequired,
  wizardName: PropTypes.string.isRequired
};

export default WizardSteps;
