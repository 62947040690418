import { useEffect } from 'react';
import { useIsMountedRef } from './useIsMountedRef';

export const useIsValidForm = (
  isValid,
  setIsValid
) => {
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (isMountedRef.current) {
      setIsValid(isValid);
    }
  }, [isValid]);
};
