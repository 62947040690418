import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
  useFlexLayout
} from 'react-table';

import TableColumnsControl from './shared/TableColumnsControl';
import TableBody from './table-base/TableBody';
import TableDefaultColumnFilter from './table-base/TableDefaultColumnFilter';
import TableHead from './table-base/TableHead';
import TablePagination from './table-base/TablePagination';
import TableSearch from './table-base/TableSearch';

import './TableManager.scss';

const TablesManager = ({
  items,
  fields,
  tableName,
  searchFields,
  hiddenColumns,
  withoutSearch,
  withoutSearchHeaders
}) => {
  const columns = useMemo(() => fields, []);
  const data = useMemo(() => items, [JSON.stringify(items)]);

  const defaultColumn = useMemo(
    () => ({
      // Default Filter UI
      Filter: TableDefaultColumnFilter
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    allColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {
      pageIndex,
      pageSize,
      globalFilter
    },

    setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: 50,
        hiddenColumns: hiddenColumns || []
      },
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetGroupBy: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
      autoResetGlobalFilter: false
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useFlexLayout
  );

  const pageSizeOptions = [5, 10, 20, 25, 50, 100];

  return (
    <div className="table-manager data-grid">
      <div className='bot-grid df-react-table'>
        {withoutSearch ? null : (
          <TableSearch
            title={tableName}
            globalFilter={globalFilter || ''}
            count={data.length}
            setGlobalFilter={setGlobalFilter}
            searchFields={searchFields}
            columns={allColumns}
            withoutSearchHeaders={withoutSearchHeaders}
          />
        )}

        <table
          className="table table-borderless"
          {...getTableProps()}
        >
          <TableColumnsControl
            columns={allColumns}
          />
          <TableHead headerGroups={headerGroups}/>
          <TableBody page={page} prepareRow={prepareRow} getTableBodyProps={getTableBodyProps}/>
        </table>
        <TablePagination
          pageSizeOptions={pageSizeOptions}
          pageSize={pageSize}
          setPageSize={setPageSize}
          nextPage={nextPage}
          previousPage={previousPage}
          itemsCount={pageOptions.length}
          pageCount={pageCount}
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
        />
      </div>
    </div>
  );
};

TablesManager.propTypes = {
  items: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  searchFields: PropTypes.array.isRequired,
  tableName: PropTypes.string.isRequired,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  withoutSearch: PropTypes.bool,
  withoutSearchHeaders: PropTypes.bool
};

export default TablesManager;
