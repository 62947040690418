import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';

import AuthContext from '../../context/auth/authContext';

import WizardsManager from '../wizards-manager/WizardsManager';

import Header from './Header';
import SideBar from './SideBar';

import './Layout.scss';
import 'react-toastify/dist/ReactToastify.css';

const Layout = ({ children }) => {
  const { isAuthenticated, loadUser, loading } = useContext(AuthContext);

  useEffect(() => {
    loadUser();
  }, []);

  const authLayout = (
    // Header
    <div id="modal-wrapper" className="wrap">
      <WizardsManager/>
      <Header/>
      <article id="Content">
        <SideBar/>
        <ToastContainer
          enableMultiContainer
          position={toast.POSITION.BOTTOM_RIGHT}
        />
        <div className="main-content">
          {children}
        </div>
      </article>
    </div>
  );

  const guestLayout = <div/>;

  return (
    <>
      {!loading && isAuthenticated ? authLayout : guestLayout}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node
};

export default Layout;
