import axios from 'axios';

export const fetchAllRevenueReports = () => axios.get('/api/revenue_updates?sort=-reportDate');

export const fetchRevenueReportById = (id) => axios.get(`/api/revenue_updates/${id}`);

export const fetchAllAppEvents = (query = '') => axios.get(`/api/app_events${query}`);

export const fetchAppEventById = (id) => axios.get(`/api/app_events/${id}`);

export const fetchAllManualRevenueReports = () => axios.get('/api/manual_revenue_reports?sort=-reportDate');

export const fetchManualRevenueReportById = (id) => axios.get(`/api/manual_revenue_reports/${id}`);

export const createManualRevenueReport = (data) => axios.post('/api/manual_revenue_reports', data);

export const updateManualRevenueReport = (id, data) => axios.put(`/api/manual_revenue_reports/${id}`, data);

export const deleteManualRevenueReportById = (id) => axios.delete(`/api/manual_revenue_reports/${id}`);

export const calculateManualReportById = (id) => axios.put(`/api/manual_revenue_reports/${id}/calculate`);
