import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useCallback, useRef } from 'react';
import { CSVLink } from 'react-csv';
import { useFieldArray, useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import { hideCurrentForm } from '../../actions/drawerForms';

import {
  createReportAction,
  fetchReportCSVDataAction,
  generateReportAction,
  getInitialReportFormDataStateAction
} from '../../actions/reports';

import {
  DASHBOARD_COUNTRIES_FIELD,
  DASHBOARD_DEMAND_FEEDS_FIELD,
  DASHBOARD_DF_SOURCE_FIELD,
  DASHBOARD_DEMAND_TAGS_FIELD,
  DASHBOARD_ENDPOINT_ID_FIELD,
  DASHBOARD_ENDPOINT_STATUS_FIELD,
  DASHBOARD_ENDPOINT_TAGS_FIELD,
  DASHBOARD_EP_SOURCE_FIELD,
  DASHBOARD_INTERVAL,
  DASHBOARD_INTERVAL_CUSTOM,
  DASHBOARD_INTERVAL_YESTERDAY,
  DASHBOARD_OS_FIELD,
  DASHBOARD_PLATFORM_FIELD,
  DASHBOARD_SUB_BIDS_FIELD,
  REPORTS_BREAKDOWN_FIELD,
  REPORTS_BROWSER_FIELD,
  REPORTS_DATE_RANGE_FIELD,
  REPORTS_DEMAND_STATUS_FIELD,
  REPORTS_DIMENSION_FILTERS_FIELD,
  REPORTS_DIMENSIONS_FIELD,
  REPORTS_KEY_FIELD,
  REPORTS_METRIC_FILTERS_FIELD,
  REPORTS_METRICS_FIELD,
  REPORTS_OPERATION_FIELD,
  REPORTS_VALUE_FIELD,
  DASHBOARD_TIMEZONE_FIELD
} from '../../actions/types';

import { initialReportsFormDataState } from '../../reducers/reports';
import { useClearArrayError } from '../wizards-manager/custom-hooks/useClearArrayError';
import { useFormsOptions } from '../wizards-manager/custom-hooks/useFormsOptions';
import {
  getOptions,
  handleArrayErrorMessage,
  handleArrayHaveError
} from '../wizards-manager/helpers/wizard-helpers';

import { timezonesOptions } from '../wizards-manager/wizards/dashboard/helpers/timezones';

import Popover from '../wizards-manager/wizards/shared/Popover';
import WizardInputWithValidation from '../wizards-manager/wizards/shared/WizardInputWithValidation';
import WizardSelectWithValidation, {
  ASYNC_TYPE,
  CREATE_TYPE,
  SELECT_TYPE
} from '../wizards-manager/wizards/shared/WizardSelectWithValidation';

import {
  dimensionsList,
  filterDimensionsList,
  getBreakdownList,
  metricsList,
  operationsList,
  reportsLoadDemandOptions,
  reportsLoadDemandSourceOptions,
  reportsLoadEndpointOptions,
  reportsLoadTrafficSourceOptions,
  platformsList,
  getFilterDimensionsOperatorOptions,
  handleFilterDimensionsChange,
  reportsStatusList
} from './helpers';

import ReportsDatePicker from './ReportsDatePicker';

import { reportsSchema } from './validators';

import './ReportsWizard.scss';

export const renderDimensionsValueField = (dimensions, index, defaultValue, control, errors, staticList) => {
  const dimension = dimensions[index];

  if (dimension) {
    const key = dimension[REPORTS_KEY_FIELD];

    if (key) {
      const value = key.value;

      switch (value) {
        case DASHBOARD_ENDPOINT_ID_FIELD:
          return (
            <WizardSelectWithValidation
              control={control}
              isMulti={true}
              error={handleArrayHaveError(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_VALUE_FIELD, index)}
              errorMessage={handleArrayErrorMessage(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_VALUE_FIELD, index)}
              name={`${REPORTS_DIMENSION_FILTERS_FIELD}[${index}][${REPORTS_VALUE_FIELD}]`}
              options={[]}
              selectType={ASYNC_TYPE}
              label=''
              defaultValue={defaultValue}
              loadOptions={reportsLoadEndpointOptions}
            />
          );

        case DASHBOARD_EP_SOURCE_FIELD:
          return (
            <WizardSelectWithValidation
              control={control}
              isMulti={true}
              error={handleArrayHaveError(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_VALUE_FIELD, index)}
              errorMessage={handleArrayErrorMessage(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_VALUE_FIELD, index)}
              name={`${REPORTS_DIMENSION_FILTERS_FIELD}[${index}][${REPORTS_VALUE_FIELD}]`}
              options={[]}
              selectType={ASYNC_TYPE}
              label=''
              defaultValue={defaultValue}
              loadOptions={reportsLoadTrafficSourceOptions}
            />
          );

        case DASHBOARD_DEMAND_FEEDS_FIELD:
          return (
            <WizardSelectWithValidation
              control={control}
              isMulti={true}
              error={handleArrayHaveError(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_VALUE_FIELD, index)}
              errorMessage={handleArrayErrorMessage(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_VALUE_FIELD, index)}
              name={`${REPORTS_DIMENSION_FILTERS_FIELD}[${index}][${REPORTS_VALUE_FIELD}]`}
              options={[]}
              selectType={ASYNC_TYPE}
              label=''
              defaultValue={defaultValue}
              loadOptions={reportsLoadDemandOptions}
            />
          );

        case DASHBOARD_DF_SOURCE_FIELD:
          return (
            <WizardSelectWithValidation
              control={control}
              isMulti={true}
              error={handleArrayHaveError(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_VALUE_FIELD, index)}
              errorMessage={handleArrayErrorMessage(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_VALUE_FIELD, index)}
              name={`${REPORTS_DIMENSION_FILTERS_FIELD}[${index}][${REPORTS_VALUE_FIELD}]`}
              options={[]}
              selectType={ASYNC_TYPE}
              label=''
              defaultValue={defaultValue}
              loadOptions={reportsLoadDemandSourceOptions}
            />
          );

        case DASHBOARD_COUNTRIES_FIELD:
          return (
            <WizardSelectWithValidation
              control={control}
              isMulti={true}
              error={handleArrayHaveError(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_VALUE_FIELD, index)}
              errorMessage={handleArrayErrorMessage(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_VALUE_FIELD, index)}
              name={`${REPORTS_DIMENSION_FILTERS_FIELD}[${index}][${REPORTS_VALUE_FIELD}]`}
              options={staticList.countryOptions}
              selectType={SELECT_TYPE}
              label=''
              defaultValue={defaultValue}
            />
          );

        case DASHBOARD_OS_FIELD:
          return (
            <WizardSelectWithValidation
              control={control}
              isMulti={true}
              error={handleArrayHaveError(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_VALUE_FIELD, index)}
              errorMessage={handleArrayErrorMessage(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_VALUE_FIELD, index)}
              name={`${REPORTS_DIMENSION_FILTERS_FIELD}[${index}][${REPORTS_VALUE_FIELD}]`}
              options={staticList.osOptions}
              selectType={SELECT_TYPE}
              label=''
              defaultValue={defaultValue}
            />
          );

        case DASHBOARD_SUB_BIDS_FIELD:
          return (
            <WizardSelectWithValidation
              control={control}
              isMulti={true}
              error={handleArrayHaveError(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_VALUE_FIELD, index)}
              errorMessage={handleArrayErrorMessage(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_VALUE_FIELD, index)}
              name={`${REPORTS_DIMENSION_FILTERS_FIELD}[${index}][${REPORTS_VALUE_FIELD}]`}
              options={[]}
              selectType={CREATE_TYPE}
              label=''
              defaultValue={defaultValue}
            />
          );

        case DASHBOARD_PLATFORM_FIELD:
          return (
            <WizardSelectWithValidation
              control={control}
              isMulti={true}
              error={handleArrayHaveError(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_VALUE_FIELD, index)}
              errorMessage={handleArrayErrorMessage(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_VALUE_FIELD, index)}
              name={`${REPORTS_DIMENSION_FILTERS_FIELD}[${index}][${REPORTS_VALUE_FIELD}]`}
              options={platformsList}
              selectType={SELECT_TYPE}
              label=''
              defaultValue={defaultValue}
            />
          );

        case REPORTS_BROWSER_FIELD:
          return (
            <WizardSelectWithValidation
              control={control}
              isMulti={true}
              error={handleArrayHaveError(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_VALUE_FIELD, index)}
              errorMessage={handleArrayErrorMessage(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_VALUE_FIELD, index)}
              name={`${REPORTS_DIMENSION_FILTERS_FIELD}[${index}][${REPORTS_VALUE_FIELD}]`}
              options={staticList.browserOptions}
              selectType={SELECT_TYPE}
              label=''
              defaultValue={defaultValue}
            />
          );

        case DASHBOARD_ENDPOINT_TAGS_FIELD:
        case DASHBOARD_DEMAND_TAGS_FIELD:
          return (
            <WizardSelectWithValidation
              control={control}
              isMulti={true}
              error={handleArrayHaveError(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_VALUE_FIELD, index)}
              errorMessage={handleArrayErrorMessage(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_VALUE_FIELD, index)}
              name={`${REPORTS_DIMENSION_FILTERS_FIELD}[${index}][${REPORTS_VALUE_FIELD}]`}
              options={staticList.tagOptions}
              selectType={SELECT_TYPE}
              label=''
              defaultValue={defaultValue}
            />
          );

        case DASHBOARD_ENDPOINT_STATUS_FIELD:
        case REPORTS_DEMAND_STATUS_FIELD:
          return (
            <WizardSelectWithValidation
              control={control}
              isMulti={false}
              error={handleArrayHaveError(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_VALUE_FIELD, index)}
              errorMessage={handleArrayErrorMessage(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_VALUE_FIELD, index)}
              name={`${REPORTS_DIMENSION_FILTERS_FIELD}[${index}][${REPORTS_VALUE_FIELD}]`}
              options={reportsStatusList}
              selectType={SELECT_TYPE}
              label=''
              defaultValue={defaultValue}
            />
          );
      }
    }
  }

  return (
    <WizardSelectWithValidation
      control={control}
      isMulti={false}
      error={handleArrayHaveError(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_VALUE_FIELD, index)}
      errorMessage={handleArrayErrorMessage(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_VALUE_FIELD, index)}
      name={`${REPORTS_DIMENSION_FILTERS_FIELD}[${index}][${REPORTS_VALUE_FIELD}]`}
      options={[]}
      selectType={SELECT_TYPE}
      label=''
      defaultValue={defaultValue}
    />
  );
};

const getCSVFileName = (title) => {
  const additionalName = title
    ? '_' + title.trim().toLowerCase().replace(/\s/g, '_')
    : '';

  return `digital_flare_report${additionalName}.csv`;
};

const ReportsWizard = () => {
  const dispatch = useDispatch();

  const {
    defaultValues,
    staticList,
    isOpen
  } = useSelector(
    state => ({
      defaultValues: state.reports.formData,
      staticList: state.staticList,
      isOpen: state.drawerForms.isOpen
    })
  );

  const [interval, setInterval] = useState(defaultValues.interval);
  const [fromDate, setFromDate] = useState(defaultValues.dateRange.from);
  const [toDate, setToDate] = useState(defaultValues.dateRange.to);

  const [loadingCSV, setLoadingCSV] = useState(false);
  const [reports, setReports] = useState([]);
  const [showCSV, setShowCSV] = useState(false);
  const csvRef = useRef(null);

  const {
    control,
    getValues,
    setValue,
    watch,
    errors,
    clearError,
    register,
    reset,
    triggerValidation
  } = useForm({
    mode: 'onChange',
    defaultValues,
    validationSchema: reportsSchema
  });

  const handleSetInterval = useCallback((interval) => {
    setValue(DASHBOARD_COUNTRIES_FIELD, { label: 'None', value: 'none' });
    setInterval(interval);
  }, []);

  const filterDimensionsArray = useFieldArray({
    control,
    name: REPORTS_DIMENSION_FILTERS_FIELD
  });

  const filterDimensions = filterDimensionsArray.fields;
  const appendFilterDimensions = filterDimensionsArray.append;
  const removeFilterDimension = filterDimensionsArray.remove;

  const filterMetricsArray = useFieldArray(({
    control,
    name: REPORTS_METRIC_FILTERS_FIELD
  }));

  const filterMetrics = filterMetricsArray.fields;
  const appendFilterMetrics = filterMetricsArray.append;
  const removeFilterMetrics = filterMetricsArray.remove;

  const [dimensionsOptions, setDimensionsOptions] = useState(filterDimensionsList);
  const [metricsOptions, setMetricsOptions] = useState(metricsList);

  const watchFilterDimensions = watch(REPORTS_DIMENSION_FILTERS_FIELD, defaultValues[REPORTS_DIMENSION_FILTERS_FIELD]);
  const watchFilterMetrics = watch(REPORTS_METRIC_FILTERS_FIELD, defaultValues[REPORTS_METRIC_FILTERS_FIELD]);

  useFormsOptions(getOptions(watchFilterDimensions || []), filterDimensionsList, setDimensionsOptions, REPORTS_KEY_FIELD, false);
  useFormsOptions(getOptions(watchFilterMetrics || []), metricsList, setMetricsOptions, REPORTS_KEY_FIELD, false);

  useClearArrayError(REPORTS_DIMENSION_FILTERS_FIELD, watchFilterDimensions, clearError);
  useClearArrayError(REPORTS_METRIC_FILTERS_FIELD, watchFilterMetrics, clearError);

  const handleDownloadCSV = async () => {
    setLoadingCSV(true);

    const isValid = await triggerValidation();

    if (isValid) {
      const values = getValues({ nest: true });

      values[DASHBOARD_INTERVAL] = interval;
      values[REPORTS_DATE_RANGE_FIELD] = {
        from: fromDate,
        to: toDate
      };

      if (!values[REPORTS_DIMENSION_FILTERS_FIELD]) {
        values[REPORTS_DIMENSION_FILTERS_FIELD] = [];
      }

      if (!values[REPORTS_METRIC_FILTERS_FIELD]) {
        values[REPORTS_METRIC_FILTERS_FIELD] = [];
      }

      const reports = await fetchReportCSVDataAction(values);

      if (reports) {
        generateReportAction(values)(dispatch);
        setReports(reports);
        setShowCSV(true);

        if (csvRef.current) {
          csvRef.current.link.click();
        }

        setLoadingCSV(false);
        setShowCSV(false);
        hideCurrentForm()(dispatch);
      } else {
        setReports([]);
        setShowCSV(false);
        setLoadingCSV(false);
      }
    } else {
      setReports([]);
      setShowCSV(false);
      setLoadingCSV(false);
    }
  };

  const handleGenerateReport = useCallback(async () => {
    const isValid = await triggerValidation();

    if (isValid) {
      const values = getValues({ nest: true });

      values[DASHBOARD_INTERVAL] = interval;
      values[REPORTS_DATE_RANGE_FIELD] = {
        from: fromDate,
        to: toDate
      };

      if (!values[REPORTS_DIMENSION_FILTERS_FIELD]) {
        values[REPORTS_DIMENSION_FILTERS_FIELD] = [];
      }

      if (!values[REPORTS_METRIC_FILTERS_FIELD]) {
        values[REPORTS_METRIC_FILTERS_FIELD] = [];
      }

      generateReportAction(values)(dispatch);
      hideCurrentForm()(dispatch);
    }
  }, [interval, fromDate, toDate]);

  const handleReset = useCallback(() => {
    setInterval(DASHBOARD_INTERVAL_YESTERDAY);
    getInitialReportFormDataStateAction()(dispatch);
    reset(initialReportsFormDataState);
  }, []);

  // Title input
  const [title, setTitle] = useState('');
  const [isFocusedTitle, setIsFocusedTitle] = useState(false);
  const [onFocusTitle, setOnFocusTitle] = useState(false);

  const handleBlur = useCallback(() => setOnFocusTitle(false), []);
  const handleFocus = useCallback(() => {
    if (!isFocusedTitle) {
      setIsFocusedTitle(true);
    }

    setOnFocusTitle(true);
  }, [isFocusedTitle]);

  const onChangeTitle = useCallback((e) => setTitle(e.target.value), []);

  const handleOnSave = useCallback(async () => {
    const isValid = await triggerValidation();

    if (!title && !isFocusedTitle) {
      setIsFocusedTitle(true);
    }

    if (isValid && title) {
      const values = getValues({ nest: true });

      values[DASHBOARD_INTERVAL] = interval;

      if (!values[REPORTS_DIMENSION_FILTERS_FIELD]) {
        values[REPORTS_DIMENSION_FILTERS_FIELD] = [];
      }

      if (!values[REPORTS_METRIC_FILTERS_FIELD]) {
        values[REPORTS_METRIC_FILTERS_FIELD] = [];
      }

      const success = await createReportAction(values, title)(dispatch);

      if (success) {
        hideCurrentForm()(dispatch);
      }
    }
  }, [title, interval, isFocusedTitle]);

  return (
    <Modal
      appElement={document.getElementById('modal-wrapper')}
      style={{
        overlay: {
          zIndex: 9998
        },
        content: {
          padding: 0,
          top: 80,
          bottom: 80,
          left: 80,
          right: 80
        }
      }}
      isOpen={isOpen}
    >
      <div
        className="reports-wizard panel panel-create drawer-form-lg panel-show"
      >
        <div className="panel-title">
          <h4>Insights Generator</h4>
          <p className="btn-close">
            <span onClick={() => {
              hideCurrentForm()(dispatch);
            }}/>
          </p>
        </div>

        <div className="reports-wizard-container panel-content">
          <div className="reports-wizard-form-content">
            <ReportsDatePicker
              interval={interval}
              setInterval={handleSetInterval}
              fromDate={fromDate}
              setFromDate={setFromDate}
              setToDate={setToDate}
              toDate={toDate}
            />

            <WizardSelectWithValidation
              control={control}
              isMulti={false}
              error={!!errors[DASHBOARD_TIMEZONE_FIELD]}
              errorMessage={errors[DASHBOARD_TIMEZONE_FIELD] ? errors[DASHBOARD_TIMEZONE_FIELD].message : ''}
              name={DASHBOARD_TIMEZONE_FIELD}
              options={timezonesOptions}
              selectType={SELECT_TYPE}
              label='By Timezone'
              defaultValue={defaultValues[DASHBOARD_TIMEZONE_FIELD]}
            />

            <WizardSelectWithValidation
              control={control}
              error={false}
              isMulti={false}
              errorMessage={''}
              name={REPORTS_BREAKDOWN_FIELD}
              options={getBreakdownList(interval)}
              selectType={SELECT_TYPE}
              label='Break by'
              defaultValue={defaultValues[REPORTS_BREAKDOWN_FIELD]}
            />

            <WizardSelectWithValidation
              control={control}
              error={false}
              isMulti={true}
              errorMessage={''}
              name={REPORTS_DIMENSIONS_FIELD}
              options={dimensionsList}
              selectType={SELECT_TYPE}
              label='Dimensions'
              defaultValue={defaultValues[REPORTS_DIMENSIONS_FIELD]}
            />

            <WizardSelectWithValidation
              control={control}
              error={!!errors[REPORTS_METRICS_FIELD]}
              errorMessage={errors[REPORTS_METRICS_FIELD] ? errors[REPORTS_METRICS_FIELD].message : ''}
              isMulti={true}
              name={REPORTS_METRICS_FIELD}
              options={metricsList}
              selectType={SELECT_TYPE}
              label='Metrics'
              defaultValue={defaultValues[REPORTS_METRICS_FIELD]}
            />

            <div className="group action-right filters-box">
              <div className="header-width-add">
                <h4>Filter Dimensions</h4>

                <div className="add-new">
                  <p onClick={() => appendFilterDimensions({
                    [REPORTS_KEY_FIELD]: null,
                    [REPORTS_OPERATION_FIELD]: {
                      label: 'is in',
                      value: 'is_in'
                    },
                    [REPORTS_VALUE_FIELD]: null
                  })}>
                    <i className="fas fa-plus"/>
                    <span className="add-item">Add new</span>
                  </p>
                </div>
              </div>

              <div className="group-item">
                <div className="form-group">
                  <div className="row-list">
                    {filterDimensions.map((dimension, index) => (
                      <div className="row-item" key={dimension.id}>
                        <div className="row">
                          <div className="col-sm-4">
                            <WizardSelectWithValidation
                              control={control}
                              isMulti={false}
                              error={handleArrayHaveError(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_KEY_FIELD, index)}
                              errorMessage={handleArrayErrorMessage(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_KEY_FIELD, index)}
                              name={`${REPORTS_DIMENSION_FILTERS_FIELD}[${index}][${REPORTS_KEY_FIELD}]`}
                              options={dimensionsOptions}
                              selectType={SELECT_TYPE}
                              label=''
                              defaultValue={dimension[REPORTS_KEY_FIELD]}
                              onChange={handleFilterDimensionsChange(index, setValue)}
                            />
                          </div>
                          <div className="col-sm-2">
                            <WizardSelectWithValidation
                              control={control}
                              isMulti={false}
                              error={handleArrayHaveError(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_OPERATION_FIELD, index)}
                              errorMessage={handleArrayErrorMessage(errors, REPORTS_DIMENSION_FILTERS_FIELD, REPORTS_OPERATION_FIELD, index)}
                              name={`${REPORTS_DIMENSION_FILTERS_FIELD}[${index}][${REPORTS_OPERATION_FIELD}]`}
                              options={getFilterDimensionsOperatorOptions(watchFilterDimensions, index)}
                              selectType={SELECT_TYPE}
                              label=''
                              defaultValue={dimension[REPORTS_OPERATION_FIELD]}
                            />
                          </div>
                          <div className="col-sm-6">
                            {renderDimensionsValueField(
                              watchFilterDimensions,
                              index,
                              dimension[REPORTS_VALUE_FIELD],
                              control,
                              errors,
                              staticList
                            )}
                          </div>
                        </div>
                        <span
                          className="action-icon delete-item"
                          onClick={() => removeFilterDimension(index)}
                        >
                          <i className="far fa-trash-alt"/>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="group action-right filters-box last-filter-box">
              <div className="header-width-add">
                <h4>Filter Metrics</h4>

                <div className="add-new">
                  <p onClick={() => appendFilterMetrics({
                    [REPORTS_KEY_FIELD]: null,
                    [REPORTS_OPERATION_FIELD]: {
                      label: '>',
                      value: 'gt'
                    },
                    [REPORTS_VALUE_FIELD]: 0
                  })}>
                    <i className="fas fa-plus"/>
                    <span className="add-item">Add new</span>
                  </p>
                </div>
              </div>

              <div className="group-item">
                <div className="form-group">
                  <div className="row-list">
                    {filterMetrics.map((metric, index) => (
                      <div className="row-item" key={metric.id}>
                        <div className="row">
                          <div className="col-sm-4">
                            <WizardSelectWithValidation
                              control={control}
                              isMulti={false}
                              error={handleArrayHaveError(errors, REPORTS_METRIC_FILTERS_FIELD, REPORTS_KEY_FIELD, index)}
                              errorMessage={handleArrayErrorMessage(errors, REPORTS_METRIC_FILTERS_FIELD, REPORTS_KEY_FIELD, index)}
                              name={`${REPORTS_METRIC_FILTERS_FIELD}[${index}][${REPORTS_KEY_FIELD}]`}
                              options={metricsOptions}
                              selectType={SELECT_TYPE}
                              label=''
                              defaultValue={metric[REPORTS_KEY_FIELD]}
                            />
                          </div>
                          <div className="col-sm-2">
                            <WizardSelectWithValidation
                              control={control}
                              isMulti={false}
                              error={handleArrayHaveError(errors, REPORTS_METRIC_FILTERS_FIELD, REPORTS_OPERATION_FIELD, index)}
                              errorMessage={handleArrayErrorMessage(errors, REPORTS_METRIC_FILTERS_FIELD, REPORTS_OPERATION_FIELD, index)}
                              name={`${REPORTS_METRIC_FILTERS_FIELD}[${index}][${REPORTS_OPERATION_FIELD}]`}
                              options={operationsList}
                              selectType={SELECT_TYPE}
                              label=''
                              defaultValue={metric[REPORTS_OPERATION_FIELD]}
                            />
                          </div>
                          <div className="col-sm-6 filter-metrics-value">
                            <WizardInputWithValidation
                              type='number'
                              error={handleArrayHaveError(errors, REPORTS_METRIC_FILTERS_FIELD, REPORTS_VALUE_FIELD, index)}
                              errorMessage={handleArrayErrorMessage(errors, REPORTS_METRIC_FILTERS_FIELD, REPORTS_VALUE_FIELD, index)}
                              name={`${REPORTS_METRIC_FILTERS_FIELD}[${index}][${REPORTS_VALUE_FIELD}]`}
                              propRef={register()}
                              placeholder='value'
                              defaultValue={metric[REPORTS_VALUE_FIELD]}
                            />
                          </div>
                        </div>
                        <span
                          className="action-icon delete-item"
                          onClick={() => removeFilterMetrics(index)}
                        >
                          <i className="far fa-trash-alt"/>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="panel-footer">
          <button onClick={handleGenerateReport} className="btn btn-dark-violet">Generate report</button>
          <button onClick={handleReset} className="second-btn btn btn-dark-violet">Reset</button>

          <button
            className="second-btn btn btn-dark-violet"
            onClick={handleDownloadCSV}
            disabled={loadingCSV}
          >
            Download csv
          </button>

          {showCSV ? <CSVLink
            ref={csvRef}
            className="second-btn btn btn-dark-violet hidden-CSV"
            data={reports}
            filename={getCSVFileName(title)}
            onClick={() => {
              hideCurrentForm()(dispatch);
            }}
          >
            Download csv
          </CSVLink> : null}

          {interval !== DASHBOARD_INTERVAL_CUSTOM ? (
            <div className="reports-wizard-save">
              <Popover
                title='Title is required'
                open={onFocusTitle && !title}
              >
                <div className="reports-wizard-save-input-box">
                  <input
                    className={isFocusedTitle && !title ? 'error' : ''}
                    type="type"
                    name="title"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={onChangeTitle}
                    value={title}
                  />

                  {(isFocusedTitle && !title && !onFocusTitle) &&
                  <FontAwesomeIcon className="field-error-icon" icon={faExclamationCircle}/>}
                </div>
              </Popover>

              <button onClick={handleOnSave} className="btn btn-dark-violet">Save As Quick Report</button>
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default ReportsWizard;
