import * as yup from 'yup';

import {
  DEMAND_STATS_SETUP_FIELD,
  DEMAND_FEED_SETUP_FIELD,
  DEMAND_INTEGRATION_FIELD,
  VALUE_FIELD,
  DEMAND_FIELDS_FIELD,
  DEMAND_NAME_FIELD
} from '../../constants';

import { selectSchema } from '../shared/shared-schemas';

const fieldsSchema = yup.object().shape({
  [DEMAND_NAME_FIELD]: yup.string().required('Field name is required'),
  [VALUE_FIELD]: yup.string().required('Field value is required')
});

const setupSchema = yup.object().shape({
  [DEMAND_INTEGRATION_FIELD]: selectSchema.required('Integration is required').nullable(),
  [DEMAND_FIELDS_FIELD]: yup.array().of(fieldsSchema).required('At least 1 element is required').min(1, 'At least 1 element is required')
});

export const setupDataSchema = yup.object().shape({
  [DEMAND_FEED_SETUP_FIELD]: setupSchema,
  [DEMAND_STATS_SETUP_FIELD]: setupSchema
});

export const validateSetupData = (data) => {
  try {
    setupDataSchema.validateSync(data);

    return true;
  } catch (e) {
    return false;
  }
};
