import { useEffect } from 'react';

export const useClearArrayError = (
  field,
  data,
  clearError,
  nestedField
) => {
  useEffect(() => {
    if (nestedField) {
      clearError(`field[${nestedField}]`);
    } else {
      clearError(field);
    }
  }, [JSON.stringify(data)]);
};
