export const proxiesOptions = [
  {
    label: 'Allow',
    value: 'allow'
  },
  {
    label: 'Block',
    value: 'block'
  },
  {
    label: 'DHC',
    value: 'dhc'
  },
  {
    label: 'VPN',
    value: 'vpn'
  }
];

export const deviceTypesOptions = [
  {
    label: 'All',
    value: '*'
  },
  {
    label: 'Desktop',
    value: 'desktop'
  },
  {
    label: 'Mobile',
    value: 'mobile'
  },
  {
    label: 'Tablet',
    value: 'tablet'
  },
  {
    label: 'Smartty',
    value: 'smartty'
  },
  {
    label: 'Console',
    value: 'console'
  },
  {
    label: 'Wearable',
    value: 'wearable'
  },
  {
    label: 'Other',
    value: 'other'
  }
];

export const renderOutputOptions = [
  {
    label: 'JSON',
    value: 'json'
  }
];

export const redirectTypeOptions = [
  {
    label: 'Default',
    value: 'default'
  },
  {
    label: '302',
    value: '302'
  },
  {
    label: 'Meta Redirect(no-ref) /10% IVT Scan ',
    value: 'meta_pm10'
  },
  {
    label: 'Meta Redirect(no-ref) /25% IVT Scan',
    value: 'meta_pm25'
  },
  {
    label: 'Meta Redirect(no-ref) /50% IVT Scan',
    value: 'meta_pm50'
  },
  {
    label: 'ProtectMedia No-Filter (no-ref)',
    value: 'pmnf'
  },
  {
    label: 'ProtectMedia No-Filter (orig-ref)',
    value: 'pmnf_origref'
  },
  {
    label: 'ProtectMedia (no-ref)',
    value: 'pm'
  },
  {
    label: 'ProtectMedia (orig-ref)',
    value: 'pm_origref'
  },
  {
    label: 'P2P/CSS',
    value: 'p2sp'
  },
  {
    label: 'P2P/BG',
    value: 'p2bgp'
  },
  {
    label: 'P2I/CSS',
    value: 'p2si'
  }
];

export const segmentationEncOptions = [
  {
    label: 'Default (UUID hash)',
    value: 'orig_subid'
  },
  {
    label: 'Text Cypher (use _)',
    value: 'text_cypher'
  },
  {
    label: 'Text Cypher (no _)',
    value: 'text_cypher2'
  }
];


export const segmentationOptions = [
  {
    label: 'SubId (Default)',
    value: 'orig_subid'
  },
  {
    label: 'Endpoint',
    value: 'epid'
  },
  {
    label: 'Country',
    value: 'geo'
  },
  {
    label: 'Country & SubId',
    value: 'geo-orig_subid'
  },
  {
    label: 'Partial Orig-SubId',
    value: 'partial_orig_subid'
  },
  {
    label: 'Partial Orig-SubId (deep 2)',
    value: 'partial_orig_subid2'
  }
];
