import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getInitialEndpointDemandsStateAction, updateEndpointDemandsAction } from '../../../../actions/endpointDemands';

import {
  OPTIMIZATION_FORM,
  PROTECTION_FORM,
  TARGETING_FORM,
  ENDPOINT_DEMANDS_WIZARD
} from '../../constants';

import { validateEndpointDemandsFormData } from '../../validators/endpoint-demands';

import Wizard from '../../wizard-base/Wizard';

import EndpointDemandsOptimizationForm from './forms/EndpointDemandsOptimizationForm';
import EndpointDemandsProtectionForm from './forms/EndpointDemandsProtectionForm';
import EndpointDemandsTargetingForm from './forms/EndpointDemandsTargeting';

const EndpointDemandsWizard = () => {
  const {
    id,
    staticList
  } = useSelector(
    state => ({
      id: state[ENDPOINT_DEMANDS_WIZARD].formData._id,
      staticList: state.staticList
    })
  );

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const error = await updateEndpointDemandsAction(staticList)(dispatch);

    if (error) {
      // TODO Handle error
      return false;
    }

    return true;
  };

  const stepsForms = [
    {
      label: OPTIMIZATION_FORM,
      component: EndpointDemandsOptimizationForm
    },
    {
      label: TARGETING_FORM,
      component: EndpointDemandsTargetingForm
    },
    {
      label: PROTECTION_FORM,
      component: EndpointDemandsProtectionForm
    }
  ];

  return (
    <Wizard
      clearFormState={getInitialEndpointDemandsStateAction}
      stepsForms={stepsForms}
      handleSubmit={handleSubmit}
      updateTitle={() => null}
      title=''
      titlePlaceholder=''
      id={id}
      validateForms={validateEndpointDemandsFormData}
      withoutTitle={true}
      wizardName={`${id ? 'Update' : 'New'} Endpoint Demand`}
    />
  );
};

export default EndpointDemandsWizard;
